/* You can add global styles to this file, and also import other style files */
@font-face {
  font-family: 'Open Sans';
  src: url('./assets/zsui/src/core/OpenSans-Light.woff') format('woff'), url('./assets/zsui/src/core/OpenSans-Light.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Open Sans';
  src: url('./assets/zsui/src/core/OpenSans-Light-Italic.woff') format('woff'), url('./assets/zsui/src/core/OpenSans-Light-Italic.woff2') format('woff2');
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: 'Open Sans';
  src: url('./assets/zsui/src/core/OpenSans-Regular.woff') format('woff'), url('./assets/zsui/src/core/OpenSans-Regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Open Sans';
  src: url('./assets/zsui/src/core/OpenSans-Italic.woff') format('woff'), url('./assets/zsui/src/core/OpenSans-Italic.woff2') format('woff2');
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: 'Open Sans';
  src: url('./assets/zsui/src/core/OpenSans-SemiBold.woff') format('woff'), url('./assets/zsui/src/core/OpenSans-SemiBold.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'Open Sans';
  src: url('./assets/zsui/src/core/OpenSans-SemiBold-Italic.woff') format('woff'), url('./assets/zsui/src/core/OpenSans-SemiBold-Italic.woff2') format('woff2');
  font-weight: 600;
  font-style: italic;
}
@font-face {
  font-family: 'Open Sans';
  src: url('./assets/zsui/src/core/OpenSans-Bold.woff') format('woff'), url('./assets/zsui/src/core/OpenSans-Bold.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'Open Sans';
  src: url('./assets/zsui/src/core/OpenSans-Bold-Italic.woff') format('woff'), url('./assets/zsui/src/core/OpenSans-Bold-Italic.woff2') format('woff2');
  font-weight: 700;
  font-style: italic;
}
.zs-master-style *[disabled],
.zs-master-style *.zs-disabled,
.zs-master-style a.zs-disabled,
.zs-master-style a[disabled],
.zs-master-style button[disabled],
.zs-master-style button.zs-disabled,
.zs-master-style *.zs-calendar > table td[disabled],
.zs-master-style a.zs-calendar > table td[disabled],
.zs-master-style button.zs-calendar > table td[disabled],
.zs-master-style *.zs-range-calendar > table td[disabled],
.zs-master-style a.zs-range-calendar > table td[disabled],
.zs-master-style button.zs-range-calendar > table td[disabled],
.zs-master-style *.zs-range-calendar > section > table td[disabled],
.zs-master-style a.zs-range-calendar > section > table td[disabled],
.zs-master-style button.zs-range-calendar > section > table td[disabled] {
  cursor: pointer;
  pointer-events: none;
  opacity: 1;
}
.zs-master-style .zs-danger-text {
  color: #B21111;
}
.zs-master-style .zs-danger-text:visited {
  color: #B21111;
}
.zs-master-style .zs-danger-text:focus {
  color: #892208;
}
.zs-master-style .zs-danger-text:hover {
  color: #F27755;
}
.zs-master-style .zs-danger-text:active {
  color: #5C1A0B;
}
.zs-master-style .zs-danger-border {
  border: 1px solid #B21111;
}
.zs-master-style .zs-danger-border:focus {
  border: 1px solid #892208;
}
.zs-master-style .zs-danger-border:hover {
  border: 1px solid #F27755;
}
.zs-master-style .zs-danger-border:active {
  border: 1px solid #5C1A0B;
}
.zs-master-style .zs-danger-background {
  background: #B21111;
}
.zs-master-style .zs-danger-background:focus {
  background: #892208;
}
.zs-master-style .zs-danger-background:hover {
  background: #F27755;
}
.zs-master-style .zs-danger-background:active {
  background: #5C1A0B;
}
.zs-master-style .zs-success-text {
  color: #00AA67;
}
.zs-master-style .zs-success-text:visited {
  color: #00AA67;
}
.zs-master-style .zs-success-text:focus {
  color: #0A6E5E;
}
.zs-master-style .zs-success-text:hover {
  color: #54D7AF;
}
.zs-master-style .zs-success-text:active {
  color: #0C4846;
}
.zs-master-style .zs-success-border {
  border: 1px solid #00AA67;
}
.zs-master-style .zs-success-border:focus {
  border: 1px solid #0A6E5E;
}
.zs-master-style .zs-success-border:hover {
  border: 1px solid #54D7AF;
}
.zs-master-style .zs-success-border:active {
  border: 1px solid #0C4846;
}
.zs-master-style .zs-success-background {
  background: #00AA67;
}
.zs-master-style .zs-success-background:focus {
  background: #0A6E5E;
}
.zs-master-style .zs-success-background:hover {
  background: #54D7AF;
}
.zs-master-style .zs-success-background:active {
  background: #0C4846;
}
.zs-master-style .zs-warning-text {
  color: #E3A900;
}
.zs-master-style .zs-warning-text:visited {
  color: #E3A900;
}
.zs-master-style .zs-warning-text:focus {
  color: #8A640C;
}
.zs-master-style .zs-warning-text:hover {
  color: #FDCA63;
}
.zs-master-style .zs-warning-text:active {
  color: #563F0E;
}
.zs-master-style .zs-warning-border {
  border: 1px solid #E3A900;
}
.zs-master-style .zs-warning-border:focus {
  border: 1px solid #8A640C;
}
.zs-master-style .zs-warning-border:hover {
  border: 1px solid #FDCA63;
}
.zs-master-style .zs-warning-border:active {
  border: 1px solid #563F0E;
}
.zs-master-style .zs-warning-background {
  background: #E3A900;
}
.zs-master-style .zs-warning-background:focus {
  background: #8A640C;
}
.zs-master-style .zs-warning-background:hover {
  background: #FDCA63;
}
.zs-master-style .zs-warning-background:active {
  background: #563F0E;
}
.zs-master-style .zs-info-text {
  color: #2B44C7;
}
.zs-master-style .zs-info-text:visited {
  color: #2B44C7;
}
.zs-master-style .zs-info-text:focus {
  color: #141187;
}
.zs-master-style .zs-info-text:hover {
  color: #7E92E9;
}
.zs-master-style .zs-info-text:active {
  color: #160C60;
}
.zs-master-style .zs-info-border {
  border: 1px solid #2B44C7;
}
.zs-master-style .zs-info-border:focus {
  border: 1px solid #141187;
}
.zs-master-style .zs-info-border:hover {
  border: 1px solid #7E92E9;
}
.zs-master-style .zs-info-border:active {
  border: 1px solid #160C60;
}
.zs-master-style .zs-info-background {
  background: #2B44C7;
}
.zs-master-style .zs-info-background:focus {
  background: #141187;
}
.zs-master-style .zs-info-background:hover {
  background: #7E92E9;
}
.zs-master-style .zs-info-background:active {
  background: #160C60;
}
.zs-icon + .zs-icon {
  margin: 0em 0em 0em 0.125em;
}
@font-face {
  font-family: 'zsIcons';
  src: url('./assets/zsui/src/core/zsIcons.woff2?v5.1.0') format('woff2'), url('./assets/zsui/src/core/zsIcons.woff?v5.1.0') format('woff');
  font-weight: normal;
  font-style: normal;
}
.zs-master-style .zs-icon {
  font-family: 'zsIcons';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Enable Ligatures ================ */
  -webkit-font-feature-settings: "liga";
  -moz-font-feature-settings: "liga=1";
  -moz-font-feature-settings: "liga";
  -ms-font-feature-settings: "liga" 1;
  -o-font-feature-settings: "liga";
  font-feature-settings: "liga";
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  display: inline-block;
  vertical-align: middle;
}
.zs-master-style .zs-interactive-primary,
.zs-master-style .zs-interactive-secondary {
  display: inline-block;
}
.zs-master-style .zs-interactive-primary .zs-icon,
.zs-master-style .zs-interactive-secondary .zs-icon {
  pointer-events: none;
}
.zs-master-style a:hover > .zs-icon::before,
.zs-master-style a:hover > .zs-icon::after {
  color: inherit;
}
.zs-master-style a:hover > .zs-icon:before,
.zs-master-style a > .zs-icon:before {
  margin-right: 0;
}
.zs-master-style a.zs-icon:before {
  cursor: pointer;
}
.zs-master-style a.zs-icon,
.zs-master-style a.zs-icon:hover {
  cursor: pointer;
}
.zs-master-style .zs-icon-add::before {
  content: "\ea01";
}
.zs-master-style .zs-icon-add-circle::before {
  content: "\ea02";
}
.zs-master-style .zs-icon-add-circle-fill::before {
  content: "\ea03";
}
.zs-master-style .zs-icon-ampersand::before {
  content: "\ea04";
}
.zs-master-style .zs-icon-app-nav::before {
  content: "\ea05";
}
.zs-master-style .zs-icon-arrow-down::before {
  content: "\ea06";
}
.zs-master-style .zs-icon-arrow-down-circle::before {
  content: "\ea07";
}
.zs-master-style .zs-icon-arrow-down-circle-fill::before {
  content: "\ea08";
}
.zs-master-style .zs-icon-arrow-first::before {
  content: "\ea09";
}
.zs-master-style .zs-icon-arrow-first-fill::before {
  content: "\ea0a";
}
.zs-master-style .zs-icon-arrow-last::before {
  content: "\ea0b";
}
.zs-master-style .zs-icon-arrow-last-fill::before {
  content: "\ea0c";
}
.zs-master-style .zs-icon-arrow-left::before {
  content: "\ea0d";
}
.zs-master-style .zs-icon-arrow-next-circle::before {
  content: "\ea0e";
}
.zs-master-style .zs-icon-arrow-next-circle-fill::before {
  content: "\ea0f";
}
.zs-master-style .zs-icon-arrow-prev-circle::before {
  content: "\ea10";
}
.zs-master-style .zs-icon-arrow-prev-circle-fill::before {
  content: "\ea11";
}
.zs-master-style .zs-icon-arrow-redo::before {
  content: "\ea12";
}
.zs-master-style .zs-icon-arrow-reply::before {
  content: "\ea13";
}
.zs-master-style .zs-icon-arrow-reply-fill::before {
  content: "\ea14";
}
.zs-master-style .zs-icon-arrow-return::before {
  content: "\ea15";
}
.zs-master-style .zs-icon-arrow-revise::before {
  content: "\ea16";
}
.zs-master-style .zs-icon-arrow-right::before {
  content: "\ea17";
}
.zs-master-style .zs-icon-arrow-send::before {
  content: "\ea18";
}
.zs-master-style .zs-icon-arrow-up::before {
  content: "\ea19";
}
.zs-master-style .zs-icon-arrow-up-circle::before {
  content: "\ea1a";
}
.zs-master-style .zs-icon-arrow-up-circle-fill::before {
  content: "\ea1b";
}
.zs-master-style .zs-icon-arrow-up-down::before {
  content: "\ea1c";
}
.zs-master-style .zs-icon-bookmark::before {
  content: "\ea1d";
}
.zs-master-style .zs-icon-bookmark-fill::before {
  content: "\ea1e";
}
.zs-master-style .zs-icon-bullet::before {
  content: "\ea1f";
}
.zs-master-style .zs-icon-bullet-left::before {
  content: "\ea20";
}
.zs-master-style .zs-icon-business-rule::before {
  content: "\ea21";
}
.zs-master-style .zs-icon-calendar::before {
  content: "\ea22";
}
.zs-master-style .zs-icon-carat-down::before {
  content: "\ea23";
}
.zs-master-style .zs-icon-carat-left::before {
  content: "\ea24";
}
.zs-master-style .zs-icon-carat-right::before {
  content: "\ea25";
}
.zs-master-style .zs-icon-carat-up::before {
  content: "\ea26";
}
.zs-master-style .zs-icon-card::before {
  content: "\ea27";
}
.zs-master-style .zs-icon-card-flip::before {
  content: "\ea28";
}
.zs-master-style .zs-icon-check::before {
  content: "\ea29";
}
.zs-master-style .zs-icon-check-circle::before {
  content: "\ea2a";
}
.zs-master-style .zs-icon-check-circle-fill::before {
  content: "\ea2b";
}
.zs-master-style .zs-icon-circle-fill::before {
  content: "\ea2c";
}
.zs-master-style .zs-icon-circle-outline::before {
  content: "\ea2d";
}
.zs-master-style .zs-icon-clock-pending::before {
  content: "\ea2e";
}
.zs-master-style .zs-icon-close::before {
  content: "\ea2f";
}
.zs-master-style .zs-icon-close-circle::before {
  content: "\ea30";
}
.zs-master-style .zs-icon-close-circle-fill::before {
  content: "\ea31";
}
.zs-master-style .zs-icon-delete::before {
  content: "\ea32";
}
.zs-master-style .zs-icon-delete-fill::before {
  content: "\ea33";
}
.zs-master-style .zs-icon-dislike::before {
  content: "\ea34";
}
.zs-master-style .zs-icon-dislike-fill::before {
  content: "\ea35";
}
.zs-master-style .zs-icon-divide::before {
  content: "\ea36";
}
.zs-master-style .zs-icon-download::before {
  content: "\ea37";
}
.zs-master-style .zs-icon-edit::before {
  content: "\ea38";
}
.zs-master-style .zs-icon-edit-fill::before {
  content: "\ea39";
}
.zs-master-style .zs-icon-eye-close::before {
  content: "\ea3a";
}
.zs-master-style .zs-icon-eye-open::before {
  content: "\ea3b";
}
.zs-master-style .zs-icon-filter::before {
  content: "\ea3c";
}
.zs-master-style .zs-icon-flag::before {
  content: "\ea3d";
}
.zs-master-style .zs-icon-flag-fill::before {
  content: "\ea3e";
}
.zs-master-style .zs-icon-font-size::before {
  content: "\ea3f";
}
.zs-master-style .zs-icon-item-drag::before {
  content: "\ea40";
}
.zs-master-style .zs-icon-item-group::before {
  content: "\ea41";
}
.zs-master-style .zs-icon-item-ungroup::before {
  content: "\ea42";
}
.zs-master-style .zs-icon-labels::before {
  content: "\ea43";
}
.zs-master-style .zs-icon-labels-rotate::before {
  content: "\ea44";
}
.zs-master-style .zs-icon-layers::before {
  content: "\ea45";
}
.zs-master-style .zs-icon-layers-fill::before {
  content: "\ea46";
}
.zs-master-style .zs-icon-like::before {
  content: "\ea47";
}
.zs-master-style .zs-icon-like-fill::before {
  content: "\ea48";
}
.zs-master-style .zs-icon-link::before {
  content: "\ea49";
}
.zs-master-style .zs-icon-loader::before {
  content: "\ea4a";
}
.zs-master-style .zs-icon-lock::before {
  content: "\ea4b";
}
.zs-master-style .zs-icon-lock-fill::before {
  content: "\ea4c";
}
.zs-master-style .zs-icon-lock-unlock::before {
  content: "\ea4d";
}
.zs-master-style .zs-icon-lock-unlock-fill::before {
  content: "\ea4e";
}
.zs-master-style .zs-icon-login::before {
  content: "\ea4f";
}
.zs-master-style .zs-icon-logout::before {
  content: "\ea50";
}
.zs-master-style .zs-icon-mail::before {
  content: "\ea51";
}
.zs-master-style .zs-icon-mail-fill::before {
  content: "\ea52";
}
.zs-master-style .zs-icon-mobile-nav::before {
  content: "\ea53";
}
.zs-master-style .zs-icon-more::before {
  content: "\ea54";
}
.zs-master-style .zs-icon-multiply::before {
  content: "\ea55";
}
.zs-master-style .zs-icon-object-group::before {
  content: "\ea56";
}
.zs-master-style .zs-icon-palette::before {
  content: "\ea57";
}
.zs-master-style .zs-icon-palette-fill::before {
  content: "\ea58";
}
.zs-master-style .zs-icon-parens-left::before {
  content: "\ea59";
}
.zs-master-style .zs-icon-parens-right::before {
  content: "\ea5a";
}
.zs-master-style .zs-icon-pause::before {
  content: "\ea5b";
}
.zs-master-style .zs-icon-pause-fill::before {
  content: "\ea5c";
}
.zs-master-style .zs-icon-pin::before {
  content: "\ea5d";
}
.zs-master-style .zs-icon-pin-fill::before {
  content: "\ea5e";
}
.zs-master-style .zs-icon-pin-undo::before {
  content: "\ea5f";
}
.zs-master-style .zs-icon-pin-undo-fill::before {
  content: "\ea60";
}
.zs-master-style .zs-icon-play-circle::before {
  content: "\ea61";
}
.zs-master-style .zs-icon-play-circle-fill::before {
  content: "\ea62";
}
.zs-master-style .zs-icon-print::before {
  content: "\ea63";
}
.zs-master-style .zs-icon-refresh::before {
  content: "\ea64";
}
.zs-master-style .zs-icon-reminder::before {
  content: "\ea65";
}
.zs-master-style .zs-icon-save::before {
  content: "\ea66";
}
.zs-master-style .zs-icon-save-fill::before {
  content: "\ea67";
}
.zs-master-style .zs-icon-scissors::before {
  content: "\ea68";
}
.zs-master-style .zs-icon-search::before {
  content: "\ea69";
}
.zs-master-style .zs-icon-settings::before {
  content: "\ea6a";
}
.zs-master-style .zs-icon-settings-fill::before {
  content: "\ea6b";
}
.zs-master-style .zs-icon-sort::before {
  content: "\ea6c";
}
.zs-master-style .zs-icon-sort-ascend::before {
  content: "\ea6d";
}
.zs-master-style .zs-icon-sort-descend::before {
  content: "\ea6e";
}
.zs-master-style .zs-icon-star::before {
  content: "\ea6f";
}
.zs-master-style .zs-icon-star-fill::before {
  content: "\ea70";
}
.zs-master-style .zs-icon-stop-circle::before {
  content: "\ea71";
}
.zs-master-style .zs-icon-stop-circle-fill::before {
  content: "\ea72";
}
.zs-master-style .zs-icon-subtract::before {
  content: "\ea73";
}
.zs-master-style .zs-icon-subtract-circle::before {
  content: "\ea74";
}
.zs-master-style .zs-icon-subtract-circle-fill::before {
  content: "\ea75";
}
.zs-master-style .zs-icon-tag::before {
  content: "\ea76";
}
.zs-master-style .zs-icon-tag-fill::before {
  content: "\ea77";
}
.zs-master-style .zs-icon-upload::before {
  content: "\ea78";
}
.zs-master-style .zs-icon-upload-cloud::before {
  content: "\ea79";
}
.zs-master-style .zs-icon-upload-cloud-fill::before {
  content: "\ea7a";
}
.zs-master-style .zs-icon-video::before {
  content: "\ea7b";
}
.zs-master-style .zs-icon-video-fill::before {
  content: "\ea7c";
}
.zs-master-style .zs-icon-alarm::before {
  content: "\ea7d";
}
.zs-master-style .zs-icon-alarm-fill::before {
  content: "\ea7e";
}
.zs-master-style .zs-icon-announce::before {
  content: "\ea7f";
}
.zs-master-style .zs-icon-announce-fill::before {
  content: "\ea80";
}
.zs-master-style .zs-icon-error-circle::before {
  content: "\ea81";
}
.zs-master-style .zs-icon-error-circle-fill::before {
  content: "\ea82";
}
.zs-master-style .zs-icon-error-diamond::before {
  content: "\ea83";
}
.zs-master-style .zs-icon-error-diamond-fill::before {
  content: "\ea84";
}
.zs-master-style .zs-icon-error-square::before {
  content: "\ea85";
}
.zs-master-style .zs-icon-error-square-fill::before {
  content: "\ea86";
}
.zs-master-style .zs-icon-error-triangle::before {
  content: "\ea87";
}
.zs-master-style .zs-icon-error-triangle-fill::before {
  content: "\ea88";
}
.zs-master-style .zs-icon-notification::before {
  content: "\ea89";
}
.zs-master-style .zs-icon-notification-fill::before {
  content: "\ea8a";
}
.zs-master-style .zs-icon-at-line::before {
  content: "\ea8b";
}
.zs-master-style .zs-icon-briefcase::before {
  content: "\ea8c";
}
.zs-master-style .zs-icon-briefcase-fill::before {
  content: "\ea8d";
}
.zs-master-style .zs-icon-chat::before {
  content: "\ea8e";
}
.zs-master-style .zs-icon-chat-fill::before {
  content: "\ea8f";
}
.zs-master-style .zs-icon-field-unit::before {
  content: "\ea90";
}
.zs-master-style .zs-icon-field-unit-fill::before {
  content: "\ea91";
}
.zs-master-style .zs-icon-globe::before {
  content: "\ea92";
}
.zs-master-style .zs-icon-hcp::before {
  content: "\ea93";
}
.zs-master-style .zs-icon-help::before {
  content: "\ea94";
}
.zs-master-style .zs-icon-help-fill::before {
  content: "\ea95";
}
.zs-master-style .zs-icon-home-office::before {
  content: "\ea96";
}
.zs-master-style .zs-icon-home-office-fill::before {
  content: "\ea97";
}
.zs-master-style .zs-icon-hospital::before {
  content: "\ea98";
}
.zs-master-style .zs-icon-hospital-fill::before {
  content: "\ea99";
}
.zs-master-style .zs-icon-info::before {
  content: "\ea9a";
}
.zs-master-style .zs-icon-info-fill::before {
  content: "\ea9b";
}
.zs-master-style .zs-icon-local-team::before {
  content: "\ea9c";
}
.zs-master-style .zs-icon-local-team-fill::before {
  content: "\ea9d";
}
.zs-master-style .zs-icon-product::before {
  content: "\ea9e";
}
.zs-master-style .zs-icon-product-fill::before {
  content: "\ea9f";
}
.zs-master-style .zs-icon-rep-call::before {
  content: "\eaa0";
}
.zs-master-style .zs-icon-rep-call-fill::before {
  content: "\eaa1";
}
.zs-master-style .zs-icon-team::before {
  content: "\eaa2";
}
.zs-master-style .zs-icon-team-fill::before {
  content: "\eaa3";
}
.zs-master-style .zs-icon-user::before {
  content: "\eaa4";
}
.zs-master-style .zs-icon-user-fill::before {
  content: "\eaa5";
}
.zs-master-style .zs-icon-user-profile::before {
  content: "\eaa6";
}
.zs-master-style .zs-icon-user-rep::before {
  content: "\eaa7";
}
.zs-master-style .zs-icon-user-rep-fill::before {
  content: "\eaa8";
}
.zs-master-style .zs-icon-user-role::before {
  content: "\eaa9";
}
.zs-master-style .zs-icon-user-role-fill::before {
  content: "\eaaa";
}
.zs-master-style .zs-icon-user-selected::before {
  content: "\eaab";
}
.zs-master-style .zs-icon-user-selected-fill::before {
  content: "\eaac";
}
.zs-master-style .zs-icon-user-settings::before {
  content: "\eaad";
}
.zs-master-style .zs-icon-user-settings-fill::before {
  content: "\eaae";
}
.zs-master-style .zs-icon-frame-expand::before {
  content: "\eaaf";
}
.zs-master-style .zs-icon-frame-resize::before {
  content: "\eab0";
}
.zs-master-style .zs-icon-frame-restore::before {
  content: "\eab1";
}
.zs-master-style .zs-icon-resize-corner::before {
  content: "\eab2";
}
.zs-master-style .zs-icon-site::before {
  content: "\eab3";
}
.zs-master-style .zs-icon-site-fill::before {
  content: "\eab4";
}
.zs-master-style .zs-icon-window-new::before {
  content: "\eab5";
}
.zs-master-style .zs-icon-doc::before {
  content: "\eab6";
}
.zs-master-style .zs-icon-doc-generic::before {
  content: "\eab7";
}
.zs-master-style .zs-icon-file-attach::before {
  content: "\eab8";
}
.zs-master-style .zs-icon-file-copy::before {
  content: "\eab9";
}
.zs-master-style .zs-icon-file-doc::before {
  content: "\eaba";
}
.zs-master-style .zs-icon-file-edit::before {
  content: "\eabb";
}
.zs-master-style .zs-icon-file-pdf::before {
  content: "\eabc";
}
.zs-master-style .zs-icon-file-ppt::before {
  content: "\eabd";
}
.zs-master-style .zs-icon-file-sql::before {
  content: "\eabe";
}
.zs-master-style .zs-icon-file-txt::before {
  content: "\eabf";
}
.zs-master-style .zs-icon-file-xls::before {
  content: "\eac0";
}
.zs-master-style .zs-icon-file-xml::before {
  content: "\eac1";
}
.zs-master-style .zs-icon-file-zip::before {
  content: "\eac2";
}
.zs-master-style .zs-icon-folder-closed::before {
  content: "\eac3";
}
.zs-master-style .zs-icon-folder-closed-fill::before {
  content: "\eac4";
}
.zs-master-style .zs-icon-folder-open::before {
  content: "\eac5";
}
.zs-master-style .zs-icon-folder-open-fill::before {
  content: "\eac6";
}
.zs-master-style .zs-icon-folder-shortcut::before {
  content: "\eac7";
}
.zs-master-style .zs-icon-folder-shortcut-fill::before {
  content: "\eac8";
}
.zs-master-style .zs-icon-img::before {
  content: "\eac9";
}
.zs-master-style .zs-icon-note::before {
  content: "\eaca";
}
.zs-master-style .zs-icon-preview::before {
  content: "\eacb";
}
.zs-master-style .zs-icon-diagram-view::before {
  content: "\eacc";
}
.zs-master-style .zs-icon-geo-detail::before {
  content: "\eacd";
}
.zs-master-style .zs-icon-geo-detail-fill::before {
  content: "\eace";
}
.zs-master-style .zs-icon-geo-level::before {
  content: "\eacf";
}
.zs-master-style .zs-icon-legend-diamond::before {
  content: "\ead0";
}
.zs-master-style .zs-icon-legend-ellipse::before {
  content: "\ead1";
}
.zs-master-style .zs-icon-legend-hexagon::before {
  content: "\ead2";
}
.zs-master-style .zs-icon-legend-pentagon::before {
  content: "\ead3";
}
.zs-master-style .zs-icon-legend-square::before {
  content: "\ead4";
}
.zs-master-style .zs-icon-legend-star::before {
  content: "\ead5";
}
.zs-master-style .zs-icon-legend-triangle::before {
  content: "\ead6";
}
.zs-master-style .zs-icon-locate::before {
  content: "\ead7";
}
.zs-master-style .zs-icon-locate-fill::before {
  content: "\ead8";
}
.zs-master-style .zs-icon-map-align::before {
  content: "\ead9";
}
.zs-master-style .zs-icon-map-focus::before {
  content: "\eada";
}
.zs-master-style .zs-icon-map-graph::before {
  content: "\eadb";
}
.zs-master-style .zs-icon-map-pin::before {
  content: "\eadc";
}
.zs-master-style .zs-icon-map-pin-fill::before {
  content: "\eadd";
}
.zs-master-style .zs-icon-map-route::before {
  content: "\eade";
}
.zs-master-style .zs-icon-map-select::before {
  content: "\eadf";
}
.zs-master-style .zs-icon-map-select-lasso::before {
  content: "\eae0";
}
.zs-master-style .zs-icon-map-select-rectangle::before {
  content: "\eae1";
}
.zs-master-style .zs-icon-map-view::before {
  content: "\eae2";
}
.zs-master-style .zs-icon-map-view-affiliations::before {
  content: "\eae3";
}
.zs-master-style .zs-icon-map-view-alignments::before {
  content: "\eae4";
}
.zs-master-style .zs-icon-map-view-split::before {
  content: "\eae5";
}
.zs-master-style .zs-icon-map-view-split-fill::before {
  content: "\eae6";
}
.zs-master-style .zs-icon-map-zoom-adjust::before {
  content: "\eae7";
}
.zs-master-style .zs-icon-map-zoom-rectangle::before {
  content: "\eae8";
}
.zs-master-style .zs-icon-mapsettings::before {
  content: "\eae9";
}
.zs-master-style .zs-icon-measure::before {
  content: "\eaea";
}
.zs-master-style .zs-icon-navigation::before {
  content: "\eaeb";
}
.zs-master-style .zs-icon-navigation-fill::before {
  content: "\eaec";
}
.zs-master-style .zs-icon-route::before {
  content: "\eaed";
}
.zs-master-style .zs-icon-territory::before {
  content: "\eaee";
}
.zs-master-style .zs-icon-zoom-to-actual::before {
  content: "\eaef";
}
.zs-master-style .zs-icon-zoom-to-fit::before {
  content: "\eaf0";
}
.zs-master-style .zs-icon-data::before {
  content: "\eaf1";
}
.zs-master-style .zs-icon-field-insights::before {
  content: "\eaf2";
}
.zs-master-style .zs-icon-field-insights-fill::before {
  content: "\eaf3";
}
.zs-master-style .zs-icon-home::before {
  content: "\eaf4";
}
.zs-master-style .zs-icon-home-fill::before {
  content: "\eaf5";
}
.zs-master-style .zs-icon-incentives::before {
  content: "\eaf6";
}
.zs-master-style .zs-icon-incentives-fill::before {
  content: "\eaf7";
}
.zs-master-style .zs-icon-planning::before {
  content: "\eaf8";
}
.zs-master-style .zs-icon-planning-fill::before {
  content: "\eaf9";
}
.zs-master-style .zs-icon-reports::before {
  content: "\eafa";
}
.zs-master-style .zs-icon-reports-fill::before {
  content: "\eafb";
}
.zs-master-style .zs-icon-roster::before {
  content: "\eafc";
}
.zs-master-style .zs-icon-award-trip::before {
  content: "\eafd";
}
.zs-master-style .zs-icon-contest::before {
  content: "\eafe";
}
.zs-master-style .zs-icon-contest-fill::before {
  content: "\eaff";
}
.zs-master-style .zs-icon-equals::before {
  content: "\eb00";
}
.zs-master-style .zs-icon-goal::before {
  content: "\eb01";
}
.zs-master-style .zs-icon-goal-fill::before {
  content: "\eb02";
}
.zs-master-style .zs-icon-incentive-dollar::before {
  content: "\eb03";
}
.zs-master-style .zs-icon-incentive-euro::before {
  content: "\eb04";
}
.zs-master-style .zs-icon-incentive-pound::before {
  content: "\eb05";
}
.zs-master-style .zs-icon-incentive-yen::before {
  content: "\eb06";
}
.zs-master-style .zs-icon-product-metric::before {
  content: "\eb07";
}
.zs-master-style .zs-icon-report-calc::before {
  content: "\eb08";
}
.zs-master-style .zs-icon-report-dashboard::before {
  content: "\eb09";
}
.zs-master-style .zs-icon-report-derived::before {
  content: "\eb0a";
}
.zs-master-style .zs-icon-report-derived-fill::before {
  content: "\eb0b";
}
.zs-master-style .zs-icon-report-generated::before {
  content: "\eb0c";
}
.zs-master-style .zs-icon-report-goal::before {
  content: "\eb0d";
}
.zs-master-style .zs-icon-report-preview::before {
  content: "\eb0e";
}
.zs-master-style .zs-icon-report-publish::before {
  content: "\eb0f";
}
.zs-master-style .zs-icon-report-task::before {
  content: "\eb10";
}
.zs-master-style .zs-icon-report-template::before {
  content: "\eb11";
}
.zs-master-style .zs-icon-report-trend::before {
  content: "\eb12";
}
.zs-master-style .zs-icon-report-unpublish::before {
  content: "\eb13";
}
.zs-master-style .zs-icon-report-valid::before {
  content: "\eb14";
}
.zs-master-style .zs-icon-columns::before {
  content: "\eb15";
}
.zs-master-style .zs-icon-columns-fill::before {
  content: "\eb16";
}
.zs-master-style .zs-icon-data-arrow-down::before {
  content: "\eb17";
}
.zs-master-style .zs-icon-data-arrow-up::before {
  content: "\eb18";
}
.zs-master-style .zs-icon-data-list::before {
  content: "\eb19";
}
.zs-master-style .zs-icon-data-publish::before {
  content: "\eb1a";
}
.zs-master-style .zs-icon-data-publish-done::before {
  content: "\eb1b";
}
.zs-master-style .zs-icon-data-zero-negative::before {
  content: "\eb1c";
}
.zs-master-style .zs-icon-data-zero-positive::before {
  content: "\eb1d";
}
.zs-master-style .zs-icon-table::before {
  content: "\eb1e";
}
.zs-master-style .zs-icon-table-id::before {
  content: "\eb1f";
}
.zs-master-style .zs-icon-table-id-fill::before {
  content: "\eb20";
}
.zs-master-style .zs-icon-table-key::before {
  content: "\eb21";
}
.zs-master-style .zs-icon-table-preview::before {
  content: "\eb22";
}
.zs-master-style .zs-icon-logo-apache-spark::before {
  content: "\eb23";
}
.zs-master-style .zs-icon-logo-codepen::before {
  content: "\eb24";
}
/* 
** Modern Typography styles
*/
.zs-master-style {
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  color: #2F2C3C;
}
.zs-master-style .zs-h1,
.zs-master-style h1 {
  font-size: 2em;
  font-weight: 700;
  line-height: 1.5;
  letter-spacing: -0.046em;
  margin: 1em 0em 1em 0em;
}
.zs-master-style .zs-h2,
.zs-master-style h2 {
  font-size: 1.5em;
  font-weight: 700;
  line-height: 1.33;
  letter-spacing: -0.021em;
  margin: 1.33333333em 0em 1.33333333em 0em;
}
.zs-master-style .zs-h3,
.zs-master-style h3 {
  font-size: 1.25em;
  font-weight: 700;
  line-height: 1.4;
  letter-spacing: -0.008em;
  margin: 1.2em 0em 1.2em 0em;
}
.zs-master-style .zs-h4,
.zs-master-style h4 {
  font-size: 1em;
  font-weight: 700;
  font-style: normal;
  opacity: 1;
  line-height: 1.5;
  letter-spacing: -0.009em;
  margin: 1em 0em 1em 0em;
}
.zs-master-style .zs-h5,
.zs-master-style h5 {
  font-size: 0.875em;
  font-weight: 700;
  line-height: 1.4;
  letter-spacing: 0.011em;
  margin: 0.57142857em 0em 0.57142857em 0em;
}
.zs-master-style .zs-h6,
.zs-master-style h6 {
  font-size: 0.75em;
  line-height: 1.4;
  font-weight: 700;
  letter-spacing: -0.013em;
  margin: 0.33333333em 0em 0.33333333em 0em;
}
.zs-master-style .zs-super-hero {
  font-size: 4em;
  line-height: 1.5;
  letter-spacing: -0.047em;
}
.zs-master-style .zs-hero {
  font-size: 3em;
  font-weight: 700;
  line-height: 1.5;
  letter-spacing: 0.003em;
  margin: 0em 0em 1em 0em;
}
.zs-master-style .zs-subtitle-1 {
  font-size: 1em;
  line-height: 1.5;
  letter-spacing: 0.013em;
}
.zs-master-style .zs-subtitle-2 {
  font-size: 0.875em;
  line-height: 1.44;
  letter-spacing: 0.014em;
}
.zs-master-style .zs-body {
  font-size: 1em;
  line-height: 1.5;
  letter-spacing: -0.009em;
  margin: 1em 0em 1em 0em;
}
.zs-master-style .zs-body-small {
  font-size: 0.875em;
  line-height: 1.4;
  letter-spacing: -0.011em;
  margin: 1.14285714em 0em 1.14285714em 0em;
}
.zs-master-style .zs-body-extra-small {
  font-size: 0.75em;
  line-height: 1.63;
  letter-spacing: -0.03em;
}
.zs-master-style .zs-caption-1 {
  font-size: 0.75em;
  line-height: 1.5;
  letter-spacing: -0.013em;
  margin: -1em 0em 0.66666667em 0em;
}
.zs-master-style .zs-caption-2 {
  font-size: 0.625em;
  line-height: 1.5;
  letter-spacing: -0.015em;
  margin: -0.8em 0em 0.8em 0em;
}
.zs-master-style .zs-caption-2 + .zs-caption-2 {
  margin: 0.8em 0em 0.8em 0em;
}
.zs-master-style .zs-overline {
  font-size: 0.625em;
  font-weight: 600;
  line-height: 1.5;
  text-transform: uppercase;
  letter-spacing: 0.4em;
}
.zs-master-style .zs-label-1 {
  font-size: 0.875em;
  font-weight: 600;
  line-height: 1.5;
  letter-spacing: -0.011em;
}
.zs-master-style .zs-label-2 {
  font-size: 0.625em;
  font-weight: 600;
  line-height: 1.5;
  letter-spacing: -0.013em;
}
.zs-master-style .zs-header-block {
  margin: 2em 0em 2em 0em;
}
.zs-master-style .zs-header-block .zs-overline {
  margin: 0em 0em 0.4em 0em;
}
.zs-master-style .zs-header-block .zs-h1,
.zs-master-style .zs-header-block h1 {
  margin: 0em 0em 0.125em 0em;
}
.zs-master-style .zs-header-block .zs-h2,
.zs-master-style .zs-header-block h2 {
  margin: 0em 0em 0.16666667em 0em;
}
.zs-master-style .zs-header-block .zs-subtitle-1 {
  margin: 0em 0em 0.25em 0em;
}
.zs-master-style a {
  color: #2D8B93;
  text-decoration: none;
  letter-spacing: -0.013em;
}
.zs-master-style a:visited {
  color: #2D535F;
}
.zs-master-style a:focus {
  color: #27A6A4;
  outline-offset: -2px;
  outline: 2px solid;
  outline-color: #27A6A4;
  z-index: 1;
}
.zs-master-style a:hover {
  color: #27A6A4;
  text-decoration: none;
}
.zs-master-style a:active {
  color: #2F6F7B;
}
.zs-master-style a.zs-disabled,
.zs-master-style a[disabled],
.zs-master-style a.zs-calendar > table td[disabled],
.zs-master-style a.zs-range-calendar > table td[disabled],
.zs-master-style a.zs-range-calendar > section > table td[disabled] {
  color: #B2B0B6;
}
.zs-master-style .zs-link-action {
  color: #2F2C3C;
  background: transparent;
}
.zs-master-style .zs-link-action:visited {
  color: inherit;
  background: inherit;
}
.zs-master-style .zs-link-action:focus {
  color: #27A6A4;
  background: rgba(39, 166, 164, 0.1);
  outline: 2px solid #27A6A4;
  outline-offset: -2px;
  z-index: 1;
}
.zs-master-style .zs-link-action:hover {
  color: #27A6A4;
  background: rgba(39, 166, 164, 0.1);
}
.zs-master-style .zs-link-action:active {
  color: #ffffff;
  background: #2D8B93;
}
.zs-master-style .zs-link-action.zs-active {
  color: #ffffff;
  background: #27A6A4;
}
.zs-master-style .zs-link-action.zs-disabled,
.zs-master-style .zs-link-action[disabled],
.zs-master-style .zs-link-action.zs-calendar > table td[disabled],
.zs-master-style .zs-link-action.zs-range-calendar > table td[disabled],
.zs-master-style .zs-link-action.zs-range-calendar > section > table td[disabled] {
  color: #B2B0B6;
}
.zs-master-style ul.zs-list,
.zs-master-style ol.zs-list {
  margin: 0em 0em 0em 2em;
}
.zs-master-style ul.zs-list > li,
.zs-master-style ol.zs-list > li {
  margin: 0em 0em 0.25em 0em;
  padding: 0em 0em 0em 0.5em;
  line-height: 1.44;
  letter-spacing: -0.013em;
}
.zs-master-style ul.zs-list > li:first-child,
.zs-master-style ol.zs-list > li:first-child {
  margin: 0.5em 0em 0.25em 0em;
}
.zs-master-style ul.zs-list h4,
.zs-master-style ol.zs-list h4 {
  margin: 1.5em 0em 0em 0.5em;
}
.zs-master-style ul.zs-list ul,
.zs-master-style ol.zs-list ul,
.zs-master-style ul.zs-list ol,
.zs-master-style ol.zs-list ol {
  margin: 0em 0em 0em 2.5em;
}
.zs-master-style ul.zs-bullet-list {
  list-style-type: none;
  margin: 0;
}
.zs-master-style ul.zs-bullet-list > li {
  display: flex;
  align-items: center;
  padding-left: 0;
}
.zs-master-style ul.zs-bullet-list > li:before {
  font-family: 'zsIcons';
  font-size: 1.5em;
  content: "\ea20";
  padding: 0em 0em 0em 0.66666667em;
  line-height: 1;
}
.zs-master-style ul.zs-bullet-list h4 {
  margin: 1.5em 0em 0em 2.5em;
}
.zs-master-style ul.zs-bullet-list ul {
  margin: 0em 0em 0em 2.5em;
}
.zs-master-style em,
.zs-master-style b {
  font-weight: 700;
}
.zs-master-style i {
  font-style: italic;
}
.zs-master-style p,
.zs-master-style .zs-paragraph {
  margin: 0.75em 0em 0.5em 0em;
}
.zs-master-style hr {
  border-bottom: 1px solid #DEDCDE;
  *zoom: 1;
  display: block !important;
}
.zs-master-style hr:before,
.zs-master-style hr:after {
  content: "";
  display: table;
}
.zs-master-style hr:after {
  clear: both;
}
.zs-master-style h1:first-child,
.zs-master-style h2:first-child,
.zs-master-style h3:first-child,
.zs-master-style h4:first-child,
.zs-master-style .zs-h1:first-child,
.zs-master-style .zs-h2:first-child,
.zs-master-style .zs-h3:first-child,
.zs-master-style .zs-h4:first-child,
.zs-master-style p:first-child,
.zs-master-style .zs-paragraph:first-child,
.zs-master-style table:first-child {
  margin-top: 0;
}
.zs-master-style > h1:last-child,
.zs-master-style > h2:last-child,
.zs-master-style > h3:last-child,
.zs-master-style > h4:last-child,
.zs-master-style > .zs-h1:last-child,
.zs-master-style > .zs-h2:last-child,
.zs-master-style > .zs-h3:last-child,
.zs-master-style > .zs-h4:last-child,
.zs-master-style > p:last-child,
.zs-master-style > .zs-paragraph:last-child,
.zs-master-style > table:last-child {
  margin-bottom: 0;
}
.zs-loader {
  display: inline-block;
  line-height: 1;
}
.zs-loader [loader] {
  display: inline-block;
  position: relative;
  width: 1em;
  height: 1em;
  border: 0.125em solid rgba(255, 255, 255, 0.5);
  border-radius: 50%;
}
.zs-loader [loader]:before,
.zs-loader [loader]:after,
.zs-loader [loader] span::after,
.zs-loader [loader] span::before {
  content: '';
  box-sizing: border-box;
  display: block;
  position: absolute;
  top: -0.125em;
  left: -0.125em;
  width: 1em;
  height: 1em;
  border: 0.125em solid #ffffff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #ffffff transparent transparent transparent;
}
.zs-loader [loader]::before {
  animation-delay: -0.45s;
}
.zs-loader [loader]::after {
  animation-delay: -0.3s;
}
.zs-loader [loader] span::before {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.zs-master-style,
.zs-master-style *,
.zs-master-style:before,
.zs-master-style:after,
.zs-master-style *:before,
.zs-master-style *:after {
  margin: 0;
  padding: 0;
  border: 0;
  box-sizing: border-box;
}
.zs-master-style,
.zs-master-style * {
  font-family: "Open Sans", sans-serif;
}
.zs-custom-scrollbar {
  /* width */
  /* Track */
  /* Handle */
  /* Handle on hover */
}
.zs-custom-scrollbar ::-webkit-scrollbar,
.zs-custom-scrollbar::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}
.zs-custom-scrollbar ::-webkit-scrollbar-track,
.zs-custom-scrollbar::-webkit-scrollbar-track {
  background: #F4F3F3;
}
.zs-custom-scrollbar ::-webkit-scrollbar-thumb,
.zs-custom-scrollbar::-webkit-scrollbar-thumb {
  background: #B2B0B6;
  border-radius: 8px;
  border: 2px solid #F4F3F3;
}
.zs-custom-scrollbar ::-webkit-scrollbar-thumb:hover,
.zs-custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #87848D;
}
.zs-master-style .zs-interactive-primary,
.zs-master-style .zs-icon.zs-interactive-primary {
  color: #27A6A4;
}
.zs-master-style .zs-interactive-primary:visited,
.zs-master-style .zs-icon.zs-interactive-primary:visited {
  color: #27A6A4;
}
.zs-master-style .zs-interactive-primary:focus,
.zs-master-style .zs-icon.zs-interactive-primary:focus {
  color: #2F6F7B;
  outline: 2px solid #27A6A4;
}
.zs-master-style .zs-interactive-primary:hover,
.zs-master-style .zs-icon.zs-interactive-primary:hover {
  color: #62D2D1;
}
.zs-master-style .zs-interactive-primary:active,
.zs-master-style .zs-icon.zs-interactive-primary:active {
  color: #2D535F;
}
.zs-master-style .zs-interactive-secondary,
.zs-master-style .zs-icon.zs-interactive-secondary {
  color: #716E79;
}
.zs-master-style .zs-interactive-secondary:visited,
.zs-master-style .zs-icon.zs-interactive-secondary:visited {
  color: #716E79;
}
.zs-master-style .zs-interactive-secondary:focus,
.zs-master-style .zs-icon.zs-interactive-secondary:focus {
  color: #454250;
}
.zs-master-style .zs-interactive-secondary:hover,
.zs-master-style .zs-icon.zs-interactive-secondary:hover {
  color: #9C9AA1;
}
.zs-master-style .zs-interactive-secondary:active,
.zs-master-style .zs-icon.zs-interactive-secondary:active {
  color: #2F2C3C;
}
.zs-master-style .zs-interactive-primary.zs-disabled,
.zs-master-style .zs-interactive-secondary.zs-disabled,
.zs-master-style .zs-interactive-primary.zs-calendar > table td[disabled],
.zs-master-style .zs-interactive-secondary.zs-calendar > table td[disabled],
.zs-master-style .zs-interactive-primary.zs-range-calendar > table td[disabled],
.zs-master-style .zs-interactive-secondary.zs-range-calendar > table td[disabled],
.zs-master-style .zs-interactive-primary.zs-range-calendar > section > table td[disabled],
.zs-master-style .zs-interactive-secondary.zs-range-calendar > section > table td[disabled] {
  color: #B2B0B6;
}
.zs-master-style .zs-interactive-primary.zs-danger,
.zs-master-style .zs-interactive-secondary.zs-danger {
  color: #B21111;
}
.zs-master-style .zs-interactive-primary.zs-danger:visited,
.zs-master-style .zs-interactive-secondary.zs-danger:visited {
  color: #B21111;
}
.zs-master-style .zs-interactive-primary.zs-danger:focus,
.zs-master-style .zs-interactive-secondary.zs-danger:focus {
  color: #892208;
}
.zs-master-style .zs-interactive-primary.zs-danger:hover,
.zs-master-style .zs-interactive-secondary.zs-danger:hover {
  color: #F27755;
}
.zs-master-style .zs-interactive-primary.zs-danger:active,
.zs-master-style .zs-interactive-secondary.zs-danger:active {
  color: #5C1A0B;
}
.zs-master-style .zs-interactive-primary.zs-success,
.zs-master-style .zs-interactive-secondary.zs-success {
  color: #00AA67;
}
.zs-master-style .zs-interactive-primary.zs-success:visited,
.zs-master-style .zs-interactive-secondary.zs-success:visited {
  color: #00AA67;
}
.zs-master-style .zs-interactive-primary.zs-success:focus,
.zs-master-style .zs-interactive-secondary.zs-success:focus {
  color: #0A6E5E;
}
.zs-master-style .zs-interactive-primary.zs-success:hover,
.zs-master-style .zs-interactive-secondary.zs-success:hover {
  color: #54D7AF;
}
.zs-master-style .zs-interactive-primary.zs-success:active,
.zs-master-style .zs-interactive-secondary.zs-success:active {
  color: #0C4846;
}
.zs-master-style .zs-interactive-primary.zs-warning,
.zs-master-style .zs-interactive-secondary.zs-warning {
  color: #E3A900;
}
.zs-master-style .zs-interactive-primary.zs-warning:visited,
.zs-master-style .zs-interactive-secondary.zs-warning:visited {
  color: #E3A900;
}
.zs-master-style .zs-interactive-primary.zs-warning:focus,
.zs-master-style .zs-interactive-secondary.zs-warning:focus {
  color: #8A640C;
}
.zs-master-style .zs-interactive-primary.zs-warning:hover,
.zs-master-style .zs-interactive-secondary.zs-warning:hover {
  color: #FDCA63;
}
.zs-master-style .zs-interactive-primary.zs-warning:active,
.zs-master-style .zs-interactive-secondary.zs-warning:active {
  color: #563F0E;
}
.zs-master-style .zs-interactive-primary.zs-info,
.zs-master-style .zs-interactive-secondary.zs-info {
  color: #2B44C7;
}
.zs-master-style .zs-interactive-primary.zs-info:visited,
.zs-master-style .zs-interactive-secondary.zs-info:visited {
  color: #2B44C7;
}
.zs-master-style .zs-interactive-primary.zs-info:focus,
.zs-master-style .zs-interactive-secondary.zs-info:focus {
  color: #141187;
}
.zs-master-style .zs-interactive-primary.zs-info:hover,
.zs-master-style .zs-interactive-secondary.zs-info:hover {
  color: #7E92E9;
}
.zs-master-style .zs-interactive-primary.zs-info:active,
.zs-master-style .zs-interactive-secondary.zs-info:active {
  color: #160C60;
}
.zs-clear {
  *zoom: 1;
  display: block !important;
}
.zs-clear:before,
.zs-clear:after {
  content: "";
  display: table;
}
.zs-clear:after {
  clear: both;
}
.zs-highlight,
mark {
  background-color: #FFD680;
  color: inherit;
}
/* IE11 scroll fix */
body {
  -ms-overflow-style: scrollbar;
}
.zs-master-style input::-ms-clear {
  display: none;
}
.zs-button {
  text-decoration: none;
  vertical-align: middle;
  line-height: 1.5;
  font-size: 1em;
  font-weight: 600;
  border: 0px;
  box-shadow: none;
  box-shadow: inset 0 0 0 1px #B2B0B6;
  color: #454250;
  background-color: #ffffff;
  border-radius: 0.25em;
  padding: 0.5em 1em 0.5em 1em;
  white-space: nowrap;
  -webkit-appearance: none;
}
.zs-button span {
  vertical-align: middle;
}
.zs-button:focus {
  color: #2F6F7B;
  box-shadow: inset 0 0 0 1px #2F6F7B;
  outline: none;
}
.zs-button:focus > * {
  outline: inherit;
}
.zs-button:focus > .zs-icon {
  color: inherit;
}
.zs-button:hover {
  cursor: pointer;
  background-color: #62D2D1;
  color: #ffffff;
  box-shadow: inset 0 0 0 1px #62D2D1;
}
.zs-button:hover .zs-icon {
  color: inherit;
}
.zs-button:active {
  background: #ffffff;
  color: #2D535F;
  box-shadow: inset 0 0 0 1px #2D535F;
}
.zs-button:active > .zs-icon {
  color: inherit;
}
.zs-button.zs-danger {
  color: #B21111;
  border: 0px;
  box-shadow: none;
  box-shadow: inset 0 0 0 1px #B21111;
  background: #ffffff;
}
.zs-button.zs-danger:visited {
  color: #B21111;
}
.zs-button.zs-danger:focus {
  color: #892208;
}
.zs-button.zs-danger:hover {
  color: #ffffff;
}
.zs-button.zs-danger:active {
  color: #5C1A0B;
}
.zs-button.zs-danger:focus {
  box-shadow: inset 0 0 0 1px #892208;
}
.zs-button.zs-danger:hover {
  box-shadow: inset 0 0 0 1px #F27755;
}
.zs-button.zs-danger:active {
  box-shadow: inset 0 0 0 1px #5C1A0B;
}
.zs-button.zs-danger:focus {
  background: #ffffff;
}
.zs-button.zs-danger:hover {
  background: #F27755;
}
.zs-button.zs-danger:active {
  background: #ffffff;
}
.zs-button.zs-success {
  color: #00AA67;
  border: 0px;
  box-shadow: none;
  box-shadow: inset 0 0 0 1px #00AA67;
  background: #ffffff;
}
.zs-button.zs-success:visited {
  color: #00AA67;
}
.zs-button.zs-success:focus {
  color: #0A6E5E;
}
.zs-button.zs-success:hover {
  color: #ffffff;
}
.zs-button.zs-success:active {
  color: #0C4846;
}
.zs-button.zs-success:focus {
  box-shadow: inset 0 0 0 1px #0A6E5E;
}
.zs-button.zs-success:hover {
  box-shadow: inset 0 0 0 1px #54D7AF;
}
.zs-button.zs-success:active {
  box-shadow: inset 0 0 0 1px #0C4846;
}
.zs-button.zs-success:focus {
  background: #ffffff;
}
.zs-button.zs-success:hover {
  background: #54D7AF;
}
.zs-button.zs-success:active {
  background: #ffffff;
}
.zs-button.zs-warning {
  color: #E3A900;
  border: 0px;
  box-shadow: none;
  box-shadow: inset 0 0 0 1px #E3A900;
  background: #ffffff;
}
.zs-button.zs-warning:visited {
  color: #E3A900;
}
.zs-button.zs-warning:focus {
  color: #8A640C;
}
.zs-button.zs-warning:hover {
  color: #ffffff;
}
.zs-button.zs-warning:active {
  color: #563F0E;
}
.zs-button.zs-warning:focus {
  box-shadow: inset 0 0 0 1px #8A640C;
}
.zs-button.zs-warning:hover {
  box-shadow: inset 0 0 0 1px #FDCA63;
}
.zs-button.zs-warning:active {
  box-shadow: inset 0 0 0 1px #563F0E;
}
.zs-button.zs-warning:focus {
  background: #ffffff;
}
.zs-button.zs-warning:hover {
  background: #FDCA63;
}
.zs-button.zs-warning:active {
  background: #ffffff;
}
.zs-button.zs-info {
  color: #2B44C7;
  border: 0px;
  box-shadow: none;
  box-shadow: inset 0 0 0 1px #2B44C7;
  background: #ffffff;
}
.zs-button.zs-info:visited {
  color: #2B44C7;
}
.zs-button.zs-info:focus {
  color: #141187;
}
.zs-button.zs-info:hover {
  color: #ffffff;
}
.zs-button.zs-info:active {
  color: #160C60;
}
.zs-button.zs-info:focus {
  box-shadow: inset 0 0 0 1px #141187;
}
.zs-button.zs-info:hover {
  box-shadow: inset 0 0 0 1px #7E92E9;
}
.zs-button.zs-info:active {
  box-shadow: inset 0 0 0 1px #160C60;
}
.zs-button.zs-info:focus {
  background: #ffffff;
}
.zs-button.zs-info:hover {
  background: #7E92E9;
}
.zs-button.zs-info:active {
  background: #ffffff;
}
.zs-button::-moz-focus-inner {
  border: none;
}
.zs-button-action,
.zs-button-solid {
  background-color: #27A6A4;
  color: #ffffff;
  box-shadow: inset 0 0 0 1px #27A6A4;
}
.zs-button-action:focus,
.zs-button-solid:focus {
  background: #2F6F7B;
  color: #ffffff;
  box-shadow: inset 0 0 0 1px #2F6F7B;
}
.zs-button-action:hover,
.zs-button-solid:hover {
  color: #ffffff;
  background: #62D2D1;
  box-shadow: inset 0 0 0 1px #62D2D1;
}
.zs-button-action:active,
.zs-button-solid:active {
  background: #2D535F;
  color: #ffffff;
  box-shadow: inset 0 0 0 1px #2D535F;
}
.zs-button-action.zs-danger,
.zs-button-solid.zs-danger {
  background: #B21111;
  border: 0px;
  box-shadow: none;
  box-shadow: inset 0 0 0 1px #B21111;
  color: #ffffff;
}
.zs-button-action.zs-danger:focus,
.zs-button-solid.zs-danger:focus {
  background: #892208;
}
.zs-button-action.zs-danger:hover,
.zs-button-solid.zs-danger:hover {
  background: #F27755;
}
.zs-button-action.zs-danger:active,
.zs-button-solid.zs-danger:active {
  background: #5C1A0B;
}
.zs-button-action.zs-danger:focus,
.zs-button-solid.zs-danger:focus {
  box-shadow: inset 0 0 0 1px #892208;
}
.zs-button-action.zs-danger:hover,
.zs-button-solid.zs-danger:hover {
  box-shadow: inset 0 0 0 1px #F27755;
}
.zs-button-action.zs-danger:active,
.zs-button-solid.zs-danger:active {
  box-shadow: inset 0 0 0 1px #5C1A0B;
}
.zs-button-action.zs-danger:visited,
.zs-button-solid.zs-danger:visited {
  color: #ffffff;
}
.zs-button-action.zs-danger:focus,
.zs-button-solid.zs-danger:focus {
  color: #ffffff;
}
.zs-button-action.zs-danger:hover,
.zs-button-solid.zs-danger:hover {
  color: #ffffff;
}
.zs-button-action.zs-danger:active,
.zs-button-solid.zs-danger:active {
  color: #ffffff;
}
.zs-button-action.zs-success,
.zs-button-solid.zs-success {
  background: #00AA67;
  border: 0px;
  box-shadow: none;
  box-shadow: inset 0 0 0 1px #00AA67;
  color: #ffffff;
}
.zs-button-action.zs-success:focus,
.zs-button-solid.zs-success:focus {
  background: #0A6E5E;
}
.zs-button-action.zs-success:hover,
.zs-button-solid.zs-success:hover {
  background: #54D7AF;
}
.zs-button-action.zs-success:active,
.zs-button-solid.zs-success:active {
  background: #0C4846;
}
.zs-button-action.zs-success:focus,
.zs-button-solid.zs-success:focus {
  box-shadow: inset 0 0 0 1px #0A6E5E;
}
.zs-button-action.zs-success:hover,
.zs-button-solid.zs-success:hover {
  box-shadow: inset 0 0 0 1px #54D7AF;
}
.zs-button-action.zs-success:active,
.zs-button-solid.zs-success:active {
  box-shadow: inset 0 0 0 1px #0C4846;
}
.zs-button-action.zs-success:visited,
.zs-button-solid.zs-success:visited {
  color: #ffffff;
}
.zs-button-action.zs-success:focus,
.zs-button-solid.zs-success:focus {
  color: #ffffff;
}
.zs-button-action.zs-success:hover,
.zs-button-solid.zs-success:hover {
  color: #ffffff;
}
.zs-button-action.zs-success:active,
.zs-button-solid.zs-success:active {
  color: #ffffff;
}
.zs-button-action.zs-warning,
.zs-button-solid.zs-warning {
  background: #E3A900;
  border: 0px;
  box-shadow: none;
  box-shadow: inset 0 0 0 1px #E3A900;
  color: #ffffff;
}
.zs-button-action.zs-warning:focus,
.zs-button-solid.zs-warning:focus {
  background: #8A640C;
}
.zs-button-action.zs-warning:hover,
.zs-button-solid.zs-warning:hover {
  background: #FDCA63;
}
.zs-button-action.zs-warning:active,
.zs-button-solid.zs-warning:active {
  background: #563F0E;
}
.zs-button-action.zs-warning:focus,
.zs-button-solid.zs-warning:focus {
  box-shadow: inset 0 0 0 1px #8A640C;
}
.zs-button-action.zs-warning:hover,
.zs-button-solid.zs-warning:hover {
  box-shadow: inset 0 0 0 1px #FDCA63;
}
.zs-button-action.zs-warning:active,
.zs-button-solid.zs-warning:active {
  box-shadow: inset 0 0 0 1px #563F0E;
}
.zs-button-action.zs-warning:visited,
.zs-button-solid.zs-warning:visited {
  color: #ffffff;
}
.zs-button-action.zs-warning:focus,
.zs-button-solid.zs-warning:focus {
  color: #ffffff;
}
.zs-button-action.zs-warning:hover,
.zs-button-solid.zs-warning:hover {
  color: #ffffff;
}
.zs-button-action.zs-warning:active,
.zs-button-solid.zs-warning:active {
  color: #ffffff;
}
.zs-button-action.zs-info,
.zs-button-solid.zs-info {
  background: #2B44C7;
  border: 0px;
  box-shadow: none;
  box-shadow: inset 0 0 0 1px #2B44C7;
  color: #ffffff;
}
.zs-button-action.zs-info:focus,
.zs-button-solid.zs-info:focus {
  background: #141187;
}
.zs-button-action.zs-info:hover,
.zs-button-solid.zs-info:hover {
  background: #7E92E9;
}
.zs-button-action.zs-info:active,
.zs-button-solid.zs-info:active {
  background: #160C60;
}
.zs-button-action.zs-info:focus,
.zs-button-solid.zs-info:focus {
  box-shadow: inset 0 0 0 1px #141187;
}
.zs-button-action.zs-info:hover,
.zs-button-solid.zs-info:hover {
  box-shadow: inset 0 0 0 1px #7E92E9;
}
.zs-button-action.zs-info:active,
.zs-button-solid.zs-info:active {
  box-shadow: inset 0 0 0 1px #160C60;
}
.zs-button-action.zs-info:visited,
.zs-button-solid.zs-info:visited {
  color: #ffffff;
}
.zs-button-action.zs-info:focus,
.zs-button-solid.zs-info:focus {
  color: #ffffff;
}
.zs-button-action.zs-info:hover,
.zs-button-solid.zs-info:hover {
  color: #ffffff;
}
.zs-button-action.zs-info:active,
.zs-button-solid.zs-info:active {
  color: #ffffff;
}
a.zs-button {
  white-space: nowrap;
  text-overflow: ellipsis;
  display: inline-block;
  text-decoration: none;
  box-shadow: inset 0 0 0 2px transparent;
  color: #2D8B93;
}
a.zs-button:focus {
  color: #27A6A4;
  background: #F3FCFE;
  box-shadow: inset 0 0 0 2px #27A6A4;
  outline: none;
}
a.zs-button:hover {
  color: #27A6A4;
  background: #F3FCFE;
  box-shadow: inset 0 0 0 2px transparent;
}
a.zs-button:active {
  color: #ffffff;
  background: #2F6F7B;
  box-shadow: inset 0 0 0 2px transparent;
}
a.zs-button.zs-danger {
  color: #B21111;
  background: #ffffff;
  border: 0px;
  box-shadow: none;
  box-shadow: inset 0 0 0 1px transparent;
}
a.zs-button.zs-danger:visited {
  color: #B21111;
}
a.zs-button.zs-danger:focus {
  color: #ffffff;
}
a.zs-button.zs-danger:hover {
  color: #ffffff;
}
a.zs-button.zs-danger:active {
  color: #ffffff;
}
a.zs-button.zs-danger:focus {
  background: #892208;
}
a.zs-button.zs-danger:hover {
  background: #F27755;
}
a.zs-button.zs-danger:active {
  background: #5C1A0B;
}
a.zs-button.zs-danger:focus {
  box-shadow: inset 0 0 0 1px #892208;
}
a.zs-button.zs-danger:hover {
  box-shadow: inset 0 0 0 1px #F27755;
}
a.zs-button.zs-danger:active {
  box-shadow: inset 0 0 0 1px #5C1A0B;
}
a.zs-button.zs-success {
  color: #00AA67;
  background: #ffffff;
  border: 0px;
  box-shadow: none;
  box-shadow: inset 0 0 0 1px transparent;
}
a.zs-button.zs-success:visited {
  color: #00AA67;
}
a.zs-button.zs-success:focus {
  color: #ffffff;
}
a.zs-button.zs-success:hover {
  color: #ffffff;
}
a.zs-button.zs-success:active {
  color: #ffffff;
}
a.zs-button.zs-success:focus {
  background: #0A6E5E;
}
a.zs-button.zs-success:hover {
  background: #54D7AF;
}
a.zs-button.zs-success:active {
  background: #0C4846;
}
a.zs-button.zs-success:focus {
  box-shadow: inset 0 0 0 1px #0A6E5E;
}
a.zs-button.zs-success:hover {
  box-shadow: inset 0 0 0 1px #54D7AF;
}
a.zs-button.zs-success:active {
  box-shadow: inset 0 0 0 1px #0C4846;
}
a.zs-button.zs-warning {
  color: #E3A900;
  background: #ffffff;
  border: 0px;
  box-shadow: none;
  box-shadow: inset 0 0 0 1px transparent;
}
a.zs-button.zs-warning:visited {
  color: #E3A900;
}
a.zs-button.zs-warning:focus {
  color: #ffffff;
}
a.zs-button.zs-warning:hover {
  color: #ffffff;
}
a.zs-button.zs-warning:active {
  color: #ffffff;
}
a.zs-button.zs-warning:focus {
  background: #8A640C;
}
a.zs-button.zs-warning:hover {
  background: #FDCA63;
}
a.zs-button.zs-warning:active {
  background: #563F0E;
}
a.zs-button.zs-warning:focus {
  box-shadow: inset 0 0 0 1px #8A640C;
}
a.zs-button.zs-warning:hover {
  box-shadow: inset 0 0 0 1px #FDCA63;
}
a.zs-button.zs-warning:active {
  box-shadow: inset 0 0 0 1px #563F0E;
}
a.zs-button.zs-info {
  color: #2B44C7;
  background: #ffffff;
  border: 0px;
  box-shadow: none;
  box-shadow: inset 0 0 0 1px transparent;
}
a.zs-button.zs-info:visited {
  color: #2B44C7;
}
a.zs-button.zs-info:focus {
  color: #ffffff;
}
a.zs-button.zs-info:hover {
  color: #ffffff;
}
a.zs-button.zs-info:active {
  color: #ffffff;
}
a.zs-button.zs-info:focus {
  background: #141187;
}
a.zs-button.zs-info:hover {
  background: #7E92E9;
}
a.zs-button.zs-info:active {
  background: #160C60;
}
a.zs-button.zs-info:focus {
  box-shadow: inset 0 0 0 1px #141187;
}
a.zs-button.zs-info:hover {
  box-shadow: inset 0 0 0 1px #7E92E9;
}
a.zs-button.zs-info:active {
  box-shadow: inset 0 0 0 1px #160C60;
}
.zs-button .zs-icon:hover {
  cursor: pointer;
  color: inherit;
}
.zs-button .zs-icon::before,
.zs-button .zs-icon::after {
  margin: 0;
  padding: 0;
  vertical-align: top;
}
.zs-button > .zs-icon + *,
.zs-button > * + .zs-icon {
  vertical-align: middle;
}
.zs-button + .zs-button {
  margin-left: 0.5em;
}
.zs-fab {
  position: relative;
  border-radius: 100%;
  min-height: auto;
  padding: 1.25em;
}
.zs-fab > .zs-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.zs-button > .zs-icon {
  color: inherit;
  background: inherit;
  background-color: inherit;
  border-color: inherit;
}
.zs-button[disabled],
.zs-button.zs-disabled,
.zs-button.zs-calendar > table td[disabled],
.zs-button.zs-range-calendar > table td[disabled],
.zs-button.zs-range-calendar > section > table td[disabled] {
  color: #B2B0B6;
  background: none;
  background-color: #F4F3F3;
  box-shadow: inset 0 0 0 1px #B2B0B6;
}
.zs-button > *:not(.zs-icon) {
  margin: 0em 0.25em 0em 0.25em;
}
.zs-button > *:first-child {
  margin-left: 0;
}
.zs-button > *:last-child {
  margin-right: 0;
}
.zs-field input[type="submit"] {
  text-decoration: none;
  vertical-align: middle;
  line-height: 1.5;
  font-size: 1em;
  font-weight: 600;
  border: 0px;
  box-shadow: none;
  box-shadow: inset 0 0 0 1px #B2B0B6;
  color: #454250;
  background-color: #ffffff;
  border-radius: 0.25em;
  padding: 0.5em 1em 0.5em 1em;
  white-space: nowrap;
  -webkit-appearance: none;
  color: black;
  color: #fff;
}
.zs-field input[type="submit"] span {
  vertical-align: middle;
}
.zs-field input[type="submit"]:focus {
  color: #2F6F7B;
  box-shadow: inset 0 0 0 1px #2F6F7B;
  outline: none;
}
.zs-field input[type="submit"]:focus > * {
  outline: inherit;
}
.zs-field input[type="submit"]:focus > .zs-icon {
  color: inherit;
}
.zs-field input[type="submit"]:hover {
  cursor: pointer;
  background-color: #62D2D1;
  color: #ffffff;
  box-shadow: inset 0 0 0 1px #62D2D1;
}
.zs-field input[type="submit"]:hover .zs-icon {
  color: inherit;
}
.zs-field input[type="submit"]:active {
  background: #ffffff;
  color: #2D535F;
  box-shadow: inset 0 0 0 1px #2D535F;
}
.zs-field input[type="submit"]:active > .zs-icon {
  color: inherit;
}
.zs-field input[type="submit"].zs-danger {
  color: #B21111;
  border: 0px;
  box-shadow: none;
  box-shadow: inset 0 0 0 1px #B21111;
  background: #ffffff;
}
.zs-field input[type="submit"].zs-danger:visited {
  color: #B21111;
}
.zs-field input[type="submit"].zs-danger:focus {
  color: #892208;
}
.zs-field input[type="submit"].zs-danger:hover {
  color: #ffffff;
}
.zs-field input[type="submit"].zs-danger:active {
  color: #5C1A0B;
}
.zs-field input[type="submit"].zs-danger:focus {
  box-shadow: inset 0 0 0 1px #892208;
}
.zs-field input[type="submit"].zs-danger:hover {
  box-shadow: inset 0 0 0 1px #F27755;
}
.zs-field input[type="submit"].zs-danger:active {
  box-shadow: inset 0 0 0 1px #5C1A0B;
}
.zs-field input[type="submit"].zs-danger:focus {
  background: #ffffff;
}
.zs-field input[type="submit"].zs-danger:hover {
  background: #F27755;
}
.zs-field input[type="submit"].zs-danger:active {
  background: #ffffff;
}
.zs-field input[type="submit"].zs-success {
  color: #00AA67;
  border: 0px;
  box-shadow: none;
  box-shadow: inset 0 0 0 1px #00AA67;
  background: #ffffff;
}
.zs-field input[type="submit"].zs-success:visited {
  color: #00AA67;
}
.zs-field input[type="submit"].zs-success:focus {
  color: #0A6E5E;
}
.zs-field input[type="submit"].zs-success:hover {
  color: #ffffff;
}
.zs-field input[type="submit"].zs-success:active {
  color: #0C4846;
}
.zs-field input[type="submit"].zs-success:focus {
  box-shadow: inset 0 0 0 1px #0A6E5E;
}
.zs-field input[type="submit"].zs-success:hover {
  box-shadow: inset 0 0 0 1px #54D7AF;
}
.zs-field input[type="submit"].zs-success:active {
  box-shadow: inset 0 0 0 1px #0C4846;
}
.zs-field input[type="submit"].zs-success:focus {
  background: #ffffff;
}
.zs-field input[type="submit"].zs-success:hover {
  background: #54D7AF;
}
.zs-field input[type="submit"].zs-success:active {
  background: #ffffff;
}
.zs-field input[type="submit"].zs-warning {
  color: #E3A900;
  border: 0px;
  box-shadow: none;
  box-shadow: inset 0 0 0 1px #E3A900;
  background: #ffffff;
}
.zs-field input[type="submit"].zs-warning:visited {
  color: #E3A900;
}
.zs-field input[type="submit"].zs-warning:focus {
  color: #8A640C;
}
.zs-field input[type="submit"].zs-warning:hover {
  color: #ffffff;
}
.zs-field input[type="submit"].zs-warning:active {
  color: #563F0E;
}
.zs-field input[type="submit"].zs-warning:focus {
  box-shadow: inset 0 0 0 1px #8A640C;
}
.zs-field input[type="submit"].zs-warning:hover {
  box-shadow: inset 0 0 0 1px #FDCA63;
}
.zs-field input[type="submit"].zs-warning:active {
  box-shadow: inset 0 0 0 1px #563F0E;
}
.zs-field input[type="submit"].zs-warning:focus {
  background: #ffffff;
}
.zs-field input[type="submit"].zs-warning:hover {
  background: #FDCA63;
}
.zs-field input[type="submit"].zs-warning:active {
  background: #ffffff;
}
.zs-field input[type="submit"].zs-info {
  color: #2B44C7;
  border: 0px;
  box-shadow: none;
  box-shadow: inset 0 0 0 1px #2B44C7;
  background: #ffffff;
}
.zs-field input[type="submit"].zs-info:visited {
  color: #2B44C7;
}
.zs-field input[type="submit"].zs-info:focus {
  color: #141187;
}
.zs-field input[type="submit"].zs-info:hover {
  color: #ffffff;
}
.zs-field input[type="submit"].zs-info:active {
  color: #160C60;
}
.zs-field input[type="submit"].zs-info:focus {
  box-shadow: inset 0 0 0 1px #141187;
}
.zs-field input[type="submit"].zs-info:hover {
  box-shadow: inset 0 0 0 1px #7E92E9;
}
.zs-field input[type="submit"].zs-info:active {
  box-shadow: inset 0 0 0 1px #160C60;
}
.zs-field input[type="submit"].zs-info:focus {
  background: #ffffff;
}
.zs-field input[type="submit"].zs-info:hover {
  background: #7E92E9;
}
.zs-field input[type="submit"].zs-info:active {
  background: #ffffff;
}
.zs-field input[type="submit"].zs-button-action {
  color: #fff;
}
.zs-field input[type="submit"].zs-button-solid {
  color: #fff;
}
.zs-field input[type="submit"].zs-disabled,
.zs-field input[type="submit"][disabled],
.zs-field input[type="submit"].zs-calendar > table td[disabled],
.zs-field input[type="submit"].zs-range-calendar > table td[disabled],
.zs-field input[type="submit"].zs-range-calendar > section > table td[disabled] {
  color: grey;
}
.zs-field input[type="button"] {
  text-decoration: none;
  vertical-align: middle;
  line-height: 1.5;
  font-size: 1em;
  font-weight: 600;
  border: 0px;
  box-shadow: none;
  box-shadow: inset 0 0 0 1px #B2B0B6;
  color: #454250;
  background-color: #ffffff;
  border-radius: 0.25em;
  padding: 0.5em 1em 0.5em 1em;
  white-space: nowrap;
  -webkit-appearance: none;
  color: black;
}
.zs-field input[type="button"] span {
  vertical-align: middle;
}
.zs-field input[type="button"]:focus {
  color: #2F6F7B;
  box-shadow: inset 0 0 0 1px #2F6F7B;
  outline: none;
}
.zs-field input[type="button"]:focus > * {
  outline: inherit;
}
.zs-field input[type="button"]:focus > .zs-icon {
  color: inherit;
}
.zs-field input[type="button"]:hover {
  cursor: pointer;
  background-color: #62D2D1;
  color: #ffffff;
  box-shadow: inset 0 0 0 1px #62D2D1;
}
.zs-field input[type="button"]:hover .zs-icon {
  color: inherit;
}
.zs-field input[type="button"]:active {
  background: #ffffff;
  color: #2D535F;
  box-shadow: inset 0 0 0 1px #2D535F;
}
.zs-field input[type="button"]:active > .zs-icon {
  color: inherit;
}
.zs-field input[type="button"].zs-danger {
  color: #B21111;
  border: 0px;
  box-shadow: none;
  box-shadow: inset 0 0 0 1px #B21111;
  background: #ffffff;
}
.zs-field input[type="button"].zs-danger:visited {
  color: #B21111;
}
.zs-field input[type="button"].zs-danger:focus {
  color: #892208;
}
.zs-field input[type="button"].zs-danger:hover {
  color: #ffffff;
}
.zs-field input[type="button"].zs-danger:active {
  color: #5C1A0B;
}
.zs-field input[type="button"].zs-danger:focus {
  box-shadow: inset 0 0 0 1px #892208;
}
.zs-field input[type="button"].zs-danger:hover {
  box-shadow: inset 0 0 0 1px #F27755;
}
.zs-field input[type="button"].zs-danger:active {
  box-shadow: inset 0 0 0 1px #5C1A0B;
}
.zs-field input[type="button"].zs-danger:focus {
  background: #ffffff;
}
.zs-field input[type="button"].zs-danger:hover {
  background: #F27755;
}
.zs-field input[type="button"].zs-danger:active {
  background: #ffffff;
}
.zs-field input[type="button"].zs-success {
  color: #00AA67;
  border: 0px;
  box-shadow: none;
  box-shadow: inset 0 0 0 1px #00AA67;
  background: #ffffff;
}
.zs-field input[type="button"].zs-success:visited {
  color: #00AA67;
}
.zs-field input[type="button"].zs-success:focus {
  color: #0A6E5E;
}
.zs-field input[type="button"].zs-success:hover {
  color: #ffffff;
}
.zs-field input[type="button"].zs-success:active {
  color: #0C4846;
}
.zs-field input[type="button"].zs-success:focus {
  box-shadow: inset 0 0 0 1px #0A6E5E;
}
.zs-field input[type="button"].zs-success:hover {
  box-shadow: inset 0 0 0 1px #54D7AF;
}
.zs-field input[type="button"].zs-success:active {
  box-shadow: inset 0 0 0 1px #0C4846;
}
.zs-field input[type="button"].zs-success:focus {
  background: #ffffff;
}
.zs-field input[type="button"].zs-success:hover {
  background: #54D7AF;
}
.zs-field input[type="button"].zs-success:active {
  background: #ffffff;
}
.zs-field input[type="button"].zs-warning {
  color: #E3A900;
  border: 0px;
  box-shadow: none;
  box-shadow: inset 0 0 0 1px #E3A900;
  background: #ffffff;
}
.zs-field input[type="button"].zs-warning:visited {
  color: #E3A900;
}
.zs-field input[type="button"].zs-warning:focus {
  color: #8A640C;
}
.zs-field input[type="button"].zs-warning:hover {
  color: #ffffff;
}
.zs-field input[type="button"].zs-warning:active {
  color: #563F0E;
}
.zs-field input[type="button"].zs-warning:focus {
  box-shadow: inset 0 0 0 1px #8A640C;
}
.zs-field input[type="button"].zs-warning:hover {
  box-shadow: inset 0 0 0 1px #FDCA63;
}
.zs-field input[type="button"].zs-warning:active {
  box-shadow: inset 0 0 0 1px #563F0E;
}
.zs-field input[type="button"].zs-warning:focus {
  background: #ffffff;
}
.zs-field input[type="button"].zs-warning:hover {
  background: #FDCA63;
}
.zs-field input[type="button"].zs-warning:active {
  background: #ffffff;
}
.zs-field input[type="button"].zs-info {
  color: #2B44C7;
  border: 0px;
  box-shadow: none;
  box-shadow: inset 0 0 0 1px #2B44C7;
  background: #ffffff;
}
.zs-field input[type="button"].zs-info:visited {
  color: #2B44C7;
}
.zs-field input[type="button"].zs-info:focus {
  color: #141187;
}
.zs-field input[type="button"].zs-info:hover {
  color: #ffffff;
}
.zs-field input[type="button"].zs-info:active {
  color: #160C60;
}
.zs-field input[type="button"].zs-info:focus {
  box-shadow: inset 0 0 0 1px #141187;
}
.zs-field input[type="button"].zs-info:hover {
  box-shadow: inset 0 0 0 1px #7E92E9;
}
.zs-field input[type="button"].zs-info:active {
  box-shadow: inset 0 0 0 1px #160C60;
}
.zs-field input[type="button"].zs-info:focus {
  background: #ffffff;
}
.zs-field input[type="button"].zs-info:hover {
  background: #7E92E9;
}
.zs-field input[type="button"].zs-info:active {
  background: #ffffff;
}
.zs-field input[type="button"].zs-button-action {
  color: #fff;
}
.zs-field input[type="button"].zs-button-solid {
  color: #fff;
}
.zs-field input[type="button"].zs-disabled,
.zs-field input[type="button"][disabled],
.zs-field input[type="button"].zs-calendar > table td[disabled],
.zs-field input[type="button"].zs-range-calendar > table td[disabled],
.zs-field input[type="button"].zs-range-calendar > section > table td[disabled] {
  color: grey;
}
.zs-field input[type="reset"] {
  text-decoration: none;
  vertical-align: middle;
  line-height: 1.5;
  font-size: 1em;
  font-weight: 600;
  border: 0px;
  box-shadow: none;
  box-shadow: inset 0 0 0 1px #B2B0B6;
  color: #454250;
  background-color: #ffffff;
  border-radius: 0.25em;
  padding: 0.5em 1em 0.5em 1em;
  white-space: nowrap;
  -webkit-appearance: none;
  color: black;
}
.zs-field input[type="reset"] span {
  vertical-align: middle;
}
.zs-field input[type="reset"]:focus {
  color: #2F6F7B;
  box-shadow: inset 0 0 0 1px #2F6F7B;
  outline: none;
}
.zs-field input[type="reset"]:focus > * {
  outline: inherit;
}
.zs-field input[type="reset"]:focus > .zs-icon {
  color: inherit;
}
.zs-field input[type="reset"]:hover {
  cursor: pointer;
  background-color: #62D2D1;
  color: #ffffff;
  box-shadow: inset 0 0 0 1px #62D2D1;
}
.zs-field input[type="reset"]:hover .zs-icon {
  color: inherit;
}
.zs-field input[type="reset"]:active {
  background: #ffffff;
  color: #2D535F;
  box-shadow: inset 0 0 0 1px #2D535F;
}
.zs-field input[type="reset"]:active > .zs-icon {
  color: inherit;
}
.zs-field input[type="reset"].zs-danger {
  color: #B21111;
  border: 0px;
  box-shadow: none;
  box-shadow: inset 0 0 0 1px #B21111;
  background: #ffffff;
}
.zs-field input[type="reset"].zs-danger:visited {
  color: #B21111;
}
.zs-field input[type="reset"].zs-danger:focus {
  color: #892208;
}
.zs-field input[type="reset"].zs-danger:hover {
  color: #ffffff;
}
.zs-field input[type="reset"].zs-danger:active {
  color: #5C1A0B;
}
.zs-field input[type="reset"].zs-danger:focus {
  box-shadow: inset 0 0 0 1px #892208;
}
.zs-field input[type="reset"].zs-danger:hover {
  box-shadow: inset 0 0 0 1px #F27755;
}
.zs-field input[type="reset"].zs-danger:active {
  box-shadow: inset 0 0 0 1px #5C1A0B;
}
.zs-field input[type="reset"].zs-danger:focus {
  background: #ffffff;
}
.zs-field input[type="reset"].zs-danger:hover {
  background: #F27755;
}
.zs-field input[type="reset"].zs-danger:active {
  background: #ffffff;
}
.zs-field input[type="reset"].zs-success {
  color: #00AA67;
  border: 0px;
  box-shadow: none;
  box-shadow: inset 0 0 0 1px #00AA67;
  background: #ffffff;
}
.zs-field input[type="reset"].zs-success:visited {
  color: #00AA67;
}
.zs-field input[type="reset"].zs-success:focus {
  color: #0A6E5E;
}
.zs-field input[type="reset"].zs-success:hover {
  color: #ffffff;
}
.zs-field input[type="reset"].zs-success:active {
  color: #0C4846;
}
.zs-field input[type="reset"].zs-success:focus {
  box-shadow: inset 0 0 0 1px #0A6E5E;
}
.zs-field input[type="reset"].zs-success:hover {
  box-shadow: inset 0 0 0 1px #54D7AF;
}
.zs-field input[type="reset"].zs-success:active {
  box-shadow: inset 0 0 0 1px #0C4846;
}
.zs-field input[type="reset"].zs-success:focus {
  background: #ffffff;
}
.zs-field input[type="reset"].zs-success:hover {
  background: #54D7AF;
}
.zs-field input[type="reset"].zs-success:active {
  background: #ffffff;
}
.zs-field input[type="reset"].zs-warning {
  color: #E3A900;
  border: 0px;
  box-shadow: none;
  box-shadow: inset 0 0 0 1px #E3A900;
  background: #ffffff;
}
.zs-field input[type="reset"].zs-warning:visited {
  color: #E3A900;
}
.zs-field input[type="reset"].zs-warning:focus {
  color: #8A640C;
}
.zs-field input[type="reset"].zs-warning:hover {
  color: #ffffff;
}
.zs-field input[type="reset"].zs-warning:active {
  color: #563F0E;
}
.zs-field input[type="reset"].zs-warning:focus {
  box-shadow: inset 0 0 0 1px #8A640C;
}
.zs-field input[type="reset"].zs-warning:hover {
  box-shadow: inset 0 0 0 1px #FDCA63;
}
.zs-field input[type="reset"].zs-warning:active {
  box-shadow: inset 0 0 0 1px #563F0E;
}
.zs-field input[type="reset"].zs-warning:focus {
  background: #ffffff;
}
.zs-field input[type="reset"].zs-warning:hover {
  background: #FDCA63;
}
.zs-field input[type="reset"].zs-warning:active {
  background: #ffffff;
}
.zs-field input[type="reset"].zs-info {
  color: #2B44C7;
  border: 0px;
  box-shadow: none;
  box-shadow: inset 0 0 0 1px #2B44C7;
  background: #ffffff;
}
.zs-field input[type="reset"].zs-info:visited {
  color: #2B44C7;
}
.zs-field input[type="reset"].zs-info:focus {
  color: #141187;
}
.zs-field input[type="reset"].zs-info:hover {
  color: #ffffff;
}
.zs-field input[type="reset"].zs-info:active {
  color: #160C60;
}
.zs-field input[type="reset"].zs-info:focus {
  box-shadow: inset 0 0 0 1px #141187;
}
.zs-field input[type="reset"].zs-info:hover {
  box-shadow: inset 0 0 0 1px #7E92E9;
}
.zs-field input[type="reset"].zs-info:active {
  box-shadow: inset 0 0 0 1px #160C60;
}
.zs-field input[type="reset"].zs-info:focus {
  background: #ffffff;
}
.zs-field input[type="reset"].zs-info:hover {
  background: #7E92E9;
}
.zs-field input[type="reset"].zs-info:active {
  background: #ffffff;
}
.zs-field input[type="reset"].zs-button-action {
  color: #fff;
}
.zs-field input[type="reset"].zs-button-solid {
  color: #fff;
}
.zs-field input[type="reset"].zs-disabled,
.zs-field input[type="reset"][disabled],
.zs-field input[type="reset"].zs-calendar > table td[disabled],
.zs-field input[type="reset"].zs-range-calendar > table td[disabled],
.zs-field input[type="reset"].zs-range-calendar > section > table td[disabled] {
  color: grey;
}
.zs-wizard {
  display: block;
  margin: 2em 2em 2em 2em;
}
.zs-wizard .zs-chevron {
  width: auto;
  color: #27A6A4;
  position: relative;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  padding: 0.5em 2em 0.5em 2em;
  margin: 0em 0.25em 0em 0em;
  display: inline-block;
}
.zs-wizard .zs-chevron:first-of-type {
  margin: 0em 0.75em 0em 0em;
  background: #D6F7FF;
}
.zs-wizard .zs-chevron:first-of-type::before,
.zs-wizard .zs-chevron:first-of-type::after {
  width: 1em;
  left: auto;
  right: 0;
}
.zs-wizard .zs-chevron:first-of-type::before {
  border-radius: 0 0 0 0;
  -webkit-transform: skew(30deg, 0deg) translateX(50%);
  -moz-transform: skew(30deg, 0deg) translateX(50%);
  -ms-transform: skew(30deg, 0deg) translateX(50%);
  -o-transform: skew(30deg, 0deg) translateX(50%);
  transform: skew(30deg, 0deg) translateX(50%);
}
.zs-wizard .zs-chevron:first-of-type::after {
  border-radius: 0 0 0 0;
  -webkit-transform: skew(-30deg, 0deg) translateX(50%);
  -moz-transform: skew(-30deg, 0deg) translateX(50%);
  -ms-transform: skew(-30deg, 0deg) translateX(50%);
  -o-transform: skew(-30deg, 0deg) translateX(50%);
  transform: skew(-30deg, 0deg) translateX(50%);
  top: 50%;
}
.zs-wizard .zs-chevron:first-of-type[active] {
  background: #2D535F;
}
.zs-wizard .zs-chevron[active] * {
  color: #ffffff;
}
.zs-wizard .zs-chevron[active] ~ .zs-chevron::before,
.zs-wizard .zs-chevron[active] ~ .zs-chevron::after {
  background: #F4F3F3;
}
.zs-wizard .zs-chevron[active]::before,
.zs-wizard .zs-chevron[active]::after {
  background: #2D535F;
}
.zs-wizard .zs-chevron[active] ~ .zs-chevron .zs-wizard-chevron-text {
  color: #716E79;
}
.zs-wizard .zs-chevron[active] ~ .zs-chevron .zs-wizard-chevron-text .zs-badge-counter {
  background: #716E79;
}
.zs-wizard .zs-chevron[active] .zs-badge-counter {
  color: #2D535F;
  background: #ffffff;
}
.zs-wizard .zs-chevron[active] [text-container] {
  font-weight: 700;
}
.zs-wizard .zs-chevron[active] .zs-badge-counter {
  font-weight: 600;
  -webkit-font-smoothing: antialiased;
}
.zs-wizard .zs-chevron::before,
.zs-wizard .zs-chevron::after {
  content: '';
  position: absolute;
  left: 0;
  height: 50%;
  width: 100%;
  border: 1px solid transparent;
  border-right-width: 0px;
  background: #D6F7FF;
  z-index: -1;
}
.zs-wizard .zs-chevron:last-of-type::before,
.zs-wizard .zs-chevron:last-of-type::after {
  border-right-width: 1px;
}
.zs-wizard .zs-chevron::before {
  top: 0;
  border-bottom: none;
  -webkit-transform: skew(30deg, 0deg);
  -moz-transform: skew(30deg, 0deg);
  -ms-transform: skew(30deg, 0deg);
  -o-transform: skew(30deg, 0deg);
  transform: skew(30deg, 0deg);
}
.zs-wizard .zs-chevron::after {
  border-top: none;
  -webkit-transform: skew(-30deg, 0deg);
  -moz-transform: skew(-30deg, 0deg);
  -ms-transform: skew(-30deg, 0deg);
  -o-transform: skew(-30deg, 0deg);
  transform: skew(-30deg, 0deg);
}
.zs-wizard .zs-chevron:not(:first-of-type)::after {
  top: calc(50% - 0.5px);
}
.zs-wizard .zs-chevron [text-container] {
  line-height: 1.5;
}
.zs-wizard .zs-chevron .zs-badge-counter {
  box-shadow: none;
}
.zs-wizard .zs-chevron.zs-success .zs-wizard-chevron-text,
.zs-wizard .zs-chevron[active] ~ .zs-success .zs-wizard-chevron-text {
  color: #00AA67;
}
.zs-wizard .zs-chevron.zs-success .zs-wizard-chevron-text:visited,
.zs-wizard .zs-chevron[active] ~ .zs-success .zs-wizard-chevron-text:visited {
  color: #00AA67;
}
.zs-wizard .zs-chevron.zs-success .zs-wizard-chevron-text:focus,
.zs-wizard .zs-chevron[active] ~ .zs-success .zs-wizard-chevron-text:focus {
  color: #0A6E5E;
}
.zs-wizard .zs-chevron.zs-success .zs-wizard-chevron-text:hover,
.zs-wizard .zs-chevron[active] ~ .zs-success .zs-wizard-chevron-text:hover {
  color: #54D7AF;
}
.zs-wizard .zs-chevron.zs-success .zs-wizard-chevron-text:active,
.zs-wizard .zs-chevron[active] ~ .zs-success .zs-wizard-chevron-text:active {
  color: #0C4846;
}
.zs-wizard .zs-chevron.zs-danger .zs-wizard-chevron-text,
.zs-wizard .zs-chevron[active] ~ .zs-danger .zs-wizard-chevron-text {
  color: #B21111;
}
.zs-wizard .zs-chevron.zs-danger .zs-wizard-chevron-text:visited,
.zs-wizard .zs-chevron[active] ~ .zs-danger .zs-wizard-chevron-text:visited {
  color: #B21111;
}
.zs-wizard .zs-chevron.zs-danger .zs-wizard-chevron-text:focus,
.zs-wizard .zs-chevron[active] ~ .zs-danger .zs-wizard-chevron-text:focus {
  color: #892208;
}
.zs-wizard .zs-chevron.zs-danger .zs-wizard-chevron-text:hover,
.zs-wizard .zs-chevron[active] ~ .zs-danger .zs-wizard-chevron-text:hover {
  color: #F27755;
}
.zs-wizard .zs-chevron.zs-danger .zs-wizard-chevron-text:active,
.zs-wizard .zs-chevron[active] ~ .zs-danger .zs-wizard-chevron-text:active {
  color: #5C1A0B;
}
.zs-wizard .zs-chevron.zs-warning .zs-wizard-chevron-text,
.zs-wizard .zs-chevron[active] ~ .zs-warning .zs-wizard-chevron-text {
  color: #E3A900;
}
.zs-wizard .zs-chevron.zs-warning .zs-wizard-chevron-text:visited,
.zs-wizard .zs-chevron[active] ~ .zs-warning .zs-wizard-chevron-text:visited {
  color: #E3A900;
}
.zs-wizard .zs-chevron.zs-warning .zs-wizard-chevron-text:focus,
.zs-wizard .zs-chevron[active] ~ .zs-warning .zs-wizard-chevron-text:focus {
  color: #8A640C;
}
.zs-wizard .zs-chevron.zs-warning .zs-wizard-chevron-text:hover,
.zs-wizard .zs-chevron[active] ~ .zs-warning .zs-wizard-chevron-text:hover {
  color: #FDCA63;
}
.zs-wizard .zs-chevron.zs-warning .zs-wizard-chevron-text:active,
.zs-wizard .zs-chevron[active] ~ .zs-warning .zs-wizard-chevron-text:active {
  color: #563F0E;
}
.zs-wizard .zs-chevron.zs-info .zs-wizard-chevron-text,
.zs-wizard .zs-chevron[active] ~ .zs-info .zs-wizard-chevron-text {
  color: #2B44C7;
}
.zs-wizard .zs-chevron.zs-info .zs-wizard-chevron-text:visited,
.zs-wizard .zs-chevron[active] ~ .zs-info .zs-wizard-chevron-text:visited {
  color: #2B44C7;
}
.zs-wizard .zs-chevron.zs-info .zs-wizard-chevron-text:focus,
.zs-wizard .zs-chevron[active] ~ .zs-info .zs-wizard-chevron-text:focus {
  color: #141187;
}
.zs-wizard .zs-chevron.zs-info .zs-wizard-chevron-text:hover,
.zs-wizard .zs-chevron[active] ~ .zs-info .zs-wizard-chevron-text:hover {
  color: #7E92E9;
}
.zs-wizard .zs-chevron.zs-info .zs-wizard-chevron-text:active,
.zs-wizard .zs-chevron[active] ~ .zs-info .zs-wizard-chevron-text:active {
  color: #160C60;
}
.zs-wizard .zs-chevron.zs-success::before,
.zs-wizard .zs-chevron.zs-success::after,
.zs-wizard .zs-chevron.zs-success:first-of-type {
  background: #F1FEFF;
}
.zs-wizard .zs-chevron.zs-danger::before,
.zs-wizard .zs-chevron.zs-warning::before,
.zs-wizard .zs-chevron.zs-info::before,
.zs-wizard .zs-chevron.zs-danger::after,
.zs-wizard .zs-chevron.zs-warning::after,
.zs-wizard .zs-chevron.zs-info::after,
.zs-wizard .zs-chevron.zs-danger:first-of-type,
.zs-wizard .zs-chevron.zs-warning:first-of-type,
.zs-wizard .zs-chevron.zs-info:first-of-type {
  background: #F4F3F3;
}
.zs-wizard .zs-chevron.zs-success[active]::before,
.zs-wizard .zs-chevron.zs-success[active]::after,
.zs-wizard .zs-chevron.zs-success[active]:first-of-type {
  background: #00AA67;
}
.zs-wizard .zs-chevron.zs-danger[active]::before,
.zs-wizard .zs-chevron.zs-danger[active]::after,
.zs-wizard .zs-chevron.zs-danger[active]:first-of-type {
  background: #B21111;
}
.zs-wizard .zs-chevron.zs-warning[active]::before,
.zs-wizard .zs-chevron.zs-warning[active]::after,
.zs-wizard .zs-chevron.zs-warning[active]:first-of-type {
  background: #E3A900;
}
.zs-wizard .zs-chevron.zs-info[active]::before,
.zs-wizard .zs-chevron.zs-info[active]::after,
.zs-wizard .zs-chevron.zs-info[active]:first-of-type {
  background: #2B44C7;
}
.zs-wizard[nonclickable] .zs-chevron {
  pointer-events: none;
}
.zs-wizard [source-id] {
  display: none;
}
.zs-wizard [source-id][active] {
  display: block;
}
.zs-toggle-component {
  position: relative;
  display: flex;
  align-items: center;
}
.zs-toggle-component [label]:not(:empty) {
  margin-right: 1em;
}
.zs-toggle-component input,
.zs-toggle-component input[disabled] {
  position: absolute;
  opacity: 0;
}
.toggle-container {
  height: 2em;
  min-width: 4em;
  display: inline-block;
  vertical-align: middle;
  position: absolute;
  min-width: 8em;
}
.toggle-outer-container {
  display: inline-block;
  position: relative;
  height: 2em;
  width: 4em;
}
.zs-toggle {
  overflow: hidden;
  cursor: pointer;
  border-radius: 9999px;
  height: 2em;
  width: 50%;
}
.toggle-inner {
  margin-left: -100%;
  display: inline-block;
  white-space: nowrap;
  transition: margin 300ms;
}
.toggle-inner .zs-toggle-on {
  pointer-events: none;
}
.toggle-inner .zs-toggle-button {
  left: 0.25em;
}
.toggle-inner.active {
  margin-left: 0px;
}
.toggle-inner.active .zs-toggle-button {
  left: 2.5em;
}
.toggle-inner.active .zs-toggle-off {
  pointer-events: none;
}
.zs-toggle .zs-toggle-on,
.zs-toggle .zs-toggle-off,
.zs-toggle .zs-toggle-button {
  display: inline-block;
  vertical-align: top;
}
.zs-toggle .zs-toggle-on,
.zs-toggle .zs-toggle-off {
  min-width: 4em;
  height: 2em;
  text-align: center;
}
.zs-toggle-on {
  background: #27A6A4;
  color: #2F2C3C;
  padding: 0.125em 0.25em 0.125em 0.25em;
  padding-right: 1em;
}
.zs-toggle-off {
  background: #716E79;
  color: #2F2C3C;
  padding: 0.125em 0.25em 0.125em 0.25em;
  padding-left: 1em;
}
.zs-toggle-button {
  width: 1.5em;
  height: 1.5em;
  border-radius: 100%;
  background: #ffffff;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(26, 22, 40, 0.12), 0px 2px 4px rgba(26, 22, 40, 0.12);
  border: none;
  position: absolute;
  z-index: 99;
  margin: 0em 0em 0em -0.125em;
  transition: left 200ms;
  top: 50%;
  transform: translateY(-50%);
}
.zs-toggle-component[focussed] .toggle-container::before {
  content: ' ';
  position: absolute;
  border: 2px solid #27A6A4;
  left: -0.25em;
  top: -0.25em;
  right: 3.75em;
  bottom: -0.25em;
  border-radius: 1.5em;
  z-index: -1;
}
.zs-toggle-component[focussed] .zs-toggle-on {
  background: #2F6F7B;
}
.zs-toggle-component[focussed] .zs-toggle-off {
  background: #454250;
}
.zs-toggle-component[disabled] .zs-toggle-on,
.zs-toggle-component.zs-disabled .zs-toggle-on,
.zs-toggle-component[disabled] .zs-toggle-off,
.zs-toggle-component.zs-disabled .zs-toggle-off,
.zs-toggle-component.zs-calendar > table td[disabled] .zs-toggle-on,
.zs-toggle-component.zs-calendar > table td[disabled] .zs-toggle-off,
.zs-toggle-component.zs-range-calendar > table td[disabled] .zs-toggle-on,
.zs-toggle-component.zs-range-calendar > table td[disabled] .zs-toggle-off,
.zs-toggle-component.zs-range-calendar > section > table td[disabled] .zs-toggle-on,
.zs-toggle-component.zs-range-calendar > section > table td[disabled] .zs-toggle-off {
  background: none;
  background-color: #B2B0B6;
}
.zs-toggle-component[disabled] .zs-toggle-button,
.zs-toggle-component.zs-disabled .zs-toggle-button,
.zs-toggle-component.zs-calendar > table td[disabled] .zs-toggle-button,
.zs-toggle-component.zs-range-calendar > table td[disabled] .zs-toggle-button,
.zs-toggle-component.zs-range-calendar > section > table td[disabled] .zs-toggle-button {
  background: none;
  background-color: #F4F3F3;
}
.zs-bg-overlay-90 {
  background: rgba(255, 255, 255, 0.9) !important;
}
.zs-bg-overlay-60 {
  background: rgba(255, 255, 255, 0.6) !important;
}
.zs-bg-overlay-40 {
  background: rgba(255, 255, 255, 0.4) !important;
}
.zs-bg-overlay-20 {
  background: rgba(255, 255, 255, 0.2) !important;
}
.zs-bg-transparent {
  background: transparent !important;
}
.zs-bg-surface-5 {
  background: #1A1628 !important;
}
.zs-bg-surface-4 {
  background: #454250 !important;
}
.zs-bg-surface-3 {
  background: #716E79 !important;
}
.zs-bg-surface-2 {
  background: #F4F3F3 !important;
}
.zs-bg-surface-1 {
  background: #FAFAFA !important;
}
.zs-bg-data-color-12 {
  background: #E1F65D !important;
}
.zs-bg-data-color-11 {
  background: #5AD767 !important;
}
.zs-bg-data-color-10 {
  background: #18F3CC !important;
}
.zs-bg-data-color-9 {
  background: #70C2FE !important;
}
.zs-bg-data-color-8 {
  background: #5648FB !important;
}
.zs-bg-data-color-7 {
  background: #6328B8 !important;
}
.zs-bg-data-color-6 {
  background: #ED39DB !important;
}
.zs-bg-data-color-5 {
  background: #FD595F !important;
}
.zs-bg-data-color-4 {
  background: #EB6620 !important;
}
.zs-bg-data-color-3 {
  background: #FF9836 !important;
}
.zs-bg-data-color-2 {
  background: #FFC62B !important;
}
.zs-bg-data-color-1 {
  background: #FFE949 !important;
}
.zs-bg-border {
  background: #DEDCDE !important;
}
.zs-bg-shadow {
  background: #2F2C3C !important;
}
.zs-bg-selected {
  background: #027AFF !important;
}
.zs-bg-neutral-inverse {
  background: #FAFAFA !important;
}
.zs-bg-info-inverse {
  background: #EEEEFF !important;
}
.zs-bg-warning-inverse {
  background: #FFF9F1 !important;
}
.zs-bg-error-inverse {
  background: #FFEDE9 !important;
}
.zs-bg-success-inverse {
  background: #F1FEFF !important;
}
.zs-bg-info-secondary {
  background: #A6B2F3 !important;
}
.zs-bg-warning-secondary {
  background: #E3A900 !important;
}
.zs-bg-error-secondary {
  background: #FFB6A5 !important;
}
.zs-bg-success-secondary {
  background: #00AA67 !important;
}
.zs-bg-info {
  background: #2B44C7 !important;
}
.zs-bg-warning {
  background: #E3A900 !important;
}
.zs-bg-error {
  background: #B21111 !important;
}
.zs-bg-success {
  background: #00AA67 !important;
}
.zs-bg-link-hover {
  background: #27A6A4 !important;
}
.zs-bg-link {
  background: #2D8B93 !important;
}
.zs-bg-disabled {
  background: #B2B0B6 !important;
}
.zs-bg-standard {
  background: #ffffff !important;
}
.zs-text-disabled {
  color: #B2B0B6 !important;
}
.zs-text-medium {
  color: #454250 !important;
}
.zs-text-caption {
  color: #716E79 !important;
}
.zs-text-headline {
  color: #1A1628 !important;
}
.zs-text-inverse {
  color: #FAFAFA !important;
}
.zs-text-data-color-12 {
  color: #E1F65D !important;
}
.zs-text-data-color-11 {
  color: #5AD767 !important;
}
.zs-text-data-color-10 {
  color: #18F3CC !important;
}
.zs-text-data-color-9 {
  color: #70C2FE !important;
}
.zs-text-data-color-8 {
  color: #5648FB !important;
}
.zs-text-data-color-7 {
  color: #6328B8 !important;
}
.zs-text-data-color-6 {
  color: #ED39DB !important;
}
.zs-text-data-color-5 {
  color: #FD595F !important;
}
.zs-text-data-color-4 {
  color: #EB6620 !important;
}
.zs-text-data-color-3 {
  color: #FF9836 !important;
}
.zs-text-data-color-2 {
  color: #FFC62B !important;
}
.zs-text-data-color-1 {
  color: #FFE949 !important;
}
.zs-text-nav {
  color: #27A6A4 !important;
}
.zs-text-neutral {
  color: #1A1628 !important;
}
.zs-text-info {
  color: #2B44C7 !important;
}
.zs-text-warning {
  color: #E3A900 !important;
}
.zs-text-success {
  color: #00AA67 !important;
}
.zs-text-error {
  color: #B21111 !important;
}
.zs-text-default {
  color: #DEDCDE !important;
}
.zs-master-style.zs-bg-overlay-20,
.zs-master-style .zs-bg-overlay-20 {
  background: rgba(255, 255, 255, 0.2) !important;
}
.zs-master-style.zs-bg-overlay-40,
.zs-master-style .zs-bg-overlay-40 {
  background: rgba(255, 255, 255, 0.4) !important;
}
.zs-master-style.zs-bg-overlay-60,
.zs-master-style .zs-bg-overlay-60 {
  background: rgba(255, 255, 255, 0.6) !important;
}
.zs-master-style.zs-bg-overlay-90,
.zs-master-style .zs-bg-overlay-90 {
  background: rgba(255, 255, 255, 0.9) !important;
}
.zs-master-style.zs-text-inverse,
.zs-master-style .zs-text-inverse {
  color: #FAFAFA !important;
}
.zs-master-style.zs-text-default,
.zs-master-style .zs-text-default {
  color: #2F2C3C !important;
}
.zs-size-tiny,
.zs-size-t {
  font-size: 0.5em !important;
}
.zs-size-extra-extra-small,
.zs-size-xxs {
  font-size: 0.625em !important;
}
.zs-size-extra-small,
.zs-size-xs {
  font-size: 0.75em !important;
}
.zs-size-small,
.zs-size-s {
  font-size: 0.875em !important;
}
.zs-size-normal,
.zs-size-n {
  font-size: 1em !important;
}
.zs-size-large,
.zs-size-l {
  font-size: 1.25em !important;
}
.zs-size-extra-large,
.zs-size-xl {
  font-size: 1.5em !important;
}
.zs-size-extra-extra-large,
.zs-size-xxl {
  font-size: 2em !important;
}
.zs-size-super {
  font-size: 4em !important;
}
.zs-size-hero {
  font-size: 3em !important;
}
.zs-font-weight-light {
  font-weight: 300 !important;
}
.zs-font-weight-semi-bold {
  font-weight: 600 !important;
}
.zs-font-weight-normal {
  font-weight: normal !important;
}
.zs-font-weight-bold {
  font-weight: 700 !important;
}
.zs-font-style-oblique {
  font-style: oblique !important;
}
.zs-font-style-normal {
  font-style: normal !important;
}
.zs-font-style-italic {
  font-style: italic !important;
}
.zs-valign-super {
  vertical-align: super !important;
}
.zs-valign-sub {
  vertical-align: sub !important;
}
.zs-valign-text-top {
  vertical-align: text-top !important;
}
.zs-valign-text-bottom {
  vertical-align: text-bottom !important;
}
.zs-valign-bottom {
  vertical-align: bottom !important;
}
.zs-valign-middle {
  vertical-align: middle !important;
}
.zs-valign-top {
  vertical-align: top !important;
}
.zs-valign-baseline {
  vertical-align: baseline !important;
}
.zs-text-align-justify {
  text-align: justify !important;
}
.zs-text-align-center {
  text-align: center !important;
}
.zs-text-align-right {
  text-align: right !important;
}
.zs-text-align-left {
  text-align: left !important;
}
.zs-float-none {
  float: none !important;
}
.zs-float-right {
  float: right !important;
}
.zs-float-left {
  float: left !important;
}
.zs-clear-float:after {
  content: " ";
  display: block;
  clear: both;
}
.zs-height-0 {
  height: 0% !important;
}
.zs-width-0 {
  width: 0% !important;
}
.zs-height-10 {
  height: 10% !important;
}
.zs-width-10 {
  width: 10% !important;
}
.zs-height-20 {
  height: 20% !important;
}
.zs-width-20 {
  width: 20% !important;
}
.zs-height-25 {
  height: 25% !important;
}
.zs-width-25 {
  width: 25% !important;
}
.zs-height-30 {
  height: 30% !important;
}
.zs-width-30 {
  width: 30% !important;
}
.zs-height-40 {
  height: 40% !important;
}
.zs-width-40 {
  width: 40% !important;
}
.zs-height-50 {
  height: 50% !important;
}
.zs-width-50 {
  width: 50% !important;
}
.zs-height-60 {
  height: 60% !important;
}
.zs-width-60 {
  width: 60% !important;
}
.zs-height-70 {
  height: 70% !important;
}
.zs-width-70 {
  width: 70% !important;
}
.zs-height-75 {
  height: 75% !important;
}
.zs-width-75 {
  width: 75% !important;
}
.zs-height-80 {
  height: 80% !important;
}
.zs-width-80 {
  width: 80% !important;
}
.zs-height-90 {
  height: 90% !important;
}
.zs-width-90 {
  width: 90% !important;
}
.zs-height-100 {
  height: 100% !important;
}
.zs-width-100 {
  width: 100% !important;
}
.zs-height-auto {
  height: auto !important;
}
.zs-width-auto {
  width: auto !important;
}
.zs-display-inline-grid {
  display: inline-grid !important;
}
.zs-display-grid {
  display: grid !important;
}
.zs-display-inline-flex {
  display: inline-flex !important;
}
.zs-display-flex {
  display: flex !important;
}
.zs-display-table-cell {
  display: table-cell !important;
}
.zs-display-table-row {
  display: table-row !important;
}
.zs-display-table {
  display: table !important;
}
.zs-display-none {
  display: none !important;
}
.zs-display-inline-block {
  display: inline-block !important;
}
.zs-display-inline {
  display: inline !important;
}
.zs-display-block {
  display: block !important;
}
[class~="zs-padding-0"] {
  padding: 0em 0em 0em 0em !important;
}
[class~="zs-margin-0"] {
  margin: 0em 0em 0em 0em !important;
}
[class~="zs-padding-0.5"] {
  padding: 0.25em 0.25em 0.25em 0.25em !important;
}
[class~="zs-margin-0.5"] {
  margin: 0.25em 0.25em 0.25em 0.25em !important;
}
[class~="zs-padding-1"] {
  padding: 0.5em 0.5em 0.5em 0.5em !important;
}
[class~="zs-margin-1"] {
  margin: 0.5em 0.5em 0.5em 0.5em !important;
}
[class~="zs-padding-1.5"] {
  padding: 0.75em 0.75em 0.75em 0.75em !important;
}
[class~="zs-margin-1.5"] {
  margin: 0.75em 0.75em 0.75em 0.75em !important;
}
[class~="zs-padding-2"] {
  padding: 1em 1em 1em 1em !important;
}
[class~="zs-margin-2"] {
  margin: 1em 1em 1em 1em !important;
}
[class~="zs-padding-0-0"] {
  padding: 0em 0em 0em 0em !important;
}
[class~="zs-margin-0-0"] {
  margin: 0em 0em 0em 0em !important;
}
[class~="zs-padding-0-0.5"] {
  padding: 0em 0.25em 0em 0.25em !important;
}
[class~="zs-margin-0-0.5"] {
  margin: 0em 0.25em 0em 0.25em !important;
}
[class~="zs-padding-0-1"] {
  padding: 0em 0.5em 0em 0.5em !important;
}
[class~="zs-margin-0-1"] {
  margin: 0em 0.5em 0em 0.5em !important;
}
[class~="zs-padding-0-1.5"] {
  padding: 0em 0.75em 0em 0.75em !important;
}
[class~="zs-margin-0-1.5"] {
  margin: 0em 0.75em 0em 0.75em !important;
}
[class~="zs-padding-0-2"] {
  padding: 0em 1em 0em 1em !important;
}
[class~="zs-margin-0-2"] {
  margin: 0em 1em 0em 1em !important;
}
[class~="zs-padding-0.5-0"] {
  padding: 0.25em 0em 0.25em 0em !important;
}
[class~="zs-margin-0.5-0"] {
  margin: 0.25em 0em 0.25em 0em !important;
}
[class~="zs-padding-0.5-0.5"] {
  padding: 0.25em 0.25em 0.25em 0.25em !important;
}
[class~="zs-margin-0.5-0.5"] {
  margin: 0.25em 0.25em 0.25em 0.25em !important;
}
[class~="zs-padding-0.5-1"] {
  padding: 0.25em 0.5em 0.25em 0.5em !important;
}
[class~="zs-margin-0.5-1"] {
  margin: 0.25em 0.5em 0.25em 0.5em !important;
}
[class~="zs-padding-0.5-1.5"] {
  padding: 0.25em 0.75em 0.25em 0.75em !important;
}
[class~="zs-margin-0.5-1.5"] {
  margin: 0.25em 0.75em 0.25em 0.75em !important;
}
[class~="zs-padding-0.5-2"] {
  padding: 0.25em 1em 0.25em 1em !important;
}
[class~="zs-margin-0.5-2"] {
  margin: 0.25em 1em 0.25em 1em !important;
}
[class~="zs-padding-1-0"] {
  padding: 0.5em 0em 0.5em 0em !important;
}
[class~="zs-margin-1-0"] {
  margin: 0.5em 0em 0.5em 0em !important;
}
[class~="zs-padding-1-0.5"] {
  padding: 0.5em 0.25em 0.5em 0.25em !important;
}
[class~="zs-margin-1-0.5"] {
  margin: 0.5em 0.25em 0.5em 0.25em !important;
}
[class~="zs-padding-1-1"] {
  padding: 0.5em 0.5em 0.5em 0.5em !important;
}
[class~="zs-margin-1-1"] {
  margin: 0.5em 0.5em 0.5em 0.5em !important;
}
[class~="zs-padding-1-1.5"] {
  padding: 0.5em 0.75em 0.5em 0.75em !important;
}
[class~="zs-margin-1-1.5"] {
  margin: 0.5em 0.75em 0.5em 0.75em !important;
}
[class~="zs-padding-1-2"] {
  padding: 0.5em 1em 0.5em 1em !important;
}
[class~="zs-margin-1-2"] {
  margin: 0.5em 1em 0.5em 1em !important;
}
[class~="zs-padding-1.5-0"] {
  padding: 0.75em 0em 0.75em 0em !important;
}
[class~="zs-margin-1.5-0"] {
  margin: 0.75em 0em 0.75em 0em !important;
}
[class~="zs-padding-1.5-0.5"] {
  padding: 0.75em 0.25em 0.75em 0.25em !important;
}
[class~="zs-margin-1.5-0.5"] {
  margin: 0.75em 0.25em 0.75em 0.25em !important;
}
[class~="zs-padding-1.5-1"] {
  padding: 0.75em 0.5em 0.75em 0.5em !important;
}
[class~="zs-margin-1.5-1"] {
  margin: 0.75em 0.5em 0.75em 0.5em !important;
}
[class~="zs-padding-1.5-1.5"] {
  padding: 0.75em 0.75em 0.75em 0.75em !important;
}
[class~="zs-margin-1.5-1.5"] {
  margin: 0.75em 0.75em 0.75em 0.75em !important;
}
[class~="zs-padding-1.5-2"] {
  padding: 0.75em 1em 0.75em 1em !important;
}
[class~="zs-margin-1.5-2"] {
  margin: 0.75em 1em 0.75em 1em !important;
}
[class~="zs-padding-2-0"] {
  padding: 1em 0em 1em 0em !important;
}
[class~="zs-margin-2-0"] {
  margin: 1em 0em 1em 0em !important;
}
[class~="zs-padding-2-0.5"] {
  padding: 1em 0.25em 1em 0.25em !important;
}
[class~="zs-margin-2-0.5"] {
  margin: 1em 0.25em 1em 0.25em !important;
}
[class~="zs-padding-2-1"] {
  padding: 1em 0.5em 1em 0.5em !important;
}
[class~="zs-margin-2-1"] {
  margin: 1em 0.5em 1em 0.5em !important;
}
[class~="zs-padding-2-1.5"] {
  padding: 1em 0.75em 1em 0.75em !important;
}
[class~="zs-margin-2-1.5"] {
  margin: 1em 0.75em 1em 0.75em !important;
}
[class~="zs-padding-2-2"] {
  padding: 1em 1em 1em 1em !important;
}
[class~="zs-margin-2-2"] {
  margin: 1em 1em 1em 1em !important;
}
[class~="zs-padding-0-0-0-0"] {
  padding: 0em 0em 0em 0em !important;
}
[class~="zs-margin-0-0-0-0"] {
  margin: 0em 0em 0em 0em !important;
}
[class~="zs-padding-0-0-0-0.5"] {
  padding: 0em 0em 0em 0.25em !important;
}
[class~="zs-margin-0-0-0-0.5"] {
  margin: 0em 0em 0em 0.25em !important;
}
[class~="zs-padding-0-0-0-1"] {
  padding: 0em 0em 0em 0.5em !important;
}
[class~="zs-margin-0-0-0-1"] {
  margin: 0em 0em 0em 0.5em !important;
}
[class~="zs-padding-0-0-0-1.5"] {
  padding: 0em 0em 0em 0.75em !important;
}
[class~="zs-margin-0-0-0-1.5"] {
  margin: 0em 0em 0em 0.75em !important;
}
[class~="zs-padding-0-0-0-2"] {
  padding: 0em 0em 0em 1em !important;
}
[class~="zs-margin-0-0-0-2"] {
  margin: 0em 0em 0em 1em !important;
}
[class~="zs-padding-0-0-0.5-0"] {
  padding: 0em 0em 0.25em 0em !important;
}
[class~="zs-margin-0-0-0.5-0"] {
  margin: 0em 0em 0.25em 0em !important;
}
[class~="zs-padding-0-0-0.5-0.5"] {
  padding: 0em 0em 0.25em 0.25em !important;
}
[class~="zs-margin-0-0-0.5-0.5"] {
  margin: 0em 0em 0.25em 0.25em !important;
}
[class~="zs-padding-0-0-0.5-1"] {
  padding: 0em 0em 0.25em 0.5em !important;
}
[class~="zs-margin-0-0-0.5-1"] {
  margin: 0em 0em 0.25em 0.5em !important;
}
[class~="zs-padding-0-0-0.5-1.5"] {
  padding: 0em 0em 0.25em 0.75em !important;
}
[class~="zs-margin-0-0-0.5-1.5"] {
  margin: 0em 0em 0.25em 0.75em !important;
}
[class~="zs-padding-0-0-0.5-2"] {
  padding: 0em 0em 0.25em 1em !important;
}
[class~="zs-margin-0-0-0.5-2"] {
  margin: 0em 0em 0.25em 1em !important;
}
[class~="zs-padding-0-0-1-0"] {
  padding: 0em 0em 0.5em 0em !important;
}
[class~="zs-margin-0-0-1-0"] {
  margin: 0em 0em 0.5em 0em !important;
}
[class~="zs-padding-0-0-1-0.5"] {
  padding: 0em 0em 0.5em 0.25em !important;
}
[class~="zs-margin-0-0-1-0.5"] {
  margin: 0em 0em 0.5em 0.25em !important;
}
[class~="zs-padding-0-0-1-1"] {
  padding: 0em 0em 0.5em 0.5em !important;
}
[class~="zs-margin-0-0-1-1"] {
  margin: 0em 0em 0.5em 0.5em !important;
}
[class~="zs-padding-0-0-1-1.5"] {
  padding: 0em 0em 0.5em 0.75em !important;
}
[class~="zs-margin-0-0-1-1.5"] {
  margin: 0em 0em 0.5em 0.75em !important;
}
[class~="zs-padding-0-0-1-2"] {
  padding: 0em 0em 0.5em 1em !important;
}
[class~="zs-margin-0-0-1-2"] {
  margin: 0em 0em 0.5em 1em !important;
}
[class~="zs-padding-0-0-1.5-0"] {
  padding: 0em 0em 0.75em 0em !important;
}
[class~="zs-margin-0-0-1.5-0"] {
  margin: 0em 0em 0.75em 0em !important;
}
[class~="zs-padding-0-0-1.5-0.5"] {
  padding: 0em 0em 0.75em 0.25em !important;
}
[class~="zs-margin-0-0-1.5-0.5"] {
  margin: 0em 0em 0.75em 0.25em !important;
}
[class~="zs-padding-0-0-1.5-1"] {
  padding: 0em 0em 0.75em 0.5em !important;
}
[class~="zs-margin-0-0-1.5-1"] {
  margin: 0em 0em 0.75em 0.5em !important;
}
[class~="zs-padding-0-0-1.5-1.5"] {
  padding: 0em 0em 0.75em 0.75em !important;
}
[class~="zs-margin-0-0-1.5-1.5"] {
  margin: 0em 0em 0.75em 0.75em !important;
}
[class~="zs-padding-0-0-1.5-2"] {
  padding: 0em 0em 0.75em 1em !important;
}
[class~="zs-margin-0-0-1.5-2"] {
  margin: 0em 0em 0.75em 1em !important;
}
[class~="zs-padding-0-0-2-0"] {
  padding: 0em 0em 1em 0em !important;
}
[class~="zs-margin-0-0-2-0"] {
  margin: 0em 0em 1em 0em !important;
}
[class~="zs-padding-0-0-2-0.5"] {
  padding: 0em 0em 1em 0.25em !important;
}
[class~="zs-margin-0-0-2-0.5"] {
  margin: 0em 0em 1em 0.25em !important;
}
[class~="zs-padding-0-0-2-1"] {
  padding: 0em 0em 1em 0.5em !important;
}
[class~="zs-margin-0-0-2-1"] {
  margin: 0em 0em 1em 0.5em !important;
}
[class~="zs-padding-0-0-2-1.5"] {
  padding: 0em 0em 1em 0.75em !important;
}
[class~="zs-margin-0-0-2-1.5"] {
  margin: 0em 0em 1em 0.75em !important;
}
[class~="zs-padding-0-0-2-2"] {
  padding: 0em 0em 1em 1em !important;
}
[class~="zs-margin-0-0-2-2"] {
  margin: 0em 0em 1em 1em !important;
}
[class~="zs-padding-0-0.5-0-0"] {
  padding: 0em 0.25em 0em 0em !important;
}
[class~="zs-margin-0-0.5-0-0"] {
  margin: 0em 0.25em 0em 0em !important;
}
[class~="zs-padding-0-0.5-0-0.5"] {
  padding: 0em 0.25em 0em 0.25em !important;
}
[class~="zs-margin-0-0.5-0-0.5"] {
  margin: 0em 0.25em 0em 0.25em !important;
}
[class~="zs-padding-0-0.5-0-1"] {
  padding: 0em 0.25em 0em 0.5em !important;
}
[class~="zs-margin-0-0.5-0-1"] {
  margin: 0em 0.25em 0em 0.5em !important;
}
[class~="zs-padding-0-0.5-0-1.5"] {
  padding: 0em 0.25em 0em 0.75em !important;
}
[class~="zs-margin-0-0.5-0-1.5"] {
  margin: 0em 0.25em 0em 0.75em !important;
}
[class~="zs-padding-0-0.5-0-2"] {
  padding: 0em 0.25em 0em 1em !important;
}
[class~="zs-margin-0-0.5-0-2"] {
  margin: 0em 0.25em 0em 1em !important;
}
[class~="zs-padding-0-0.5-0.5-0"] {
  padding: 0em 0.25em 0.25em 0em !important;
}
[class~="zs-margin-0-0.5-0.5-0"] {
  margin: 0em 0.25em 0.25em 0em !important;
}
[class~="zs-padding-0-0.5-0.5-0.5"] {
  padding: 0em 0.25em 0.25em 0.25em !important;
}
[class~="zs-margin-0-0.5-0.5-0.5"] {
  margin: 0em 0.25em 0.25em 0.25em !important;
}
[class~="zs-padding-0-0.5-0.5-1"] {
  padding: 0em 0.25em 0.25em 0.5em !important;
}
[class~="zs-margin-0-0.5-0.5-1"] {
  margin: 0em 0.25em 0.25em 0.5em !important;
}
[class~="zs-padding-0-0.5-0.5-1.5"] {
  padding: 0em 0.25em 0.25em 0.75em !important;
}
[class~="zs-margin-0-0.5-0.5-1.5"] {
  margin: 0em 0.25em 0.25em 0.75em !important;
}
[class~="zs-padding-0-0.5-0.5-2"] {
  padding: 0em 0.25em 0.25em 1em !important;
}
[class~="zs-margin-0-0.5-0.5-2"] {
  margin: 0em 0.25em 0.25em 1em !important;
}
[class~="zs-padding-0-0.5-1-0"] {
  padding: 0em 0.25em 0.5em 0em !important;
}
[class~="zs-margin-0-0.5-1-0"] {
  margin: 0em 0.25em 0.5em 0em !important;
}
[class~="zs-padding-0-0.5-1-0.5"] {
  padding: 0em 0.25em 0.5em 0.25em !important;
}
[class~="zs-margin-0-0.5-1-0.5"] {
  margin: 0em 0.25em 0.5em 0.25em !important;
}
[class~="zs-padding-0-0.5-1-1"] {
  padding: 0em 0.25em 0.5em 0.5em !important;
}
[class~="zs-margin-0-0.5-1-1"] {
  margin: 0em 0.25em 0.5em 0.5em !important;
}
[class~="zs-padding-0-0.5-1-1.5"] {
  padding: 0em 0.25em 0.5em 0.75em !important;
}
[class~="zs-margin-0-0.5-1-1.5"] {
  margin: 0em 0.25em 0.5em 0.75em !important;
}
[class~="zs-padding-0-0.5-1-2"] {
  padding: 0em 0.25em 0.5em 1em !important;
}
[class~="zs-margin-0-0.5-1-2"] {
  margin: 0em 0.25em 0.5em 1em !important;
}
[class~="zs-padding-0-0.5-1.5-0"] {
  padding: 0em 0.25em 0.75em 0em !important;
}
[class~="zs-margin-0-0.5-1.5-0"] {
  margin: 0em 0.25em 0.75em 0em !important;
}
[class~="zs-padding-0-0.5-1.5-0.5"] {
  padding: 0em 0.25em 0.75em 0.25em !important;
}
[class~="zs-margin-0-0.5-1.5-0.5"] {
  margin: 0em 0.25em 0.75em 0.25em !important;
}
[class~="zs-padding-0-0.5-1.5-1"] {
  padding: 0em 0.25em 0.75em 0.5em !important;
}
[class~="zs-margin-0-0.5-1.5-1"] {
  margin: 0em 0.25em 0.75em 0.5em !important;
}
[class~="zs-padding-0-0.5-1.5-1.5"] {
  padding: 0em 0.25em 0.75em 0.75em !important;
}
[class~="zs-margin-0-0.5-1.5-1.5"] {
  margin: 0em 0.25em 0.75em 0.75em !important;
}
[class~="zs-padding-0-0.5-1.5-2"] {
  padding: 0em 0.25em 0.75em 1em !important;
}
[class~="zs-margin-0-0.5-1.5-2"] {
  margin: 0em 0.25em 0.75em 1em !important;
}
[class~="zs-padding-0-0.5-2-0"] {
  padding: 0em 0.25em 1em 0em !important;
}
[class~="zs-margin-0-0.5-2-0"] {
  margin: 0em 0.25em 1em 0em !important;
}
[class~="zs-padding-0-0.5-2-0.5"] {
  padding: 0em 0.25em 1em 0.25em !important;
}
[class~="zs-margin-0-0.5-2-0.5"] {
  margin: 0em 0.25em 1em 0.25em !important;
}
[class~="zs-padding-0-0.5-2-1"] {
  padding: 0em 0.25em 1em 0.5em !important;
}
[class~="zs-margin-0-0.5-2-1"] {
  margin: 0em 0.25em 1em 0.5em !important;
}
[class~="zs-padding-0-0.5-2-1.5"] {
  padding: 0em 0.25em 1em 0.75em !important;
}
[class~="zs-margin-0-0.5-2-1.5"] {
  margin: 0em 0.25em 1em 0.75em !important;
}
[class~="zs-padding-0-0.5-2-2"] {
  padding: 0em 0.25em 1em 1em !important;
}
[class~="zs-margin-0-0.5-2-2"] {
  margin: 0em 0.25em 1em 1em !important;
}
[class~="zs-padding-0-1-0-0"] {
  padding: 0em 0.5em 0em 0em !important;
}
[class~="zs-margin-0-1-0-0"] {
  margin: 0em 0.5em 0em 0em !important;
}
[class~="zs-padding-0-1-0-0.5"] {
  padding: 0em 0.5em 0em 0.25em !important;
}
[class~="zs-margin-0-1-0-0.5"] {
  margin: 0em 0.5em 0em 0.25em !important;
}
[class~="zs-padding-0-1-0-1"] {
  padding: 0em 0.5em 0em 0.5em !important;
}
[class~="zs-margin-0-1-0-1"] {
  margin: 0em 0.5em 0em 0.5em !important;
}
[class~="zs-padding-0-1-0-1.5"] {
  padding: 0em 0.5em 0em 0.75em !important;
}
[class~="zs-margin-0-1-0-1.5"] {
  margin: 0em 0.5em 0em 0.75em !important;
}
[class~="zs-padding-0-1-0-2"] {
  padding: 0em 0.5em 0em 1em !important;
}
[class~="zs-margin-0-1-0-2"] {
  margin: 0em 0.5em 0em 1em !important;
}
[class~="zs-padding-0-1-0.5-0"] {
  padding: 0em 0.5em 0.25em 0em !important;
}
[class~="zs-margin-0-1-0.5-0"] {
  margin: 0em 0.5em 0.25em 0em !important;
}
[class~="zs-padding-0-1-0.5-0.5"] {
  padding: 0em 0.5em 0.25em 0.25em !important;
}
[class~="zs-margin-0-1-0.5-0.5"] {
  margin: 0em 0.5em 0.25em 0.25em !important;
}
[class~="zs-padding-0-1-0.5-1"] {
  padding: 0em 0.5em 0.25em 0.5em !important;
}
[class~="zs-margin-0-1-0.5-1"] {
  margin: 0em 0.5em 0.25em 0.5em !important;
}
[class~="zs-padding-0-1-0.5-1.5"] {
  padding: 0em 0.5em 0.25em 0.75em !important;
}
[class~="zs-margin-0-1-0.5-1.5"] {
  margin: 0em 0.5em 0.25em 0.75em !important;
}
[class~="zs-padding-0-1-0.5-2"] {
  padding: 0em 0.5em 0.25em 1em !important;
}
[class~="zs-margin-0-1-0.5-2"] {
  margin: 0em 0.5em 0.25em 1em !important;
}
[class~="zs-padding-0-1-1-0"] {
  padding: 0em 0.5em 0.5em 0em !important;
}
[class~="zs-margin-0-1-1-0"] {
  margin: 0em 0.5em 0.5em 0em !important;
}
[class~="zs-padding-0-1-1-0.5"] {
  padding: 0em 0.5em 0.5em 0.25em !important;
}
[class~="zs-margin-0-1-1-0.5"] {
  margin: 0em 0.5em 0.5em 0.25em !important;
}
[class~="zs-padding-0-1-1-1"] {
  padding: 0em 0.5em 0.5em 0.5em !important;
}
[class~="zs-margin-0-1-1-1"] {
  margin: 0em 0.5em 0.5em 0.5em !important;
}
[class~="zs-padding-0-1-1-1.5"] {
  padding: 0em 0.5em 0.5em 0.75em !important;
}
[class~="zs-margin-0-1-1-1.5"] {
  margin: 0em 0.5em 0.5em 0.75em !important;
}
[class~="zs-padding-0-1-1-2"] {
  padding: 0em 0.5em 0.5em 1em !important;
}
[class~="zs-margin-0-1-1-2"] {
  margin: 0em 0.5em 0.5em 1em !important;
}
[class~="zs-padding-0-1-1.5-0"] {
  padding: 0em 0.5em 0.75em 0em !important;
}
[class~="zs-margin-0-1-1.5-0"] {
  margin: 0em 0.5em 0.75em 0em !important;
}
[class~="zs-padding-0-1-1.5-0.5"] {
  padding: 0em 0.5em 0.75em 0.25em !important;
}
[class~="zs-margin-0-1-1.5-0.5"] {
  margin: 0em 0.5em 0.75em 0.25em !important;
}
[class~="zs-padding-0-1-1.5-1"] {
  padding: 0em 0.5em 0.75em 0.5em !important;
}
[class~="zs-margin-0-1-1.5-1"] {
  margin: 0em 0.5em 0.75em 0.5em !important;
}
[class~="zs-padding-0-1-1.5-1.5"] {
  padding: 0em 0.5em 0.75em 0.75em !important;
}
[class~="zs-margin-0-1-1.5-1.5"] {
  margin: 0em 0.5em 0.75em 0.75em !important;
}
[class~="zs-padding-0-1-1.5-2"] {
  padding: 0em 0.5em 0.75em 1em !important;
}
[class~="zs-margin-0-1-1.5-2"] {
  margin: 0em 0.5em 0.75em 1em !important;
}
[class~="zs-padding-0-1-2-0"] {
  padding: 0em 0.5em 1em 0em !important;
}
[class~="zs-margin-0-1-2-0"] {
  margin: 0em 0.5em 1em 0em !important;
}
[class~="zs-padding-0-1-2-0.5"] {
  padding: 0em 0.5em 1em 0.25em !important;
}
[class~="zs-margin-0-1-2-0.5"] {
  margin: 0em 0.5em 1em 0.25em !important;
}
[class~="zs-padding-0-1-2-1"] {
  padding: 0em 0.5em 1em 0.5em !important;
}
[class~="zs-margin-0-1-2-1"] {
  margin: 0em 0.5em 1em 0.5em !important;
}
[class~="zs-padding-0-1-2-1.5"] {
  padding: 0em 0.5em 1em 0.75em !important;
}
[class~="zs-margin-0-1-2-1.5"] {
  margin: 0em 0.5em 1em 0.75em !important;
}
[class~="zs-padding-0-1-2-2"] {
  padding: 0em 0.5em 1em 1em !important;
}
[class~="zs-margin-0-1-2-2"] {
  margin: 0em 0.5em 1em 1em !important;
}
[class~="zs-padding-0-1.5-0-0"] {
  padding: 0em 0.75em 0em 0em !important;
}
[class~="zs-margin-0-1.5-0-0"] {
  margin: 0em 0.75em 0em 0em !important;
}
[class~="zs-padding-0-1.5-0-0.5"] {
  padding: 0em 0.75em 0em 0.25em !important;
}
[class~="zs-margin-0-1.5-0-0.5"] {
  margin: 0em 0.75em 0em 0.25em !important;
}
[class~="zs-padding-0-1.5-0-1"] {
  padding: 0em 0.75em 0em 0.5em !important;
}
[class~="zs-margin-0-1.5-0-1"] {
  margin: 0em 0.75em 0em 0.5em !important;
}
[class~="zs-padding-0-1.5-0-1.5"] {
  padding: 0em 0.75em 0em 0.75em !important;
}
[class~="zs-margin-0-1.5-0-1.5"] {
  margin: 0em 0.75em 0em 0.75em !important;
}
[class~="zs-padding-0-1.5-0-2"] {
  padding: 0em 0.75em 0em 1em !important;
}
[class~="zs-margin-0-1.5-0-2"] {
  margin: 0em 0.75em 0em 1em !important;
}
[class~="zs-padding-0-1.5-0.5-0"] {
  padding: 0em 0.75em 0.25em 0em !important;
}
[class~="zs-margin-0-1.5-0.5-0"] {
  margin: 0em 0.75em 0.25em 0em !important;
}
[class~="zs-padding-0-1.5-0.5-0.5"] {
  padding: 0em 0.75em 0.25em 0.25em !important;
}
[class~="zs-margin-0-1.5-0.5-0.5"] {
  margin: 0em 0.75em 0.25em 0.25em !important;
}
[class~="zs-padding-0-1.5-0.5-1"] {
  padding: 0em 0.75em 0.25em 0.5em !important;
}
[class~="zs-margin-0-1.5-0.5-1"] {
  margin: 0em 0.75em 0.25em 0.5em !important;
}
[class~="zs-padding-0-1.5-0.5-1.5"] {
  padding: 0em 0.75em 0.25em 0.75em !important;
}
[class~="zs-margin-0-1.5-0.5-1.5"] {
  margin: 0em 0.75em 0.25em 0.75em !important;
}
[class~="zs-padding-0-1.5-0.5-2"] {
  padding: 0em 0.75em 0.25em 1em !important;
}
[class~="zs-margin-0-1.5-0.5-2"] {
  margin: 0em 0.75em 0.25em 1em !important;
}
[class~="zs-padding-0-1.5-1-0"] {
  padding: 0em 0.75em 0.5em 0em !important;
}
[class~="zs-margin-0-1.5-1-0"] {
  margin: 0em 0.75em 0.5em 0em !important;
}
[class~="zs-padding-0-1.5-1-0.5"] {
  padding: 0em 0.75em 0.5em 0.25em !important;
}
[class~="zs-margin-0-1.5-1-0.5"] {
  margin: 0em 0.75em 0.5em 0.25em !important;
}
[class~="zs-padding-0-1.5-1-1"] {
  padding: 0em 0.75em 0.5em 0.5em !important;
}
[class~="zs-margin-0-1.5-1-1"] {
  margin: 0em 0.75em 0.5em 0.5em !important;
}
[class~="zs-padding-0-1.5-1-1.5"] {
  padding: 0em 0.75em 0.5em 0.75em !important;
}
[class~="zs-margin-0-1.5-1-1.5"] {
  margin: 0em 0.75em 0.5em 0.75em !important;
}
[class~="zs-padding-0-1.5-1-2"] {
  padding: 0em 0.75em 0.5em 1em !important;
}
[class~="zs-margin-0-1.5-1-2"] {
  margin: 0em 0.75em 0.5em 1em !important;
}
[class~="zs-padding-0-1.5-1.5-0"] {
  padding: 0em 0.75em 0.75em 0em !important;
}
[class~="zs-margin-0-1.5-1.5-0"] {
  margin: 0em 0.75em 0.75em 0em !important;
}
[class~="zs-padding-0-1.5-1.5-0.5"] {
  padding: 0em 0.75em 0.75em 0.25em !important;
}
[class~="zs-margin-0-1.5-1.5-0.5"] {
  margin: 0em 0.75em 0.75em 0.25em !important;
}
[class~="zs-padding-0-1.5-1.5-1"] {
  padding: 0em 0.75em 0.75em 0.5em !important;
}
[class~="zs-margin-0-1.5-1.5-1"] {
  margin: 0em 0.75em 0.75em 0.5em !important;
}
[class~="zs-padding-0-1.5-1.5-1.5"] {
  padding: 0em 0.75em 0.75em 0.75em !important;
}
[class~="zs-margin-0-1.5-1.5-1.5"] {
  margin: 0em 0.75em 0.75em 0.75em !important;
}
[class~="zs-padding-0-1.5-1.5-2"] {
  padding: 0em 0.75em 0.75em 1em !important;
}
[class~="zs-margin-0-1.5-1.5-2"] {
  margin: 0em 0.75em 0.75em 1em !important;
}
[class~="zs-padding-0-1.5-2-0"] {
  padding: 0em 0.75em 1em 0em !important;
}
[class~="zs-margin-0-1.5-2-0"] {
  margin: 0em 0.75em 1em 0em !important;
}
[class~="zs-padding-0-1.5-2-0.5"] {
  padding: 0em 0.75em 1em 0.25em !important;
}
[class~="zs-margin-0-1.5-2-0.5"] {
  margin: 0em 0.75em 1em 0.25em !important;
}
[class~="zs-padding-0-1.5-2-1"] {
  padding: 0em 0.75em 1em 0.5em !important;
}
[class~="zs-margin-0-1.5-2-1"] {
  margin: 0em 0.75em 1em 0.5em !important;
}
[class~="zs-padding-0-1.5-2-1.5"] {
  padding: 0em 0.75em 1em 0.75em !important;
}
[class~="zs-margin-0-1.5-2-1.5"] {
  margin: 0em 0.75em 1em 0.75em !important;
}
[class~="zs-padding-0-1.5-2-2"] {
  padding: 0em 0.75em 1em 1em !important;
}
[class~="zs-margin-0-1.5-2-2"] {
  margin: 0em 0.75em 1em 1em !important;
}
[class~="zs-padding-0-2-0-0"] {
  padding: 0em 1em 0em 0em !important;
}
[class~="zs-margin-0-2-0-0"] {
  margin: 0em 1em 0em 0em !important;
}
[class~="zs-padding-0-2-0-0.5"] {
  padding: 0em 1em 0em 0.25em !important;
}
[class~="zs-margin-0-2-0-0.5"] {
  margin: 0em 1em 0em 0.25em !important;
}
[class~="zs-padding-0-2-0-1"] {
  padding: 0em 1em 0em 0.5em !important;
}
[class~="zs-margin-0-2-0-1"] {
  margin: 0em 1em 0em 0.5em !important;
}
[class~="zs-padding-0-2-0-1.5"] {
  padding: 0em 1em 0em 0.75em !important;
}
[class~="zs-margin-0-2-0-1.5"] {
  margin: 0em 1em 0em 0.75em !important;
}
[class~="zs-padding-0-2-0-2"] {
  padding: 0em 1em 0em 1em !important;
}
[class~="zs-margin-0-2-0-2"] {
  margin: 0em 1em 0em 1em !important;
}
[class~="zs-padding-0-2-0.5-0"] {
  padding: 0em 1em 0.25em 0em !important;
}
[class~="zs-margin-0-2-0.5-0"] {
  margin: 0em 1em 0.25em 0em !important;
}
[class~="zs-padding-0-2-0.5-0.5"] {
  padding: 0em 1em 0.25em 0.25em !important;
}
[class~="zs-margin-0-2-0.5-0.5"] {
  margin: 0em 1em 0.25em 0.25em !important;
}
[class~="zs-padding-0-2-0.5-1"] {
  padding: 0em 1em 0.25em 0.5em !important;
}
[class~="zs-margin-0-2-0.5-1"] {
  margin: 0em 1em 0.25em 0.5em !important;
}
[class~="zs-padding-0-2-0.5-1.5"] {
  padding: 0em 1em 0.25em 0.75em !important;
}
[class~="zs-margin-0-2-0.5-1.5"] {
  margin: 0em 1em 0.25em 0.75em !important;
}
[class~="zs-padding-0-2-0.5-2"] {
  padding: 0em 1em 0.25em 1em !important;
}
[class~="zs-margin-0-2-0.5-2"] {
  margin: 0em 1em 0.25em 1em !important;
}
[class~="zs-padding-0-2-1-0"] {
  padding: 0em 1em 0.5em 0em !important;
}
[class~="zs-margin-0-2-1-0"] {
  margin: 0em 1em 0.5em 0em !important;
}
[class~="zs-padding-0-2-1-0.5"] {
  padding: 0em 1em 0.5em 0.25em !important;
}
[class~="zs-margin-0-2-1-0.5"] {
  margin: 0em 1em 0.5em 0.25em !important;
}
[class~="zs-padding-0-2-1-1"] {
  padding: 0em 1em 0.5em 0.5em !important;
}
[class~="zs-margin-0-2-1-1"] {
  margin: 0em 1em 0.5em 0.5em !important;
}
[class~="zs-padding-0-2-1-1.5"] {
  padding: 0em 1em 0.5em 0.75em !important;
}
[class~="zs-margin-0-2-1-1.5"] {
  margin: 0em 1em 0.5em 0.75em !important;
}
[class~="zs-padding-0-2-1-2"] {
  padding: 0em 1em 0.5em 1em !important;
}
[class~="zs-margin-0-2-1-2"] {
  margin: 0em 1em 0.5em 1em !important;
}
[class~="zs-padding-0-2-1.5-0"] {
  padding: 0em 1em 0.75em 0em !important;
}
[class~="zs-margin-0-2-1.5-0"] {
  margin: 0em 1em 0.75em 0em !important;
}
[class~="zs-padding-0-2-1.5-0.5"] {
  padding: 0em 1em 0.75em 0.25em !important;
}
[class~="zs-margin-0-2-1.5-0.5"] {
  margin: 0em 1em 0.75em 0.25em !important;
}
[class~="zs-padding-0-2-1.5-1"] {
  padding: 0em 1em 0.75em 0.5em !important;
}
[class~="zs-margin-0-2-1.5-1"] {
  margin: 0em 1em 0.75em 0.5em !important;
}
[class~="zs-padding-0-2-1.5-1.5"] {
  padding: 0em 1em 0.75em 0.75em !important;
}
[class~="zs-margin-0-2-1.5-1.5"] {
  margin: 0em 1em 0.75em 0.75em !important;
}
[class~="zs-padding-0-2-1.5-2"] {
  padding: 0em 1em 0.75em 1em !important;
}
[class~="zs-margin-0-2-1.5-2"] {
  margin: 0em 1em 0.75em 1em !important;
}
[class~="zs-padding-0-2-2-0"] {
  padding: 0em 1em 1em 0em !important;
}
[class~="zs-margin-0-2-2-0"] {
  margin: 0em 1em 1em 0em !important;
}
[class~="zs-padding-0-2-2-0.5"] {
  padding: 0em 1em 1em 0.25em !important;
}
[class~="zs-margin-0-2-2-0.5"] {
  margin: 0em 1em 1em 0.25em !important;
}
[class~="zs-padding-0-2-2-1"] {
  padding: 0em 1em 1em 0.5em !important;
}
[class~="zs-margin-0-2-2-1"] {
  margin: 0em 1em 1em 0.5em !important;
}
[class~="zs-padding-0-2-2-1.5"] {
  padding: 0em 1em 1em 0.75em !important;
}
[class~="zs-margin-0-2-2-1.5"] {
  margin: 0em 1em 1em 0.75em !important;
}
[class~="zs-padding-0-2-2-2"] {
  padding: 0em 1em 1em 1em !important;
}
[class~="zs-margin-0-2-2-2"] {
  margin: 0em 1em 1em 1em !important;
}
[class~="zs-padding-0.5-0-0-0"] {
  padding: 0.25em 0em 0em 0em !important;
}
[class~="zs-margin-0.5-0-0-0"] {
  margin: 0.25em 0em 0em 0em !important;
}
[class~="zs-padding-0.5-0-0-0.5"] {
  padding: 0.25em 0em 0em 0.25em !important;
}
[class~="zs-margin-0.5-0-0-0.5"] {
  margin: 0.25em 0em 0em 0.25em !important;
}
[class~="zs-padding-0.5-0-0-1"] {
  padding: 0.25em 0em 0em 0.5em !important;
}
[class~="zs-margin-0.5-0-0-1"] {
  margin: 0.25em 0em 0em 0.5em !important;
}
[class~="zs-padding-0.5-0-0-1.5"] {
  padding: 0.25em 0em 0em 0.75em !important;
}
[class~="zs-margin-0.5-0-0-1.5"] {
  margin: 0.25em 0em 0em 0.75em !important;
}
[class~="zs-padding-0.5-0-0-2"] {
  padding: 0.25em 0em 0em 1em !important;
}
[class~="zs-margin-0.5-0-0-2"] {
  margin: 0.25em 0em 0em 1em !important;
}
[class~="zs-padding-0.5-0-0.5-0"] {
  padding: 0.25em 0em 0.25em 0em !important;
}
[class~="zs-margin-0.5-0-0.5-0"] {
  margin: 0.25em 0em 0.25em 0em !important;
}
[class~="zs-padding-0.5-0-0.5-0.5"] {
  padding: 0.25em 0em 0.25em 0.25em !important;
}
[class~="zs-margin-0.5-0-0.5-0.5"] {
  margin: 0.25em 0em 0.25em 0.25em !important;
}
[class~="zs-padding-0.5-0-0.5-1"] {
  padding: 0.25em 0em 0.25em 0.5em !important;
}
[class~="zs-margin-0.5-0-0.5-1"] {
  margin: 0.25em 0em 0.25em 0.5em !important;
}
[class~="zs-padding-0.5-0-0.5-1.5"] {
  padding: 0.25em 0em 0.25em 0.75em !important;
}
[class~="zs-margin-0.5-0-0.5-1.5"] {
  margin: 0.25em 0em 0.25em 0.75em !important;
}
[class~="zs-padding-0.5-0-0.5-2"] {
  padding: 0.25em 0em 0.25em 1em !important;
}
[class~="zs-margin-0.5-0-0.5-2"] {
  margin: 0.25em 0em 0.25em 1em !important;
}
[class~="zs-padding-0.5-0-1-0"] {
  padding: 0.25em 0em 0.5em 0em !important;
}
[class~="zs-margin-0.5-0-1-0"] {
  margin: 0.25em 0em 0.5em 0em !important;
}
[class~="zs-padding-0.5-0-1-0.5"] {
  padding: 0.25em 0em 0.5em 0.25em !important;
}
[class~="zs-margin-0.5-0-1-0.5"] {
  margin: 0.25em 0em 0.5em 0.25em !important;
}
[class~="zs-padding-0.5-0-1-1"] {
  padding: 0.25em 0em 0.5em 0.5em !important;
}
[class~="zs-margin-0.5-0-1-1"] {
  margin: 0.25em 0em 0.5em 0.5em !important;
}
[class~="zs-padding-0.5-0-1-1.5"] {
  padding: 0.25em 0em 0.5em 0.75em !important;
}
[class~="zs-margin-0.5-0-1-1.5"] {
  margin: 0.25em 0em 0.5em 0.75em !important;
}
[class~="zs-padding-0.5-0-1-2"] {
  padding: 0.25em 0em 0.5em 1em !important;
}
[class~="zs-margin-0.5-0-1-2"] {
  margin: 0.25em 0em 0.5em 1em !important;
}
[class~="zs-padding-0.5-0-1.5-0"] {
  padding: 0.25em 0em 0.75em 0em !important;
}
[class~="zs-margin-0.5-0-1.5-0"] {
  margin: 0.25em 0em 0.75em 0em !important;
}
[class~="zs-padding-0.5-0-1.5-0.5"] {
  padding: 0.25em 0em 0.75em 0.25em !important;
}
[class~="zs-margin-0.5-0-1.5-0.5"] {
  margin: 0.25em 0em 0.75em 0.25em !important;
}
[class~="zs-padding-0.5-0-1.5-1"] {
  padding: 0.25em 0em 0.75em 0.5em !important;
}
[class~="zs-margin-0.5-0-1.5-1"] {
  margin: 0.25em 0em 0.75em 0.5em !important;
}
[class~="zs-padding-0.5-0-1.5-1.5"] {
  padding: 0.25em 0em 0.75em 0.75em !important;
}
[class~="zs-margin-0.5-0-1.5-1.5"] {
  margin: 0.25em 0em 0.75em 0.75em !important;
}
[class~="zs-padding-0.5-0-1.5-2"] {
  padding: 0.25em 0em 0.75em 1em !important;
}
[class~="zs-margin-0.5-0-1.5-2"] {
  margin: 0.25em 0em 0.75em 1em !important;
}
[class~="zs-padding-0.5-0-2-0"] {
  padding: 0.25em 0em 1em 0em !important;
}
[class~="zs-margin-0.5-0-2-0"] {
  margin: 0.25em 0em 1em 0em !important;
}
[class~="zs-padding-0.5-0-2-0.5"] {
  padding: 0.25em 0em 1em 0.25em !important;
}
[class~="zs-margin-0.5-0-2-0.5"] {
  margin: 0.25em 0em 1em 0.25em !important;
}
[class~="zs-padding-0.5-0-2-1"] {
  padding: 0.25em 0em 1em 0.5em !important;
}
[class~="zs-margin-0.5-0-2-1"] {
  margin: 0.25em 0em 1em 0.5em !important;
}
[class~="zs-padding-0.5-0-2-1.5"] {
  padding: 0.25em 0em 1em 0.75em !important;
}
[class~="zs-margin-0.5-0-2-1.5"] {
  margin: 0.25em 0em 1em 0.75em !important;
}
[class~="zs-padding-0.5-0-2-2"] {
  padding: 0.25em 0em 1em 1em !important;
}
[class~="zs-margin-0.5-0-2-2"] {
  margin: 0.25em 0em 1em 1em !important;
}
[class~="zs-padding-0.5-0.5-0-0"] {
  padding: 0.25em 0.25em 0em 0em !important;
}
[class~="zs-margin-0.5-0.5-0-0"] {
  margin: 0.25em 0.25em 0em 0em !important;
}
[class~="zs-padding-0.5-0.5-0-0.5"] {
  padding: 0.25em 0.25em 0em 0.25em !important;
}
[class~="zs-margin-0.5-0.5-0-0.5"] {
  margin: 0.25em 0.25em 0em 0.25em !important;
}
[class~="zs-padding-0.5-0.5-0-1"] {
  padding: 0.25em 0.25em 0em 0.5em !important;
}
[class~="zs-margin-0.5-0.5-0-1"] {
  margin: 0.25em 0.25em 0em 0.5em !important;
}
[class~="zs-padding-0.5-0.5-0-1.5"] {
  padding: 0.25em 0.25em 0em 0.75em !important;
}
[class~="zs-margin-0.5-0.5-0-1.5"] {
  margin: 0.25em 0.25em 0em 0.75em !important;
}
[class~="zs-padding-0.5-0.5-0-2"] {
  padding: 0.25em 0.25em 0em 1em !important;
}
[class~="zs-margin-0.5-0.5-0-2"] {
  margin: 0.25em 0.25em 0em 1em !important;
}
[class~="zs-padding-0.5-0.5-0.5-0"] {
  padding: 0.25em 0.25em 0.25em 0em !important;
}
[class~="zs-margin-0.5-0.5-0.5-0"] {
  margin: 0.25em 0.25em 0.25em 0em !important;
}
[class~="zs-padding-0.5-0.5-0.5-0.5"] {
  padding: 0.25em 0.25em 0.25em 0.25em !important;
}
[class~="zs-margin-0.5-0.5-0.5-0.5"] {
  margin: 0.25em 0.25em 0.25em 0.25em !important;
}
[class~="zs-padding-0.5-0.5-0.5-1"] {
  padding: 0.25em 0.25em 0.25em 0.5em !important;
}
[class~="zs-margin-0.5-0.5-0.5-1"] {
  margin: 0.25em 0.25em 0.25em 0.5em !important;
}
[class~="zs-padding-0.5-0.5-0.5-1.5"] {
  padding: 0.25em 0.25em 0.25em 0.75em !important;
}
[class~="zs-margin-0.5-0.5-0.5-1.5"] {
  margin: 0.25em 0.25em 0.25em 0.75em !important;
}
[class~="zs-padding-0.5-0.5-0.5-2"] {
  padding: 0.25em 0.25em 0.25em 1em !important;
}
[class~="zs-margin-0.5-0.5-0.5-2"] {
  margin: 0.25em 0.25em 0.25em 1em !important;
}
[class~="zs-padding-0.5-0.5-1-0"] {
  padding: 0.25em 0.25em 0.5em 0em !important;
}
[class~="zs-margin-0.5-0.5-1-0"] {
  margin: 0.25em 0.25em 0.5em 0em !important;
}
[class~="zs-padding-0.5-0.5-1-0.5"] {
  padding: 0.25em 0.25em 0.5em 0.25em !important;
}
[class~="zs-margin-0.5-0.5-1-0.5"] {
  margin: 0.25em 0.25em 0.5em 0.25em !important;
}
[class~="zs-padding-0.5-0.5-1-1"] {
  padding: 0.25em 0.25em 0.5em 0.5em !important;
}
[class~="zs-margin-0.5-0.5-1-1"] {
  margin: 0.25em 0.25em 0.5em 0.5em !important;
}
[class~="zs-padding-0.5-0.5-1-1.5"] {
  padding: 0.25em 0.25em 0.5em 0.75em !important;
}
[class~="zs-margin-0.5-0.5-1-1.5"] {
  margin: 0.25em 0.25em 0.5em 0.75em !important;
}
[class~="zs-padding-0.5-0.5-1-2"] {
  padding: 0.25em 0.25em 0.5em 1em !important;
}
[class~="zs-margin-0.5-0.5-1-2"] {
  margin: 0.25em 0.25em 0.5em 1em !important;
}
[class~="zs-padding-0.5-0.5-1.5-0"] {
  padding: 0.25em 0.25em 0.75em 0em !important;
}
[class~="zs-margin-0.5-0.5-1.5-0"] {
  margin: 0.25em 0.25em 0.75em 0em !important;
}
[class~="zs-padding-0.5-0.5-1.5-0.5"] {
  padding: 0.25em 0.25em 0.75em 0.25em !important;
}
[class~="zs-margin-0.5-0.5-1.5-0.5"] {
  margin: 0.25em 0.25em 0.75em 0.25em !important;
}
[class~="zs-padding-0.5-0.5-1.5-1"] {
  padding: 0.25em 0.25em 0.75em 0.5em !important;
}
[class~="zs-margin-0.5-0.5-1.5-1"] {
  margin: 0.25em 0.25em 0.75em 0.5em !important;
}
[class~="zs-padding-0.5-0.5-1.5-1.5"] {
  padding: 0.25em 0.25em 0.75em 0.75em !important;
}
[class~="zs-margin-0.5-0.5-1.5-1.5"] {
  margin: 0.25em 0.25em 0.75em 0.75em !important;
}
[class~="zs-padding-0.5-0.5-1.5-2"] {
  padding: 0.25em 0.25em 0.75em 1em !important;
}
[class~="zs-margin-0.5-0.5-1.5-2"] {
  margin: 0.25em 0.25em 0.75em 1em !important;
}
[class~="zs-padding-0.5-0.5-2-0"] {
  padding: 0.25em 0.25em 1em 0em !important;
}
[class~="zs-margin-0.5-0.5-2-0"] {
  margin: 0.25em 0.25em 1em 0em !important;
}
[class~="zs-padding-0.5-0.5-2-0.5"] {
  padding: 0.25em 0.25em 1em 0.25em !important;
}
[class~="zs-margin-0.5-0.5-2-0.5"] {
  margin: 0.25em 0.25em 1em 0.25em !important;
}
[class~="zs-padding-0.5-0.5-2-1"] {
  padding: 0.25em 0.25em 1em 0.5em !important;
}
[class~="zs-margin-0.5-0.5-2-1"] {
  margin: 0.25em 0.25em 1em 0.5em !important;
}
[class~="zs-padding-0.5-0.5-2-1.5"] {
  padding: 0.25em 0.25em 1em 0.75em !important;
}
[class~="zs-margin-0.5-0.5-2-1.5"] {
  margin: 0.25em 0.25em 1em 0.75em !important;
}
[class~="zs-padding-0.5-0.5-2-2"] {
  padding: 0.25em 0.25em 1em 1em !important;
}
[class~="zs-margin-0.5-0.5-2-2"] {
  margin: 0.25em 0.25em 1em 1em !important;
}
[class~="zs-padding-0.5-1-0-0"] {
  padding: 0.25em 0.5em 0em 0em !important;
}
[class~="zs-margin-0.5-1-0-0"] {
  margin: 0.25em 0.5em 0em 0em !important;
}
[class~="zs-padding-0.5-1-0-0.5"] {
  padding: 0.25em 0.5em 0em 0.25em !important;
}
[class~="zs-margin-0.5-1-0-0.5"] {
  margin: 0.25em 0.5em 0em 0.25em !important;
}
[class~="zs-padding-0.5-1-0-1"] {
  padding: 0.25em 0.5em 0em 0.5em !important;
}
[class~="zs-margin-0.5-1-0-1"] {
  margin: 0.25em 0.5em 0em 0.5em !important;
}
[class~="zs-padding-0.5-1-0-1.5"] {
  padding: 0.25em 0.5em 0em 0.75em !important;
}
[class~="zs-margin-0.5-1-0-1.5"] {
  margin: 0.25em 0.5em 0em 0.75em !important;
}
[class~="zs-padding-0.5-1-0-2"] {
  padding: 0.25em 0.5em 0em 1em !important;
}
[class~="zs-margin-0.5-1-0-2"] {
  margin: 0.25em 0.5em 0em 1em !important;
}
[class~="zs-padding-0.5-1-0.5-0"] {
  padding: 0.25em 0.5em 0.25em 0em !important;
}
[class~="zs-margin-0.5-1-0.5-0"] {
  margin: 0.25em 0.5em 0.25em 0em !important;
}
[class~="zs-padding-0.5-1-0.5-0.5"] {
  padding: 0.25em 0.5em 0.25em 0.25em !important;
}
[class~="zs-margin-0.5-1-0.5-0.5"] {
  margin: 0.25em 0.5em 0.25em 0.25em !important;
}
[class~="zs-padding-0.5-1-0.5-1"] {
  padding: 0.25em 0.5em 0.25em 0.5em !important;
}
[class~="zs-margin-0.5-1-0.5-1"] {
  margin: 0.25em 0.5em 0.25em 0.5em !important;
}
[class~="zs-padding-0.5-1-0.5-1.5"] {
  padding: 0.25em 0.5em 0.25em 0.75em !important;
}
[class~="zs-margin-0.5-1-0.5-1.5"] {
  margin: 0.25em 0.5em 0.25em 0.75em !important;
}
[class~="zs-padding-0.5-1-0.5-2"] {
  padding: 0.25em 0.5em 0.25em 1em !important;
}
[class~="zs-margin-0.5-1-0.5-2"] {
  margin: 0.25em 0.5em 0.25em 1em !important;
}
[class~="zs-padding-0.5-1-1-0"] {
  padding: 0.25em 0.5em 0.5em 0em !important;
}
[class~="zs-margin-0.5-1-1-0"] {
  margin: 0.25em 0.5em 0.5em 0em !important;
}
[class~="zs-padding-0.5-1-1-0.5"] {
  padding: 0.25em 0.5em 0.5em 0.25em !important;
}
[class~="zs-margin-0.5-1-1-0.5"] {
  margin: 0.25em 0.5em 0.5em 0.25em !important;
}
[class~="zs-padding-0.5-1-1-1"] {
  padding: 0.25em 0.5em 0.5em 0.5em !important;
}
[class~="zs-margin-0.5-1-1-1"] {
  margin: 0.25em 0.5em 0.5em 0.5em !important;
}
[class~="zs-padding-0.5-1-1-1.5"] {
  padding: 0.25em 0.5em 0.5em 0.75em !important;
}
[class~="zs-margin-0.5-1-1-1.5"] {
  margin: 0.25em 0.5em 0.5em 0.75em !important;
}
[class~="zs-padding-0.5-1-1-2"] {
  padding: 0.25em 0.5em 0.5em 1em !important;
}
[class~="zs-margin-0.5-1-1-2"] {
  margin: 0.25em 0.5em 0.5em 1em !important;
}
[class~="zs-padding-0.5-1-1.5-0"] {
  padding: 0.25em 0.5em 0.75em 0em !important;
}
[class~="zs-margin-0.5-1-1.5-0"] {
  margin: 0.25em 0.5em 0.75em 0em !important;
}
[class~="zs-padding-0.5-1-1.5-0.5"] {
  padding: 0.25em 0.5em 0.75em 0.25em !important;
}
[class~="zs-margin-0.5-1-1.5-0.5"] {
  margin: 0.25em 0.5em 0.75em 0.25em !important;
}
[class~="zs-padding-0.5-1-1.5-1"] {
  padding: 0.25em 0.5em 0.75em 0.5em !important;
}
[class~="zs-margin-0.5-1-1.5-1"] {
  margin: 0.25em 0.5em 0.75em 0.5em !important;
}
[class~="zs-padding-0.5-1-1.5-1.5"] {
  padding: 0.25em 0.5em 0.75em 0.75em !important;
}
[class~="zs-margin-0.5-1-1.5-1.5"] {
  margin: 0.25em 0.5em 0.75em 0.75em !important;
}
[class~="zs-padding-0.5-1-1.5-2"] {
  padding: 0.25em 0.5em 0.75em 1em !important;
}
[class~="zs-margin-0.5-1-1.5-2"] {
  margin: 0.25em 0.5em 0.75em 1em !important;
}
[class~="zs-padding-0.5-1-2-0"] {
  padding: 0.25em 0.5em 1em 0em !important;
}
[class~="zs-margin-0.5-1-2-0"] {
  margin: 0.25em 0.5em 1em 0em !important;
}
[class~="zs-padding-0.5-1-2-0.5"] {
  padding: 0.25em 0.5em 1em 0.25em !important;
}
[class~="zs-margin-0.5-1-2-0.5"] {
  margin: 0.25em 0.5em 1em 0.25em !important;
}
[class~="zs-padding-0.5-1-2-1"] {
  padding: 0.25em 0.5em 1em 0.5em !important;
}
[class~="zs-margin-0.5-1-2-1"] {
  margin: 0.25em 0.5em 1em 0.5em !important;
}
[class~="zs-padding-0.5-1-2-1.5"] {
  padding: 0.25em 0.5em 1em 0.75em !important;
}
[class~="zs-margin-0.5-1-2-1.5"] {
  margin: 0.25em 0.5em 1em 0.75em !important;
}
[class~="zs-padding-0.5-1-2-2"] {
  padding: 0.25em 0.5em 1em 1em !important;
}
[class~="zs-margin-0.5-1-2-2"] {
  margin: 0.25em 0.5em 1em 1em !important;
}
[class~="zs-padding-0.5-1.5-0-0"] {
  padding: 0.25em 0.75em 0em 0em !important;
}
[class~="zs-margin-0.5-1.5-0-0"] {
  margin: 0.25em 0.75em 0em 0em !important;
}
[class~="zs-padding-0.5-1.5-0-0.5"] {
  padding: 0.25em 0.75em 0em 0.25em !important;
}
[class~="zs-margin-0.5-1.5-0-0.5"] {
  margin: 0.25em 0.75em 0em 0.25em !important;
}
[class~="zs-padding-0.5-1.5-0-1"] {
  padding: 0.25em 0.75em 0em 0.5em !important;
}
[class~="zs-margin-0.5-1.5-0-1"] {
  margin: 0.25em 0.75em 0em 0.5em !important;
}
[class~="zs-padding-0.5-1.5-0-1.5"] {
  padding: 0.25em 0.75em 0em 0.75em !important;
}
[class~="zs-margin-0.5-1.5-0-1.5"] {
  margin: 0.25em 0.75em 0em 0.75em !important;
}
[class~="zs-padding-0.5-1.5-0-2"] {
  padding: 0.25em 0.75em 0em 1em !important;
}
[class~="zs-margin-0.5-1.5-0-2"] {
  margin: 0.25em 0.75em 0em 1em !important;
}
[class~="zs-padding-0.5-1.5-0.5-0"] {
  padding: 0.25em 0.75em 0.25em 0em !important;
}
[class~="zs-margin-0.5-1.5-0.5-0"] {
  margin: 0.25em 0.75em 0.25em 0em !important;
}
[class~="zs-padding-0.5-1.5-0.5-0.5"] {
  padding: 0.25em 0.75em 0.25em 0.25em !important;
}
[class~="zs-margin-0.5-1.5-0.5-0.5"] {
  margin: 0.25em 0.75em 0.25em 0.25em !important;
}
[class~="zs-padding-0.5-1.5-0.5-1"] {
  padding: 0.25em 0.75em 0.25em 0.5em !important;
}
[class~="zs-margin-0.5-1.5-0.5-1"] {
  margin: 0.25em 0.75em 0.25em 0.5em !important;
}
[class~="zs-padding-0.5-1.5-0.5-1.5"] {
  padding: 0.25em 0.75em 0.25em 0.75em !important;
}
[class~="zs-margin-0.5-1.5-0.5-1.5"] {
  margin: 0.25em 0.75em 0.25em 0.75em !important;
}
[class~="zs-padding-0.5-1.5-0.5-2"] {
  padding: 0.25em 0.75em 0.25em 1em !important;
}
[class~="zs-margin-0.5-1.5-0.5-2"] {
  margin: 0.25em 0.75em 0.25em 1em !important;
}
[class~="zs-padding-0.5-1.5-1-0"] {
  padding: 0.25em 0.75em 0.5em 0em !important;
}
[class~="zs-margin-0.5-1.5-1-0"] {
  margin: 0.25em 0.75em 0.5em 0em !important;
}
[class~="zs-padding-0.5-1.5-1-0.5"] {
  padding: 0.25em 0.75em 0.5em 0.25em !important;
}
[class~="zs-margin-0.5-1.5-1-0.5"] {
  margin: 0.25em 0.75em 0.5em 0.25em !important;
}
[class~="zs-padding-0.5-1.5-1-1"] {
  padding: 0.25em 0.75em 0.5em 0.5em !important;
}
[class~="zs-margin-0.5-1.5-1-1"] {
  margin: 0.25em 0.75em 0.5em 0.5em !important;
}
[class~="zs-padding-0.5-1.5-1-1.5"] {
  padding: 0.25em 0.75em 0.5em 0.75em !important;
}
[class~="zs-margin-0.5-1.5-1-1.5"] {
  margin: 0.25em 0.75em 0.5em 0.75em !important;
}
[class~="zs-padding-0.5-1.5-1-2"] {
  padding: 0.25em 0.75em 0.5em 1em !important;
}
[class~="zs-margin-0.5-1.5-1-2"] {
  margin: 0.25em 0.75em 0.5em 1em !important;
}
[class~="zs-padding-0.5-1.5-1.5-0"] {
  padding: 0.25em 0.75em 0.75em 0em !important;
}
[class~="zs-margin-0.5-1.5-1.5-0"] {
  margin: 0.25em 0.75em 0.75em 0em !important;
}
[class~="zs-padding-0.5-1.5-1.5-0.5"] {
  padding: 0.25em 0.75em 0.75em 0.25em !important;
}
[class~="zs-margin-0.5-1.5-1.5-0.5"] {
  margin: 0.25em 0.75em 0.75em 0.25em !important;
}
[class~="zs-padding-0.5-1.5-1.5-1"] {
  padding: 0.25em 0.75em 0.75em 0.5em !important;
}
[class~="zs-margin-0.5-1.5-1.5-1"] {
  margin: 0.25em 0.75em 0.75em 0.5em !important;
}
[class~="zs-padding-0.5-1.5-1.5-1.5"] {
  padding: 0.25em 0.75em 0.75em 0.75em !important;
}
[class~="zs-margin-0.5-1.5-1.5-1.5"] {
  margin: 0.25em 0.75em 0.75em 0.75em !important;
}
[class~="zs-padding-0.5-1.5-1.5-2"] {
  padding: 0.25em 0.75em 0.75em 1em !important;
}
[class~="zs-margin-0.5-1.5-1.5-2"] {
  margin: 0.25em 0.75em 0.75em 1em !important;
}
[class~="zs-padding-0.5-1.5-2-0"] {
  padding: 0.25em 0.75em 1em 0em !important;
}
[class~="zs-margin-0.5-1.5-2-0"] {
  margin: 0.25em 0.75em 1em 0em !important;
}
[class~="zs-padding-0.5-1.5-2-0.5"] {
  padding: 0.25em 0.75em 1em 0.25em !important;
}
[class~="zs-margin-0.5-1.5-2-0.5"] {
  margin: 0.25em 0.75em 1em 0.25em !important;
}
[class~="zs-padding-0.5-1.5-2-1"] {
  padding: 0.25em 0.75em 1em 0.5em !important;
}
[class~="zs-margin-0.5-1.5-2-1"] {
  margin: 0.25em 0.75em 1em 0.5em !important;
}
[class~="zs-padding-0.5-1.5-2-1.5"] {
  padding: 0.25em 0.75em 1em 0.75em !important;
}
[class~="zs-margin-0.5-1.5-2-1.5"] {
  margin: 0.25em 0.75em 1em 0.75em !important;
}
[class~="zs-padding-0.5-1.5-2-2"] {
  padding: 0.25em 0.75em 1em 1em !important;
}
[class~="zs-margin-0.5-1.5-2-2"] {
  margin: 0.25em 0.75em 1em 1em !important;
}
[class~="zs-padding-0.5-2-0-0"] {
  padding: 0.25em 1em 0em 0em !important;
}
[class~="zs-margin-0.5-2-0-0"] {
  margin: 0.25em 1em 0em 0em !important;
}
[class~="zs-padding-0.5-2-0-0.5"] {
  padding: 0.25em 1em 0em 0.25em !important;
}
[class~="zs-margin-0.5-2-0-0.5"] {
  margin: 0.25em 1em 0em 0.25em !important;
}
[class~="zs-padding-0.5-2-0-1"] {
  padding: 0.25em 1em 0em 0.5em !important;
}
[class~="zs-margin-0.5-2-0-1"] {
  margin: 0.25em 1em 0em 0.5em !important;
}
[class~="zs-padding-0.5-2-0-1.5"] {
  padding: 0.25em 1em 0em 0.75em !important;
}
[class~="zs-margin-0.5-2-0-1.5"] {
  margin: 0.25em 1em 0em 0.75em !important;
}
[class~="zs-padding-0.5-2-0-2"] {
  padding: 0.25em 1em 0em 1em !important;
}
[class~="zs-margin-0.5-2-0-2"] {
  margin: 0.25em 1em 0em 1em !important;
}
[class~="zs-padding-0.5-2-0.5-0"] {
  padding: 0.25em 1em 0.25em 0em !important;
}
[class~="zs-margin-0.5-2-0.5-0"] {
  margin: 0.25em 1em 0.25em 0em !important;
}
[class~="zs-padding-0.5-2-0.5-0.5"] {
  padding: 0.25em 1em 0.25em 0.25em !important;
}
[class~="zs-margin-0.5-2-0.5-0.5"] {
  margin: 0.25em 1em 0.25em 0.25em !important;
}
[class~="zs-padding-0.5-2-0.5-1"] {
  padding: 0.25em 1em 0.25em 0.5em !important;
}
[class~="zs-margin-0.5-2-0.5-1"] {
  margin: 0.25em 1em 0.25em 0.5em !important;
}
[class~="zs-padding-0.5-2-0.5-1.5"] {
  padding: 0.25em 1em 0.25em 0.75em !important;
}
[class~="zs-margin-0.5-2-0.5-1.5"] {
  margin: 0.25em 1em 0.25em 0.75em !important;
}
[class~="zs-padding-0.5-2-0.5-2"] {
  padding: 0.25em 1em 0.25em 1em !important;
}
[class~="zs-margin-0.5-2-0.5-2"] {
  margin: 0.25em 1em 0.25em 1em !important;
}
[class~="zs-padding-0.5-2-1-0"] {
  padding: 0.25em 1em 0.5em 0em !important;
}
[class~="zs-margin-0.5-2-1-0"] {
  margin: 0.25em 1em 0.5em 0em !important;
}
[class~="zs-padding-0.5-2-1-0.5"] {
  padding: 0.25em 1em 0.5em 0.25em !important;
}
[class~="zs-margin-0.5-2-1-0.5"] {
  margin: 0.25em 1em 0.5em 0.25em !important;
}
[class~="zs-padding-0.5-2-1-1"] {
  padding: 0.25em 1em 0.5em 0.5em !important;
}
[class~="zs-margin-0.5-2-1-1"] {
  margin: 0.25em 1em 0.5em 0.5em !important;
}
[class~="zs-padding-0.5-2-1-1.5"] {
  padding: 0.25em 1em 0.5em 0.75em !important;
}
[class~="zs-margin-0.5-2-1-1.5"] {
  margin: 0.25em 1em 0.5em 0.75em !important;
}
[class~="zs-padding-0.5-2-1-2"] {
  padding: 0.25em 1em 0.5em 1em !important;
}
[class~="zs-margin-0.5-2-1-2"] {
  margin: 0.25em 1em 0.5em 1em !important;
}
[class~="zs-padding-0.5-2-1.5-0"] {
  padding: 0.25em 1em 0.75em 0em !important;
}
[class~="zs-margin-0.5-2-1.5-0"] {
  margin: 0.25em 1em 0.75em 0em !important;
}
[class~="zs-padding-0.5-2-1.5-0.5"] {
  padding: 0.25em 1em 0.75em 0.25em !important;
}
[class~="zs-margin-0.5-2-1.5-0.5"] {
  margin: 0.25em 1em 0.75em 0.25em !important;
}
[class~="zs-padding-0.5-2-1.5-1"] {
  padding: 0.25em 1em 0.75em 0.5em !important;
}
[class~="zs-margin-0.5-2-1.5-1"] {
  margin: 0.25em 1em 0.75em 0.5em !important;
}
[class~="zs-padding-0.5-2-1.5-1.5"] {
  padding: 0.25em 1em 0.75em 0.75em !important;
}
[class~="zs-margin-0.5-2-1.5-1.5"] {
  margin: 0.25em 1em 0.75em 0.75em !important;
}
[class~="zs-padding-0.5-2-1.5-2"] {
  padding: 0.25em 1em 0.75em 1em !important;
}
[class~="zs-margin-0.5-2-1.5-2"] {
  margin: 0.25em 1em 0.75em 1em !important;
}
[class~="zs-padding-0.5-2-2-0"] {
  padding: 0.25em 1em 1em 0em !important;
}
[class~="zs-margin-0.5-2-2-0"] {
  margin: 0.25em 1em 1em 0em !important;
}
[class~="zs-padding-0.5-2-2-0.5"] {
  padding: 0.25em 1em 1em 0.25em !important;
}
[class~="zs-margin-0.5-2-2-0.5"] {
  margin: 0.25em 1em 1em 0.25em !important;
}
[class~="zs-padding-0.5-2-2-1"] {
  padding: 0.25em 1em 1em 0.5em !important;
}
[class~="zs-margin-0.5-2-2-1"] {
  margin: 0.25em 1em 1em 0.5em !important;
}
[class~="zs-padding-0.5-2-2-1.5"] {
  padding: 0.25em 1em 1em 0.75em !important;
}
[class~="zs-margin-0.5-2-2-1.5"] {
  margin: 0.25em 1em 1em 0.75em !important;
}
[class~="zs-padding-0.5-2-2-2"] {
  padding: 0.25em 1em 1em 1em !important;
}
[class~="zs-margin-0.5-2-2-2"] {
  margin: 0.25em 1em 1em 1em !important;
}
[class~="zs-padding-1-0-0-0"] {
  padding: 0.5em 0em 0em 0em !important;
}
[class~="zs-margin-1-0-0-0"] {
  margin: 0.5em 0em 0em 0em !important;
}
[class~="zs-padding-1-0-0-0.5"] {
  padding: 0.5em 0em 0em 0.25em !important;
}
[class~="zs-margin-1-0-0-0.5"] {
  margin: 0.5em 0em 0em 0.25em !important;
}
[class~="zs-padding-1-0-0-1"] {
  padding: 0.5em 0em 0em 0.5em !important;
}
[class~="zs-margin-1-0-0-1"] {
  margin: 0.5em 0em 0em 0.5em !important;
}
[class~="zs-padding-1-0-0-1.5"] {
  padding: 0.5em 0em 0em 0.75em !important;
}
[class~="zs-margin-1-0-0-1.5"] {
  margin: 0.5em 0em 0em 0.75em !important;
}
[class~="zs-padding-1-0-0-2"] {
  padding: 0.5em 0em 0em 1em !important;
}
[class~="zs-margin-1-0-0-2"] {
  margin: 0.5em 0em 0em 1em !important;
}
[class~="zs-padding-1-0-0.5-0"] {
  padding: 0.5em 0em 0.25em 0em !important;
}
[class~="zs-margin-1-0-0.5-0"] {
  margin: 0.5em 0em 0.25em 0em !important;
}
[class~="zs-padding-1-0-0.5-0.5"] {
  padding: 0.5em 0em 0.25em 0.25em !important;
}
[class~="zs-margin-1-0-0.5-0.5"] {
  margin: 0.5em 0em 0.25em 0.25em !important;
}
[class~="zs-padding-1-0-0.5-1"] {
  padding: 0.5em 0em 0.25em 0.5em !important;
}
[class~="zs-margin-1-0-0.5-1"] {
  margin: 0.5em 0em 0.25em 0.5em !important;
}
[class~="zs-padding-1-0-0.5-1.5"] {
  padding: 0.5em 0em 0.25em 0.75em !important;
}
[class~="zs-margin-1-0-0.5-1.5"] {
  margin: 0.5em 0em 0.25em 0.75em !important;
}
[class~="zs-padding-1-0-0.5-2"] {
  padding: 0.5em 0em 0.25em 1em !important;
}
[class~="zs-margin-1-0-0.5-2"] {
  margin: 0.5em 0em 0.25em 1em !important;
}
[class~="zs-padding-1-0-1-0"] {
  padding: 0.5em 0em 0.5em 0em !important;
}
[class~="zs-margin-1-0-1-0"] {
  margin: 0.5em 0em 0.5em 0em !important;
}
[class~="zs-padding-1-0-1-0.5"] {
  padding: 0.5em 0em 0.5em 0.25em !important;
}
[class~="zs-margin-1-0-1-0.5"] {
  margin: 0.5em 0em 0.5em 0.25em !important;
}
[class~="zs-padding-1-0-1-1"] {
  padding: 0.5em 0em 0.5em 0.5em !important;
}
[class~="zs-margin-1-0-1-1"] {
  margin: 0.5em 0em 0.5em 0.5em !important;
}
[class~="zs-padding-1-0-1-1.5"] {
  padding: 0.5em 0em 0.5em 0.75em !important;
}
[class~="zs-margin-1-0-1-1.5"] {
  margin: 0.5em 0em 0.5em 0.75em !important;
}
[class~="zs-padding-1-0-1-2"] {
  padding: 0.5em 0em 0.5em 1em !important;
}
[class~="zs-margin-1-0-1-2"] {
  margin: 0.5em 0em 0.5em 1em !important;
}
[class~="zs-padding-1-0-1.5-0"] {
  padding: 0.5em 0em 0.75em 0em !important;
}
[class~="zs-margin-1-0-1.5-0"] {
  margin: 0.5em 0em 0.75em 0em !important;
}
[class~="zs-padding-1-0-1.5-0.5"] {
  padding: 0.5em 0em 0.75em 0.25em !important;
}
[class~="zs-margin-1-0-1.5-0.5"] {
  margin: 0.5em 0em 0.75em 0.25em !important;
}
[class~="zs-padding-1-0-1.5-1"] {
  padding: 0.5em 0em 0.75em 0.5em !important;
}
[class~="zs-margin-1-0-1.5-1"] {
  margin: 0.5em 0em 0.75em 0.5em !important;
}
[class~="zs-padding-1-0-1.5-1.5"] {
  padding: 0.5em 0em 0.75em 0.75em !important;
}
[class~="zs-margin-1-0-1.5-1.5"] {
  margin: 0.5em 0em 0.75em 0.75em !important;
}
[class~="zs-padding-1-0-1.5-2"] {
  padding: 0.5em 0em 0.75em 1em !important;
}
[class~="zs-margin-1-0-1.5-2"] {
  margin: 0.5em 0em 0.75em 1em !important;
}
[class~="zs-padding-1-0-2-0"] {
  padding: 0.5em 0em 1em 0em !important;
}
[class~="zs-margin-1-0-2-0"] {
  margin: 0.5em 0em 1em 0em !important;
}
[class~="zs-padding-1-0-2-0.5"] {
  padding: 0.5em 0em 1em 0.25em !important;
}
[class~="zs-margin-1-0-2-0.5"] {
  margin: 0.5em 0em 1em 0.25em !important;
}
[class~="zs-padding-1-0-2-1"] {
  padding: 0.5em 0em 1em 0.5em !important;
}
[class~="zs-margin-1-0-2-1"] {
  margin: 0.5em 0em 1em 0.5em !important;
}
[class~="zs-padding-1-0-2-1.5"] {
  padding: 0.5em 0em 1em 0.75em !important;
}
[class~="zs-margin-1-0-2-1.5"] {
  margin: 0.5em 0em 1em 0.75em !important;
}
[class~="zs-padding-1-0-2-2"] {
  padding: 0.5em 0em 1em 1em !important;
}
[class~="zs-margin-1-0-2-2"] {
  margin: 0.5em 0em 1em 1em !important;
}
[class~="zs-padding-1-0.5-0-0"] {
  padding: 0.5em 0.25em 0em 0em !important;
}
[class~="zs-margin-1-0.5-0-0"] {
  margin: 0.5em 0.25em 0em 0em !important;
}
[class~="zs-padding-1-0.5-0-0.5"] {
  padding: 0.5em 0.25em 0em 0.25em !important;
}
[class~="zs-margin-1-0.5-0-0.5"] {
  margin: 0.5em 0.25em 0em 0.25em !important;
}
[class~="zs-padding-1-0.5-0-1"] {
  padding: 0.5em 0.25em 0em 0.5em !important;
}
[class~="zs-margin-1-0.5-0-1"] {
  margin: 0.5em 0.25em 0em 0.5em !important;
}
[class~="zs-padding-1-0.5-0-1.5"] {
  padding: 0.5em 0.25em 0em 0.75em !important;
}
[class~="zs-margin-1-0.5-0-1.5"] {
  margin: 0.5em 0.25em 0em 0.75em !important;
}
[class~="zs-padding-1-0.5-0-2"] {
  padding: 0.5em 0.25em 0em 1em !important;
}
[class~="zs-margin-1-0.5-0-2"] {
  margin: 0.5em 0.25em 0em 1em !important;
}
[class~="zs-padding-1-0.5-0.5-0"] {
  padding: 0.5em 0.25em 0.25em 0em !important;
}
[class~="zs-margin-1-0.5-0.5-0"] {
  margin: 0.5em 0.25em 0.25em 0em !important;
}
[class~="zs-padding-1-0.5-0.5-0.5"] {
  padding: 0.5em 0.25em 0.25em 0.25em !important;
}
[class~="zs-margin-1-0.5-0.5-0.5"] {
  margin: 0.5em 0.25em 0.25em 0.25em !important;
}
[class~="zs-padding-1-0.5-0.5-1"] {
  padding: 0.5em 0.25em 0.25em 0.5em !important;
}
[class~="zs-margin-1-0.5-0.5-1"] {
  margin: 0.5em 0.25em 0.25em 0.5em !important;
}
[class~="zs-padding-1-0.5-0.5-1.5"] {
  padding: 0.5em 0.25em 0.25em 0.75em !important;
}
[class~="zs-margin-1-0.5-0.5-1.5"] {
  margin: 0.5em 0.25em 0.25em 0.75em !important;
}
[class~="zs-padding-1-0.5-0.5-2"] {
  padding: 0.5em 0.25em 0.25em 1em !important;
}
[class~="zs-margin-1-0.5-0.5-2"] {
  margin: 0.5em 0.25em 0.25em 1em !important;
}
[class~="zs-padding-1-0.5-1-0"] {
  padding: 0.5em 0.25em 0.5em 0em !important;
}
[class~="zs-margin-1-0.5-1-0"] {
  margin: 0.5em 0.25em 0.5em 0em !important;
}
[class~="zs-padding-1-0.5-1-0.5"] {
  padding: 0.5em 0.25em 0.5em 0.25em !important;
}
[class~="zs-margin-1-0.5-1-0.5"] {
  margin: 0.5em 0.25em 0.5em 0.25em !important;
}
[class~="zs-padding-1-0.5-1-1"] {
  padding: 0.5em 0.25em 0.5em 0.5em !important;
}
[class~="zs-margin-1-0.5-1-1"] {
  margin: 0.5em 0.25em 0.5em 0.5em !important;
}
[class~="zs-padding-1-0.5-1-1.5"] {
  padding: 0.5em 0.25em 0.5em 0.75em !important;
}
[class~="zs-margin-1-0.5-1-1.5"] {
  margin: 0.5em 0.25em 0.5em 0.75em !important;
}
[class~="zs-padding-1-0.5-1-2"] {
  padding: 0.5em 0.25em 0.5em 1em !important;
}
[class~="zs-margin-1-0.5-1-2"] {
  margin: 0.5em 0.25em 0.5em 1em !important;
}
[class~="zs-padding-1-0.5-1.5-0"] {
  padding: 0.5em 0.25em 0.75em 0em !important;
}
[class~="zs-margin-1-0.5-1.5-0"] {
  margin: 0.5em 0.25em 0.75em 0em !important;
}
[class~="zs-padding-1-0.5-1.5-0.5"] {
  padding: 0.5em 0.25em 0.75em 0.25em !important;
}
[class~="zs-margin-1-0.5-1.5-0.5"] {
  margin: 0.5em 0.25em 0.75em 0.25em !important;
}
[class~="zs-padding-1-0.5-1.5-1"] {
  padding: 0.5em 0.25em 0.75em 0.5em !important;
}
[class~="zs-margin-1-0.5-1.5-1"] {
  margin: 0.5em 0.25em 0.75em 0.5em !important;
}
[class~="zs-padding-1-0.5-1.5-1.5"] {
  padding: 0.5em 0.25em 0.75em 0.75em !important;
}
[class~="zs-margin-1-0.5-1.5-1.5"] {
  margin: 0.5em 0.25em 0.75em 0.75em !important;
}
[class~="zs-padding-1-0.5-1.5-2"] {
  padding: 0.5em 0.25em 0.75em 1em !important;
}
[class~="zs-margin-1-0.5-1.5-2"] {
  margin: 0.5em 0.25em 0.75em 1em !important;
}
[class~="zs-padding-1-0.5-2-0"] {
  padding: 0.5em 0.25em 1em 0em !important;
}
[class~="zs-margin-1-0.5-2-0"] {
  margin: 0.5em 0.25em 1em 0em !important;
}
[class~="zs-padding-1-0.5-2-0.5"] {
  padding: 0.5em 0.25em 1em 0.25em !important;
}
[class~="zs-margin-1-0.5-2-0.5"] {
  margin: 0.5em 0.25em 1em 0.25em !important;
}
[class~="zs-padding-1-0.5-2-1"] {
  padding: 0.5em 0.25em 1em 0.5em !important;
}
[class~="zs-margin-1-0.5-2-1"] {
  margin: 0.5em 0.25em 1em 0.5em !important;
}
[class~="zs-padding-1-0.5-2-1.5"] {
  padding: 0.5em 0.25em 1em 0.75em !important;
}
[class~="zs-margin-1-0.5-2-1.5"] {
  margin: 0.5em 0.25em 1em 0.75em !important;
}
[class~="zs-padding-1-0.5-2-2"] {
  padding: 0.5em 0.25em 1em 1em !important;
}
[class~="zs-margin-1-0.5-2-2"] {
  margin: 0.5em 0.25em 1em 1em !important;
}
[class~="zs-padding-1-1-0-0"] {
  padding: 0.5em 0.5em 0em 0em !important;
}
[class~="zs-margin-1-1-0-0"] {
  margin: 0.5em 0.5em 0em 0em !important;
}
[class~="zs-padding-1-1-0-0.5"] {
  padding: 0.5em 0.5em 0em 0.25em !important;
}
[class~="zs-margin-1-1-0-0.5"] {
  margin: 0.5em 0.5em 0em 0.25em !important;
}
[class~="zs-padding-1-1-0-1"] {
  padding: 0.5em 0.5em 0em 0.5em !important;
}
[class~="zs-margin-1-1-0-1"] {
  margin: 0.5em 0.5em 0em 0.5em !important;
}
[class~="zs-padding-1-1-0-1.5"] {
  padding: 0.5em 0.5em 0em 0.75em !important;
}
[class~="zs-margin-1-1-0-1.5"] {
  margin: 0.5em 0.5em 0em 0.75em !important;
}
[class~="zs-padding-1-1-0-2"] {
  padding: 0.5em 0.5em 0em 1em !important;
}
[class~="zs-margin-1-1-0-2"] {
  margin: 0.5em 0.5em 0em 1em !important;
}
[class~="zs-padding-1-1-0.5-0"] {
  padding: 0.5em 0.5em 0.25em 0em !important;
}
[class~="zs-margin-1-1-0.5-0"] {
  margin: 0.5em 0.5em 0.25em 0em !important;
}
[class~="zs-padding-1-1-0.5-0.5"] {
  padding: 0.5em 0.5em 0.25em 0.25em !important;
}
[class~="zs-margin-1-1-0.5-0.5"] {
  margin: 0.5em 0.5em 0.25em 0.25em !important;
}
[class~="zs-padding-1-1-0.5-1"] {
  padding: 0.5em 0.5em 0.25em 0.5em !important;
}
[class~="zs-margin-1-1-0.5-1"] {
  margin: 0.5em 0.5em 0.25em 0.5em !important;
}
[class~="zs-padding-1-1-0.5-1.5"] {
  padding: 0.5em 0.5em 0.25em 0.75em !important;
}
[class~="zs-margin-1-1-0.5-1.5"] {
  margin: 0.5em 0.5em 0.25em 0.75em !important;
}
[class~="zs-padding-1-1-0.5-2"] {
  padding: 0.5em 0.5em 0.25em 1em !important;
}
[class~="zs-margin-1-1-0.5-2"] {
  margin: 0.5em 0.5em 0.25em 1em !important;
}
[class~="zs-padding-1-1-1-0"] {
  padding: 0.5em 0.5em 0.5em 0em !important;
}
[class~="zs-margin-1-1-1-0"] {
  margin: 0.5em 0.5em 0.5em 0em !important;
}
[class~="zs-padding-1-1-1-0.5"] {
  padding: 0.5em 0.5em 0.5em 0.25em !important;
}
[class~="zs-margin-1-1-1-0.5"] {
  margin: 0.5em 0.5em 0.5em 0.25em !important;
}
[class~="zs-padding-1-1-1-1"] {
  padding: 0.5em 0.5em 0.5em 0.5em !important;
}
[class~="zs-margin-1-1-1-1"] {
  margin: 0.5em 0.5em 0.5em 0.5em !important;
}
[class~="zs-padding-1-1-1-1.5"] {
  padding: 0.5em 0.5em 0.5em 0.75em !important;
}
[class~="zs-margin-1-1-1-1.5"] {
  margin: 0.5em 0.5em 0.5em 0.75em !important;
}
[class~="zs-padding-1-1-1-2"] {
  padding: 0.5em 0.5em 0.5em 1em !important;
}
[class~="zs-margin-1-1-1-2"] {
  margin: 0.5em 0.5em 0.5em 1em !important;
}
[class~="zs-padding-1-1-1.5-0"] {
  padding: 0.5em 0.5em 0.75em 0em !important;
}
[class~="zs-margin-1-1-1.5-0"] {
  margin: 0.5em 0.5em 0.75em 0em !important;
}
[class~="zs-padding-1-1-1.5-0.5"] {
  padding: 0.5em 0.5em 0.75em 0.25em !important;
}
[class~="zs-margin-1-1-1.5-0.5"] {
  margin: 0.5em 0.5em 0.75em 0.25em !important;
}
[class~="zs-padding-1-1-1.5-1"] {
  padding: 0.5em 0.5em 0.75em 0.5em !important;
}
[class~="zs-margin-1-1-1.5-1"] {
  margin: 0.5em 0.5em 0.75em 0.5em !important;
}
[class~="zs-padding-1-1-1.5-1.5"] {
  padding: 0.5em 0.5em 0.75em 0.75em !important;
}
[class~="zs-margin-1-1-1.5-1.5"] {
  margin: 0.5em 0.5em 0.75em 0.75em !important;
}
[class~="zs-padding-1-1-1.5-2"] {
  padding: 0.5em 0.5em 0.75em 1em !important;
}
[class~="zs-margin-1-1-1.5-2"] {
  margin: 0.5em 0.5em 0.75em 1em !important;
}
[class~="zs-padding-1-1-2-0"] {
  padding: 0.5em 0.5em 1em 0em !important;
}
[class~="zs-margin-1-1-2-0"] {
  margin: 0.5em 0.5em 1em 0em !important;
}
[class~="zs-padding-1-1-2-0.5"] {
  padding: 0.5em 0.5em 1em 0.25em !important;
}
[class~="zs-margin-1-1-2-0.5"] {
  margin: 0.5em 0.5em 1em 0.25em !important;
}
[class~="zs-padding-1-1-2-1"] {
  padding: 0.5em 0.5em 1em 0.5em !important;
}
[class~="zs-margin-1-1-2-1"] {
  margin: 0.5em 0.5em 1em 0.5em !important;
}
[class~="zs-padding-1-1-2-1.5"] {
  padding: 0.5em 0.5em 1em 0.75em !important;
}
[class~="zs-margin-1-1-2-1.5"] {
  margin: 0.5em 0.5em 1em 0.75em !important;
}
[class~="zs-padding-1-1-2-2"] {
  padding: 0.5em 0.5em 1em 1em !important;
}
[class~="zs-margin-1-1-2-2"] {
  margin: 0.5em 0.5em 1em 1em !important;
}
[class~="zs-padding-1-1.5-0-0"] {
  padding: 0.5em 0.75em 0em 0em !important;
}
[class~="zs-margin-1-1.5-0-0"] {
  margin: 0.5em 0.75em 0em 0em !important;
}
[class~="zs-padding-1-1.5-0-0.5"] {
  padding: 0.5em 0.75em 0em 0.25em !important;
}
[class~="zs-margin-1-1.5-0-0.5"] {
  margin: 0.5em 0.75em 0em 0.25em !important;
}
[class~="zs-padding-1-1.5-0-1"] {
  padding: 0.5em 0.75em 0em 0.5em !important;
}
[class~="zs-margin-1-1.5-0-1"] {
  margin: 0.5em 0.75em 0em 0.5em !important;
}
[class~="zs-padding-1-1.5-0-1.5"] {
  padding: 0.5em 0.75em 0em 0.75em !important;
}
[class~="zs-margin-1-1.5-0-1.5"] {
  margin: 0.5em 0.75em 0em 0.75em !important;
}
[class~="zs-padding-1-1.5-0-2"] {
  padding: 0.5em 0.75em 0em 1em !important;
}
[class~="zs-margin-1-1.5-0-2"] {
  margin: 0.5em 0.75em 0em 1em !important;
}
[class~="zs-padding-1-1.5-0.5-0"] {
  padding: 0.5em 0.75em 0.25em 0em !important;
}
[class~="zs-margin-1-1.5-0.5-0"] {
  margin: 0.5em 0.75em 0.25em 0em !important;
}
[class~="zs-padding-1-1.5-0.5-0.5"] {
  padding: 0.5em 0.75em 0.25em 0.25em !important;
}
[class~="zs-margin-1-1.5-0.5-0.5"] {
  margin: 0.5em 0.75em 0.25em 0.25em !important;
}
[class~="zs-padding-1-1.5-0.5-1"] {
  padding: 0.5em 0.75em 0.25em 0.5em !important;
}
[class~="zs-margin-1-1.5-0.5-1"] {
  margin: 0.5em 0.75em 0.25em 0.5em !important;
}
[class~="zs-padding-1-1.5-0.5-1.5"] {
  padding: 0.5em 0.75em 0.25em 0.75em !important;
}
[class~="zs-margin-1-1.5-0.5-1.5"] {
  margin: 0.5em 0.75em 0.25em 0.75em !important;
}
[class~="zs-padding-1-1.5-0.5-2"] {
  padding: 0.5em 0.75em 0.25em 1em !important;
}
[class~="zs-margin-1-1.5-0.5-2"] {
  margin: 0.5em 0.75em 0.25em 1em !important;
}
[class~="zs-padding-1-1.5-1-0"] {
  padding: 0.5em 0.75em 0.5em 0em !important;
}
[class~="zs-margin-1-1.5-1-0"] {
  margin: 0.5em 0.75em 0.5em 0em !important;
}
[class~="zs-padding-1-1.5-1-0.5"] {
  padding: 0.5em 0.75em 0.5em 0.25em !important;
}
[class~="zs-margin-1-1.5-1-0.5"] {
  margin: 0.5em 0.75em 0.5em 0.25em !important;
}
[class~="zs-padding-1-1.5-1-1"] {
  padding: 0.5em 0.75em 0.5em 0.5em !important;
}
[class~="zs-margin-1-1.5-1-1"] {
  margin: 0.5em 0.75em 0.5em 0.5em !important;
}
[class~="zs-padding-1-1.5-1-1.5"] {
  padding: 0.5em 0.75em 0.5em 0.75em !important;
}
[class~="zs-margin-1-1.5-1-1.5"] {
  margin: 0.5em 0.75em 0.5em 0.75em !important;
}
[class~="zs-padding-1-1.5-1-2"] {
  padding: 0.5em 0.75em 0.5em 1em !important;
}
[class~="zs-margin-1-1.5-1-2"] {
  margin: 0.5em 0.75em 0.5em 1em !important;
}
[class~="zs-padding-1-1.5-1.5-0"] {
  padding: 0.5em 0.75em 0.75em 0em !important;
}
[class~="zs-margin-1-1.5-1.5-0"] {
  margin: 0.5em 0.75em 0.75em 0em !important;
}
[class~="zs-padding-1-1.5-1.5-0.5"] {
  padding: 0.5em 0.75em 0.75em 0.25em !important;
}
[class~="zs-margin-1-1.5-1.5-0.5"] {
  margin: 0.5em 0.75em 0.75em 0.25em !important;
}
[class~="zs-padding-1-1.5-1.5-1"] {
  padding: 0.5em 0.75em 0.75em 0.5em !important;
}
[class~="zs-margin-1-1.5-1.5-1"] {
  margin: 0.5em 0.75em 0.75em 0.5em !important;
}
[class~="zs-padding-1-1.5-1.5-1.5"] {
  padding: 0.5em 0.75em 0.75em 0.75em !important;
}
[class~="zs-margin-1-1.5-1.5-1.5"] {
  margin: 0.5em 0.75em 0.75em 0.75em !important;
}
[class~="zs-padding-1-1.5-1.5-2"] {
  padding: 0.5em 0.75em 0.75em 1em !important;
}
[class~="zs-margin-1-1.5-1.5-2"] {
  margin: 0.5em 0.75em 0.75em 1em !important;
}
[class~="zs-padding-1-1.5-2-0"] {
  padding: 0.5em 0.75em 1em 0em !important;
}
[class~="zs-margin-1-1.5-2-0"] {
  margin: 0.5em 0.75em 1em 0em !important;
}
[class~="zs-padding-1-1.5-2-0.5"] {
  padding: 0.5em 0.75em 1em 0.25em !important;
}
[class~="zs-margin-1-1.5-2-0.5"] {
  margin: 0.5em 0.75em 1em 0.25em !important;
}
[class~="zs-padding-1-1.5-2-1"] {
  padding: 0.5em 0.75em 1em 0.5em !important;
}
[class~="zs-margin-1-1.5-2-1"] {
  margin: 0.5em 0.75em 1em 0.5em !important;
}
[class~="zs-padding-1-1.5-2-1.5"] {
  padding: 0.5em 0.75em 1em 0.75em !important;
}
[class~="zs-margin-1-1.5-2-1.5"] {
  margin: 0.5em 0.75em 1em 0.75em !important;
}
[class~="zs-padding-1-1.5-2-2"] {
  padding: 0.5em 0.75em 1em 1em !important;
}
[class~="zs-margin-1-1.5-2-2"] {
  margin: 0.5em 0.75em 1em 1em !important;
}
[class~="zs-padding-1-2-0-0"] {
  padding: 0.5em 1em 0em 0em !important;
}
[class~="zs-margin-1-2-0-0"] {
  margin: 0.5em 1em 0em 0em !important;
}
[class~="zs-padding-1-2-0-0.5"] {
  padding: 0.5em 1em 0em 0.25em !important;
}
[class~="zs-margin-1-2-0-0.5"] {
  margin: 0.5em 1em 0em 0.25em !important;
}
[class~="zs-padding-1-2-0-1"] {
  padding: 0.5em 1em 0em 0.5em !important;
}
[class~="zs-margin-1-2-0-1"] {
  margin: 0.5em 1em 0em 0.5em !important;
}
[class~="zs-padding-1-2-0-1.5"] {
  padding: 0.5em 1em 0em 0.75em !important;
}
[class~="zs-margin-1-2-0-1.5"] {
  margin: 0.5em 1em 0em 0.75em !important;
}
[class~="zs-padding-1-2-0-2"] {
  padding: 0.5em 1em 0em 1em !important;
}
[class~="zs-margin-1-2-0-2"] {
  margin: 0.5em 1em 0em 1em !important;
}
[class~="zs-padding-1-2-0.5-0"] {
  padding: 0.5em 1em 0.25em 0em !important;
}
[class~="zs-margin-1-2-0.5-0"] {
  margin: 0.5em 1em 0.25em 0em !important;
}
[class~="zs-padding-1-2-0.5-0.5"] {
  padding: 0.5em 1em 0.25em 0.25em !important;
}
[class~="zs-margin-1-2-0.5-0.5"] {
  margin: 0.5em 1em 0.25em 0.25em !important;
}
[class~="zs-padding-1-2-0.5-1"] {
  padding: 0.5em 1em 0.25em 0.5em !important;
}
[class~="zs-margin-1-2-0.5-1"] {
  margin: 0.5em 1em 0.25em 0.5em !important;
}
[class~="zs-padding-1-2-0.5-1.5"] {
  padding: 0.5em 1em 0.25em 0.75em !important;
}
[class~="zs-margin-1-2-0.5-1.5"] {
  margin: 0.5em 1em 0.25em 0.75em !important;
}
[class~="zs-padding-1-2-0.5-2"] {
  padding: 0.5em 1em 0.25em 1em !important;
}
[class~="zs-margin-1-2-0.5-2"] {
  margin: 0.5em 1em 0.25em 1em !important;
}
[class~="zs-padding-1-2-1-0"] {
  padding: 0.5em 1em 0.5em 0em !important;
}
[class~="zs-margin-1-2-1-0"] {
  margin: 0.5em 1em 0.5em 0em !important;
}
[class~="zs-padding-1-2-1-0.5"] {
  padding: 0.5em 1em 0.5em 0.25em !important;
}
[class~="zs-margin-1-2-1-0.5"] {
  margin: 0.5em 1em 0.5em 0.25em !important;
}
[class~="zs-padding-1-2-1-1"] {
  padding: 0.5em 1em 0.5em 0.5em !important;
}
[class~="zs-margin-1-2-1-1"] {
  margin: 0.5em 1em 0.5em 0.5em !important;
}
[class~="zs-padding-1-2-1-1.5"] {
  padding: 0.5em 1em 0.5em 0.75em !important;
}
[class~="zs-margin-1-2-1-1.5"] {
  margin: 0.5em 1em 0.5em 0.75em !important;
}
[class~="zs-padding-1-2-1-2"] {
  padding: 0.5em 1em 0.5em 1em !important;
}
[class~="zs-margin-1-2-1-2"] {
  margin: 0.5em 1em 0.5em 1em !important;
}
[class~="zs-padding-1-2-1.5-0"] {
  padding: 0.5em 1em 0.75em 0em !important;
}
[class~="zs-margin-1-2-1.5-0"] {
  margin: 0.5em 1em 0.75em 0em !important;
}
[class~="zs-padding-1-2-1.5-0.5"] {
  padding: 0.5em 1em 0.75em 0.25em !important;
}
[class~="zs-margin-1-2-1.5-0.5"] {
  margin: 0.5em 1em 0.75em 0.25em !important;
}
[class~="zs-padding-1-2-1.5-1"] {
  padding: 0.5em 1em 0.75em 0.5em !important;
}
[class~="zs-margin-1-2-1.5-1"] {
  margin: 0.5em 1em 0.75em 0.5em !important;
}
[class~="zs-padding-1-2-1.5-1.5"] {
  padding: 0.5em 1em 0.75em 0.75em !important;
}
[class~="zs-margin-1-2-1.5-1.5"] {
  margin: 0.5em 1em 0.75em 0.75em !important;
}
[class~="zs-padding-1-2-1.5-2"] {
  padding: 0.5em 1em 0.75em 1em !important;
}
[class~="zs-margin-1-2-1.5-2"] {
  margin: 0.5em 1em 0.75em 1em !important;
}
[class~="zs-padding-1-2-2-0"] {
  padding: 0.5em 1em 1em 0em !important;
}
[class~="zs-margin-1-2-2-0"] {
  margin: 0.5em 1em 1em 0em !important;
}
[class~="zs-padding-1-2-2-0.5"] {
  padding: 0.5em 1em 1em 0.25em !important;
}
[class~="zs-margin-1-2-2-0.5"] {
  margin: 0.5em 1em 1em 0.25em !important;
}
[class~="zs-padding-1-2-2-1"] {
  padding: 0.5em 1em 1em 0.5em !important;
}
[class~="zs-margin-1-2-2-1"] {
  margin: 0.5em 1em 1em 0.5em !important;
}
[class~="zs-padding-1-2-2-1.5"] {
  padding: 0.5em 1em 1em 0.75em !important;
}
[class~="zs-margin-1-2-2-1.5"] {
  margin: 0.5em 1em 1em 0.75em !important;
}
[class~="zs-padding-1-2-2-2"] {
  padding: 0.5em 1em 1em 1em !important;
}
[class~="zs-margin-1-2-2-2"] {
  margin: 0.5em 1em 1em 1em !important;
}
[class~="zs-padding-1.5-0-0-0"] {
  padding: 0.75em 0em 0em 0em !important;
}
[class~="zs-margin-1.5-0-0-0"] {
  margin: 0.75em 0em 0em 0em !important;
}
[class~="zs-padding-1.5-0-0-0.5"] {
  padding: 0.75em 0em 0em 0.25em !important;
}
[class~="zs-margin-1.5-0-0-0.5"] {
  margin: 0.75em 0em 0em 0.25em !important;
}
[class~="zs-padding-1.5-0-0-1"] {
  padding: 0.75em 0em 0em 0.5em !important;
}
[class~="zs-margin-1.5-0-0-1"] {
  margin: 0.75em 0em 0em 0.5em !important;
}
[class~="zs-padding-1.5-0-0-1.5"] {
  padding: 0.75em 0em 0em 0.75em !important;
}
[class~="zs-margin-1.5-0-0-1.5"] {
  margin: 0.75em 0em 0em 0.75em !important;
}
[class~="zs-padding-1.5-0-0-2"] {
  padding: 0.75em 0em 0em 1em !important;
}
[class~="zs-margin-1.5-0-0-2"] {
  margin: 0.75em 0em 0em 1em !important;
}
[class~="zs-padding-1.5-0-0.5-0"] {
  padding: 0.75em 0em 0.25em 0em !important;
}
[class~="zs-margin-1.5-0-0.5-0"] {
  margin: 0.75em 0em 0.25em 0em !important;
}
[class~="zs-padding-1.5-0-0.5-0.5"] {
  padding: 0.75em 0em 0.25em 0.25em !important;
}
[class~="zs-margin-1.5-0-0.5-0.5"] {
  margin: 0.75em 0em 0.25em 0.25em !important;
}
[class~="zs-padding-1.5-0-0.5-1"] {
  padding: 0.75em 0em 0.25em 0.5em !important;
}
[class~="zs-margin-1.5-0-0.5-1"] {
  margin: 0.75em 0em 0.25em 0.5em !important;
}
[class~="zs-padding-1.5-0-0.5-1.5"] {
  padding: 0.75em 0em 0.25em 0.75em !important;
}
[class~="zs-margin-1.5-0-0.5-1.5"] {
  margin: 0.75em 0em 0.25em 0.75em !important;
}
[class~="zs-padding-1.5-0-0.5-2"] {
  padding: 0.75em 0em 0.25em 1em !important;
}
[class~="zs-margin-1.5-0-0.5-2"] {
  margin: 0.75em 0em 0.25em 1em !important;
}
[class~="zs-padding-1.5-0-1-0"] {
  padding: 0.75em 0em 0.5em 0em !important;
}
[class~="zs-margin-1.5-0-1-0"] {
  margin: 0.75em 0em 0.5em 0em !important;
}
[class~="zs-padding-1.5-0-1-0.5"] {
  padding: 0.75em 0em 0.5em 0.25em !important;
}
[class~="zs-margin-1.5-0-1-0.5"] {
  margin: 0.75em 0em 0.5em 0.25em !important;
}
[class~="zs-padding-1.5-0-1-1"] {
  padding: 0.75em 0em 0.5em 0.5em !important;
}
[class~="zs-margin-1.5-0-1-1"] {
  margin: 0.75em 0em 0.5em 0.5em !important;
}
[class~="zs-padding-1.5-0-1-1.5"] {
  padding: 0.75em 0em 0.5em 0.75em !important;
}
[class~="zs-margin-1.5-0-1-1.5"] {
  margin: 0.75em 0em 0.5em 0.75em !important;
}
[class~="zs-padding-1.5-0-1-2"] {
  padding: 0.75em 0em 0.5em 1em !important;
}
[class~="zs-margin-1.5-0-1-2"] {
  margin: 0.75em 0em 0.5em 1em !important;
}
[class~="zs-padding-1.5-0-1.5-0"] {
  padding: 0.75em 0em 0.75em 0em !important;
}
[class~="zs-margin-1.5-0-1.5-0"] {
  margin: 0.75em 0em 0.75em 0em !important;
}
[class~="zs-padding-1.5-0-1.5-0.5"] {
  padding: 0.75em 0em 0.75em 0.25em !important;
}
[class~="zs-margin-1.5-0-1.5-0.5"] {
  margin: 0.75em 0em 0.75em 0.25em !important;
}
[class~="zs-padding-1.5-0-1.5-1"] {
  padding: 0.75em 0em 0.75em 0.5em !important;
}
[class~="zs-margin-1.5-0-1.5-1"] {
  margin: 0.75em 0em 0.75em 0.5em !important;
}
[class~="zs-padding-1.5-0-1.5-1.5"] {
  padding: 0.75em 0em 0.75em 0.75em !important;
}
[class~="zs-margin-1.5-0-1.5-1.5"] {
  margin: 0.75em 0em 0.75em 0.75em !important;
}
[class~="zs-padding-1.5-0-1.5-2"] {
  padding: 0.75em 0em 0.75em 1em !important;
}
[class~="zs-margin-1.5-0-1.5-2"] {
  margin: 0.75em 0em 0.75em 1em !important;
}
[class~="zs-padding-1.5-0-2-0"] {
  padding: 0.75em 0em 1em 0em !important;
}
[class~="zs-margin-1.5-0-2-0"] {
  margin: 0.75em 0em 1em 0em !important;
}
[class~="zs-padding-1.5-0-2-0.5"] {
  padding: 0.75em 0em 1em 0.25em !important;
}
[class~="zs-margin-1.5-0-2-0.5"] {
  margin: 0.75em 0em 1em 0.25em !important;
}
[class~="zs-padding-1.5-0-2-1"] {
  padding: 0.75em 0em 1em 0.5em !important;
}
[class~="zs-margin-1.5-0-2-1"] {
  margin: 0.75em 0em 1em 0.5em !important;
}
[class~="zs-padding-1.5-0-2-1.5"] {
  padding: 0.75em 0em 1em 0.75em !important;
}
[class~="zs-margin-1.5-0-2-1.5"] {
  margin: 0.75em 0em 1em 0.75em !important;
}
[class~="zs-padding-1.5-0-2-2"] {
  padding: 0.75em 0em 1em 1em !important;
}
[class~="zs-margin-1.5-0-2-2"] {
  margin: 0.75em 0em 1em 1em !important;
}
[class~="zs-padding-1.5-0.5-0-0"] {
  padding: 0.75em 0.25em 0em 0em !important;
}
[class~="zs-margin-1.5-0.5-0-0"] {
  margin: 0.75em 0.25em 0em 0em !important;
}
[class~="zs-padding-1.5-0.5-0-0.5"] {
  padding: 0.75em 0.25em 0em 0.25em !important;
}
[class~="zs-margin-1.5-0.5-0-0.5"] {
  margin: 0.75em 0.25em 0em 0.25em !important;
}
[class~="zs-padding-1.5-0.5-0-1"] {
  padding: 0.75em 0.25em 0em 0.5em !important;
}
[class~="zs-margin-1.5-0.5-0-1"] {
  margin: 0.75em 0.25em 0em 0.5em !important;
}
[class~="zs-padding-1.5-0.5-0-1.5"] {
  padding: 0.75em 0.25em 0em 0.75em !important;
}
[class~="zs-margin-1.5-0.5-0-1.5"] {
  margin: 0.75em 0.25em 0em 0.75em !important;
}
[class~="zs-padding-1.5-0.5-0-2"] {
  padding: 0.75em 0.25em 0em 1em !important;
}
[class~="zs-margin-1.5-0.5-0-2"] {
  margin: 0.75em 0.25em 0em 1em !important;
}
[class~="zs-padding-1.5-0.5-0.5-0"] {
  padding: 0.75em 0.25em 0.25em 0em !important;
}
[class~="zs-margin-1.5-0.5-0.5-0"] {
  margin: 0.75em 0.25em 0.25em 0em !important;
}
[class~="zs-padding-1.5-0.5-0.5-0.5"] {
  padding: 0.75em 0.25em 0.25em 0.25em !important;
}
[class~="zs-margin-1.5-0.5-0.5-0.5"] {
  margin: 0.75em 0.25em 0.25em 0.25em !important;
}
[class~="zs-padding-1.5-0.5-0.5-1"] {
  padding: 0.75em 0.25em 0.25em 0.5em !important;
}
[class~="zs-margin-1.5-0.5-0.5-1"] {
  margin: 0.75em 0.25em 0.25em 0.5em !important;
}
[class~="zs-padding-1.5-0.5-0.5-1.5"] {
  padding: 0.75em 0.25em 0.25em 0.75em !important;
}
[class~="zs-margin-1.5-0.5-0.5-1.5"] {
  margin: 0.75em 0.25em 0.25em 0.75em !important;
}
[class~="zs-padding-1.5-0.5-0.5-2"] {
  padding: 0.75em 0.25em 0.25em 1em !important;
}
[class~="zs-margin-1.5-0.5-0.5-2"] {
  margin: 0.75em 0.25em 0.25em 1em !important;
}
[class~="zs-padding-1.5-0.5-1-0"] {
  padding: 0.75em 0.25em 0.5em 0em !important;
}
[class~="zs-margin-1.5-0.5-1-0"] {
  margin: 0.75em 0.25em 0.5em 0em !important;
}
[class~="zs-padding-1.5-0.5-1-0.5"] {
  padding: 0.75em 0.25em 0.5em 0.25em !important;
}
[class~="zs-margin-1.5-0.5-1-0.5"] {
  margin: 0.75em 0.25em 0.5em 0.25em !important;
}
[class~="zs-padding-1.5-0.5-1-1"] {
  padding: 0.75em 0.25em 0.5em 0.5em !important;
}
[class~="zs-margin-1.5-0.5-1-1"] {
  margin: 0.75em 0.25em 0.5em 0.5em !important;
}
[class~="zs-padding-1.5-0.5-1-1.5"] {
  padding: 0.75em 0.25em 0.5em 0.75em !important;
}
[class~="zs-margin-1.5-0.5-1-1.5"] {
  margin: 0.75em 0.25em 0.5em 0.75em !important;
}
[class~="zs-padding-1.5-0.5-1-2"] {
  padding: 0.75em 0.25em 0.5em 1em !important;
}
[class~="zs-margin-1.5-0.5-1-2"] {
  margin: 0.75em 0.25em 0.5em 1em !important;
}
[class~="zs-padding-1.5-0.5-1.5-0"] {
  padding: 0.75em 0.25em 0.75em 0em !important;
}
[class~="zs-margin-1.5-0.5-1.5-0"] {
  margin: 0.75em 0.25em 0.75em 0em !important;
}
[class~="zs-padding-1.5-0.5-1.5-0.5"] {
  padding: 0.75em 0.25em 0.75em 0.25em !important;
}
[class~="zs-margin-1.5-0.5-1.5-0.5"] {
  margin: 0.75em 0.25em 0.75em 0.25em !important;
}
[class~="zs-padding-1.5-0.5-1.5-1"] {
  padding: 0.75em 0.25em 0.75em 0.5em !important;
}
[class~="zs-margin-1.5-0.5-1.5-1"] {
  margin: 0.75em 0.25em 0.75em 0.5em !important;
}
[class~="zs-padding-1.5-0.5-1.5-1.5"] {
  padding: 0.75em 0.25em 0.75em 0.75em !important;
}
[class~="zs-margin-1.5-0.5-1.5-1.5"] {
  margin: 0.75em 0.25em 0.75em 0.75em !important;
}
[class~="zs-padding-1.5-0.5-1.5-2"] {
  padding: 0.75em 0.25em 0.75em 1em !important;
}
[class~="zs-margin-1.5-0.5-1.5-2"] {
  margin: 0.75em 0.25em 0.75em 1em !important;
}
[class~="zs-padding-1.5-0.5-2-0"] {
  padding: 0.75em 0.25em 1em 0em !important;
}
[class~="zs-margin-1.5-0.5-2-0"] {
  margin: 0.75em 0.25em 1em 0em !important;
}
[class~="zs-padding-1.5-0.5-2-0.5"] {
  padding: 0.75em 0.25em 1em 0.25em !important;
}
[class~="zs-margin-1.5-0.5-2-0.5"] {
  margin: 0.75em 0.25em 1em 0.25em !important;
}
[class~="zs-padding-1.5-0.5-2-1"] {
  padding: 0.75em 0.25em 1em 0.5em !important;
}
[class~="zs-margin-1.5-0.5-2-1"] {
  margin: 0.75em 0.25em 1em 0.5em !important;
}
[class~="zs-padding-1.5-0.5-2-1.5"] {
  padding: 0.75em 0.25em 1em 0.75em !important;
}
[class~="zs-margin-1.5-0.5-2-1.5"] {
  margin: 0.75em 0.25em 1em 0.75em !important;
}
[class~="zs-padding-1.5-0.5-2-2"] {
  padding: 0.75em 0.25em 1em 1em !important;
}
[class~="zs-margin-1.5-0.5-2-2"] {
  margin: 0.75em 0.25em 1em 1em !important;
}
[class~="zs-padding-1.5-1-0-0"] {
  padding: 0.75em 0.5em 0em 0em !important;
}
[class~="zs-margin-1.5-1-0-0"] {
  margin: 0.75em 0.5em 0em 0em !important;
}
[class~="zs-padding-1.5-1-0-0.5"] {
  padding: 0.75em 0.5em 0em 0.25em !important;
}
[class~="zs-margin-1.5-1-0-0.5"] {
  margin: 0.75em 0.5em 0em 0.25em !important;
}
[class~="zs-padding-1.5-1-0-1"] {
  padding: 0.75em 0.5em 0em 0.5em !important;
}
[class~="zs-margin-1.5-1-0-1"] {
  margin: 0.75em 0.5em 0em 0.5em !important;
}
[class~="zs-padding-1.5-1-0-1.5"] {
  padding: 0.75em 0.5em 0em 0.75em !important;
}
[class~="zs-margin-1.5-1-0-1.5"] {
  margin: 0.75em 0.5em 0em 0.75em !important;
}
[class~="zs-padding-1.5-1-0-2"] {
  padding: 0.75em 0.5em 0em 1em !important;
}
[class~="zs-margin-1.5-1-0-2"] {
  margin: 0.75em 0.5em 0em 1em !important;
}
[class~="zs-padding-1.5-1-0.5-0"] {
  padding: 0.75em 0.5em 0.25em 0em !important;
}
[class~="zs-margin-1.5-1-0.5-0"] {
  margin: 0.75em 0.5em 0.25em 0em !important;
}
[class~="zs-padding-1.5-1-0.5-0.5"] {
  padding: 0.75em 0.5em 0.25em 0.25em !important;
}
[class~="zs-margin-1.5-1-0.5-0.5"] {
  margin: 0.75em 0.5em 0.25em 0.25em !important;
}
[class~="zs-padding-1.5-1-0.5-1"] {
  padding: 0.75em 0.5em 0.25em 0.5em !important;
}
[class~="zs-margin-1.5-1-0.5-1"] {
  margin: 0.75em 0.5em 0.25em 0.5em !important;
}
[class~="zs-padding-1.5-1-0.5-1.5"] {
  padding: 0.75em 0.5em 0.25em 0.75em !important;
}
[class~="zs-margin-1.5-1-0.5-1.5"] {
  margin: 0.75em 0.5em 0.25em 0.75em !important;
}
[class~="zs-padding-1.5-1-0.5-2"] {
  padding: 0.75em 0.5em 0.25em 1em !important;
}
[class~="zs-margin-1.5-1-0.5-2"] {
  margin: 0.75em 0.5em 0.25em 1em !important;
}
[class~="zs-padding-1.5-1-1-0"] {
  padding: 0.75em 0.5em 0.5em 0em !important;
}
[class~="zs-margin-1.5-1-1-0"] {
  margin: 0.75em 0.5em 0.5em 0em !important;
}
[class~="zs-padding-1.5-1-1-0.5"] {
  padding: 0.75em 0.5em 0.5em 0.25em !important;
}
[class~="zs-margin-1.5-1-1-0.5"] {
  margin: 0.75em 0.5em 0.5em 0.25em !important;
}
[class~="zs-padding-1.5-1-1-1"] {
  padding: 0.75em 0.5em 0.5em 0.5em !important;
}
[class~="zs-margin-1.5-1-1-1"] {
  margin: 0.75em 0.5em 0.5em 0.5em !important;
}
[class~="zs-padding-1.5-1-1-1.5"] {
  padding: 0.75em 0.5em 0.5em 0.75em !important;
}
[class~="zs-margin-1.5-1-1-1.5"] {
  margin: 0.75em 0.5em 0.5em 0.75em !important;
}
[class~="zs-padding-1.5-1-1-2"] {
  padding: 0.75em 0.5em 0.5em 1em !important;
}
[class~="zs-margin-1.5-1-1-2"] {
  margin: 0.75em 0.5em 0.5em 1em !important;
}
[class~="zs-padding-1.5-1-1.5-0"] {
  padding: 0.75em 0.5em 0.75em 0em !important;
}
[class~="zs-margin-1.5-1-1.5-0"] {
  margin: 0.75em 0.5em 0.75em 0em !important;
}
[class~="zs-padding-1.5-1-1.5-0.5"] {
  padding: 0.75em 0.5em 0.75em 0.25em !important;
}
[class~="zs-margin-1.5-1-1.5-0.5"] {
  margin: 0.75em 0.5em 0.75em 0.25em !important;
}
[class~="zs-padding-1.5-1-1.5-1"] {
  padding: 0.75em 0.5em 0.75em 0.5em !important;
}
[class~="zs-margin-1.5-1-1.5-1"] {
  margin: 0.75em 0.5em 0.75em 0.5em !important;
}
[class~="zs-padding-1.5-1-1.5-1.5"] {
  padding: 0.75em 0.5em 0.75em 0.75em !important;
}
[class~="zs-margin-1.5-1-1.5-1.5"] {
  margin: 0.75em 0.5em 0.75em 0.75em !important;
}
[class~="zs-padding-1.5-1-1.5-2"] {
  padding: 0.75em 0.5em 0.75em 1em !important;
}
[class~="zs-margin-1.5-1-1.5-2"] {
  margin: 0.75em 0.5em 0.75em 1em !important;
}
[class~="zs-padding-1.5-1-2-0"] {
  padding: 0.75em 0.5em 1em 0em !important;
}
[class~="zs-margin-1.5-1-2-0"] {
  margin: 0.75em 0.5em 1em 0em !important;
}
[class~="zs-padding-1.5-1-2-0.5"] {
  padding: 0.75em 0.5em 1em 0.25em !important;
}
[class~="zs-margin-1.5-1-2-0.5"] {
  margin: 0.75em 0.5em 1em 0.25em !important;
}
[class~="zs-padding-1.5-1-2-1"] {
  padding: 0.75em 0.5em 1em 0.5em !important;
}
[class~="zs-margin-1.5-1-2-1"] {
  margin: 0.75em 0.5em 1em 0.5em !important;
}
[class~="zs-padding-1.5-1-2-1.5"] {
  padding: 0.75em 0.5em 1em 0.75em !important;
}
[class~="zs-margin-1.5-1-2-1.5"] {
  margin: 0.75em 0.5em 1em 0.75em !important;
}
[class~="zs-padding-1.5-1-2-2"] {
  padding: 0.75em 0.5em 1em 1em !important;
}
[class~="zs-margin-1.5-1-2-2"] {
  margin: 0.75em 0.5em 1em 1em !important;
}
[class~="zs-padding-1.5-1.5-0-0"] {
  padding: 0.75em 0.75em 0em 0em !important;
}
[class~="zs-margin-1.5-1.5-0-0"] {
  margin: 0.75em 0.75em 0em 0em !important;
}
[class~="zs-padding-1.5-1.5-0-0.5"] {
  padding: 0.75em 0.75em 0em 0.25em !important;
}
[class~="zs-margin-1.5-1.5-0-0.5"] {
  margin: 0.75em 0.75em 0em 0.25em !important;
}
[class~="zs-padding-1.5-1.5-0-1"] {
  padding: 0.75em 0.75em 0em 0.5em !important;
}
[class~="zs-margin-1.5-1.5-0-1"] {
  margin: 0.75em 0.75em 0em 0.5em !important;
}
[class~="zs-padding-1.5-1.5-0-1.5"] {
  padding: 0.75em 0.75em 0em 0.75em !important;
}
[class~="zs-margin-1.5-1.5-0-1.5"] {
  margin: 0.75em 0.75em 0em 0.75em !important;
}
[class~="zs-padding-1.5-1.5-0-2"] {
  padding: 0.75em 0.75em 0em 1em !important;
}
[class~="zs-margin-1.5-1.5-0-2"] {
  margin: 0.75em 0.75em 0em 1em !important;
}
[class~="zs-padding-1.5-1.5-0.5-0"] {
  padding: 0.75em 0.75em 0.25em 0em !important;
}
[class~="zs-margin-1.5-1.5-0.5-0"] {
  margin: 0.75em 0.75em 0.25em 0em !important;
}
[class~="zs-padding-1.5-1.5-0.5-0.5"] {
  padding: 0.75em 0.75em 0.25em 0.25em !important;
}
[class~="zs-margin-1.5-1.5-0.5-0.5"] {
  margin: 0.75em 0.75em 0.25em 0.25em !important;
}
[class~="zs-padding-1.5-1.5-0.5-1"] {
  padding: 0.75em 0.75em 0.25em 0.5em !important;
}
[class~="zs-margin-1.5-1.5-0.5-1"] {
  margin: 0.75em 0.75em 0.25em 0.5em !important;
}
[class~="zs-padding-1.5-1.5-0.5-1.5"] {
  padding: 0.75em 0.75em 0.25em 0.75em !important;
}
[class~="zs-margin-1.5-1.5-0.5-1.5"] {
  margin: 0.75em 0.75em 0.25em 0.75em !important;
}
[class~="zs-padding-1.5-1.5-0.5-2"] {
  padding: 0.75em 0.75em 0.25em 1em !important;
}
[class~="zs-margin-1.5-1.5-0.5-2"] {
  margin: 0.75em 0.75em 0.25em 1em !important;
}
[class~="zs-padding-1.5-1.5-1-0"] {
  padding: 0.75em 0.75em 0.5em 0em !important;
}
[class~="zs-margin-1.5-1.5-1-0"] {
  margin: 0.75em 0.75em 0.5em 0em !important;
}
[class~="zs-padding-1.5-1.5-1-0.5"] {
  padding: 0.75em 0.75em 0.5em 0.25em !important;
}
[class~="zs-margin-1.5-1.5-1-0.5"] {
  margin: 0.75em 0.75em 0.5em 0.25em !important;
}
[class~="zs-padding-1.5-1.5-1-1"] {
  padding: 0.75em 0.75em 0.5em 0.5em !important;
}
[class~="zs-margin-1.5-1.5-1-1"] {
  margin: 0.75em 0.75em 0.5em 0.5em !important;
}
[class~="zs-padding-1.5-1.5-1-1.5"] {
  padding: 0.75em 0.75em 0.5em 0.75em !important;
}
[class~="zs-margin-1.5-1.5-1-1.5"] {
  margin: 0.75em 0.75em 0.5em 0.75em !important;
}
[class~="zs-padding-1.5-1.5-1-2"] {
  padding: 0.75em 0.75em 0.5em 1em !important;
}
[class~="zs-margin-1.5-1.5-1-2"] {
  margin: 0.75em 0.75em 0.5em 1em !important;
}
[class~="zs-padding-1.5-1.5-1.5-0"] {
  padding: 0.75em 0.75em 0.75em 0em !important;
}
[class~="zs-margin-1.5-1.5-1.5-0"] {
  margin: 0.75em 0.75em 0.75em 0em !important;
}
[class~="zs-padding-1.5-1.5-1.5-0.5"] {
  padding: 0.75em 0.75em 0.75em 0.25em !important;
}
[class~="zs-margin-1.5-1.5-1.5-0.5"] {
  margin: 0.75em 0.75em 0.75em 0.25em !important;
}
[class~="zs-padding-1.5-1.5-1.5-1"] {
  padding: 0.75em 0.75em 0.75em 0.5em !important;
}
[class~="zs-margin-1.5-1.5-1.5-1"] {
  margin: 0.75em 0.75em 0.75em 0.5em !important;
}
[class~="zs-padding-1.5-1.5-1.5-1.5"] {
  padding: 0.75em 0.75em 0.75em 0.75em !important;
}
[class~="zs-margin-1.5-1.5-1.5-1.5"] {
  margin: 0.75em 0.75em 0.75em 0.75em !important;
}
[class~="zs-padding-1.5-1.5-1.5-2"] {
  padding: 0.75em 0.75em 0.75em 1em !important;
}
[class~="zs-margin-1.5-1.5-1.5-2"] {
  margin: 0.75em 0.75em 0.75em 1em !important;
}
[class~="zs-padding-1.5-1.5-2-0"] {
  padding: 0.75em 0.75em 1em 0em !important;
}
[class~="zs-margin-1.5-1.5-2-0"] {
  margin: 0.75em 0.75em 1em 0em !important;
}
[class~="zs-padding-1.5-1.5-2-0.5"] {
  padding: 0.75em 0.75em 1em 0.25em !important;
}
[class~="zs-margin-1.5-1.5-2-0.5"] {
  margin: 0.75em 0.75em 1em 0.25em !important;
}
[class~="zs-padding-1.5-1.5-2-1"] {
  padding: 0.75em 0.75em 1em 0.5em !important;
}
[class~="zs-margin-1.5-1.5-2-1"] {
  margin: 0.75em 0.75em 1em 0.5em !important;
}
[class~="zs-padding-1.5-1.5-2-1.5"] {
  padding: 0.75em 0.75em 1em 0.75em !important;
}
[class~="zs-margin-1.5-1.5-2-1.5"] {
  margin: 0.75em 0.75em 1em 0.75em !important;
}
[class~="zs-padding-1.5-1.5-2-2"] {
  padding: 0.75em 0.75em 1em 1em !important;
}
[class~="zs-margin-1.5-1.5-2-2"] {
  margin: 0.75em 0.75em 1em 1em !important;
}
[class~="zs-padding-1.5-2-0-0"] {
  padding: 0.75em 1em 0em 0em !important;
}
[class~="zs-margin-1.5-2-0-0"] {
  margin: 0.75em 1em 0em 0em !important;
}
[class~="zs-padding-1.5-2-0-0.5"] {
  padding: 0.75em 1em 0em 0.25em !important;
}
[class~="zs-margin-1.5-2-0-0.5"] {
  margin: 0.75em 1em 0em 0.25em !important;
}
[class~="zs-padding-1.5-2-0-1"] {
  padding: 0.75em 1em 0em 0.5em !important;
}
[class~="zs-margin-1.5-2-0-1"] {
  margin: 0.75em 1em 0em 0.5em !important;
}
[class~="zs-padding-1.5-2-0-1.5"] {
  padding: 0.75em 1em 0em 0.75em !important;
}
[class~="zs-margin-1.5-2-0-1.5"] {
  margin: 0.75em 1em 0em 0.75em !important;
}
[class~="zs-padding-1.5-2-0-2"] {
  padding: 0.75em 1em 0em 1em !important;
}
[class~="zs-margin-1.5-2-0-2"] {
  margin: 0.75em 1em 0em 1em !important;
}
[class~="zs-padding-1.5-2-0.5-0"] {
  padding: 0.75em 1em 0.25em 0em !important;
}
[class~="zs-margin-1.5-2-0.5-0"] {
  margin: 0.75em 1em 0.25em 0em !important;
}
[class~="zs-padding-1.5-2-0.5-0.5"] {
  padding: 0.75em 1em 0.25em 0.25em !important;
}
[class~="zs-margin-1.5-2-0.5-0.5"] {
  margin: 0.75em 1em 0.25em 0.25em !important;
}
[class~="zs-padding-1.5-2-0.5-1"] {
  padding: 0.75em 1em 0.25em 0.5em !important;
}
[class~="zs-margin-1.5-2-0.5-1"] {
  margin: 0.75em 1em 0.25em 0.5em !important;
}
[class~="zs-padding-1.5-2-0.5-1.5"] {
  padding: 0.75em 1em 0.25em 0.75em !important;
}
[class~="zs-margin-1.5-2-0.5-1.5"] {
  margin: 0.75em 1em 0.25em 0.75em !important;
}
[class~="zs-padding-1.5-2-0.5-2"] {
  padding: 0.75em 1em 0.25em 1em !important;
}
[class~="zs-margin-1.5-2-0.5-2"] {
  margin: 0.75em 1em 0.25em 1em !important;
}
[class~="zs-padding-1.5-2-1-0"] {
  padding: 0.75em 1em 0.5em 0em !important;
}
[class~="zs-margin-1.5-2-1-0"] {
  margin: 0.75em 1em 0.5em 0em !important;
}
[class~="zs-padding-1.5-2-1-0.5"] {
  padding: 0.75em 1em 0.5em 0.25em !important;
}
[class~="zs-margin-1.5-2-1-0.5"] {
  margin: 0.75em 1em 0.5em 0.25em !important;
}
[class~="zs-padding-1.5-2-1-1"] {
  padding: 0.75em 1em 0.5em 0.5em !important;
}
[class~="zs-margin-1.5-2-1-1"] {
  margin: 0.75em 1em 0.5em 0.5em !important;
}
[class~="zs-padding-1.5-2-1-1.5"] {
  padding: 0.75em 1em 0.5em 0.75em !important;
}
[class~="zs-margin-1.5-2-1-1.5"] {
  margin: 0.75em 1em 0.5em 0.75em !important;
}
[class~="zs-padding-1.5-2-1-2"] {
  padding: 0.75em 1em 0.5em 1em !important;
}
[class~="zs-margin-1.5-2-1-2"] {
  margin: 0.75em 1em 0.5em 1em !important;
}
[class~="zs-padding-1.5-2-1.5-0"] {
  padding: 0.75em 1em 0.75em 0em !important;
}
[class~="zs-margin-1.5-2-1.5-0"] {
  margin: 0.75em 1em 0.75em 0em !important;
}
[class~="zs-padding-1.5-2-1.5-0.5"] {
  padding: 0.75em 1em 0.75em 0.25em !important;
}
[class~="zs-margin-1.5-2-1.5-0.5"] {
  margin: 0.75em 1em 0.75em 0.25em !important;
}
[class~="zs-padding-1.5-2-1.5-1"] {
  padding: 0.75em 1em 0.75em 0.5em !important;
}
[class~="zs-margin-1.5-2-1.5-1"] {
  margin: 0.75em 1em 0.75em 0.5em !important;
}
[class~="zs-padding-1.5-2-1.5-1.5"] {
  padding: 0.75em 1em 0.75em 0.75em !important;
}
[class~="zs-margin-1.5-2-1.5-1.5"] {
  margin: 0.75em 1em 0.75em 0.75em !important;
}
[class~="zs-padding-1.5-2-1.5-2"] {
  padding: 0.75em 1em 0.75em 1em !important;
}
[class~="zs-margin-1.5-2-1.5-2"] {
  margin: 0.75em 1em 0.75em 1em !important;
}
[class~="zs-padding-1.5-2-2-0"] {
  padding: 0.75em 1em 1em 0em !important;
}
[class~="zs-margin-1.5-2-2-0"] {
  margin: 0.75em 1em 1em 0em !important;
}
[class~="zs-padding-1.5-2-2-0.5"] {
  padding: 0.75em 1em 1em 0.25em !important;
}
[class~="zs-margin-1.5-2-2-0.5"] {
  margin: 0.75em 1em 1em 0.25em !important;
}
[class~="zs-padding-1.5-2-2-1"] {
  padding: 0.75em 1em 1em 0.5em !important;
}
[class~="zs-margin-1.5-2-2-1"] {
  margin: 0.75em 1em 1em 0.5em !important;
}
[class~="zs-padding-1.5-2-2-1.5"] {
  padding: 0.75em 1em 1em 0.75em !important;
}
[class~="zs-margin-1.5-2-2-1.5"] {
  margin: 0.75em 1em 1em 0.75em !important;
}
[class~="zs-padding-1.5-2-2-2"] {
  padding: 0.75em 1em 1em 1em !important;
}
[class~="zs-margin-1.5-2-2-2"] {
  margin: 0.75em 1em 1em 1em !important;
}
[class~="zs-padding-2-0-0-0"] {
  padding: 1em 0em 0em 0em !important;
}
[class~="zs-margin-2-0-0-0"] {
  margin: 1em 0em 0em 0em !important;
}
[class~="zs-padding-2-0-0-0.5"] {
  padding: 1em 0em 0em 0.25em !important;
}
[class~="zs-margin-2-0-0-0.5"] {
  margin: 1em 0em 0em 0.25em !important;
}
[class~="zs-padding-2-0-0-1"] {
  padding: 1em 0em 0em 0.5em !important;
}
[class~="zs-margin-2-0-0-1"] {
  margin: 1em 0em 0em 0.5em !important;
}
[class~="zs-padding-2-0-0-1.5"] {
  padding: 1em 0em 0em 0.75em !important;
}
[class~="zs-margin-2-0-0-1.5"] {
  margin: 1em 0em 0em 0.75em !important;
}
[class~="zs-padding-2-0-0-2"] {
  padding: 1em 0em 0em 1em !important;
}
[class~="zs-margin-2-0-0-2"] {
  margin: 1em 0em 0em 1em !important;
}
[class~="zs-padding-2-0-0.5-0"] {
  padding: 1em 0em 0.25em 0em !important;
}
[class~="zs-margin-2-0-0.5-0"] {
  margin: 1em 0em 0.25em 0em !important;
}
[class~="zs-padding-2-0-0.5-0.5"] {
  padding: 1em 0em 0.25em 0.25em !important;
}
[class~="zs-margin-2-0-0.5-0.5"] {
  margin: 1em 0em 0.25em 0.25em !important;
}
[class~="zs-padding-2-0-0.5-1"] {
  padding: 1em 0em 0.25em 0.5em !important;
}
[class~="zs-margin-2-0-0.5-1"] {
  margin: 1em 0em 0.25em 0.5em !important;
}
[class~="zs-padding-2-0-0.5-1.5"] {
  padding: 1em 0em 0.25em 0.75em !important;
}
[class~="zs-margin-2-0-0.5-1.5"] {
  margin: 1em 0em 0.25em 0.75em !important;
}
[class~="zs-padding-2-0-0.5-2"] {
  padding: 1em 0em 0.25em 1em !important;
}
[class~="zs-margin-2-0-0.5-2"] {
  margin: 1em 0em 0.25em 1em !important;
}
[class~="zs-padding-2-0-1-0"] {
  padding: 1em 0em 0.5em 0em !important;
}
[class~="zs-margin-2-0-1-0"] {
  margin: 1em 0em 0.5em 0em !important;
}
[class~="zs-padding-2-0-1-0.5"] {
  padding: 1em 0em 0.5em 0.25em !important;
}
[class~="zs-margin-2-0-1-0.5"] {
  margin: 1em 0em 0.5em 0.25em !important;
}
[class~="zs-padding-2-0-1-1"] {
  padding: 1em 0em 0.5em 0.5em !important;
}
[class~="zs-margin-2-0-1-1"] {
  margin: 1em 0em 0.5em 0.5em !important;
}
[class~="zs-padding-2-0-1-1.5"] {
  padding: 1em 0em 0.5em 0.75em !important;
}
[class~="zs-margin-2-0-1-1.5"] {
  margin: 1em 0em 0.5em 0.75em !important;
}
[class~="zs-padding-2-0-1-2"] {
  padding: 1em 0em 0.5em 1em !important;
}
[class~="zs-margin-2-0-1-2"] {
  margin: 1em 0em 0.5em 1em !important;
}
[class~="zs-padding-2-0-1.5-0"] {
  padding: 1em 0em 0.75em 0em !important;
}
[class~="zs-margin-2-0-1.5-0"] {
  margin: 1em 0em 0.75em 0em !important;
}
[class~="zs-padding-2-0-1.5-0.5"] {
  padding: 1em 0em 0.75em 0.25em !important;
}
[class~="zs-margin-2-0-1.5-0.5"] {
  margin: 1em 0em 0.75em 0.25em !important;
}
[class~="zs-padding-2-0-1.5-1"] {
  padding: 1em 0em 0.75em 0.5em !important;
}
[class~="zs-margin-2-0-1.5-1"] {
  margin: 1em 0em 0.75em 0.5em !important;
}
[class~="zs-padding-2-0-1.5-1.5"] {
  padding: 1em 0em 0.75em 0.75em !important;
}
[class~="zs-margin-2-0-1.5-1.5"] {
  margin: 1em 0em 0.75em 0.75em !important;
}
[class~="zs-padding-2-0-1.5-2"] {
  padding: 1em 0em 0.75em 1em !important;
}
[class~="zs-margin-2-0-1.5-2"] {
  margin: 1em 0em 0.75em 1em !important;
}
[class~="zs-padding-2-0-2-0"] {
  padding: 1em 0em 1em 0em !important;
}
[class~="zs-margin-2-0-2-0"] {
  margin: 1em 0em 1em 0em !important;
}
[class~="zs-padding-2-0-2-0.5"] {
  padding: 1em 0em 1em 0.25em !important;
}
[class~="zs-margin-2-0-2-0.5"] {
  margin: 1em 0em 1em 0.25em !important;
}
[class~="zs-padding-2-0-2-1"] {
  padding: 1em 0em 1em 0.5em !important;
}
[class~="zs-margin-2-0-2-1"] {
  margin: 1em 0em 1em 0.5em !important;
}
[class~="zs-padding-2-0-2-1.5"] {
  padding: 1em 0em 1em 0.75em !important;
}
[class~="zs-margin-2-0-2-1.5"] {
  margin: 1em 0em 1em 0.75em !important;
}
[class~="zs-padding-2-0-2-2"] {
  padding: 1em 0em 1em 1em !important;
}
[class~="zs-margin-2-0-2-2"] {
  margin: 1em 0em 1em 1em !important;
}
[class~="zs-padding-2-0.5-0-0"] {
  padding: 1em 0.25em 0em 0em !important;
}
[class~="zs-margin-2-0.5-0-0"] {
  margin: 1em 0.25em 0em 0em !important;
}
[class~="zs-padding-2-0.5-0-0.5"] {
  padding: 1em 0.25em 0em 0.25em !important;
}
[class~="zs-margin-2-0.5-0-0.5"] {
  margin: 1em 0.25em 0em 0.25em !important;
}
[class~="zs-padding-2-0.5-0-1"] {
  padding: 1em 0.25em 0em 0.5em !important;
}
[class~="zs-margin-2-0.5-0-1"] {
  margin: 1em 0.25em 0em 0.5em !important;
}
[class~="zs-padding-2-0.5-0-1.5"] {
  padding: 1em 0.25em 0em 0.75em !important;
}
[class~="zs-margin-2-0.5-0-1.5"] {
  margin: 1em 0.25em 0em 0.75em !important;
}
[class~="zs-padding-2-0.5-0-2"] {
  padding: 1em 0.25em 0em 1em !important;
}
[class~="zs-margin-2-0.5-0-2"] {
  margin: 1em 0.25em 0em 1em !important;
}
[class~="zs-padding-2-0.5-0.5-0"] {
  padding: 1em 0.25em 0.25em 0em !important;
}
[class~="zs-margin-2-0.5-0.5-0"] {
  margin: 1em 0.25em 0.25em 0em !important;
}
[class~="zs-padding-2-0.5-0.5-0.5"] {
  padding: 1em 0.25em 0.25em 0.25em !important;
}
[class~="zs-margin-2-0.5-0.5-0.5"] {
  margin: 1em 0.25em 0.25em 0.25em !important;
}
[class~="zs-padding-2-0.5-0.5-1"] {
  padding: 1em 0.25em 0.25em 0.5em !important;
}
[class~="zs-margin-2-0.5-0.5-1"] {
  margin: 1em 0.25em 0.25em 0.5em !important;
}
[class~="zs-padding-2-0.5-0.5-1.5"] {
  padding: 1em 0.25em 0.25em 0.75em !important;
}
[class~="zs-margin-2-0.5-0.5-1.5"] {
  margin: 1em 0.25em 0.25em 0.75em !important;
}
[class~="zs-padding-2-0.5-0.5-2"] {
  padding: 1em 0.25em 0.25em 1em !important;
}
[class~="zs-margin-2-0.5-0.5-2"] {
  margin: 1em 0.25em 0.25em 1em !important;
}
[class~="zs-padding-2-0.5-1-0"] {
  padding: 1em 0.25em 0.5em 0em !important;
}
[class~="zs-margin-2-0.5-1-0"] {
  margin: 1em 0.25em 0.5em 0em !important;
}
[class~="zs-padding-2-0.5-1-0.5"] {
  padding: 1em 0.25em 0.5em 0.25em !important;
}
[class~="zs-margin-2-0.5-1-0.5"] {
  margin: 1em 0.25em 0.5em 0.25em !important;
}
[class~="zs-padding-2-0.5-1-1"] {
  padding: 1em 0.25em 0.5em 0.5em !important;
}
[class~="zs-margin-2-0.5-1-1"] {
  margin: 1em 0.25em 0.5em 0.5em !important;
}
[class~="zs-padding-2-0.5-1-1.5"] {
  padding: 1em 0.25em 0.5em 0.75em !important;
}
[class~="zs-margin-2-0.5-1-1.5"] {
  margin: 1em 0.25em 0.5em 0.75em !important;
}
[class~="zs-padding-2-0.5-1-2"] {
  padding: 1em 0.25em 0.5em 1em !important;
}
[class~="zs-margin-2-0.5-1-2"] {
  margin: 1em 0.25em 0.5em 1em !important;
}
[class~="zs-padding-2-0.5-1.5-0"] {
  padding: 1em 0.25em 0.75em 0em !important;
}
[class~="zs-margin-2-0.5-1.5-0"] {
  margin: 1em 0.25em 0.75em 0em !important;
}
[class~="zs-padding-2-0.5-1.5-0.5"] {
  padding: 1em 0.25em 0.75em 0.25em !important;
}
[class~="zs-margin-2-0.5-1.5-0.5"] {
  margin: 1em 0.25em 0.75em 0.25em !important;
}
[class~="zs-padding-2-0.5-1.5-1"] {
  padding: 1em 0.25em 0.75em 0.5em !important;
}
[class~="zs-margin-2-0.5-1.5-1"] {
  margin: 1em 0.25em 0.75em 0.5em !important;
}
[class~="zs-padding-2-0.5-1.5-1.5"] {
  padding: 1em 0.25em 0.75em 0.75em !important;
}
[class~="zs-margin-2-0.5-1.5-1.5"] {
  margin: 1em 0.25em 0.75em 0.75em !important;
}
[class~="zs-padding-2-0.5-1.5-2"] {
  padding: 1em 0.25em 0.75em 1em !important;
}
[class~="zs-margin-2-0.5-1.5-2"] {
  margin: 1em 0.25em 0.75em 1em !important;
}
[class~="zs-padding-2-0.5-2-0"] {
  padding: 1em 0.25em 1em 0em !important;
}
[class~="zs-margin-2-0.5-2-0"] {
  margin: 1em 0.25em 1em 0em !important;
}
[class~="zs-padding-2-0.5-2-0.5"] {
  padding: 1em 0.25em 1em 0.25em !important;
}
[class~="zs-margin-2-0.5-2-0.5"] {
  margin: 1em 0.25em 1em 0.25em !important;
}
[class~="zs-padding-2-0.5-2-1"] {
  padding: 1em 0.25em 1em 0.5em !important;
}
[class~="zs-margin-2-0.5-2-1"] {
  margin: 1em 0.25em 1em 0.5em !important;
}
[class~="zs-padding-2-0.5-2-1.5"] {
  padding: 1em 0.25em 1em 0.75em !important;
}
[class~="zs-margin-2-0.5-2-1.5"] {
  margin: 1em 0.25em 1em 0.75em !important;
}
[class~="zs-padding-2-0.5-2-2"] {
  padding: 1em 0.25em 1em 1em !important;
}
[class~="zs-margin-2-0.5-2-2"] {
  margin: 1em 0.25em 1em 1em !important;
}
[class~="zs-padding-2-1-0-0"] {
  padding: 1em 0.5em 0em 0em !important;
}
[class~="zs-margin-2-1-0-0"] {
  margin: 1em 0.5em 0em 0em !important;
}
[class~="zs-padding-2-1-0-0.5"] {
  padding: 1em 0.5em 0em 0.25em !important;
}
[class~="zs-margin-2-1-0-0.5"] {
  margin: 1em 0.5em 0em 0.25em !important;
}
[class~="zs-padding-2-1-0-1"] {
  padding: 1em 0.5em 0em 0.5em !important;
}
[class~="zs-margin-2-1-0-1"] {
  margin: 1em 0.5em 0em 0.5em !important;
}
[class~="zs-padding-2-1-0-1.5"] {
  padding: 1em 0.5em 0em 0.75em !important;
}
[class~="zs-margin-2-1-0-1.5"] {
  margin: 1em 0.5em 0em 0.75em !important;
}
[class~="zs-padding-2-1-0-2"] {
  padding: 1em 0.5em 0em 1em !important;
}
[class~="zs-margin-2-1-0-2"] {
  margin: 1em 0.5em 0em 1em !important;
}
[class~="zs-padding-2-1-0.5-0"] {
  padding: 1em 0.5em 0.25em 0em !important;
}
[class~="zs-margin-2-1-0.5-0"] {
  margin: 1em 0.5em 0.25em 0em !important;
}
[class~="zs-padding-2-1-0.5-0.5"] {
  padding: 1em 0.5em 0.25em 0.25em !important;
}
[class~="zs-margin-2-1-0.5-0.5"] {
  margin: 1em 0.5em 0.25em 0.25em !important;
}
[class~="zs-padding-2-1-0.5-1"] {
  padding: 1em 0.5em 0.25em 0.5em !important;
}
[class~="zs-margin-2-1-0.5-1"] {
  margin: 1em 0.5em 0.25em 0.5em !important;
}
[class~="zs-padding-2-1-0.5-1.5"] {
  padding: 1em 0.5em 0.25em 0.75em !important;
}
[class~="zs-margin-2-1-0.5-1.5"] {
  margin: 1em 0.5em 0.25em 0.75em !important;
}
[class~="zs-padding-2-1-0.5-2"] {
  padding: 1em 0.5em 0.25em 1em !important;
}
[class~="zs-margin-2-1-0.5-2"] {
  margin: 1em 0.5em 0.25em 1em !important;
}
[class~="zs-padding-2-1-1-0"] {
  padding: 1em 0.5em 0.5em 0em !important;
}
[class~="zs-margin-2-1-1-0"] {
  margin: 1em 0.5em 0.5em 0em !important;
}
[class~="zs-padding-2-1-1-0.5"] {
  padding: 1em 0.5em 0.5em 0.25em !important;
}
[class~="zs-margin-2-1-1-0.5"] {
  margin: 1em 0.5em 0.5em 0.25em !important;
}
[class~="zs-padding-2-1-1-1"] {
  padding: 1em 0.5em 0.5em 0.5em !important;
}
[class~="zs-margin-2-1-1-1"] {
  margin: 1em 0.5em 0.5em 0.5em !important;
}
[class~="zs-padding-2-1-1-1.5"] {
  padding: 1em 0.5em 0.5em 0.75em !important;
}
[class~="zs-margin-2-1-1-1.5"] {
  margin: 1em 0.5em 0.5em 0.75em !important;
}
[class~="zs-padding-2-1-1-2"] {
  padding: 1em 0.5em 0.5em 1em !important;
}
[class~="zs-margin-2-1-1-2"] {
  margin: 1em 0.5em 0.5em 1em !important;
}
[class~="zs-padding-2-1-1.5-0"] {
  padding: 1em 0.5em 0.75em 0em !important;
}
[class~="zs-margin-2-1-1.5-0"] {
  margin: 1em 0.5em 0.75em 0em !important;
}
[class~="zs-padding-2-1-1.5-0.5"] {
  padding: 1em 0.5em 0.75em 0.25em !important;
}
[class~="zs-margin-2-1-1.5-0.5"] {
  margin: 1em 0.5em 0.75em 0.25em !important;
}
[class~="zs-padding-2-1-1.5-1"] {
  padding: 1em 0.5em 0.75em 0.5em !important;
}
[class~="zs-margin-2-1-1.5-1"] {
  margin: 1em 0.5em 0.75em 0.5em !important;
}
[class~="zs-padding-2-1-1.5-1.5"] {
  padding: 1em 0.5em 0.75em 0.75em !important;
}
[class~="zs-margin-2-1-1.5-1.5"] {
  margin: 1em 0.5em 0.75em 0.75em !important;
}
[class~="zs-padding-2-1-1.5-2"] {
  padding: 1em 0.5em 0.75em 1em !important;
}
[class~="zs-margin-2-1-1.5-2"] {
  margin: 1em 0.5em 0.75em 1em !important;
}
[class~="zs-padding-2-1-2-0"] {
  padding: 1em 0.5em 1em 0em !important;
}
[class~="zs-margin-2-1-2-0"] {
  margin: 1em 0.5em 1em 0em !important;
}
[class~="zs-padding-2-1-2-0.5"] {
  padding: 1em 0.5em 1em 0.25em !important;
}
[class~="zs-margin-2-1-2-0.5"] {
  margin: 1em 0.5em 1em 0.25em !important;
}
[class~="zs-padding-2-1-2-1"] {
  padding: 1em 0.5em 1em 0.5em !important;
}
[class~="zs-margin-2-1-2-1"] {
  margin: 1em 0.5em 1em 0.5em !important;
}
[class~="zs-padding-2-1-2-1.5"] {
  padding: 1em 0.5em 1em 0.75em !important;
}
[class~="zs-margin-2-1-2-1.5"] {
  margin: 1em 0.5em 1em 0.75em !important;
}
[class~="zs-padding-2-1-2-2"] {
  padding: 1em 0.5em 1em 1em !important;
}
[class~="zs-margin-2-1-2-2"] {
  margin: 1em 0.5em 1em 1em !important;
}
[class~="zs-padding-2-1.5-0-0"] {
  padding: 1em 0.75em 0em 0em !important;
}
[class~="zs-margin-2-1.5-0-0"] {
  margin: 1em 0.75em 0em 0em !important;
}
[class~="zs-padding-2-1.5-0-0.5"] {
  padding: 1em 0.75em 0em 0.25em !important;
}
[class~="zs-margin-2-1.5-0-0.5"] {
  margin: 1em 0.75em 0em 0.25em !important;
}
[class~="zs-padding-2-1.5-0-1"] {
  padding: 1em 0.75em 0em 0.5em !important;
}
[class~="zs-margin-2-1.5-0-1"] {
  margin: 1em 0.75em 0em 0.5em !important;
}
[class~="zs-padding-2-1.5-0-1.5"] {
  padding: 1em 0.75em 0em 0.75em !important;
}
[class~="zs-margin-2-1.5-0-1.5"] {
  margin: 1em 0.75em 0em 0.75em !important;
}
[class~="zs-padding-2-1.5-0-2"] {
  padding: 1em 0.75em 0em 1em !important;
}
[class~="zs-margin-2-1.5-0-2"] {
  margin: 1em 0.75em 0em 1em !important;
}
[class~="zs-padding-2-1.5-0.5-0"] {
  padding: 1em 0.75em 0.25em 0em !important;
}
[class~="zs-margin-2-1.5-0.5-0"] {
  margin: 1em 0.75em 0.25em 0em !important;
}
[class~="zs-padding-2-1.5-0.5-0.5"] {
  padding: 1em 0.75em 0.25em 0.25em !important;
}
[class~="zs-margin-2-1.5-0.5-0.5"] {
  margin: 1em 0.75em 0.25em 0.25em !important;
}
[class~="zs-padding-2-1.5-0.5-1"] {
  padding: 1em 0.75em 0.25em 0.5em !important;
}
[class~="zs-margin-2-1.5-0.5-1"] {
  margin: 1em 0.75em 0.25em 0.5em !important;
}
[class~="zs-padding-2-1.5-0.5-1.5"] {
  padding: 1em 0.75em 0.25em 0.75em !important;
}
[class~="zs-margin-2-1.5-0.5-1.5"] {
  margin: 1em 0.75em 0.25em 0.75em !important;
}
[class~="zs-padding-2-1.5-0.5-2"] {
  padding: 1em 0.75em 0.25em 1em !important;
}
[class~="zs-margin-2-1.5-0.5-2"] {
  margin: 1em 0.75em 0.25em 1em !important;
}
[class~="zs-padding-2-1.5-1-0"] {
  padding: 1em 0.75em 0.5em 0em !important;
}
[class~="zs-margin-2-1.5-1-0"] {
  margin: 1em 0.75em 0.5em 0em !important;
}
[class~="zs-padding-2-1.5-1-0.5"] {
  padding: 1em 0.75em 0.5em 0.25em !important;
}
[class~="zs-margin-2-1.5-1-0.5"] {
  margin: 1em 0.75em 0.5em 0.25em !important;
}
[class~="zs-padding-2-1.5-1-1"] {
  padding: 1em 0.75em 0.5em 0.5em !important;
}
[class~="zs-margin-2-1.5-1-1"] {
  margin: 1em 0.75em 0.5em 0.5em !important;
}
[class~="zs-padding-2-1.5-1-1.5"] {
  padding: 1em 0.75em 0.5em 0.75em !important;
}
[class~="zs-margin-2-1.5-1-1.5"] {
  margin: 1em 0.75em 0.5em 0.75em !important;
}
[class~="zs-padding-2-1.5-1-2"] {
  padding: 1em 0.75em 0.5em 1em !important;
}
[class~="zs-margin-2-1.5-1-2"] {
  margin: 1em 0.75em 0.5em 1em !important;
}
[class~="zs-padding-2-1.5-1.5-0"] {
  padding: 1em 0.75em 0.75em 0em !important;
}
[class~="zs-margin-2-1.5-1.5-0"] {
  margin: 1em 0.75em 0.75em 0em !important;
}
[class~="zs-padding-2-1.5-1.5-0.5"] {
  padding: 1em 0.75em 0.75em 0.25em !important;
}
[class~="zs-margin-2-1.5-1.5-0.5"] {
  margin: 1em 0.75em 0.75em 0.25em !important;
}
[class~="zs-padding-2-1.5-1.5-1"] {
  padding: 1em 0.75em 0.75em 0.5em !important;
}
[class~="zs-margin-2-1.5-1.5-1"] {
  margin: 1em 0.75em 0.75em 0.5em !important;
}
[class~="zs-padding-2-1.5-1.5-1.5"] {
  padding: 1em 0.75em 0.75em 0.75em !important;
}
[class~="zs-margin-2-1.5-1.5-1.5"] {
  margin: 1em 0.75em 0.75em 0.75em !important;
}
[class~="zs-padding-2-1.5-1.5-2"] {
  padding: 1em 0.75em 0.75em 1em !important;
}
[class~="zs-margin-2-1.5-1.5-2"] {
  margin: 1em 0.75em 0.75em 1em !important;
}
[class~="zs-padding-2-1.5-2-0"] {
  padding: 1em 0.75em 1em 0em !important;
}
[class~="zs-margin-2-1.5-2-0"] {
  margin: 1em 0.75em 1em 0em !important;
}
[class~="zs-padding-2-1.5-2-0.5"] {
  padding: 1em 0.75em 1em 0.25em !important;
}
[class~="zs-margin-2-1.5-2-0.5"] {
  margin: 1em 0.75em 1em 0.25em !important;
}
[class~="zs-padding-2-1.5-2-1"] {
  padding: 1em 0.75em 1em 0.5em !important;
}
[class~="zs-margin-2-1.5-2-1"] {
  margin: 1em 0.75em 1em 0.5em !important;
}
[class~="zs-padding-2-1.5-2-1.5"] {
  padding: 1em 0.75em 1em 0.75em !important;
}
[class~="zs-margin-2-1.5-2-1.5"] {
  margin: 1em 0.75em 1em 0.75em !important;
}
[class~="zs-padding-2-1.5-2-2"] {
  padding: 1em 0.75em 1em 1em !important;
}
[class~="zs-margin-2-1.5-2-2"] {
  margin: 1em 0.75em 1em 1em !important;
}
[class~="zs-padding-2-2-0-0"] {
  padding: 1em 1em 0em 0em !important;
}
[class~="zs-margin-2-2-0-0"] {
  margin: 1em 1em 0em 0em !important;
}
[class~="zs-padding-2-2-0-0.5"] {
  padding: 1em 1em 0em 0.25em !important;
}
[class~="zs-margin-2-2-0-0.5"] {
  margin: 1em 1em 0em 0.25em !important;
}
[class~="zs-padding-2-2-0-1"] {
  padding: 1em 1em 0em 0.5em !important;
}
[class~="zs-margin-2-2-0-1"] {
  margin: 1em 1em 0em 0.5em !important;
}
[class~="zs-padding-2-2-0-1.5"] {
  padding: 1em 1em 0em 0.75em !important;
}
[class~="zs-margin-2-2-0-1.5"] {
  margin: 1em 1em 0em 0.75em !important;
}
[class~="zs-padding-2-2-0-2"] {
  padding: 1em 1em 0em 1em !important;
}
[class~="zs-margin-2-2-0-2"] {
  margin: 1em 1em 0em 1em !important;
}
[class~="zs-padding-2-2-0.5-0"] {
  padding: 1em 1em 0.25em 0em !important;
}
[class~="zs-margin-2-2-0.5-0"] {
  margin: 1em 1em 0.25em 0em !important;
}
[class~="zs-padding-2-2-0.5-0.5"] {
  padding: 1em 1em 0.25em 0.25em !important;
}
[class~="zs-margin-2-2-0.5-0.5"] {
  margin: 1em 1em 0.25em 0.25em !important;
}
[class~="zs-padding-2-2-0.5-1"] {
  padding: 1em 1em 0.25em 0.5em !important;
}
[class~="zs-margin-2-2-0.5-1"] {
  margin: 1em 1em 0.25em 0.5em !important;
}
[class~="zs-padding-2-2-0.5-1.5"] {
  padding: 1em 1em 0.25em 0.75em !important;
}
[class~="zs-margin-2-2-0.5-1.5"] {
  margin: 1em 1em 0.25em 0.75em !important;
}
[class~="zs-padding-2-2-0.5-2"] {
  padding: 1em 1em 0.25em 1em !important;
}
[class~="zs-margin-2-2-0.5-2"] {
  margin: 1em 1em 0.25em 1em !important;
}
[class~="zs-padding-2-2-1-0"] {
  padding: 1em 1em 0.5em 0em !important;
}
[class~="zs-margin-2-2-1-0"] {
  margin: 1em 1em 0.5em 0em !important;
}
[class~="zs-padding-2-2-1-0.5"] {
  padding: 1em 1em 0.5em 0.25em !important;
}
[class~="zs-margin-2-2-1-0.5"] {
  margin: 1em 1em 0.5em 0.25em !important;
}
[class~="zs-padding-2-2-1-1"] {
  padding: 1em 1em 0.5em 0.5em !important;
}
[class~="zs-margin-2-2-1-1"] {
  margin: 1em 1em 0.5em 0.5em !important;
}
[class~="zs-padding-2-2-1-1.5"] {
  padding: 1em 1em 0.5em 0.75em !important;
}
[class~="zs-margin-2-2-1-1.5"] {
  margin: 1em 1em 0.5em 0.75em !important;
}
[class~="zs-padding-2-2-1-2"] {
  padding: 1em 1em 0.5em 1em !important;
}
[class~="zs-margin-2-2-1-2"] {
  margin: 1em 1em 0.5em 1em !important;
}
[class~="zs-padding-2-2-1.5-0"] {
  padding: 1em 1em 0.75em 0em !important;
}
[class~="zs-margin-2-2-1.5-0"] {
  margin: 1em 1em 0.75em 0em !important;
}
[class~="zs-padding-2-2-1.5-0.5"] {
  padding: 1em 1em 0.75em 0.25em !important;
}
[class~="zs-margin-2-2-1.5-0.5"] {
  margin: 1em 1em 0.75em 0.25em !important;
}
[class~="zs-padding-2-2-1.5-1"] {
  padding: 1em 1em 0.75em 0.5em !important;
}
[class~="zs-margin-2-2-1.5-1"] {
  margin: 1em 1em 0.75em 0.5em !important;
}
[class~="zs-padding-2-2-1.5-1.5"] {
  padding: 1em 1em 0.75em 0.75em !important;
}
[class~="zs-margin-2-2-1.5-1.5"] {
  margin: 1em 1em 0.75em 0.75em !important;
}
[class~="zs-padding-2-2-1.5-2"] {
  padding: 1em 1em 0.75em 1em !important;
}
[class~="zs-margin-2-2-1.5-2"] {
  margin: 1em 1em 0.75em 1em !important;
}
[class~="zs-padding-2-2-2-0"] {
  padding: 1em 1em 1em 0em !important;
}
[class~="zs-margin-2-2-2-0"] {
  margin: 1em 1em 1em 0em !important;
}
[class~="zs-padding-2-2-2-0.5"] {
  padding: 1em 1em 1em 0.25em !important;
}
[class~="zs-margin-2-2-2-0.5"] {
  margin: 1em 1em 1em 0.25em !important;
}
[class~="zs-padding-2-2-2-1"] {
  padding: 1em 1em 1em 0.5em !important;
}
[class~="zs-margin-2-2-2-1"] {
  margin: 1em 1em 1em 0.5em !important;
}
[class~="zs-padding-2-2-2-1.5"] {
  padding: 1em 1em 1em 0.75em !important;
}
[class~="zs-margin-2-2-2-1.5"] {
  margin: 1em 1em 1em 0.75em !important;
}
[class~="zs-padding-2-2-2-2"] {
  padding: 1em 1em 1em 1em !important;
}
[class~="zs-margin-2-2-2-2"] {
  margin: 1em 1em 1em 1em !important;
}
[class~="zs-shadow-flat-bottom-12"] {
  box-shadow: 0 12px 0 rgba(26, 22, 40, 0.1) !important;
}
[class~="zs-shadow-flat-bottom-8"] {
  box-shadow: 0 8px 0 rgba(26, 22, 40, 0.1) !important;
}
[class~="zs-shadow-flat-bottom-4"] {
  box-shadow: 0 4px 0 rgba(26, 22, 40, 0.1) !important;
}
[class~="zs-shadow-flat-bottom-2"] {
  box-shadow: 0 2px 0 rgba(26, 22, 40, 0.1) !important;
}
[class~="zs-shadow-flat-top-12"] {
  box-shadow: 0 -12px 0 rgba(26, 22, 40, 0.1) !important;
}
[class~="zs-shadow-flat-top-8"] {
  box-shadow: 0 -8px 0 rgba(26, 22, 40, 0.1) !important;
}
[class~="zs-shadow-flat-top-4"] {
  box-shadow: 0 -4px 0 rgba(26, 22, 40, 0.1) !important;
}
[class~="zs-shadow-flat-top-2"] {
  box-shadow: 0 -2px 0 rgba(26, 22, 40, 0.1) !important;
}
[class~="zs-shadow-flat-right-12"] {
  box-shadow: 12px 0 0 rgba(26, 22, 40, 0.1) !important;
}
[class~="zs-shadow-flat-right-8"] {
  box-shadow: 8px 0 0 rgba(26, 22, 40, 0.1) !important;
}
[class~="zs-shadow-flat-right-4"] {
  box-shadow: 4px 0 0 rgba(26, 22, 40, 0.1) !important;
}
[class~="zs-shadow-flat-right-2"] {
  box-shadow: 2px 0 0 rgba(26, 22, 40, 0.1) !important;
}
[class~="zs-shadow-flat-left-12"] {
  box-shadow: -12px 0 0 rgba(26, 22, 40, 0.1) !important;
}
[class~="zs-shadow-flat-left-8"] {
  box-shadow: -8px 0 0 rgba(26, 22, 40, 0.1) !important;
}
[class~="zs-shadow-flat-left-4"] {
  box-shadow: -4px 0 0 rgba(26, 22, 40, 0.1) !important;
}
[class~="zs-shadow-flat-left-2"] {
  box-shadow: -2px 0 0 rgba(26, 22, 40, 0.1) !important;
}
[class~="zs-shadow-flat-angle-right-12"] {
  box-shadow: 12px 12px 0 rgba(26, 22, 40, 0.1) !important;
}
[class~="zs-shadow-flat-angle-right-8"] {
  box-shadow: 8px 8px 0 rgba(26, 22, 40, 0.1) !important;
}
[class~="zs-shadow-flat-angle-right-4"] {
  box-shadow: 4px 4px 0 rgba(26, 22, 40, 0.1) !important;
}
[class~="zs-shadow-flat-angle-right-2"] {
  box-shadow: 2px 2px 0 rgba(26, 22, 40, 0.1) !important;
}
[class~="zs-shadow-flat-angle-left-12"] {
  box-shadow: -12px 12px 0 rgba(26, 22, 40, 0.1) !important;
}
[class~="zs-shadow-flat-angle-left-8"] {
  box-shadow: -8px 8px 0 rgba(26, 22, 40, 0.1) !important;
}
[class~="zs-shadow-flat-angle-left-4"] {
  box-shadow: -4px 4px 0 rgba(26, 22, 40, 0.1) !important;
}
[class~="zs-shadow-flat-angle-left-2"] {
  box-shadow: -2px 2px 0 rgba(26, 22, 40, 0.1) !important;
}
.zs-shadow-none {
  box-shadow: none !important;
}
.zs-shadow-blur-1 {
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(26, 22, 40, 0.12), 0px 2px 4px rgba(26, 22, 40, 0.12) !important;
}
.zs-shadow-blur-2 {
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(26, 22, 40, 0.12), 0px 4px 8px rgba(26, 22, 40, 0.18) !important;
}
.zs-shadow-blur-3 {
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(26, 22, 40, 0.12), 0px 10px 20px rgba(26, 22, 40, 0.18) !important;
}
.zs-shadow-blur-4 {
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(26, 22, 40, 0.12), 0px 16px 24px rgba(26, 22, 40, 0.2) !important;
}
.zs-shadow-blur-5 {
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(26, 22, 40, 0.08), 0px 16px 24px rgba(26, 22, 40, 0.1), 0px 24px 32px rgba(26, 22, 40, 0.14) !important;
}
.zs-visibility-visible {
  visibility: visible !important;
}
.zs-visibility-hidden {
  visibility: hidden !important;
}
.zs-border {
  border: 1px solid #DEDCDE !important;
}
.zs-border-top {
  border-top: 1px solid #DEDCDE !important;
}
.zs-border-right {
  border-right: 1px solid #DEDCDE !important;
}
.zs-border-bottom {
  border-bottom: 1px solid #DEDCDE !important;
}
.zs-border-left {
  border-left: 1px solid #DEDCDE !important;
}
.zs-border-0 {
  border: 0 !important;
}
.zs-border-top-0 {
  border-top: 0 !important;
}
.zs-border-right-0 {
  border-right: 0 !important;
}
.zs-border-bottom-0 {
  border-bottom: 0 !important;
}
.zs-border-left-0 {
  border-left: 0 !important;
}
.zs-border-rounded {
  border-radius: 0.25em !important;
}
.zs-border-rounded-top {
  border-top-left-radius: 0.25em !important;
  border-top-right-radius: 0.25em !important;
}
.zs-border-rounded-right {
  border-top-right-radius: 0.25em !important;
  border-bottom-right-radius: 0.25em !important;
}
.zs-border-rounded-bottom {
  border-bottom-right-radius: 0.25em !important;
  border-bottom-left-radius: 0.25em !important;
}
.zs-border-rounded-left {
  border-top-left-radius: 0.25em !important;
  border-bottom-left-radius: 0.25em !important;
}
.zs-border-circle {
  border-radius: 50% !important;
}
.zs-border-rounded-0 {
  border-radius: 0 !important;
}
.zs-border-color-disabled {
  border-color: #B2B0B6 !important;
}
.zs-border-color-info-secondary {
  border-color: #A6B2F3 !important;
}
.zs-border-color-success-secondary {
  border-color: #00AA67 !important;
}
.zs-border-color-warning-secondary {
  border-color: #E3A900 !important;
}
.zs-border-color-error-secondary {
  border-color: #FFB6A5 !important;
}
.zs-border-color-info {
  border-color: #2B44C7 !important;
}
.zs-border-color-success {
  border-color: #00AA67 !important;
}
.zs-border-color-warning {
  border-color: #E3A900 !important;
}
.zs-border-color-error {
  border-color: #B21111 !important;
}
.zs-border-color-default {
  border-color: #DEDCDE !important;
}
/**
* Toolbar component styles
*/
.zs-toolbar {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.zs-toolbar > a {
  flex: 1 1 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1em 1em 1em 1em;
}
.zs-toolbar > a > .zs-icon {
  padding: 0em 0.125em 0em 0.125em;
  color: inherit;
}
.zs-toolbar > a > .zs-icon::before {
  margin: 0;
  display: block;
  vertical-align: initial;
}
.zs-toolbar > a > .zs-icon + *,
.zs-toolbar > a > * {
  padding: 0em 0.33333333em 0em 0.33333333em;
  cursor: pointer;
}
.zs-toolbar > a > *:last-child {
  padding-right: 0;
}
.zs-toolbar > a > *:first-child {
  padding-left: 0;
}
.zs-toolbar > a > .zs-icon {
  padding: 0em 0em 0em 0em;
}
.zs-toolbar > a.zs-toolbar-partial-separator {
  position: relative;
}
.zs-toolbar > a.zs-toolbar-partial-separator::after {
  content: '\00a0';
  border: 1px solid #DEDCDE;
  border-left: none;
  position: absolute;
  right: 0;
  width: 0;
  top: 50%;
  transform: translateY(-50%);
}
.zs-toolbar > a.zs-toolbar-separator {
  border-right: 1px solid #DEDCDE;
}
.zs-toolbar > a:focus.zs-toolbar-partial-separator::after,
.zs-toolbar > a:focus.zs-toolbar-separator {
  border-color: transparent;
}
.zs-toolbar > a.zs-active {
  color: #ffffff;
}
.zs-toolbar > a.zs-active > .zs-icon::before {
  color: inherit;
}
.zs-toolbar > a.zs-active:hover {
  color: #ffffff;
}
.zs-toolbar.zs-toolbar-vertical {
  flex-direction: column;
  height: 100%;
}
.zs-toolbar.zs-toolbar-vertical > a {
  padding: 0.75em 0.5em 0.75em 0.5em;
}
.zs-toolbar.zs-toolbar-vertical > a.zs-toolbar-separator {
  border-right: none;
  border-bottom: 1px solid #DEDCDE;
}
.zs-toolbar.zs-toolbar-labels-below > a {
  flex-direction: column;
}
.zs-toolbar.zs-toolbar-labels-below > a > * {
  padding: 0em 0em 0em 0em;
}
.zs-toolbar.zs-toolbar-vertical.zs-toolbar.zs-toolbar-labels-below > a {
  text-align: center;
}
.zs-toolbar.zs-toolbar-align-left > a {
  justify-content: flex-start;
}
.zs-toolbar-target > a:target,
.zs-toolbar-target > a:active {
  color: #ffffff;
}
.zs-toolbar-target > a:target > .zs-icon::before,
.zs-toolbar-target > a:active > .zs-icon::before {
  color: inherit;
}
.zs-toolbar-target > a:target:hover,
.zs-toolbar-target > a:active:hover {
  color: #ffffff;
}
.zs-layout-full-sidenav {
  display: -ms-grid;
  display: grid;
  grid-template-columns: auto minmax(min-content, 1fr) auto;
  -ms-grid-columns: auto minmax(min-content, 1fr) auto;
  grid-template-rows: auto minmax(min-content, 1fr) auto;
  -ms-grid-rows: auto minmax(min-content, 1fr) auto;
}
.zs-layout-full-sidenav > header,
.zs-layout-full-sidenav > .zs-header {
  grid-column-start: 2;
  -ms-grid-column: 2;
  grid-row-start: 1;
  -ms-grid-row: 1;
}
.zs-layout-full-sidenav > nav,
.zs-layout-full-sidenav > .zs-nav {
  grid-column-start: 1;
  -ms-grid-column: 1;
  grid-row-start: 1;
  -ms-grid-row: 1;
  grid-row-end: span 3;
  -ms-grid-row-span: 3;
  display: -ms-grid;
  display: grid;
  grid-template-columns: auto;
  -ms-grid-columns: auto;
  grid-template-rows: auto auto 1fr;
  -ms-grid-rows: auto auto 1fr;
  background: linear-gradient(180deg, #FEFBF4 0%, #022D42 0%, #2F6F7B 140%);
  padding: 0.5em 0em 1.5em 0em;
  overflow: hidden;
}
.zs-layout-full-sidenav > nav :nth-of-type(1n+1),
.zs-layout-full-sidenav > .zs-nav :nth-of-type(1n+1) {
  grid-column-start: 1;
  -ms-grid-column: 1;
}
.zs-layout-full-sidenav > nav :nth-of-type(n + 1),
.zs-layout-full-sidenav > .zs-nav :nth-of-type(n + 1) {
  grid-row-start: 1;
  -ms-grid-row: 1;
}
.zs-layout-full-sidenav > nav :nth-of-type(n + 2),
.zs-layout-full-sidenav > .zs-nav :nth-of-type(n + 2) {
  grid-row-start: 2;
  -ms-grid-row: 2;
}
.zs-layout-full-sidenav > nav :nth-of-type(n + 3),
.zs-layout-full-sidenav > .zs-nav :nth-of-type(n + 3) {
  grid-row-start: 3;
  -ms-grid-row: 3;
}
.zs-layout-full-sidenav > nav :nth-of-type(n + 4),
.zs-layout-full-sidenav > .zs-nav :nth-of-type(n + 4) {
  grid-row-start: 4;
  -ms-grid-row: 4;
}
.zs-layout-full-sidenav > main,
.zs-layout-full-sidenav > .zs-main {
  grid-column-start: 2;
  -ms-grid-column: 2;
  grid-row-start: 2;
  -ms-grid-row: 2;
}
.zs-layout-full-sidenav > footer,
.zs-layout-full-sidenav .zs-footer {
  grid-column-start: 2;
  -ms-grid-column: 2;
  grid-row-start: 3;
  -ms-grid-row: 3;
}
* {
  margin: 0;
  padding: 0;
  border: 0;
  box-sizing: border-box;
}
body {
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  color: #2F2C3C;
}
body a {
  color: #2D8B93;
  text-decoration: none;
}
body a:hover {
  color: #27A6A4;
  text-decoration: none;
}
body a:active {
  color: #2F6F7B;
}
body a:visited {
  color: #2D535F;
}
.zs-brand-bar {
  background: #1A1628;
  display: -ms-grid;
  display: grid;
  grid-template-columns: max-content max-content 1fr;
  -ms-grid-columns: max-content max-content 1fr;
}
.zs-brand-bar .zs-logo-container {
  grid-column-start: 1;
  -ms-grid-column: 1;
  grid-row-start: 1;
  -ms-grid-row: 1;
  display: flex;
  padding: 0.25em 1em 0.25em 1em;
}
.zs-brand-bar .zs-app-name {
  grid-column-start: 2;
  -ms-grid-column: 2;
  grid-row-start: 1;
  -ms-grid-row: 1;
  align-self: center;
  -ms-grid-row-align: center;
  color: #FAFAFA;
}
h2 {
  font-size: 1.5em;
  font-weight: 700;
  line-height: 1.33;
  margin: 1em 0em 0.5em 0em;
}
section {
  margin: 8em 0em 0em 0em;
  text-align: center;
}
section p,
section li {
  font-size: 0.875em;
}
section ul {
  display: inline-block;
  text-align: left;
}
section ul li {
  margin: 0em 0em 0.5em 0em;
}
section p {
  margin: 0em 0em 2.25em 0em;
}
.zs-radio {
  display: block;
  position: relative;
  font-size: 1em;
  cursor: pointer;
}
.zs-radio input {
  position: absolute;
  width: 0;
  height: 0;
  opacity: 0;
  font-size: inherit;
}
.zs-radio input ~ [radio] {
  position: relative;
  padding: 0em 0em 0em 1.5em;
  font-size: 1em;
  line-height: 1.5;
  display: inline;
  letter-spacing: -0.013em;
}
.zs-radio input ~ [radio]::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  background: #FAFAFA;
  border: 1px solid #DEDCDE;
  transform: translateY(-50%);
}
.zs-radio input:hover ~ [radio]::before {
  border-color: #27A6A4;
}
.zs-radio input:checked ~ [radio]::before {
  border-color: #27A6A4;
}
.zs-radio input:checked ~ [radio]::after {
  position: absolute;
  top: 50%;
  left: 0.25em;
  content: '';
  width: 0.5em;
  height: 0.5em;
  border-radius: 50%;
  background: #27A6A4;
  transform: translateY(-50%);
}
.zs-radio input:focus ~ [radio]::before {
  border-color: #2F6F7B;
  background: #F3FCFE;
}
.zs-radio input:focus:checked ~ [radio]::before {
  background: #FAFAFA;
}
.zs-radio input:focus:checked ~ [radio]::after {
  background: #2F6F7B;
}
.zs-radio[disabled] input ~ [radio],
.zs-radio.zs-disabled input ~ [radio],
.zs-radio.zs-calendar > table td[disabled] input ~ [radio],
.zs-radio.zs-range-calendar > table td[disabled] input ~ [radio],
.zs-radio.zs-range-calendar > section > table td[disabled] input ~ [radio] {
  color: #B2B0B6;
}
.zs-radio[disabled] input ~ [radio]::before,
.zs-radio.zs-disabled input ~ [radio]::before,
.zs-radio.zs-calendar > table td[disabled] input ~ [radio]::before,
.zs-radio.zs-range-calendar > table td[disabled] input ~ [radio]::before,
.zs-radio.zs-range-calendar > section > table td[disabled] input ~ [radio]::before {
  color: #B2B0B6;
  background: none;
  background-color: #F4F3F3;
  border-color: #B2B0B6;
}
.zs-radio[disabled] input:checked ~ [radio]::before,
.zs-radio.zs-disabled input:checked ~ [radio]::before,
.zs-radio.zs-calendar > table td[disabled] input:checked ~ [radio]::before,
.zs-radio.zs-range-calendar > table td[disabled] input:checked ~ [radio]::before,
.zs-radio.zs-range-calendar > section > table td[disabled] input:checked ~ [radio]::before {
  color: #B2B0B6;
  background: none;
  background-color: #F4F3F3;
  border-color: #B2B0B6;
}
.zs-radio[disabled] input:checked ~ [radio]::after,
.zs-radio.zs-disabled input:checked ~ [radio]::after,
.zs-radio.zs-calendar > table td[disabled] input:checked ~ [radio]::after,
.zs-radio.zs-range-calendar > table td[disabled] input:checked ~ [radio]::after,
.zs-radio.zs-range-calendar > section > table td[disabled] input:checked ~ [radio]::after {
  background: #B2B0B6;
}
.zs-checkbox {
  display: block;
  position: relative;
  font-size: 1em;
  cursor: pointer;
}
.zs-checkbox input {
  position: absolute;
  width: 0;
  height: 0;
  opacity: 0;
  font-size: inherit;
}
.zs-checkbox input ~ [checkbox] {
  position: relative;
  padding: 0em 0em 0em 1.5em;
  line-height: 1.5;
  display: inline;
  letter-spacing: -0.013em;
}
.zs-checkbox input ~ [checkbox]::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  width: 1em;
  height: 1em;
  background: #ffffff;
  border: 1px solid #DEDCDE;
  transform: translateY(-50%);
}
.zs-checkbox input:hover ~ [checkbox]::before {
  border-color: #27A6A4;
}
.zs-checkbox input:checked ~ [checkbox]::before {
  border-color: #27A6A4;
  background: #27A6A4;
}
.zs-checkbox input:checked ~ [checkbox]::after {
  transform: translateY(-50%);
  content: "\ea29";
  position: absolute;
  display: block;
  color: #ffffff;
  font-family: 'zsIcons';
  top: 50%;
}
.zs-checkbox input:indeterminate ~ [checkbox]::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  width: 1em;
  height: 1em;
  background: #ffffff;
  border: 1px solid #DEDCDE;
  transform: translateY(-50%);
}
.zs-checkbox input:indeterminate ~ [checkbox]::after {
  display: block;
  content: '';
  height: 0.5em;
  width: 0.5em;
  background: #27A6A4;
  top: 50%;
  position: absolute;
  left: 0;
  transform: translate(50%, -50%);
}
.zs-checkbox input:focus ~ [checkbox]::before {
  border-color: #2F6F7B;
  background: #F3FCFE;
}
.zs-checkbox input:checked:focus ~ [checkbox]::before {
  background: #2F6F7B;
}
.zs-checkbox input:focus:indeterminate ~ [checkbox]::before {
  background: #ffffff;
}
.zs-checkbox input:focus:indeterminate ~ [checkbox]::after {
  background: #2F6F7B;
}
.zs-checkbox[disabled] input ~ [checkbox],
.zs-checkbox.zs-disabled input ~ [checkbox],
.zs-checkbox.zs-calendar > table td[disabled] input ~ [checkbox],
.zs-checkbox.zs-range-calendar > table td[disabled] input ~ [checkbox],
.zs-checkbox.zs-range-calendar > section > table td[disabled] input ~ [checkbox] {
  color: #B2B0B6;
}
.zs-checkbox[disabled] input ~ [checkbox]::before,
.zs-checkbox.zs-disabled input ~ [checkbox]::before,
.zs-checkbox.zs-calendar > table td[disabled] input ~ [checkbox]::before,
.zs-checkbox.zs-range-calendar > table td[disabled] input ~ [checkbox]::before,
.zs-checkbox.zs-range-calendar > section > table td[disabled] input ~ [checkbox]::before {
  color: #B2B0B6;
  background: none;
  background-color: #F4F3F3;
  border-color: #B2B0B6;
}
.zs-checkbox[disabled] input:checked ~ [checkbox]::before,
.zs-checkbox.zs-disabled input:checked ~ [checkbox]::before,
.zs-checkbox.zs-calendar > table td[disabled] input:checked ~ [checkbox]::before,
.zs-checkbox.zs-range-calendar > table td[disabled] input:checked ~ [checkbox]::before,
.zs-checkbox.zs-range-calendar > section > table td[disabled] input:checked ~ [checkbox]::before {
  color: #B2B0B6;
  background: none;
  background-color: #F4F3F3;
  border-color: #B2B0B6;
}
.zs-checkbox[disabled] input:checked ~ [checkbox]::after,
.zs-checkbox.zs-disabled input:checked ~ [checkbox]::after,
.zs-checkbox.zs-calendar > table td[disabled] input:checked ~ [checkbox]::after,
.zs-checkbox.zs-range-calendar > table td[disabled] input:checked ~ [checkbox]::after,
.zs-checkbox.zs-range-calendar > section > table td[disabled] input:checked ~ [checkbox]::after {
  color: #B2B0B6;
}
.zs-checkbox[disabled] input:indeterminate ~ [checkbox]::after,
.zs-checkbox.zs-disabled input:indeterminate ~ [checkbox]::after,
.zs-checkbox.zs-calendar > table td[disabled] input:indeterminate ~ [checkbox]::after,
.zs-checkbox.zs-range-calendar > table td[disabled] input:indeterminate ~ [checkbox]::after,
.zs-checkbox.zs-range-calendar > section > table td[disabled] input:indeterminate ~ [checkbox]::after {
  background: #B2B0B6;
}
.zs-action-field {
  font-size: 1em;
  display: inline-block;
}
.zs-action-field > label,
.zs-action-field [field-container] {
  display: grid;
  display: -ms-grid;
  grid-template-rows: auto auto auto;
  -ms-grid-rows: auto auto auto;
  grid-template-columns: auto 1fr auto auto;
  -ms-grid-columns: auto 1fr auto auto;
}
.zs-action-field [label] {
  display: inline-block;
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-column-span: 4;
  grid-column-end: span 4;
  font-size: 0.75em;
  padding: 0em 0em 0.66666667em 0em;
  font-weight: 700;
  letter-spacing: -0.013em;
  line-height: 1.37;
  color: #1A1628;
}
.zs-action-field [label]:empty {
  padding: 0;
}
.zs-action-field [helper] {
  display: inline-block;
  -ms-grid-row: 3;
  grid-row-start: 3;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-column-span: 4;
  grid-column-end: span 4;
  font-size: 0.75em;
  padding: 0.66666667em 0em 0em 0em;
  letter-spacing: -0.013em;
  line-height: 1.5;
  color: #716E79;
}
.zs-action-field [helper]:empty {
  padding: 0;
}
.zs-action-field [input] {
  -ms-grid-row: 2;
  grid-row-start: 2;
  -ms-grid-column: 1;
  grid-column-start: 1;
  grid-column-end: span 4;
  -ms-grid-column-span: 4;
  line-height: 1.5;
  font-size: 1em;
  border-radius: 0;
  padding: 1em 1em 1em 1em;
  height: 3.5em;
  outline: none;
  border: 1px solid #DEDCDE;
  background: #FAFAFA;
}
.zs-action-field [input]::-webkit-input-placeholder {
  font-style: italic;
  color: #716E79;
}
.zs-action-field [input]:-moz-placeholder {
  /* Firefox 18- */
  font-style: italic;
  color: #716E79;
}
.zs-action-field [input]::-moz-placeholder {
  /* Firefox 19+ */
  font-style: italic;
  color: #716E79;
}
.zs-action-field [input]:-ms-input-placeholder {
  font-style: italic;
  color: #716E79;
}
.zs-action-field [input]:focus {
  border: 1px solid #27A6A4;
}
.zs-action-field [input]:hover {
  border: 1px solid #9C9AA1;
}
.zs-action-field [input]:active {
  border: 1px solid #27A6A4;
}
.zs-action-field [input]:focus {
  background: #F4F3F3;
}
.zs-action-field [input]:hover {
  background: #FAFAFA;
}
.zs-action-field [input]:active {
  background: #F4F3F3;
}
.zs-action-field [input]:focus:hover {
  border: 1px solid #27A6A4;
  background: #F4F3F3;
}
.zs-action-field [input]:hover ~ [signifier] {
  color: #716E79;
}
.zs-action-field [input][value]:not([value=""]):not(:focus):not(:hover):not(:active) ~ [signifier] {
  color: #1A1628;
}
.zs-action-field [input]:focus:hover ~ [signifier] {
  color: #27A6A4;
}
.zs-action-field [input]:focus ~ [signifier] {
  color: #27A6A4;
}
.zs-action-field [input]:active ~ [signifier] {
  color: #27A6A4;
}
.zs-action-field input[type="text"]:not(:focus):not(:hover):not([value]):not(:disabled):not(.zs-disabled),
.zs-action-field input[type="text"]:not(:focus):not(:hover)[value=""]:not(:disabled):not(.zs-disabled) {
  background: #ffffff;
}
.zs-action-field [action] {
  -ms-grid-row: 2;
  grid-row-start: 2;
  -ms-grid-column: 4;
  grid-column-start: 4;
  align-self: center;
  justify-self: center;
  -ms-grid-row-align: center;
  font-size: 1.5em;
  margin: 0em 0.66666667em 0em 0em;
}
.zs-action-field [signifier] {
  -ms-grid-row: 2;
  grid-row-start: 2;
  -ms-grid-column: 1;
  grid-column-start: 1;
  align-self: center;
  justify-self: center;
  -ms-grid-row-align: center;
  color: #716E79;
  font-size: 1.5em;
  margin: 0em 0.66666667em 0em 0.66666667em;
}
.zs-action-field [hint] {
  -ms-grid-row: 2;
  grid-row-start: 2;
  -ms-grid-column: 3;
  grid-column-start: 3;
  align-self: center;
  justify-self: center;
  -ms-grid-row-align: center;
  font-size: 1.5em;
  margin: 0em 0.33333333em 0em 0em;
}
.zs-action-field [clear] {
  -ms-grid-row: 2;
  grid-row-start: 2;
  -ms-grid-column: 3;
  grid-column-start: 3;
  align-self: center;
  justify-self: center;
  -ms-grid-row-align: center;
  font-size: 1.5em;
  margin: 0em 0.33333333em 0em 0em;
}
.zs-action-field[clear]:not([action-icon]) [clear] {
  margin: 0em 0.66666667em 0em 0em;
}
.zs-action-field[hint-icon]:not([action-icon]) [hint] {
  margin: 0em 0.66666667em 0em 0em;
}
.zs-action-field.zs-error [input],
.zs-action-field.zs-danger [input],
.zs-action-field[invalid] [input] {
  border: 1px solid #B21111;
}
.zs-action-field.zs-error [input]:focus,
.zs-action-field.zs-danger [input]:focus,
.zs-action-field[invalid] [input]:focus {
  border: 1px solid #892208;
}
.zs-action-field.zs-error [input]:hover,
.zs-action-field.zs-danger [input]:hover,
.zs-action-field[invalid] [input]:hover {
  border: 1px solid #F27755;
}
.zs-action-field.zs-error [input]:active,
.zs-action-field.zs-danger [input]:active,
.zs-action-field[invalid] [input]:active {
  border: 1px solid #5C1A0B;
}
.zs-action-field.zs-error [action],
.zs-action-field.zs-danger [action],
.zs-action-field[invalid] [action],
.zs-action-field.zs-error [hint],
.zs-action-field.zs-danger [hint],
.zs-action-field[invalid] [hint],
.zs-action-field.zs-error [clear],
.zs-action-field.zs-danger [clear],
.zs-action-field[invalid] [clear] {
  color: #B21111;
}
.zs-action-field.zs-error [action]:visited,
.zs-action-field.zs-danger [action]:visited,
.zs-action-field[invalid] [action]:visited,
.zs-action-field.zs-error [hint]:visited,
.zs-action-field.zs-danger [hint]:visited,
.zs-action-field[invalid] [hint]:visited,
.zs-action-field.zs-error [clear]:visited,
.zs-action-field.zs-danger [clear]:visited,
.zs-action-field[invalid] [clear]:visited {
  color: #B21111;
}
.zs-action-field.zs-error [action]:focus,
.zs-action-field.zs-danger [action]:focus,
.zs-action-field[invalid] [action]:focus,
.zs-action-field.zs-error [hint]:focus,
.zs-action-field.zs-danger [hint]:focus,
.zs-action-field[invalid] [hint]:focus,
.zs-action-field.zs-error [clear]:focus,
.zs-action-field.zs-danger [clear]:focus,
.zs-action-field[invalid] [clear]:focus {
  color: #892208;
}
.zs-action-field.zs-error [action]:hover,
.zs-action-field.zs-danger [action]:hover,
.zs-action-field[invalid] [action]:hover,
.zs-action-field.zs-error [hint]:hover,
.zs-action-field.zs-danger [hint]:hover,
.zs-action-field[invalid] [hint]:hover,
.zs-action-field.zs-error [clear]:hover,
.zs-action-field.zs-danger [clear]:hover,
.zs-action-field[invalid] [clear]:hover {
  color: #F27755;
}
.zs-action-field.zs-error [action]:active,
.zs-action-field.zs-danger [action]:active,
.zs-action-field[invalid] [action]:active,
.zs-action-field.zs-error [hint]:active,
.zs-action-field.zs-danger [hint]:active,
.zs-action-field[invalid] [hint]:active,
.zs-action-field.zs-error [clear]:active,
.zs-action-field.zs-danger [clear]:active,
.zs-action-field[invalid] [clear]:active {
  color: #5C1A0B;
}
.zs-action-field.zs-error [helper],
.zs-action-field.zs-danger [helper],
.zs-action-field[invalid] [helper] {
  color: #B21111;
}
.zs-action-field.zs-success [input] {
  border: 1px solid #00AA67;
}
.zs-action-field.zs-success [input]:focus {
  border: 1px solid #0A6E5E;
}
.zs-action-field.zs-success [input]:hover {
  border: 1px solid #54D7AF;
}
.zs-action-field.zs-success [input]:active {
  border: 1px solid #0C4846;
}
.zs-action-field.zs-success [action],
.zs-action-field.zs-success [hint],
.zs-action-field.zs-success [clear] {
  color: #00AA67;
}
.zs-action-field.zs-success [action]:visited,
.zs-action-field.zs-success [hint]:visited,
.zs-action-field.zs-success [clear]:visited {
  color: #00AA67;
}
.zs-action-field.zs-success [action]:focus,
.zs-action-field.zs-success [hint]:focus,
.zs-action-field.zs-success [clear]:focus {
  color: #0A6E5E;
}
.zs-action-field.zs-success [action]:hover,
.zs-action-field.zs-success [hint]:hover,
.zs-action-field.zs-success [clear]:hover {
  color: #54D7AF;
}
.zs-action-field.zs-success [action]:active,
.zs-action-field.zs-success [hint]:active,
.zs-action-field.zs-success [clear]:active {
  color: #0C4846;
}
.zs-action-field.zs-success [helper] {
  color: #00AA67;
}
.zs-action-field.zs-warning [input] {
  border: 1px solid #E3A900;
}
.zs-action-field.zs-warning [input]:focus {
  border: 1px solid #8A640C;
}
.zs-action-field.zs-warning [input]:hover {
  border: 1px solid #FDCA63;
}
.zs-action-field.zs-warning [input]:active {
  border: 1px solid #563F0E;
}
.zs-action-field.zs-warning [action],
.zs-action-field.zs-warning [hint],
.zs-action-field.zs-warning [clear] {
  color: #E3A900;
}
.zs-action-field.zs-warning [action]:visited,
.zs-action-field.zs-warning [hint]:visited,
.zs-action-field.zs-warning [clear]:visited {
  color: #E3A900;
}
.zs-action-field.zs-warning [action]:focus,
.zs-action-field.zs-warning [hint]:focus,
.zs-action-field.zs-warning [clear]:focus {
  color: #8A640C;
}
.zs-action-field.zs-warning [action]:hover,
.zs-action-field.zs-warning [hint]:hover,
.zs-action-field.zs-warning [clear]:hover {
  color: #FDCA63;
}
.zs-action-field.zs-warning [action]:active,
.zs-action-field.zs-warning [hint]:active,
.zs-action-field.zs-warning [clear]:active {
  color: #563F0E;
}
.zs-action-field.zs-warning [helper] {
  color: #E3A900;
}
.zs-action-field.zs-info [input] {
  border: 1px solid #2B44C7;
}
.zs-action-field.zs-info [input]:focus {
  border: 1px solid #141187;
}
.zs-action-field.zs-info [input]:hover {
  border: 1px solid #7E92E9;
}
.zs-action-field.zs-info [input]:active {
  border: 1px solid #160C60;
}
.zs-action-field.zs-info [action],
.zs-action-field.zs-info [hint],
.zs-action-field.zs-info [clear] {
  color: #2B44C7;
}
.zs-action-field.zs-info [action]:visited,
.zs-action-field.zs-info [hint]:visited,
.zs-action-field.zs-info [clear]:visited {
  color: #2B44C7;
}
.zs-action-field.zs-info [action]:focus,
.zs-action-field.zs-info [hint]:focus,
.zs-action-field.zs-info [clear]:focus {
  color: #141187;
}
.zs-action-field.zs-info [action]:hover,
.zs-action-field.zs-info [hint]:hover,
.zs-action-field.zs-info [clear]:hover {
  color: #7E92E9;
}
.zs-action-field.zs-info [action]:active,
.zs-action-field.zs-info [hint]:active,
.zs-action-field.zs-info [clear]:active {
  color: #160C60;
}
.zs-action-field.zs-info [helper] {
  color: #2B44C7;
}
.zs-action-field[disabled] [input],
.zs-action-field.zs-disabled [input],
.zs-action-field.zs-calendar > table td[disabled] [input],
.zs-action-field.zs-range-calendar > table td[disabled] [input],
.zs-action-field.zs-range-calendar > section > table td[disabled] [input] {
  border: 1px solid #B2B0B6;
  color: #B2B0B6;
  background: none;
  background-color: #F4F3F3;
}
.zs-action-field[disabled] [input]::-webkit-input-placeholder,
.zs-action-field.zs-disabled [input]::-webkit-input-placeholder,
.zs-action-field.zs-calendar > table td[disabled] [input]::-webkit-input-placeholder,
.zs-action-field.zs-range-calendar > table td[disabled] [input]::-webkit-input-placeholder,
.zs-action-field.zs-range-calendar > section > table td[disabled] [input]::-webkit-input-placeholder {
  color: #B2B0B6;
}
.zs-action-field[disabled] [input]:-moz-placeholder,
.zs-action-field.zs-disabled [input]:-moz-placeholder,
.zs-action-field.zs-calendar > table td[disabled] [input]:-moz-placeholder,
.zs-action-field.zs-range-calendar > table td[disabled] [input]:-moz-placeholder,
.zs-action-field.zs-range-calendar > section > table td[disabled] [input]:-moz-placeholder {
  /* Firefox 18- */
  color: #B2B0B6;
}
.zs-action-field[disabled] [input]::-moz-placeholder,
.zs-action-field.zs-disabled [input]::-moz-placeholder,
.zs-action-field.zs-calendar > table td[disabled] [input]::-moz-placeholder,
.zs-action-field.zs-range-calendar > table td[disabled] [input]::-moz-placeholder,
.zs-action-field.zs-range-calendar > section > table td[disabled] [input]::-moz-placeholder {
  /* Firefox 19+ */
  color: #B2B0B6;
}
.zs-action-field[disabled] [input]:-ms-input-placeholder,
.zs-action-field.zs-disabled [input]:-ms-input-placeholder,
.zs-action-field.zs-calendar > table td[disabled] [input]:-ms-input-placeholder,
.zs-action-field.zs-range-calendar > table td[disabled] [input]:-ms-input-placeholder,
.zs-action-field.zs-range-calendar > section > table td[disabled] [input]:-ms-input-placeholder {
  color: #B2B0B6;
}
.zs-action-field[disabled] [clear],
.zs-action-field.zs-disabled [clear],
.zs-action-field[disabled] [hint],
.zs-action-field.zs-disabled [hint],
.zs-action-field[disabled] [action],
.zs-action-field.zs-disabled [action],
.zs-action-field[disabled] [signifier],
.zs-action-field.zs-disabled [signifier],
.zs-action-field.zs-calendar > table td[disabled] [clear],
.zs-action-field.zs-calendar > table td[disabled] [hint],
.zs-action-field.zs-calendar > table td[disabled] [action],
.zs-action-field.zs-calendar > table td[disabled] [signifier],
.zs-action-field.zs-range-calendar > table td[disabled] [clear],
.zs-action-field.zs-range-calendar > table td[disabled] [hint],
.zs-action-field.zs-range-calendar > table td[disabled] [action],
.zs-action-field.zs-range-calendar > table td[disabled] [signifier],
.zs-action-field.zs-range-calendar > section > table td[disabled] [clear],
.zs-action-field.zs-range-calendar > section > table td[disabled] [hint],
.zs-action-field.zs-range-calendar > section > table td[disabled] [action],
.zs-action-field.zs-range-calendar > section > table td[disabled] [signifier] {
  color: #B2B0B6;
}
.zs-action-field.zs-action-field-solid [input] {
  border: 0px;
  box-shadow: none;
  box-shadow: inset 0 -2px 0 0 #DEDCDE;
  background: #FAFAFA;
}
.zs-action-field.zs-action-field-solid [input]:focus {
  box-shadow: inset 0 -2px 0 0 #27A6A4;
}
.zs-action-field.zs-action-field-solid [input]:hover {
  box-shadow: inset 0 -2px 0 0 #9C9AA1;
}
.zs-action-field.zs-action-field-solid [input]:active {
  box-shadow: inset 0 -2px 0 0 #27A6A4;
}
.zs-action-field.zs-action-field-solid [input]:focus {
  background: #F4F3F3;
}
.zs-action-field.zs-action-field-solid [input]:hover {
  background: #F4F3F3;
}
.zs-action-field.zs-action-field-solid [input]:active {
  background: #F4F3F3;
}
.zs-action-field.zs-action-field-solid [input]:focus:hover {
  border: none;
  box-shadow: inset 0 -2px 0 0 #27A6A4;
}
.zs-action-field.zs-action-field-solid input[type="text"]:not(:focus):not(:hover):not([value]),
.zs-action-field.zs-action-field-solid input[type="text"]:not(:focus):not(:hover)[value=""] {
  box-shadow: none;
}
.zs-action-field.zs-action-field-solid input[type="text"]:not(:focus):not(:hover):not([value]):not(:disabled):not(.zs-disabled),
.zs-action-field.zs-action-field-solid input[type="text"]:not(:focus):not(:hover)[value=""]:not(:disabled):not(.zs-disabled) {
  background: #FAFAFA;
}
.zs-action-field.zs-action-field-solid.zs-error [input],
.zs-action-field.zs-action-field-solid.zs-danger [input],
.zs-action-field.zs-action-field-solid[invalid] [input] {
  border: 0px;
  box-shadow: none;
  box-shadow: inset 0 -2px 0 0 #B21111;
}
.zs-action-field.zs-action-field-solid.zs-error [input]:focus,
.zs-action-field.zs-action-field-solid.zs-danger [input]:focus,
.zs-action-field.zs-action-field-solid[invalid] [input]:focus {
  box-shadow: inset 0 -2px 0 0 #892208;
}
.zs-action-field.zs-action-field-solid.zs-error [input]:hover,
.zs-action-field.zs-action-field-solid.zs-danger [input]:hover,
.zs-action-field.zs-action-field-solid[invalid] [input]:hover {
  box-shadow: inset 0 -2px 0 0 #F27755;
}
.zs-action-field.zs-action-field-solid.zs-error [input]:active,
.zs-action-field.zs-action-field-solid.zs-danger [input]:active,
.zs-action-field.zs-action-field-solid[invalid] [input]:active {
  box-shadow: inset 0 -2px 0 0 #5C1A0B;
}
.zs-action-field.zs-action-field-solid.zs-success [input] {
  border: 0px;
  box-shadow: none;
  box-shadow: inset 0 -2px 0 0 #00AA67;
}
.zs-action-field.zs-action-field-solid.zs-success [input]:focus {
  box-shadow: inset 0 -2px 0 0 #0A6E5E;
}
.zs-action-field.zs-action-field-solid.zs-success [input]:hover {
  box-shadow: inset 0 -2px 0 0 #54D7AF;
}
.zs-action-field.zs-action-field-solid.zs-success [input]:active {
  box-shadow: inset 0 -2px 0 0 #0C4846;
}
.zs-action-field.zs-action-field-solid.zs-warning [input] {
  border: 0px;
  box-shadow: none;
  box-shadow: inset 0 -2px 0 0 #E3A900;
}
.zs-action-field.zs-action-field-solid.zs-warning [input]:focus {
  box-shadow: inset 0 -2px 0 0 #8A640C;
}
.zs-action-field.zs-action-field-solid.zs-warning [input]:hover {
  box-shadow: inset 0 -2px 0 0 #FDCA63;
}
.zs-action-field.zs-action-field-solid.zs-warning [input]:active {
  box-shadow: inset 0 -2px 0 0 #563F0E;
}
.zs-action-field.zs-action-field-solid.zs-info [input] {
  border: 0px;
  box-shadow: none;
  box-shadow: inset 0 -2px 0 0 #2B44C7;
}
.zs-action-field.zs-action-field-solid.zs-info [input]:focus {
  box-shadow: inset 0 -2px 0 0 #141187;
}
.zs-action-field.zs-action-field-solid.zs-info [input]:hover {
  box-shadow: inset 0 -2px 0 0 #7E92E9;
}
.zs-action-field.zs-action-field-solid.zs-info [input]:active {
  box-shadow: inset 0 -2px 0 0 #160C60;
}
.zs-action-field[icon] [input] {
  padding: 1em 1em 1em 3.5em;
}
.zs-action-field[action-icon] [input],
.zs-action-field[hint-icon] [input],
.zs-action-field[clear] [input] {
  padding-right: 3.5em;
}
.zs-action-field[action-icon][hint-icon] [input],
.zs-action-field[clear][action-icon] [input] {
  padding-right: 5.5em;
}
.zs-display-none {
  display: none;
}
[hidden] {
  display: none !important;
}
.zs-field [field].zs-input-icon[class*='zs-icon-'] {
  font-size: 1.5em;
}
.zs-field [field].zs-input-icon[class*='zs-icon-'] [input],
.zs-field [field].zs-input-icon[class*='zs-icon-'] .zs-input {
  font-size: 0.66666667em;
}
.zs-field [field].zs-input-icon[class*='zs-icon-']::before {
  font-size: inherit;
}
.zs-field [field].zs-input-icon[class*='zs-icon-'] [clear] {
  font-size: inherit;
}
.zs-field.zs-select::after {
  font-size: 24px;
}
.zs-select {
  position: relative;
  padding: 0;
  display: inline-block;
  min-width: 15.625em;
}
.zs-select select,
.zs-select select:focus {
  font-family: "Open Sans", sans-serif;
  font-size: 1em;
  -moz-appearance: none;
  appearance: none;
  -webkit-appearance: none;
  background-color: transparent;
  border: 0;
  border-radius: 0;
  outline: 0;
  font-weight: normal;
  border: 1px solid #DEDCDE;
  color: #2F2C3C;
  cursor: pointer;
  width: 100%;
  padding: 0.5em 2.25em 0.5em 0.75em;
}
.zs-select select::-ms-expand {
  display: none;
}
.zs-select:after {
  content: "\ea23";
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Enable Ligatures ================ */
  -webkit-font-feature-settings: "liga";
  -moz-font-feature-settings: "liga=1";
  -moz-font-feature-settings: "liga";
  -ms-font-feature-settings: "liga" 1;
  -o-font-feature-settings: "liga";
  font-feature-settings: "liga";
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  font-family: 'zsIcons';
  font-size: 1.5em;
  color: #716E79;
  right: 0.5em;
  top: 50%;
  margin-top: -0.5em;
  position: absolute;
  pointer-events: none;
  display: inline-block;
}
.zs-select:focus.zs-select:after {
  color: #454250;
}
.zs-select:hover.zs-select:after {
  color: #9C9AA1;
}
.zs-select:active.zs-select:after {
  color: #2F2C3C;
}
.zs-select[disabled] select,
.zs-select.zs-disabled select,
.zs-select.zs-calendar > table td[disabled] select,
.zs-select.zs-range-calendar > table td[disabled] select,
.zs-select.zs-range-calendar > section > table td[disabled] select {
  color: #B2B0B6;
  background: none;
  background-color: #F4F3F3;
  border: 1px solid #B2B0B6;
}
.zs-select[disabled]:after,
.zs-select.zs-disabled:after,
.zs-select.zs-calendar > table td[disabled]:after,
.zs-select.zs-range-calendar > table td[disabled]:after,
.zs-select.zs-range-calendar > section > table td[disabled]:after {
  color: #B2B0B6;
}
.zs-select.zs-danger select,
.zs-select[invalid] select {
  border: 0px;
  box-shadow: none;
  box-shadow: inset 0 0 0 1px #B21111;
}
.zs-select.zs-danger select:focus,
.zs-select[invalid] select:focus {
  box-shadow: inset 0 0 0 1px #892208;
}
.zs-select.zs-danger select:hover,
.zs-select[invalid] select:hover {
  box-shadow: inset 0 0 0 1px #F27755;
}
.zs-select.zs-danger select:active,
.zs-select[invalid] select:active {
  box-shadow: inset 0 0 0 1px #5C1A0B;
}
.zs-select.zs-danger:after,
.zs-select[invalid]:after {
  color: #B21111;
}
.zs-select.zs-danger:focus:after,
.zs-select[invalid]:focus:after {
  color: #892208;
}
.zs-select.zs-danger:hover:after,
.zs-select[invalid]:hover:after {
  color: #F27755;
}
.zs-select.zs-danger:active:after,
.zs-select[invalid]:active:after {
  color: #5C1A0B;
}
.zs-select.zs-success select {
  border: 0px;
  box-shadow: none;
  box-shadow: inset 0 0 0 1px #00AA67;
}
.zs-select.zs-success select:focus {
  box-shadow: inset 0 0 0 1px #0A6E5E;
}
.zs-select.zs-success select:hover {
  box-shadow: inset 0 0 0 1px #54D7AF;
}
.zs-select.zs-success select:active {
  box-shadow: inset 0 0 0 1px #0C4846;
}
.zs-select.zs-success:after {
  color: #00AA67;
}
.zs-select.zs-success:focus:after {
  color: #0A6E5E;
}
.zs-select.zs-success:hover:after {
  color: #54D7AF;
}
.zs-select.zs-success:active:after {
  color: #0C4846;
}
.zs-select.zs-warning select {
  border: 0px;
  box-shadow: none;
  box-shadow: inset 0 0 0 1px #E3A900;
}
.zs-select.zs-warning select:focus {
  box-shadow: inset 0 0 0 1px #8A640C;
}
.zs-select.zs-warning select:hover {
  box-shadow: inset 0 0 0 1px #FDCA63;
}
.zs-select.zs-warning select:active {
  box-shadow: inset 0 0 0 1px #563F0E;
}
.zs-select.zs-warning:after {
  color: #E3A900;
}
.zs-select.zs-warning:focus:after {
  color: #8A640C;
}
.zs-select.zs-warning:hover:after {
  color: #FDCA63;
}
.zs-select.zs-warning:active:after {
  color: #563F0E;
}
.zs-select.zs-info select {
  border: 0px;
  box-shadow: none;
  box-shadow: inset 0 0 0 1px #2B44C7;
}
.zs-select.zs-info select:focus {
  box-shadow: inset 0 0 0 1px #141187;
}
.zs-select.zs-info select:hover {
  box-shadow: inset 0 0 0 1px #7E92E9;
}
.zs-select.zs-info select:active {
  box-shadow: inset 0 0 0 1px #160C60;
}
.zs-select.zs-info:after {
  color: #2B44C7;
}
.zs-select.zs-info:focus:after {
  color: #141187;
}
.zs-select.zs-info:hover:after {
  color: #7E92E9;
}
.zs-select.zs-info:active:after {
  color: #160C60;
}
.zs-select > [overlay] {
  cursor: pointer;
  background-color: transparent;
  border-radius: 0;
  outline: 0;
  font-weight: 600;
  border: 1px solid #DEDCDE;
  color: #2F2C3C;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0.5em 2.25em 0.5em 0.75em;
  max-width: none;
}
.zs-select > [overlay].placeholder-active {
  color: #716E79;
}
.zs-select.zs-field[label]:after,
.zs-select.zs-action-field[label]:after {
  top: auto;
  bottom: 0.33333333em;
}
.zs-select.zs-action-field > select {
  display: none;
}
.zs-select.zs-action-field.zs-danger > [overlay],
.zs-select.zs-action-field[invalid] > [overlay] {
  border: 0px;
  box-shadow: none;
  box-shadow: inset 0 0 0 1px #B21111;
}
.zs-select.zs-action-field.zs-danger > [overlay]:focus,
.zs-select.zs-action-field[invalid] > [overlay]:focus {
  box-shadow: inset 0 0 0 1px #892208;
}
.zs-select.zs-action-field.zs-danger > [overlay]:hover,
.zs-select.zs-action-field[invalid] > [overlay]:hover {
  box-shadow: inset 0 0 0 1px #F27755;
}
.zs-select.zs-action-field.zs-danger > [overlay]:active,
.zs-select.zs-action-field[invalid] > [overlay]:active {
  box-shadow: inset 0 0 0 1px #5C1A0B;
}
.zs-select.zs-action-field.zs-success > [overlay] {
  border: 0px;
  box-shadow: none;
  box-shadow: inset 0 0 0 1px #00AA67;
}
.zs-select.zs-action-field.zs-success > [overlay]:focus {
  box-shadow: inset 0 0 0 1px #0A6E5E;
}
.zs-select.zs-action-field.zs-success > [overlay]:hover {
  box-shadow: inset 0 0 0 1px #54D7AF;
}
.zs-select.zs-action-field.zs-success > [overlay]:active {
  box-shadow: inset 0 0 0 1px #0C4846;
}
.zs-select.zs-action-field.zs-warning > [overlay] {
  border: 0px;
  box-shadow: none;
  box-shadow: inset 0 0 0 1px #E3A900;
}
.zs-select.zs-action-field.zs-warning > [overlay]:focus {
  box-shadow: inset 0 0 0 1px #8A640C;
}
.zs-select.zs-action-field.zs-warning > [overlay]:hover {
  box-shadow: inset 0 0 0 1px #FDCA63;
}
.zs-select.zs-action-field.zs-warning > [overlay]:active {
  box-shadow: inset 0 0 0 1px #563F0E;
}
.zs-select.zs-action-field.zs-info > [overlay] {
  border: 0px;
  box-shadow: none;
  box-shadow: inset 0 0 0 1px #2B44C7;
}
.zs-select.zs-action-field.zs-info > [overlay]:focus {
  box-shadow: inset 0 0 0 1px #141187;
}
.zs-select.zs-action-field.zs-info > [overlay]:hover {
  box-shadow: inset 0 0 0 1px #7E92E9;
}
.zs-select.zs-action-field.zs-info > [overlay]:active {
  box-shadow: inset 0 0 0 1px #160C60;
}
.zs-select[disabled] > [overlay],
.zs-select.zs-disabled > [overlay],
.zs-select.zs-calendar > table td[disabled] > [overlay],
.zs-select.zs-range-calendar > table td[disabled] > [overlay],
.zs-select.zs-range-calendar > section > table td[disabled] > [overlay] {
  color: #B2B0B6;
  background: none;
  background-color: #F4F3F3;
  border: 1px solid #B2B0B6;
}
.zs-select.zs-field {
  display: inline-block;
}
.zs-select.zs-field > select {
  display: none;
}
.zs-select.zs-field[label]:after {
  top: 66%;
}
.zs-select.zs-field[invalid] [overlay] {
  background: #B21111;
  border: 1px solid #B21111;
  outline: none;
}
.zs-select.zs-field[invalid] [overlay]:focus {
  background: #892208;
}
.zs-select.zs-field[invalid] [overlay]:hover {
  background: #F27755;
}
.zs-select.zs-field[invalid] [overlay]:active {
  background: #5C1A0B;
}
.zs-select.zs-field[invalid] [overlay]:focus {
  border: 1px solid #892208;
}
.zs-select.zs-field[invalid] [overlay]:hover {
  border: 1px solid #F27755;
}
.zs-select.zs-field[invalid] [overlay]:active {
  border: 1px solid #5C1A0B;
}
.zs-select.zs-field[clear][value]:not([value=""]) p.zs-icon-search::before {
  display: none;
}
.zs-search-dropdown {
  margin: -1px;
  display: block;
  background: #FAFAFA;
  border-radius: 0em;
  box-shadow: 4px 4px 0px rgba(26, 22, 40, 0.15);
  box-sizing: border-box;
  min-width: auto;
  max-width: none;
  z-index: 2000;
  border: 1px solid #DEDCDE;
}
.zs-search-dropdown > a,
.zs-search-dropdown > li,
.zs-search-dropdown > li > a,
.zs-search-dropdown > ul > li > a,
.zs-search-dropdown > nav > a {
  color: #2F2C3C;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0.5em 1.5em 0.5em 1.5em;
  line-height: 1.5;
}
.zs-search-dropdown > a.zs-active,
.zs-search-dropdown > li.zs-active,
.zs-search-dropdown > li > a.zs-active,
.zs-search-dropdown > ul > li > a.zs-active,
.zs-search-dropdown > nav > a.zs-active {
  background: #27A6A4;
}
.zs-search-dropdown > a:hover,
.zs-search-dropdown > li:hover,
.zs-search-dropdown > li > a:hover,
.zs-search-dropdown > ul > li > a:hover,
.zs-search-dropdown > nav > a:hover {
  background: rgba(39, 166, 164, 0.1);
  color: inherit;
}
.zs-search-dropdown > a:first-child,
.zs-search-dropdown > li:first-child,
.zs-search-dropdown > li > a:first-child,
.zs-search-dropdown > ul > li > a:first-child,
.zs-search-dropdown > nav > a:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.zs-search-dropdown > a:last-child,
.zs-search-dropdown > li:last-child,
.zs-search-dropdown > li > a:last-child,
.zs-search-dropdown > ul > li > a:last-child,
.zs-search-dropdown > nav > a:last-child {
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
}
.zs-search-dropdown > a:active,
.zs-search-dropdown > li:active,
.zs-search-dropdown > li > a:active,
.zs-search-dropdown > ul > li > a:active,
.zs-search-dropdown > nav > a:active {
  background: #2D8B93;
  color: inherit;
}
.zs-search-dropdown > a > *:not(.zs-icon),
.zs-search-dropdown > li > *:not(.zs-icon),
.zs-search-dropdown > li > a > *:not(.zs-icon),
.zs-search-dropdown > ul > li > a > *:not(.zs-icon),
.zs-search-dropdown > nav > a > *:not(.zs-icon) {
  vertical-align: middle;
}
.zs-search-dropdown > li > a {
  padding: 0;
  color: inherit;
}
.zs-search-dropdown > li > a:focus,
.zs-search-dropdown > li > a:hover,
.zs-search-dropdown > li > a:active,
.zs-search-dropdown > li > a.zs-active {
  color: inherit;
  background: transparent;
}
.zs-search-dropdown > li > a:focus {
  outline: 2px solid #27A6A4;
  outline-offset: -2px;
  z-index: 1;
}
.zs-search-dropdown > nav {
  max-height: 12em;
  overflow: auto;
}
.zs-search-dropdown > nav > a[optgroup-link-parent] {
  padding-left: 2.5em;
}
.zs-search-dropdown > nav > a[hover] {
  color: #27A6A4;
  background: rgba(39, 166, 164, 0.1);
}
.zs-search-dropdown > nav > a[active] {
  color: #ffffff;
  background: #27A6A4;
}
.zs-search-dropdown > nav > a[hover]:visited {
  color: #27A6A4;
  background: rgba(39, 166, 164, 0.1);
}
.zs-search-dropdown > nav > a[active]:visited {
  color: #ffffff;
  background: #27A6A4;
}
.zs-search-dropdown > nav > a[active][checkbox-link]:not([hover]) {
  color: #2F2C3C;
  background: transparent;
}
.zs-search-dropdown > nav > a[active][checkbox-link][hover],
.zs-search-dropdown > nav > a[active][checkbox-link]:hover {
  color: #27A6A4;
  background: rgba(39, 166, 164, 0.1);
}
.zs-search-dropdown > nav > a:not([index]):not(.select-all) {
  color: #716E79;
  background: transparent;
}
.zs-search-dropdown > nav > a:not([index]):not(.select-all):focus,
.zs-search-dropdown > nav > a:not([index]):not(.select-all):active,
.zs-search-dropdown > nav > a:not([index]):not(.select-all):hover {
  color: #716E79;
  background: transparent;
}
.zs-search-dropdown.zs-action-field [hidden] {
  display: none !important;
}
.zs-search-dropdown.zs-action-field [input] {
  width: 100%;
  height: 2.5em;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  padding-left: 2.5em;
}
.zs-search-dropdown.zs-action-field[action-icon] [clear] {
  margin-right: 0.33333333em;
}
.zs-search-dropdown.zs-action-field [signifier] {
  font-size: 1em;
  margin: 0em 0.75em 0em 0.75em;
}
.zs-search-dropdown.zs-action-field [action] {
  margin: 0em 0.5em 0em 0em;
}
.zs-search-dropdown > p {
  margin: 0.5em 0.5em 0.5em 0.5em !important;
  margin-top: 0.5em !important;
  display: block;
  position: relative;
}
.zs-search-dropdown > p.zs-icon-search::before {
  margin-top: 0;
  right: 0;
}
.zs-search-dropdown > p > input.zs-input {
  width: 100%;
  min-width: 100%;
}
.zs-search-dropdown > p .zs-input-icon {
  display: block;
  position: relative;
  z-index: 1;
}
.zs-search-dropdown > p .zs-input-icon textarea[input] {
  padding-right: 3em !important;
}
.zs-search-dropdown > p .zs-input-icon [input] {
  padding-right: 3.5em !important;
}
.zs-search-dropdown > p .zs-input-icon::before {
  font-family: 'zsIcons';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Enable Ligatures ================ */
  -webkit-font-feature-settings: "liga";
  -moz-font-feature-settings: "liga=1";
  -moz-font-feature-settings: "liga";
  -ms-font-feature-settings: "liga" 1;
  -o-font-feature-settings: "liga";
  font-feature-settings: "liga";
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  display: inline-block;
  pointer-events: none;
  top: 50%;
  font-size: 1.5em;
  width: auto;
  right: 0.66666667em;
  padding: 0;
  transform: translate(0, -50%);
  z-index: 1;
  text-align: center;
  background: transparent;
  position: absolute;
}
.zs-search-dropdown [highlight] {
  background-color: #FFD680;
}
.zs-search-dropdown .select-all {
  border-bottom: 1px solid #DEDCDE;
}
.zs-search-dropdown input[type="checkbox"] {
  margin: 0em 0.5em 0em 0em;
  vertical-align: middle;
}
.zs-search-dropdown .no-search-results {
  text-align: center;
  padding: 0.5em 0.5em 0.5em 0.5em;
}
.zs-open-dropdown.zs-select:after {
  content: '';
}
.zs-open-dropdown .zs-menu {
  box-shadow: none;
}
.zs-open-dropdown p .zs-input {
  min-width: 100%;
}
.zs-open-dropdown a:focus {
  outline: 0;
}
.zs-input {
  /**
	  * @todo a little hack to fix ZSUI-358
	  */
  margin-bottom: 0.5px;
}
.zs-date-picker {
  position: relative;
}
.zs-date-picker > label {
  vertical-align: bottom;
}
.zs-date-picker [input] {
  width: 100%;
  box-shadow: 2px 2px 0px rgba(26, 22, 40, 0.12);
}
.zs-date-picker [input][readonly] {
  cursor: default;
}
.zs-calendar {
  display: block;
  background: #ffffff;
  border-radius: 0;
  border: 1px solid #DEDCDE;
  box-shadow: 2px 2px 0px rgba(26, 22, 40, 0.12);
  position: absolute;
  z-index: 2000;
  font-size: 1em;
  min-width: 100%;
}
.zs-calendar > header {
  position: relative;
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: left;
  padding: 0.5em 0.25em 0.5em 0.25em;
  background: #022D42;
}
.zs-calendar > header [role="prev"] ~ span {
  margin: 0em 0.25em 0em 1.5em;
}
.zs-calendar > header [role="prev"] ~ span select:focus {
  width: inherit;
}
.zs-calendar > header [role="prev"] ~ span ~ span {
  margin: 0em 0.25em 0em 0.5em;
}
.zs-calendar > header span {
  position: relative;
  padding: 0;
  display: inline-block;
  min-width: 15.625em;
  width: inherit;
  min-width: inherit;
}
.zs-calendar > header span select,
.zs-calendar > header span select:focus {
  font-family: "Open Sans", sans-serif;
  font-size: 1em;
  -moz-appearance: none;
  appearance: none;
  -webkit-appearance: none;
  background-color: transparent;
  border: 0;
  border-radius: 0;
  outline: 0;
  font-weight: normal;
  border: 1px solid #DEDCDE;
  color: #2F2C3C;
  cursor: pointer;
  width: 100%;
  padding: 0.5em 2.25em 0.5em 0.75em;
}
.zs-calendar > header span select::-ms-expand {
  display: none;
}
.zs-calendar > header span:after {
  content: "\ea23";
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Enable Ligatures ================ */
  -webkit-font-feature-settings: "liga";
  -moz-font-feature-settings: "liga=1";
  -moz-font-feature-settings: "liga";
  -ms-font-feature-settings: "liga" 1;
  -o-font-feature-settings: "liga";
  font-feature-settings: "liga";
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  font-family: 'zsIcons';
  font-size: 1.5em;
  color: #716E79;
  right: 0.5em;
  top: 50%;
  margin-top: -0.5em;
  position: absolute;
  pointer-events: none;
  display: inline-block;
}
.zs-calendar > header span:focus.zs-calendar > header span:after {
  color: #454250;
}
.zs-calendar > header span:hover.zs-calendar > header span:after {
  color: #9C9AA1;
}
.zs-calendar > header span:active.zs-calendar > header span:after {
  color: #2F2C3C;
}
.zs-calendar > header span[disabled] select,
.zs-calendar > header span.zs-disabled select,
.zs-calendar > header span.zs-calendar > table td[disabled] select,
.zs-calendar > header span.zs-range-calendar > table td[disabled] select,
.zs-calendar > header span.zs-range-calendar > section > table td[disabled] select {
  color: #B2B0B6;
  background: none;
  background-color: #F4F3F3;
  border: 1px solid #B2B0B6;
}
.zs-calendar > header span[disabled]:after,
.zs-calendar > header span.zs-disabled:after,
.zs-calendar > header span.zs-calendar > table td[disabled]:after,
.zs-calendar > header span.zs-range-calendar > table td[disabled]:after,
.zs-calendar > header span.zs-range-calendar > section > table td[disabled]:after {
  color: #B2B0B6;
}
.zs-calendar > header span.zs-danger select,
.zs-calendar > header span[invalid] select {
  border: 0px;
  box-shadow: none;
  box-shadow: inset 0 0 0 1px #B21111;
}
.zs-calendar > header span.zs-danger select:focus,
.zs-calendar > header span[invalid] select:focus {
  box-shadow: inset 0 0 0 1px #892208;
}
.zs-calendar > header span.zs-danger select:hover,
.zs-calendar > header span[invalid] select:hover {
  box-shadow: inset 0 0 0 1px #F27755;
}
.zs-calendar > header span.zs-danger select:active,
.zs-calendar > header span[invalid] select:active {
  box-shadow: inset 0 0 0 1px #5C1A0B;
}
.zs-calendar > header span.zs-danger:after,
.zs-calendar > header span[invalid]:after {
  color: #B21111;
}
.zs-calendar > header span.zs-danger:focus:after,
.zs-calendar > header span[invalid]:focus:after {
  color: #892208;
}
.zs-calendar > header span.zs-danger:hover:after,
.zs-calendar > header span[invalid]:hover:after {
  color: #F27755;
}
.zs-calendar > header span.zs-danger:active:after,
.zs-calendar > header span[invalid]:active:after {
  color: #5C1A0B;
}
.zs-calendar > header span.zs-success select {
  border: 0px;
  box-shadow: none;
  box-shadow: inset 0 0 0 1px #00AA67;
}
.zs-calendar > header span.zs-success select:focus {
  box-shadow: inset 0 0 0 1px #0A6E5E;
}
.zs-calendar > header span.zs-success select:hover {
  box-shadow: inset 0 0 0 1px #54D7AF;
}
.zs-calendar > header span.zs-success select:active {
  box-shadow: inset 0 0 0 1px #0C4846;
}
.zs-calendar > header span.zs-success:after {
  color: #00AA67;
}
.zs-calendar > header span.zs-success:focus:after {
  color: #0A6E5E;
}
.zs-calendar > header span.zs-success:hover:after {
  color: #54D7AF;
}
.zs-calendar > header span.zs-success:active:after {
  color: #0C4846;
}
.zs-calendar > header span.zs-warning select {
  border: 0px;
  box-shadow: none;
  box-shadow: inset 0 0 0 1px #E3A900;
}
.zs-calendar > header span.zs-warning select:focus {
  box-shadow: inset 0 0 0 1px #8A640C;
}
.zs-calendar > header span.zs-warning select:hover {
  box-shadow: inset 0 0 0 1px #FDCA63;
}
.zs-calendar > header span.zs-warning select:active {
  box-shadow: inset 0 0 0 1px #563F0E;
}
.zs-calendar > header span.zs-warning:after {
  color: #E3A900;
}
.zs-calendar > header span.zs-warning:focus:after {
  color: #8A640C;
}
.zs-calendar > header span.zs-warning:hover:after {
  color: #FDCA63;
}
.zs-calendar > header span.zs-warning:active:after {
  color: #563F0E;
}
.zs-calendar > header span.zs-info select {
  border: 0px;
  box-shadow: none;
  box-shadow: inset 0 0 0 1px #2B44C7;
}
.zs-calendar > header span.zs-info select:focus {
  box-shadow: inset 0 0 0 1px #141187;
}
.zs-calendar > header span.zs-info select:hover {
  box-shadow: inset 0 0 0 1px #7E92E9;
}
.zs-calendar > header span.zs-info select:active {
  box-shadow: inset 0 0 0 1px #160C60;
}
.zs-calendar > header span.zs-info:after {
  color: #2B44C7;
}
.zs-calendar > header span.zs-info:focus:after {
  color: #141187;
}
.zs-calendar > header span.zs-info:hover:after {
  color: #7E92E9;
}
.zs-calendar > header span.zs-info:active:after {
  color: #160C60;
}
.zs-calendar > header span select,
.zs-calendar > header span select:focus {
  font-family: "Open Sans", sans-serif;
  font-size: 1em;
  -moz-appearance: none;
  appearance: none;
  -webkit-appearance: none;
  background-color: transparent;
  border: 0;
  border-radius: 0;
  outline: 0;
  font-weight: normal;
  border: 1px solid #DEDCDE;
  color: #2F2C3C;
  cursor: pointer;
  width: 100%;
  padding: 0.5em 2.25em 0.5em 0.75em;
}
.zs-calendar > header span select::-ms-expand {
  display: none;
}
.zs-calendar > header span:after {
  content: "\ea23";
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Enable Ligatures ================ */
  -webkit-font-feature-settings: "liga";
  -moz-font-feature-settings: "liga=1";
  -moz-font-feature-settings: "liga";
  -ms-font-feature-settings: "liga" 1;
  -o-font-feature-settings: "liga";
  font-feature-settings: "liga";
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  font-family: 'zsIcons';
  font-size: 1.5em;
  color: #716E79;
  right: 0.5em;
  top: 50%;
  margin-top: -0.5em;
  position: absolute;
  pointer-events: none;
  display: inline-block;
}
.zs-calendar > header span:focus.zs-calendar > header span:after {
  color: #454250;
}
.zs-calendar > header span:hover.zs-calendar > header span:after {
  color: #9C9AA1;
}
.zs-calendar > header span:active.zs-calendar > header span:after {
  color: #2F2C3C;
}
.zs-calendar > header span[disabled] select,
.zs-calendar > header span.zs-disabled select,
.zs-calendar > header span.zs-calendar > table td[disabled] select,
.zs-calendar > header span.zs-range-calendar > table td[disabled] select,
.zs-calendar > header span.zs-range-calendar > section > table td[disabled] select {
  color: #B2B0B6;
  background: none;
  background-color: #F4F3F3;
  border: 1px solid #B2B0B6;
}
.zs-calendar > header span[disabled]:after,
.zs-calendar > header span.zs-disabled:after,
.zs-calendar > header span.zs-calendar > table td[disabled]:after,
.zs-calendar > header span.zs-range-calendar > table td[disabled]:after,
.zs-calendar > header span.zs-range-calendar > section > table td[disabled]:after {
  color: #B2B0B6;
}
.zs-calendar > header span.zs-danger select,
.zs-calendar > header span[invalid] select {
  border: 0px;
  box-shadow: none;
  box-shadow: inset 0 0 0 1px #B21111;
}
.zs-calendar > header span.zs-danger select:focus,
.zs-calendar > header span[invalid] select:focus {
  box-shadow: inset 0 0 0 1px #892208;
}
.zs-calendar > header span.zs-danger select:hover,
.zs-calendar > header span[invalid] select:hover {
  box-shadow: inset 0 0 0 1px #F27755;
}
.zs-calendar > header span.zs-danger select:active,
.zs-calendar > header span[invalid] select:active {
  box-shadow: inset 0 0 0 1px #5C1A0B;
}
.zs-calendar > header span.zs-danger:after,
.zs-calendar > header span[invalid]:after {
  color: #B21111;
}
.zs-calendar > header span.zs-danger:focus:after,
.zs-calendar > header span[invalid]:focus:after {
  color: #892208;
}
.zs-calendar > header span.zs-danger:hover:after,
.zs-calendar > header span[invalid]:hover:after {
  color: #F27755;
}
.zs-calendar > header span.zs-danger:active:after,
.zs-calendar > header span[invalid]:active:after {
  color: #5C1A0B;
}
.zs-calendar > header span.zs-success select {
  border: 0px;
  box-shadow: none;
  box-shadow: inset 0 0 0 1px #00AA67;
}
.zs-calendar > header span.zs-success select:focus {
  box-shadow: inset 0 0 0 1px #0A6E5E;
}
.zs-calendar > header span.zs-success select:hover {
  box-shadow: inset 0 0 0 1px #54D7AF;
}
.zs-calendar > header span.zs-success select:active {
  box-shadow: inset 0 0 0 1px #0C4846;
}
.zs-calendar > header span.zs-success:after {
  color: #00AA67;
}
.zs-calendar > header span.zs-success:focus:after {
  color: #0A6E5E;
}
.zs-calendar > header span.zs-success:hover:after {
  color: #54D7AF;
}
.zs-calendar > header span.zs-success:active:after {
  color: #0C4846;
}
.zs-calendar > header span.zs-warning select {
  border: 0px;
  box-shadow: none;
  box-shadow: inset 0 0 0 1px #E3A900;
}
.zs-calendar > header span.zs-warning select:focus {
  box-shadow: inset 0 0 0 1px #8A640C;
}
.zs-calendar > header span.zs-warning select:hover {
  box-shadow: inset 0 0 0 1px #FDCA63;
}
.zs-calendar > header span.zs-warning select:active {
  box-shadow: inset 0 0 0 1px #563F0E;
}
.zs-calendar > header span.zs-warning:after {
  color: #E3A900;
}
.zs-calendar > header span.zs-warning:focus:after {
  color: #8A640C;
}
.zs-calendar > header span.zs-warning:hover:after {
  color: #FDCA63;
}
.zs-calendar > header span.zs-warning:active:after {
  color: #563F0E;
}
.zs-calendar > header span.zs-info select {
  border: 0px;
  box-shadow: none;
  box-shadow: inset 0 0 0 1px #2B44C7;
}
.zs-calendar > header span.zs-info select:focus {
  box-shadow: inset 0 0 0 1px #141187;
}
.zs-calendar > header span.zs-info select:hover {
  box-shadow: inset 0 0 0 1px #7E92E9;
}
.zs-calendar > header span.zs-info select:active {
  box-shadow: inset 0 0 0 1px #160C60;
}
.zs-calendar > header span.zs-info:after {
  color: #2B44C7;
}
.zs-calendar > header span.zs-info:focus:after {
  color: #141187;
}
.zs-calendar > header span.zs-info:hover:after {
  color: #7E92E9;
}
.zs-calendar > header span.zs-info:active:after {
  color: #160C60;
}
.zs-calendar > header span > [overlay] {
  cursor: pointer;
  background-color: transparent;
  border-radius: 0;
  outline: 0;
  font-weight: 600;
  border: 1px solid #DEDCDE;
  color: #2F2C3C;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0.5em 2.25em 0.5em 0.75em;
  max-width: none;
}
.zs-calendar > header span > [overlay].placeholder-active {
  color: #716E79;
}
.zs-calendar > header span.zs-field[label]:after,
.zs-calendar > header span.zs-action-field[label]:after {
  top: auto;
  bottom: 0.33333333em;
}
.zs-calendar > header span.zs-action-field > select {
  display: none;
}
.zs-calendar > header span.zs-action-field.zs-danger > [overlay],
.zs-calendar > header span.zs-action-field[invalid] > [overlay] {
  border: 0px;
  box-shadow: none;
  box-shadow: inset 0 0 0 1px #B21111;
}
.zs-calendar > header span.zs-action-field.zs-danger > [overlay]:focus,
.zs-calendar > header span.zs-action-field[invalid] > [overlay]:focus {
  box-shadow: inset 0 0 0 1px #892208;
}
.zs-calendar > header span.zs-action-field.zs-danger > [overlay]:hover,
.zs-calendar > header span.zs-action-field[invalid] > [overlay]:hover {
  box-shadow: inset 0 0 0 1px #F27755;
}
.zs-calendar > header span.zs-action-field.zs-danger > [overlay]:active,
.zs-calendar > header span.zs-action-field[invalid] > [overlay]:active {
  box-shadow: inset 0 0 0 1px #5C1A0B;
}
.zs-calendar > header span.zs-action-field.zs-success > [overlay] {
  border: 0px;
  box-shadow: none;
  box-shadow: inset 0 0 0 1px #00AA67;
}
.zs-calendar > header span.zs-action-field.zs-success > [overlay]:focus {
  box-shadow: inset 0 0 0 1px #0A6E5E;
}
.zs-calendar > header span.zs-action-field.zs-success > [overlay]:hover {
  box-shadow: inset 0 0 0 1px #54D7AF;
}
.zs-calendar > header span.zs-action-field.zs-success > [overlay]:active {
  box-shadow: inset 0 0 0 1px #0C4846;
}
.zs-calendar > header span.zs-action-field.zs-warning > [overlay] {
  border: 0px;
  box-shadow: none;
  box-shadow: inset 0 0 0 1px #E3A900;
}
.zs-calendar > header span.zs-action-field.zs-warning > [overlay]:focus {
  box-shadow: inset 0 0 0 1px #8A640C;
}
.zs-calendar > header span.zs-action-field.zs-warning > [overlay]:hover {
  box-shadow: inset 0 0 0 1px #FDCA63;
}
.zs-calendar > header span.zs-action-field.zs-warning > [overlay]:active {
  box-shadow: inset 0 0 0 1px #563F0E;
}
.zs-calendar > header span.zs-action-field.zs-info > [overlay] {
  border: 0px;
  box-shadow: none;
  box-shadow: inset 0 0 0 1px #2B44C7;
}
.zs-calendar > header span.zs-action-field.zs-info > [overlay]:focus {
  box-shadow: inset 0 0 0 1px #141187;
}
.zs-calendar > header span.zs-action-field.zs-info > [overlay]:hover {
  box-shadow: inset 0 0 0 1px #7E92E9;
}
.zs-calendar > header span.zs-action-field.zs-info > [overlay]:active {
  box-shadow: inset 0 0 0 1px #160C60;
}
.zs-calendar > header span[disabled] > [overlay],
.zs-calendar > header span.zs-disabled > [overlay],
.zs-calendar > header span.zs-calendar > table td[disabled] > [overlay],
.zs-calendar > header span.zs-range-calendar > table td[disabled] > [overlay],
.zs-calendar > header span.zs-range-calendar > section > table td[disabled] > [overlay] {
  color: #B2B0B6;
  background: none;
  background-color: #F4F3F3;
  border: 1px solid #B2B0B6;
}
.zs-calendar > header span > select[name] {
  font-weight: 600;
  border-color: transparent;
  color: #ffffff;
}
.zs-calendar > header span > select[name] option {
  font-weight: normal;
  color: #2F2C3C;
  color: initial;
}
.zs-calendar > header span:after {
  color: #ffffff;
}
.zs-calendar > header span:focus:after {
  color: #454250;
}
.zs-calendar > header span:hover:after {
  color: #9C9AA1;
}
.zs-calendar > header span:active:after {
  color: #2F2C3C;
}
.zs-calendar > header span ~ span {
  margin: 0em 0em 0em -1em;
}
.zs-calendar > header a:hover:before {
  color: inherit;
}
.zs-calendar > header [name="year"],
.zs-calendar > header [name="month"] {
  width: inherit;
  min-width: inherit;
}
.zs-calendar > table {
  width: 100%;
  padding: 1em 1em 0em 1em;
}
.zs-calendar > table th {
  padding-bottom: 0.5em;
  font-weight: normal;
  font-size: 0.625em;
  color: #716E79;
}
.zs-calendar > table td {
  cursor: pointer;
  text-align: center;
  vertical-align: middle;
  width: 2.42857143em;
  height: 2.42857143em;
  border: 1px solid #ffffff;
  font-size: 0.875em;
  width: 2.28571429em;
  height: 2em;
  padding: 0.25em 0.5em 0.25em 0.5em;
}
.zs-calendar > table td[disabled] {
  background: none;
  border-radius: 0;
  color: #B2B0B6;
  border: 1px solid #ffffff;
}
.zs-calendar > table td[notinmonth] {
  opacity: 1;
  color: #B2B0B6;
  background: none;
  border-color: #ffffff;
}
.zs-calendar > table td[notinmonth][current] {
  border: 1px solid #DEDCDE;
  background: #DEDCDE;
  color: #716E79;
}
.zs-calendar > table td[notinmonth][today] {
  background: #FFF1D6;
  color: #716E79;
}
.zs-calendar > table td[today] {
  background: #FFD680;
  border: 1px solid #FFD680;
  border-radius: 0;
  font-weight: normal;
}
.zs-calendar > table td[current] {
  border: 1px solid #DEDCDE;
  background: #DEDCDE;
  color: #2F2C3C;
  border-radius: 0;
}
.zs-calendar > table td[current]:hover {
  color: #2F2C3C;
}
.zs-calendar > table td:hover {
  color: inherit;
  border: solid 1px #716E79;
  border-radius: 0;
}
.zs-calendar > table td:active {
  color: #FFFFFF;
  background: #022D42;
}
.zs-calendar > footer {
  display: flex;
  align-items: center;
  justify-content: left;
  padding: 1em 1em 1em 1em;
  justify-content: space-between;
}
.zs-calendar > footer .zs-fab[role='next'],
.zs-calendar > footer .zs-fab[role='prev'] {
  padding: 0.625em 0.625em 0.625em 0.625em;
}
.zs-calendar > footer .zs-fab[role='next'] .zs-icon,
.zs-calendar > footer .zs-fab[role='prev'] .zs-icon {
  font-size: 0.75em;
  border-radius: inherit;
}
.zs-calendar > footer [role='today'] {
  font-size: 0.875em;
}
.zs-month-range-picker {
  display: inline-block;
}
.zs-month-range-picker .zs-input {
  outline: none;
  font-weight: normal;
  color: #2F2C3C;
  font-size: 1em;
  border-radius: 0;
  padding: 1em 1em 1em 1em;
  /**
	  * @todo a little hack to fix ZSUI-358
	  */
  margin-bottom: 0.5px;
}
.zs-month-range-picker .zs-input[type="number"] {
  text-align: right;
}
.zs-month-range-picker .zs-input[type="checkbox"],
.zs-month-range-picker .zs-input[type="radio"],
.zs-month-range-picker .zs-input[type="button"] {
  border: none;
}
.zs-month-range-picker .zs-input:focus,
.zs-month-range-picker .zs-input :invalid:focus {
  outline: none;
  box-shadow: none;
}
.zs-month-range-picker .zs-input-icon {
  position: relative;
}
.zs-month-range-picker .zs-input-icon textarea[input] {
  padding-right: 3em !important;
}
.zs-month-range-picker .zs-input-icon [input] {
  padding-right: 3.5em !important;
}
.zs-month-range-picker .zs-input-icon::before {
  font-family: 'zsIcons';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Enable Ligatures ================ */
  -webkit-font-feature-settings: "liga";
  -moz-font-feature-settings: "liga=1";
  -moz-font-feature-settings: "liga";
  -ms-font-feature-settings: "liga" 1;
  -o-font-feature-settings: "liga";
  font-feature-settings: "liga";
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  display: inline-block;
  pointer-events: none;
  top: 50%;
  font-size: 1.5em;
  width: auto;
  right: 0.66666667em;
  padding: 0;
  transform: translate(0, -50%);
  z-index: 1;
  text-align: center;
  background: transparent;
  position: absolute;
}
.zs-month-range-picker .zs-input-icon .zs-icon-calendar:hover:before {
  color: #2F2C3C;
}
.zs-month-range-picker .zs-input-icon .zs-icon-calendar:before {
  color: #2F2C3C;
}
.zs-month-range-picker .zs-input-icon .zs-icon-calendar.zs-icon {
  display: inline-block;
  background: transparent;
  position: absolute;
  right: 0.66666667em;
  top: 50%;
  font-size: 1.5em;
  transform: translateY(-50%);
}
.zs-month-range-picker.zs-field[clear][value]:not([value=""]) [field] a.zs-icon-calendar::before {
  display: none;
}
.zs-range-calendar {
  display: block;
  background: #ffffff;
  border-radius: 0;
  border: 1px solid #DEDCDE;
  box-shadow: 2px 2px 0px rgba(26, 22, 40, 0.12);
  position: absolute;
  z-index: 2000;
  font-size: 1em;
  min-width: 100%;
  z-index: 5000;
}
.zs-range-calendar > header {
  position: relative;
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: left;
  padding: 0.5em 0.25em 0.5em 0.25em;
  background: #022D42;
}
.zs-range-calendar > header [role="prev"] ~ span {
  margin: 0em 0.25em 0em 1.5em;
}
.zs-range-calendar > header [role="prev"] ~ span select:focus {
  width: inherit;
}
.zs-range-calendar > header [role="prev"] ~ span ~ span {
  margin: 0em 0.25em 0em 0.5em;
}
.zs-range-calendar > header span {
  position: relative;
  padding: 0;
  display: inline-block;
  min-width: 15.625em;
  width: inherit;
  min-width: inherit;
}
.zs-range-calendar > header span select,
.zs-range-calendar > header span select:focus {
  font-family: "Open Sans", sans-serif;
  font-size: 1em;
  -moz-appearance: none;
  appearance: none;
  -webkit-appearance: none;
  background-color: transparent;
  border: 0;
  border-radius: 0;
  outline: 0;
  font-weight: normal;
  border: 1px solid #DEDCDE;
  color: #2F2C3C;
  cursor: pointer;
  width: 100%;
  padding: 0.5em 2.25em 0.5em 0.75em;
}
.zs-range-calendar > header span select::-ms-expand {
  display: none;
}
.zs-range-calendar > header span:after {
  content: "\ea23";
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Enable Ligatures ================ */
  -webkit-font-feature-settings: "liga";
  -moz-font-feature-settings: "liga=1";
  -moz-font-feature-settings: "liga";
  -ms-font-feature-settings: "liga" 1;
  -o-font-feature-settings: "liga";
  font-feature-settings: "liga";
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  font-family: 'zsIcons';
  font-size: 1.5em;
  color: #716E79;
  right: 0.5em;
  top: 50%;
  margin-top: -0.5em;
  position: absolute;
  pointer-events: none;
  display: inline-block;
}
.zs-range-calendar > header span:focus.zs-range-calendar > header span:after {
  color: #454250;
}
.zs-range-calendar > header span:hover.zs-range-calendar > header span:after {
  color: #9C9AA1;
}
.zs-range-calendar > header span:active.zs-range-calendar > header span:after {
  color: #2F2C3C;
}
.zs-range-calendar > header span[disabled] select,
.zs-range-calendar > header span.zs-disabled select,
.zs-range-calendar > header span.zs-calendar > table td[disabled] select,
.zs-range-calendar > header span.zs-range-calendar > table td[disabled] select,
.zs-range-calendar > header span.zs-range-calendar > section > table td[disabled] select {
  color: #B2B0B6;
  background: none;
  background-color: #F4F3F3;
  border: 1px solid #B2B0B6;
}
.zs-range-calendar > header span[disabled]:after,
.zs-range-calendar > header span.zs-disabled:after,
.zs-range-calendar > header span.zs-calendar > table td[disabled]:after,
.zs-range-calendar > header span.zs-range-calendar > table td[disabled]:after,
.zs-range-calendar > header span.zs-range-calendar > section > table td[disabled]:after {
  color: #B2B0B6;
}
.zs-range-calendar > header span.zs-danger select,
.zs-range-calendar > header span[invalid] select {
  border: 0px;
  box-shadow: none;
  box-shadow: inset 0 0 0 1px #B21111;
}
.zs-range-calendar > header span.zs-danger select:focus,
.zs-range-calendar > header span[invalid] select:focus {
  box-shadow: inset 0 0 0 1px #892208;
}
.zs-range-calendar > header span.zs-danger select:hover,
.zs-range-calendar > header span[invalid] select:hover {
  box-shadow: inset 0 0 0 1px #F27755;
}
.zs-range-calendar > header span.zs-danger select:active,
.zs-range-calendar > header span[invalid] select:active {
  box-shadow: inset 0 0 0 1px #5C1A0B;
}
.zs-range-calendar > header span.zs-danger:after,
.zs-range-calendar > header span[invalid]:after {
  color: #B21111;
}
.zs-range-calendar > header span.zs-danger:focus:after,
.zs-range-calendar > header span[invalid]:focus:after {
  color: #892208;
}
.zs-range-calendar > header span.zs-danger:hover:after,
.zs-range-calendar > header span[invalid]:hover:after {
  color: #F27755;
}
.zs-range-calendar > header span.zs-danger:active:after,
.zs-range-calendar > header span[invalid]:active:after {
  color: #5C1A0B;
}
.zs-range-calendar > header span.zs-success select {
  border: 0px;
  box-shadow: none;
  box-shadow: inset 0 0 0 1px #00AA67;
}
.zs-range-calendar > header span.zs-success select:focus {
  box-shadow: inset 0 0 0 1px #0A6E5E;
}
.zs-range-calendar > header span.zs-success select:hover {
  box-shadow: inset 0 0 0 1px #54D7AF;
}
.zs-range-calendar > header span.zs-success select:active {
  box-shadow: inset 0 0 0 1px #0C4846;
}
.zs-range-calendar > header span.zs-success:after {
  color: #00AA67;
}
.zs-range-calendar > header span.zs-success:focus:after {
  color: #0A6E5E;
}
.zs-range-calendar > header span.zs-success:hover:after {
  color: #54D7AF;
}
.zs-range-calendar > header span.zs-success:active:after {
  color: #0C4846;
}
.zs-range-calendar > header span.zs-warning select {
  border: 0px;
  box-shadow: none;
  box-shadow: inset 0 0 0 1px #E3A900;
}
.zs-range-calendar > header span.zs-warning select:focus {
  box-shadow: inset 0 0 0 1px #8A640C;
}
.zs-range-calendar > header span.zs-warning select:hover {
  box-shadow: inset 0 0 0 1px #FDCA63;
}
.zs-range-calendar > header span.zs-warning select:active {
  box-shadow: inset 0 0 0 1px #563F0E;
}
.zs-range-calendar > header span.zs-warning:after {
  color: #E3A900;
}
.zs-range-calendar > header span.zs-warning:focus:after {
  color: #8A640C;
}
.zs-range-calendar > header span.zs-warning:hover:after {
  color: #FDCA63;
}
.zs-range-calendar > header span.zs-warning:active:after {
  color: #563F0E;
}
.zs-range-calendar > header span.zs-info select {
  border: 0px;
  box-shadow: none;
  box-shadow: inset 0 0 0 1px #2B44C7;
}
.zs-range-calendar > header span.zs-info select:focus {
  box-shadow: inset 0 0 0 1px #141187;
}
.zs-range-calendar > header span.zs-info select:hover {
  box-shadow: inset 0 0 0 1px #7E92E9;
}
.zs-range-calendar > header span.zs-info select:active {
  box-shadow: inset 0 0 0 1px #160C60;
}
.zs-range-calendar > header span.zs-info:after {
  color: #2B44C7;
}
.zs-range-calendar > header span.zs-info:focus:after {
  color: #141187;
}
.zs-range-calendar > header span.zs-info:hover:after {
  color: #7E92E9;
}
.zs-range-calendar > header span.zs-info:active:after {
  color: #160C60;
}
.zs-range-calendar > header span select,
.zs-range-calendar > header span select:focus {
  font-family: "Open Sans", sans-serif;
  font-size: 1em;
  -moz-appearance: none;
  appearance: none;
  -webkit-appearance: none;
  background-color: transparent;
  border: 0;
  border-radius: 0;
  outline: 0;
  font-weight: normal;
  border: 1px solid #DEDCDE;
  color: #2F2C3C;
  cursor: pointer;
  width: 100%;
  padding: 0.5em 2.25em 0.5em 0.75em;
}
.zs-range-calendar > header span select::-ms-expand {
  display: none;
}
.zs-range-calendar > header span:after {
  content: "\ea23";
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Enable Ligatures ================ */
  -webkit-font-feature-settings: "liga";
  -moz-font-feature-settings: "liga=1";
  -moz-font-feature-settings: "liga";
  -ms-font-feature-settings: "liga" 1;
  -o-font-feature-settings: "liga";
  font-feature-settings: "liga";
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  font-family: 'zsIcons';
  font-size: 1.5em;
  color: #716E79;
  right: 0.5em;
  top: 50%;
  margin-top: -0.5em;
  position: absolute;
  pointer-events: none;
  display: inline-block;
}
.zs-range-calendar > header span:focus.zs-range-calendar > header span:after {
  color: #454250;
}
.zs-range-calendar > header span:hover.zs-range-calendar > header span:after {
  color: #9C9AA1;
}
.zs-range-calendar > header span:active.zs-range-calendar > header span:after {
  color: #2F2C3C;
}
.zs-range-calendar > header span[disabled] select,
.zs-range-calendar > header span.zs-disabled select,
.zs-range-calendar > header span.zs-calendar > table td[disabled] select,
.zs-range-calendar > header span.zs-range-calendar > table td[disabled] select,
.zs-range-calendar > header span.zs-range-calendar > section > table td[disabled] select {
  color: #B2B0B6;
  background: none;
  background-color: #F4F3F3;
  border: 1px solid #B2B0B6;
}
.zs-range-calendar > header span[disabled]:after,
.zs-range-calendar > header span.zs-disabled:after,
.zs-range-calendar > header span.zs-calendar > table td[disabled]:after,
.zs-range-calendar > header span.zs-range-calendar > table td[disabled]:after,
.zs-range-calendar > header span.zs-range-calendar > section > table td[disabled]:after {
  color: #B2B0B6;
}
.zs-range-calendar > header span.zs-danger select,
.zs-range-calendar > header span[invalid] select {
  border: 0px;
  box-shadow: none;
  box-shadow: inset 0 0 0 1px #B21111;
}
.zs-range-calendar > header span.zs-danger select:focus,
.zs-range-calendar > header span[invalid] select:focus {
  box-shadow: inset 0 0 0 1px #892208;
}
.zs-range-calendar > header span.zs-danger select:hover,
.zs-range-calendar > header span[invalid] select:hover {
  box-shadow: inset 0 0 0 1px #F27755;
}
.zs-range-calendar > header span.zs-danger select:active,
.zs-range-calendar > header span[invalid] select:active {
  box-shadow: inset 0 0 0 1px #5C1A0B;
}
.zs-range-calendar > header span.zs-danger:after,
.zs-range-calendar > header span[invalid]:after {
  color: #B21111;
}
.zs-range-calendar > header span.zs-danger:focus:after,
.zs-range-calendar > header span[invalid]:focus:after {
  color: #892208;
}
.zs-range-calendar > header span.zs-danger:hover:after,
.zs-range-calendar > header span[invalid]:hover:after {
  color: #F27755;
}
.zs-range-calendar > header span.zs-danger:active:after,
.zs-range-calendar > header span[invalid]:active:after {
  color: #5C1A0B;
}
.zs-range-calendar > header span.zs-success select {
  border: 0px;
  box-shadow: none;
  box-shadow: inset 0 0 0 1px #00AA67;
}
.zs-range-calendar > header span.zs-success select:focus {
  box-shadow: inset 0 0 0 1px #0A6E5E;
}
.zs-range-calendar > header span.zs-success select:hover {
  box-shadow: inset 0 0 0 1px #54D7AF;
}
.zs-range-calendar > header span.zs-success select:active {
  box-shadow: inset 0 0 0 1px #0C4846;
}
.zs-range-calendar > header span.zs-success:after {
  color: #00AA67;
}
.zs-range-calendar > header span.zs-success:focus:after {
  color: #0A6E5E;
}
.zs-range-calendar > header span.zs-success:hover:after {
  color: #54D7AF;
}
.zs-range-calendar > header span.zs-success:active:after {
  color: #0C4846;
}
.zs-range-calendar > header span.zs-warning select {
  border: 0px;
  box-shadow: none;
  box-shadow: inset 0 0 0 1px #E3A900;
}
.zs-range-calendar > header span.zs-warning select:focus {
  box-shadow: inset 0 0 0 1px #8A640C;
}
.zs-range-calendar > header span.zs-warning select:hover {
  box-shadow: inset 0 0 0 1px #FDCA63;
}
.zs-range-calendar > header span.zs-warning select:active {
  box-shadow: inset 0 0 0 1px #563F0E;
}
.zs-range-calendar > header span.zs-warning:after {
  color: #E3A900;
}
.zs-range-calendar > header span.zs-warning:focus:after {
  color: #8A640C;
}
.zs-range-calendar > header span.zs-warning:hover:after {
  color: #FDCA63;
}
.zs-range-calendar > header span.zs-warning:active:after {
  color: #563F0E;
}
.zs-range-calendar > header span.zs-info select {
  border: 0px;
  box-shadow: none;
  box-shadow: inset 0 0 0 1px #2B44C7;
}
.zs-range-calendar > header span.zs-info select:focus {
  box-shadow: inset 0 0 0 1px #141187;
}
.zs-range-calendar > header span.zs-info select:hover {
  box-shadow: inset 0 0 0 1px #7E92E9;
}
.zs-range-calendar > header span.zs-info select:active {
  box-shadow: inset 0 0 0 1px #160C60;
}
.zs-range-calendar > header span.zs-info:after {
  color: #2B44C7;
}
.zs-range-calendar > header span.zs-info:focus:after {
  color: #141187;
}
.zs-range-calendar > header span.zs-info:hover:after {
  color: #7E92E9;
}
.zs-range-calendar > header span.zs-info:active:after {
  color: #160C60;
}
.zs-range-calendar > header span > [overlay] {
  cursor: pointer;
  background-color: transparent;
  border-radius: 0;
  outline: 0;
  font-weight: 600;
  border: 1px solid #DEDCDE;
  color: #2F2C3C;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0.5em 2.25em 0.5em 0.75em;
  max-width: none;
}
.zs-range-calendar > header span > [overlay].placeholder-active {
  color: #716E79;
}
.zs-range-calendar > header span.zs-field[label]:after,
.zs-range-calendar > header span.zs-action-field[label]:after {
  top: auto;
  bottom: 0.33333333em;
}
.zs-range-calendar > header span.zs-action-field > select {
  display: none;
}
.zs-range-calendar > header span.zs-action-field.zs-danger > [overlay],
.zs-range-calendar > header span.zs-action-field[invalid] > [overlay] {
  border: 0px;
  box-shadow: none;
  box-shadow: inset 0 0 0 1px #B21111;
}
.zs-range-calendar > header span.zs-action-field.zs-danger > [overlay]:focus,
.zs-range-calendar > header span.zs-action-field[invalid] > [overlay]:focus {
  box-shadow: inset 0 0 0 1px #892208;
}
.zs-range-calendar > header span.zs-action-field.zs-danger > [overlay]:hover,
.zs-range-calendar > header span.zs-action-field[invalid] > [overlay]:hover {
  box-shadow: inset 0 0 0 1px #F27755;
}
.zs-range-calendar > header span.zs-action-field.zs-danger > [overlay]:active,
.zs-range-calendar > header span.zs-action-field[invalid] > [overlay]:active {
  box-shadow: inset 0 0 0 1px #5C1A0B;
}
.zs-range-calendar > header span.zs-action-field.zs-success > [overlay] {
  border: 0px;
  box-shadow: none;
  box-shadow: inset 0 0 0 1px #00AA67;
}
.zs-range-calendar > header span.zs-action-field.zs-success > [overlay]:focus {
  box-shadow: inset 0 0 0 1px #0A6E5E;
}
.zs-range-calendar > header span.zs-action-field.zs-success > [overlay]:hover {
  box-shadow: inset 0 0 0 1px #54D7AF;
}
.zs-range-calendar > header span.zs-action-field.zs-success > [overlay]:active {
  box-shadow: inset 0 0 0 1px #0C4846;
}
.zs-range-calendar > header span.zs-action-field.zs-warning > [overlay] {
  border: 0px;
  box-shadow: none;
  box-shadow: inset 0 0 0 1px #E3A900;
}
.zs-range-calendar > header span.zs-action-field.zs-warning > [overlay]:focus {
  box-shadow: inset 0 0 0 1px #8A640C;
}
.zs-range-calendar > header span.zs-action-field.zs-warning > [overlay]:hover {
  box-shadow: inset 0 0 0 1px #FDCA63;
}
.zs-range-calendar > header span.zs-action-field.zs-warning > [overlay]:active {
  box-shadow: inset 0 0 0 1px #563F0E;
}
.zs-range-calendar > header span.zs-action-field.zs-info > [overlay] {
  border: 0px;
  box-shadow: none;
  box-shadow: inset 0 0 0 1px #2B44C7;
}
.zs-range-calendar > header span.zs-action-field.zs-info > [overlay]:focus {
  box-shadow: inset 0 0 0 1px #141187;
}
.zs-range-calendar > header span.zs-action-field.zs-info > [overlay]:hover {
  box-shadow: inset 0 0 0 1px #7E92E9;
}
.zs-range-calendar > header span.zs-action-field.zs-info > [overlay]:active {
  box-shadow: inset 0 0 0 1px #160C60;
}
.zs-range-calendar > header span[disabled] > [overlay],
.zs-range-calendar > header span.zs-disabled > [overlay],
.zs-range-calendar > header span.zs-calendar > table td[disabled] > [overlay],
.zs-range-calendar > header span.zs-range-calendar > table td[disabled] > [overlay],
.zs-range-calendar > header span.zs-range-calendar > section > table td[disabled] > [overlay] {
  color: #B2B0B6;
  background: none;
  background-color: #F4F3F3;
  border: 1px solid #B2B0B6;
}
.zs-range-calendar > header span > select[name] {
  font-weight: 600;
  border-color: transparent;
  color: #ffffff;
}
.zs-range-calendar > header span > select[name] option {
  font-weight: normal;
  color: #2F2C3C;
  color: initial;
}
.zs-range-calendar > header span:after {
  color: #ffffff;
}
.zs-range-calendar > header span:focus:after {
  color: #454250;
}
.zs-range-calendar > header span:hover:after {
  color: #9C9AA1;
}
.zs-range-calendar > header span:active:after {
  color: #2F2C3C;
}
.zs-range-calendar > header span ~ span {
  margin: 0em 0em 0em -1em;
}
.zs-range-calendar > header a:hover:before {
  color: inherit;
}
.zs-range-calendar > header [name="year"],
.zs-range-calendar > header [name="month"] {
  width: inherit;
  min-width: inherit;
}
.zs-range-calendar > table {
  width: 100%;
  padding: 1em 1em 0em 1em;
}
.zs-range-calendar > table th {
  padding-bottom: 0.5em;
  font-weight: normal;
  font-size: 0.625em;
  color: #716E79;
}
.zs-range-calendar > table td {
  cursor: pointer;
  text-align: center;
  vertical-align: middle;
  width: 2.42857143em;
  height: 2.42857143em;
  border: 1px solid #ffffff;
  font-size: 0.875em;
  width: 2.28571429em;
  height: 2em;
  padding: 0.25em 0.5em 0.25em 0.5em;
}
.zs-range-calendar > table td[disabled] {
  background: none;
  border-radius: 0;
  color: #B2B0B6;
  border: 1px solid #ffffff;
}
.zs-range-calendar > table td[notinmonth] {
  opacity: 1;
  color: #B2B0B6;
  background: none;
  border-color: #ffffff;
}
.zs-range-calendar > table td[notinmonth][current] {
  border: 1px solid #DEDCDE;
  background: #DEDCDE;
  color: #716E79;
}
.zs-range-calendar > table td[notinmonth][today] {
  background: #FFF1D6;
  color: #716E79;
}
.zs-range-calendar > table td[today] {
  background: #FFD680;
  border: 1px solid #FFD680;
  border-radius: 0;
  font-weight: normal;
}
.zs-range-calendar > table td[current] {
  border: 1px solid #DEDCDE;
  background: #DEDCDE;
  color: #2F2C3C;
  border-radius: 0;
}
.zs-range-calendar > table td[current]:hover {
  color: #2F2C3C;
}
.zs-range-calendar > table td:hover {
  color: inherit;
  border: solid 1px #716E79;
  border-radius: 0;
}
.zs-range-calendar > table td:active {
  color: #FFFFFF;
  background: #022D42;
}
.zs-range-calendar > footer {
  display: flex;
  align-items: center;
  justify-content: left;
  padding: 1em 1em 1em 1em;
  justify-content: space-between;
}
.zs-range-calendar > footer .zs-fab[role='next'],
.zs-range-calendar > footer .zs-fab[role='prev'] {
  padding: 0.625em 0.625em 0.625em 0.625em;
}
.zs-range-calendar > footer .zs-fab[role='next'] .zs-icon,
.zs-range-calendar > footer .zs-fab[role='prev'] .zs-icon {
  font-size: 0.75em;
  border-radius: inherit;
}
.zs-range-calendar > footer [role='today'] {
  font-size: 0.875em;
}
.zs-range-calendar > header {
  position: relative;
  white-space: nowrap;
  padding: 1em 0.5em 1em 0.5em;
  background: #022D42;
}
.zs-range-calendar > header [role="prev"],
.zs-range-calendar > header [role="next"] {
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Enable Ligatures ================ */
  -webkit-font-feature-settings: "liga";
  -moz-font-feature-settings: "liga=1";
  -moz-font-feature-settings: "liga";
  -ms-font-feature-settings: "liga" 1;
  -o-font-feature-settings: "liga";
  font-feature-settings: "liga";
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  display: inline-block;
  vertical-align: middle;
  font-family: 'zsIcons';
  font-size: 1.5em;
  color: #fff;
}
.zs-range-calendar > header [role="prev"]:visited,
.zs-range-calendar > header [role="next"]:visited {
  color: #fff;
}
.zs-range-calendar > header [role="prev"]:focus,
.zs-range-calendar > header [role="next"]:focus {
  color: #454250;
}
.zs-range-calendar > header [role="prev"]:hover,
.zs-range-calendar > header [role="next"]:hover {
  color: #9C9AA1;
}
.zs-range-calendar > header [role="prev"]:active,
.zs-range-calendar > header [role="next"]:active {
  color: #2F2C3C;
}
.zs-range-calendar > header [role="prev"][disabled],
.zs-range-calendar > header [role="next"][disabled] {
  color: #B2B0B6;
}
.zs-range-calendar > header [role="prev"] {
  margin: 0em 0.5em 0em 0em;
}
.zs-range-calendar > header [role="prev"]:before {
  content: "\ea0d";
}
.zs-range-calendar > header [role="next"] {
  margin: 0em 0em 0em 0.5em;
}
.zs-range-calendar > header [role="next"]:before {
  content: "\ea17";
}
.zs-range-calendar > header .prev-btn-container > span,
.zs-range-calendar > header .next-btn-container > span {
  font-weight: 700;
  vertical-align: middle;
  font-size: inherit;
  color: #fff;
}
.zs-range-calendar > header .prev-btn-container {
  float: left;
}
.zs-range-calendar > header .next-btn-container {
  float: right;
}
.zs-range-calendar > header a:hover:before {
  color: inherit;
}
.zs-range-calendar > section {
  padding: 0.5em 1.5em 0.5em 1.5em;
}
.zs-range-calendar > section > table {
  display: inline-table;
  border-spacing: 0.5em;
}
.zs-range-calendar > section > table td {
  cursor: pointer;
  text-align: center;
  vertical-align: middle;
  width: 2.42857143em;
  height: 2.42857143em;
  border: 1px solid #ffffff;
  font-size: 0.875em;
  width: 2.28571429em;
  height: 2em;
  padding: 0.25em 0.5em 0.25em 0.5em;
  font-weight: 600;
  font-size: inherit;
  border-radius: 0;
  padding: 0.5em 0.25em 0.5em 0.25em;
  width: 3em;
  height: 2.5em;
}
.zs-range-calendar > section > table td[disabled] {
  background: none;
  border-radius: 0;
  color: #B2B0B6;
  border: 1px solid #ffffff;
}
.zs-range-calendar > section > table td[notinmonth] {
  opacity: 1;
  color: #B2B0B6;
  background: none;
  border-color: #ffffff;
}
.zs-range-calendar > section > table td[notinmonth][current] {
  border: 1px solid #DEDCDE;
  background: #DEDCDE;
  color: #716E79;
}
.zs-range-calendar > section > table td[notinmonth][today] {
  background: #FFF1D6;
  color: #716E79;
}
.zs-range-calendar > section > table td[today] {
  background: #FFD680;
  border: 1px solid #FFD680;
  border-radius: 0;
  font-weight: normal;
}
.zs-range-calendar > section > table td[current] {
  border: 1px solid #DEDCDE;
  background: #DEDCDE;
  color: #2F2C3C;
  border-radius: 0;
}
.zs-range-calendar > section > table td[current]:hover {
  color: #2F2C3C;
}
.zs-range-calendar > section > table td:not([disabled]):not([current]) {
  color: inherit;
  border: solid 1px transparent;
}
.zs-range-calendar > section > table td:not([disabled]):not([current]):hover {
  color: inherit;
  border: solid 1px #716E79;
}
.zs-range-calendar > section > table td:not([disabled]):not([current]):active {
  color: #FFFFFF;
  background: #022D42;
}
.zs-range-calendar > section > table:first-child {
  margin: 0em 0.5em 0em 0em;
}
.zs-range-calendar > section > table:last-child {
  padding: 0em 0em 0em 0.5em;
  border-left: solid 1px #DEDCDE;
}
.zs-range-calendar > footer {
  text-align: center;
  padding: 0em 0.5em 1em 0.5em;
}
.zs-range-calendar > footer a {
  cursor: pointer;
}
.zs-range-calendar > footer [clear-range] {
  font-weight: 600;
  font-size: inherit;
}
.zs-table {
  font-size: 1em;
  border-collapse: collapse;
  border-spacing: 0;
  color: #454250;
  margin: 0.5em 1em 0.5em 1em;
}
.zs-table caption {
  text-align: left;
  margin: 0em 0em 1em 0em;
  font-size: 0.75em;
  color: #9C9AA1;
}
.zs-table:first-child {
  margin-top: 0;
}
.zs-table:first-child > caption {
  margin-top: 0;
}
.zs-table tr > td,
.zs-table tr > th {
  padding: 1em 1em 1em 1em;
  line-height: 1.5;
  border: 1px solid #DEDCDE;
  border-left: 0px;
  border-right: 0px;
  text-align: left;
  vertical-align: top;
}
.zs-table tr > td.zs-action-icons,
.zs-table tr > th.zs-action-icons {
  padding-top: 0.3em;
  padding-bottom: 0;
}
.zs-table tr > td.zs-actions,
.zs-table tr > th.zs-actions {
  padding-top: 0.1em;
  padding-bottom: 0.1em;
  vertical-align: middle;
}
.zs-table tr > td:focus,
.zs-table tr > th:focus {
  outline: 1px solid #27A6A4;
}
.zs-table tr > th {
  min-height: auto;
  height: auto;
  padding: 1em 1em 1em 1em;
  line-height: 1.5;
  font-size: 1em;
  font-weight: 600;
  background: none;
  border-bottom: 1px solid #716E79;
  vertical-align: bottom;
}
.zs-table tr > th:focus {
  outline: 1px solid #27A6A4;
}
.zs-table tr > th a[sort] {
  color: #454250;
}
.zs-table tr > th a[sort]:visited {
  color: #454250;
}
.zs-table tr > th a[sort]:focus {
  color: #454250;
}
.zs-table tr > th a[sort]:hover {
  color: #454250;
}
.zs-table tr > th a[sort]:active {
  color: #454250;
}
.zs-table tr > th[sort],
.zs-table tr > th [sort] {
  padding-right: 1.75em;
  position: relative;
}
.zs-table tr > th[sort]::after,
.zs-table tr > th [sort]::after {
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Enable Ligatures ================ */
  -webkit-font-feature-settings: "liga";
  -moz-font-feature-settings: "liga=1";
  -moz-font-feature-settings: "liga";
  -ms-font-feature-settings: "liga" 1;
  -o-font-feature-settings: "liga";
  font-feature-settings: "liga";
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  font-size: 1.25em;
  font-family: 'zsIcons';
  content: "\ea1c";
  color: #716E79;
}
.zs-table tr > th[sort]:hover::after,
.zs-table tr > th [sort]:hover::after {
  color: #9C9AA1;
}
.zs-table tr > th[sort="asc"]::after,
.zs-table tr > th [sort="asc"]::after {
  color: #27A6A4;
  content: "\eb18";
}
.zs-table tr > th[sort="desc"]::after,
.zs-table tr > th [sort="desc"]::after {
  color: #27A6A4;
  content: "\eb17";
}
.zs-table tr > th[sort="asc"]:hover::after,
.zs-table tr > th [sort="asc"]:hover::after,
.zs-table tr > th[sort="desc"]:hover::after,
.zs-table tr > th [sort="desc"]:hover::after {
  color: #27A6A4;
}
.zs-table tr > th [sort] {
  cursor: pointer;
}
.zs-table tr > th [sort]::after {
  font-size: 1.25em;
}
.zs-table tr:focus {
  outline: 1px solid #27A6A4;
}
.zs-table td,
.zs-table th,
.zs-table .zs-td {
  padding: 1em 1em 1em 1em;
  line-height: 1.5;
  border: 1px solid #DEDCDE;
  border-left: 0px;
  border-right: 0px;
  text-align: left;
  vertical-align: top;
}
.zs-table td.zs-action-icons,
.zs-table th.zs-action-icons,
.zs-table .zs-td.zs-action-icons {
  padding-top: 0.3em;
  padding-bottom: 0;
}
.zs-table td.zs-actions,
.zs-table th.zs-actions,
.zs-table .zs-td.zs-actions {
  padding-top: 0.1em;
  padding-bottom: 0.1em;
  vertical-align: middle;
}
.zs-table td:focus,
.zs-table th:focus,
.zs-table .zs-td:focus {
  outline: 1px solid #27A6A4;
}
.zs-table > thead td,
.zs-table > thead th,
.zs-table > tr > th,
.zs-table > th,
.zs-table > tbody > tr > th,
.zs-table .zs-th,
.zs-table > tfoot td,
.zs-table > tfoot th {
  min-height: auto;
  height: auto;
  padding: 1em 1em 1em 1em;
  line-height: 1.5;
  font-size: 1em;
  font-weight: 600;
  background: none;
  border-bottom: 1px solid #716E79;
  vertical-align: bottom;
}
.zs-table > thead td:focus,
.zs-table > thead th:focus,
.zs-table > tr > th:focus,
.zs-table > th:focus,
.zs-table > tbody > tr > th:focus,
.zs-table .zs-th:focus,
.zs-table > tfoot td:focus,
.zs-table > tfoot th:focus {
  outline: 1px solid #27A6A4;
}
.zs-table > thead td a[sort],
.zs-table > thead th a[sort],
.zs-table > tr > th a[sort],
.zs-table > th a[sort],
.zs-table > tbody > tr > th a[sort],
.zs-table .zs-th a[sort],
.zs-table > tfoot td a[sort],
.zs-table > tfoot th a[sort] {
  color: #454250;
}
.zs-table > thead td a[sort]:visited,
.zs-table > thead th a[sort]:visited,
.zs-table > tr > th a[sort]:visited,
.zs-table > th a[sort]:visited,
.zs-table > tbody > tr > th a[sort]:visited,
.zs-table .zs-th a[sort]:visited,
.zs-table > tfoot td a[sort]:visited,
.zs-table > tfoot th a[sort]:visited {
  color: #454250;
}
.zs-table > thead td a[sort]:focus,
.zs-table > thead th a[sort]:focus,
.zs-table > tr > th a[sort]:focus,
.zs-table > th a[sort]:focus,
.zs-table > tbody > tr > th a[sort]:focus,
.zs-table .zs-th a[sort]:focus,
.zs-table > tfoot td a[sort]:focus,
.zs-table > tfoot th a[sort]:focus {
  color: #454250;
}
.zs-table > thead td a[sort]:hover,
.zs-table > thead th a[sort]:hover,
.zs-table > tr > th a[sort]:hover,
.zs-table > th a[sort]:hover,
.zs-table > tbody > tr > th a[sort]:hover,
.zs-table .zs-th a[sort]:hover,
.zs-table > tfoot td a[sort]:hover,
.zs-table > tfoot th a[sort]:hover {
  color: #454250;
}
.zs-table > thead td a[sort]:active,
.zs-table > thead th a[sort]:active,
.zs-table > tr > th a[sort]:active,
.zs-table > th a[sort]:active,
.zs-table > tbody > tr > th a[sort]:active,
.zs-table .zs-th a[sort]:active,
.zs-table > tfoot td a[sort]:active,
.zs-table > tfoot th a[sort]:active {
  color: #454250;
}
.zs-table > thead td[sort],
.zs-table > thead th[sort],
.zs-table > tr > th[sort],
.zs-table > th[sort],
.zs-table > tbody > tr > th[sort],
.zs-table .zs-th[sort],
.zs-table > tfoot td[sort],
.zs-table > tfoot th[sort],
.zs-table > thead td [sort],
.zs-table > thead th [sort],
.zs-table > tr > th [sort],
.zs-table > th [sort],
.zs-table > tbody > tr > th [sort],
.zs-table .zs-th [sort],
.zs-table > tfoot td [sort],
.zs-table > tfoot th [sort] {
  padding-right: 1.75em;
  position: relative;
}
.zs-table > thead td[sort]::after,
.zs-table > thead th[sort]::after,
.zs-table > tr > th[sort]::after,
.zs-table > th[sort]::after,
.zs-table > tbody > tr > th[sort]::after,
.zs-table .zs-th[sort]::after,
.zs-table > tfoot td[sort]::after,
.zs-table > tfoot th[sort]::after,
.zs-table > thead td [sort]::after,
.zs-table > thead th [sort]::after,
.zs-table > tr > th [sort]::after,
.zs-table > th [sort]::after,
.zs-table > tbody > tr > th [sort]::after,
.zs-table .zs-th [sort]::after,
.zs-table > tfoot td [sort]::after,
.zs-table > tfoot th [sort]::after {
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Enable Ligatures ================ */
  -webkit-font-feature-settings: "liga";
  -moz-font-feature-settings: "liga=1";
  -moz-font-feature-settings: "liga";
  -ms-font-feature-settings: "liga" 1;
  -o-font-feature-settings: "liga";
  font-feature-settings: "liga";
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  font-size: 1.25em;
  font-family: 'zsIcons';
  content: "\ea1c";
  color: #716E79;
}
.zs-table > thead td[sort]:hover::after,
.zs-table > thead th[sort]:hover::after,
.zs-table > tr > th[sort]:hover::after,
.zs-table > th[sort]:hover::after,
.zs-table > tbody > tr > th[sort]:hover::after,
.zs-table .zs-th[sort]:hover::after,
.zs-table > tfoot td[sort]:hover::after,
.zs-table > tfoot th[sort]:hover::after,
.zs-table > thead td [sort]:hover::after,
.zs-table > thead th [sort]:hover::after,
.zs-table > tr > th [sort]:hover::after,
.zs-table > th [sort]:hover::after,
.zs-table > tbody > tr > th [sort]:hover::after,
.zs-table .zs-th [sort]:hover::after,
.zs-table > tfoot td [sort]:hover::after,
.zs-table > tfoot th [sort]:hover::after {
  color: #9C9AA1;
}
.zs-table > thead td[sort="asc"]::after,
.zs-table > thead th[sort="asc"]::after,
.zs-table > tr > th[sort="asc"]::after,
.zs-table > th[sort="asc"]::after,
.zs-table > tbody > tr > th[sort="asc"]::after,
.zs-table .zs-th[sort="asc"]::after,
.zs-table > tfoot td[sort="asc"]::after,
.zs-table > tfoot th[sort="asc"]::after,
.zs-table > thead td [sort="asc"]::after,
.zs-table > thead th [sort="asc"]::after,
.zs-table > tr > th [sort="asc"]::after,
.zs-table > th [sort="asc"]::after,
.zs-table > tbody > tr > th [sort="asc"]::after,
.zs-table .zs-th [sort="asc"]::after,
.zs-table > tfoot td [sort="asc"]::after,
.zs-table > tfoot th [sort="asc"]::after {
  color: #27A6A4;
  content: "\eb18";
}
.zs-table > thead td[sort="desc"]::after,
.zs-table > thead th[sort="desc"]::after,
.zs-table > tr > th[sort="desc"]::after,
.zs-table > th[sort="desc"]::after,
.zs-table > tbody > tr > th[sort="desc"]::after,
.zs-table .zs-th[sort="desc"]::after,
.zs-table > tfoot td[sort="desc"]::after,
.zs-table > tfoot th[sort="desc"]::after,
.zs-table > thead td [sort="desc"]::after,
.zs-table > thead th [sort="desc"]::after,
.zs-table > tr > th [sort="desc"]::after,
.zs-table > th [sort="desc"]::after,
.zs-table > tbody > tr > th [sort="desc"]::after,
.zs-table .zs-th [sort="desc"]::after,
.zs-table > tfoot td [sort="desc"]::after,
.zs-table > tfoot th [sort="desc"]::after {
  color: #27A6A4;
  content: "\eb17";
}
.zs-table > thead td[sort="asc"]:hover::after,
.zs-table > thead th[sort="asc"]:hover::after,
.zs-table > tr > th[sort="asc"]:hover::after,
.zs-table > th[sort="asc"]:hover::after,
.zs-table > tbody > tr > th[sort="asc"]:hover::after,
.zs-table .zs-th[sort="asc"]:hover::after,
.zs-table > tfoot td[sort="asc"]:hover::after,
.zs-table > tfoot th[sort="asc"]:hover::after,
.zs-table > thead td [sort="asc"]:hover::after,
.zs-table > thead th [sort="asc"]:hover::after,
.zs-table > tr > th [sort="asc"]:hover::after,
.zs-table > th [sort="asc"]:hover::after,
.zs-table > tbody > tr > th [sort="asc"]:hover::after,
.zs-table .zs-th [sort="asc"]:hover::after,
.zs-table > tfoot td [sort="asc"]:hover::after,
.zs-table > tfoot th [sort="asc"]:hover::after,
.zs-table > thead td[sort="desc"]:hover::after,
.zs-table > thead th[sort="desc"]:hover::after,
.zs-table > tr > th[sort="desc"]:hover::after,
.zs-table > th[sort="desc"]:hover::after,
.zs-table > tbody > tr > th[sort="desc"]:hover::after,
.zs-table .zs-th[sort="desc"]:hover::after,
.zs-table > tfoot td[sort="desc"]:hover::after,
.zs-table > tfoot th[sort="desc"]:hover::after,
.zs-table > thead td [sort="desc"]:hover::after,
.zs-table > thead th [sort="desc"]:hover::after,
.zs-table > tr > th [sort="desc"]:hover::after,
.zs-table > th [sort="desc"]:hover::after,
.zs-table > tbody > tr > th [sort="desc"]:hover::after,
.zs-table .zs-th [sort="desc"]:hover::after,
.zs-table > tfoot td [sort="desc"]:hover::after,
.zs-table > tfoot th [sort="desc"]:hover::after {
  color: #27A6A4;
}
.zs-table > thead td [sort],
.zs-table > thead th [sort],
.zs-table > tr > th [sort],
.zs-table > th [sort],
.zs-table > tbody > tr > th [sort],
.zs-table .zs-th [sort],
.zs-table > tfoot td [sort],
.zs-table > tfoot th [sort] {
  cursor: pointer;
}
.zs-table > thead td [sort]::after,
.zs-table > thead th [sort]::after,
.zs-table > tr > th [sort]::after,
.zs-table > th [sort]::after,
.zs-table > tbody > tr > th [sort]::after,
.zs-table .zs-th [sort]::after,
.zs-table > tfoot td [sort]::after,
.zs-table > tfoot th [sort]::after {
  font-size: 1.25em;
}
.zs-table > tfoot td,
.zs-table > tfoot th {
  background: transparent;
  vertical-align: top;
}
.zs-table > tfoot > tr > td {
  border-bottom: 1px solid #DEDCDE;
}
.zs-table.zs-table-condense > thead > tr > td,
.zs-table.zs-table-condense > tfoot > tr > td,
.zs-table.zs-table-condense > tbody > tr > td,
.zs-table.zs-table-condense > thead > tr > th,
.zs-table.zs-table-condense > tfoot > tr > th,
.zs-table.zs-table-condense > tbody > tr > th {
  padding: 0.5em 0.5em 0.5em 0.5em;
}
.zs-table.zs-table-generous > thead > tr > td,
.zs-table.zs-table-generous > tfoot > tr > td,
.zs-table.zs-table-generous > tbody > tr > td,
.zs-table.zs-table-generous > thead > tr > th,
.zs-table.zs-table-generous > tfoot > tr > th,
.zs-table.zs-table-generous > tbody > tr > th {
  padding: 1.5em 1em 1.5em 1em;
}
.zs-table .zs-danger {
  background: #FFB6A5;
}
.zs-table .zs-warning {
  background: #FFDB93;
}
.zs-table .zs-success {
  background: #ADF2E7;
}
.zs-table .zs-active {
  background-color: rgba(236, 114, 0, 0.1);
}
.zs-table tr[border*="none"] > * {
  border: none;
}
.zs-table tr[border*="right"] > * {
  border-right: 1px solid #DEDCDE;
}
.zs-table tr[border*="left"] > * {
  border-left: 1px solid #DEDCDE;
}
.zs-table tr[border*="top"] > * {
  border-top: 1px solid #DEDCDE;
}
.zs-table tr[border*="bottom"] > * {
  border-bottom: 1px solid #DEDCDE;
}
.zs-table [border*="none"] {
  border: 0 !important;
}
.zs-table [border*="right"] {
  border-right: 1px solid #DEDCDE !important;
}
.zs-table [border*="left"] {
  border-left: 1px solid #DEDCDE !important;
}
.zs-table [border*="top"] {
  border-top: 1px solid #DEDCDE !important;
}
.zs-table [border*="bottom"] {
  border-bottom: 1px solid #DEDCDE !important;
}
.zs-table [scope="colgroup"],
.zs-table [scope="rowgroup"] {
  vertical-align: middle;
  text-align: center;
}
.zs-table tr[align="right"] > td,
.zs-table tr[align="right"] > th {
  text-align: right;
}
.zs-table tr[align="left"] > td,
.zs-table tr[align="left"] > th {
  text-align: left;
}
.zs-table tr[align="center"] > td,
.zs-table tr[align="center"] > th {
  text-align: center;
}
.zs-table tr[valign="top"] > td,
.zs-table tr[valign="top"] > th {
  vertical-align: top;
}
.zs-table tr[valign="middle"] > td,
.zs-table tr[valign="middle"] > th {
  vertical-align: middle;
}
.zs-table tr[valign="bottom"] > td,
.zs-table tr[valign="bottom"] > th {
  vertical-align: bottom;
}
.zs-table tr[valign="baseline"] > td,
.zs-table tr[valign="baseline"] > th {
  vertical-align: baseline;
}
.zs-table [align="right"] {
  text-align: right !important;
}
.zs-table [align="left"] {
  text-align: left !important;
}
.zs-table [align="center"] {
  text-align: center !important;
}
.zs-table [valign="top"] {
  vertical-align: top !important;
}
.zs-table [valign="middle"] {
  vertical-align: middle !important;
}
.zs-table [valign="bottom"] {
  vertical-align: bottom !important;
}
.zs-table [valign="baseline"] {
  vertical-align: baseline !important;
}
.zs-table [mark] {
  background-color: rgba(39, 166, 164, 0.1) !important;
}
.zs-table:focus {
  outline: 1px solid #27A6A4;
}
.zs-table a:focus {
  outline: 1px solid #27A6A4;
}
.zs-table > thead > tr > th a[sort]::after,
.zs-table > thead > tr > td a[sort]::after,
.zs-table > thead > tr > th a[sort="asc"]::after,
.zs-table > thead > tr > td a[sort="asc"]::after,
.zs-table > thead > tr > th a[sort="desc"]::after,
.zs-table > thead > tr > td a[sort="desc"]::after,
.zs-table > thead > tr > th [sort]::after,
.zs-table > thead > tr > td [sort]::after,
.zs-table > thead > tr > th [sort="asc"]::after,
.zs-table > thead > tr > td [sort="asc"]::after,
.zs-table > thead > tr > th [sort="desc"]::after,
.zs-table > thead > tr > td [sort="desc"]::after {
  font-size: 20px;
}
.zs-data-grid {
  position: relative;
  display: inline-grid;
  display: -ms-inline-grid;
  -ms-grid-rows: 1fr;
  -ms-grid-columns: 1fr;
}
.zs-data-grid .zs-scrollable {
  overflow: auto;
}
.zs-data-grid .zs-fixed {
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.zs-data-grid .zs-fixed::-webkit-scrollbar {
  display: none;
}
.zs-data-grid .zs-fixed .zs-table {
  margin-right: 0;
  border-right: 1px solid #716E79;
}
.zs-data-grid .zs-fixed .zs-table td:last-child {
  border-right: 1px solid #716E79;
}
.zs-data-grid .zs-sticky {
  overflow-x: hidden;
  visibility: visible;
}
.zs-data-grid .zs-table {
  margin: 0;
  line-height: 1;
}
.zs-data-grid .zs-table > thead td a[sort],
.zs-data-grid .zs-table > thead th a[sort] {
  display: inline-block;
}
.zs-data-grid[sticky-header] {
  grid-template-rows: min-content 1fr;
  -ms-grid-rows: min-content 1fr;
}
.zs-data-grid[sticky-header] [sticky-header-container] {
  grid-row-start: 1;
  -ms-grid-row: 1;
}
.zs-data-grid[sticky-header] [scrollable-container] {
  grid-row-start: 2;
  -ms-grid-row: 2;
}
.zs-data-grid[sticky-header] [scrollable-container] > .zs-table thead {
  visibility: hidden;
}
.zs-data-grid[locked-columns] {
  grid-template-columns: max-content 1fr;
  -ms-grid-columns: max-content 1fr;
}
.zs-data-grid[locked-columns] > [locked-column-container] {
  grid-column-start: 1;
  -ms-grid-column: 1;
}
.zs-data-grid[locked-columns] > [scrollable-container] {
  grid-column-start: 2;
  -ms-grid-column: 2;
}
.zs-data-grid[sticky-header][locked-columns] [locked-sticky-header-container] {
  grid-row-start: 1;
  -ms-grid-row: 1;
  grid-column-start: 1;
  -ms-grid-column: 1;
}
.zs-data-grid[sticky-header][locked-columns] [locked-sticky-header-container] .zs-table {
  border-right: 1px solid #716E79;
}
.zs-data-grid[sticky-header][locked-columns] [sticky-header-container] {
  grid-row-start: 1;
  -ms-grid-row: 1;
  grid-column-start: 2;
  -ms-grid-column: 2;
}
.zs-data-grid[sticky-header][locked-columns] [locked-column-container] {
  grid-row-start: 2;
  -ms-grid-row: 2;
  grid-column-start: 1;
  -ms-grid-column: 1;
}
.zs-data-grid[sticky-header][locked-columns] [locked-column-container] > .zs-table thead {
  visibility: hidden;
}
.zs-data-grid[sticky-header][locked-columns] [scrollable-container] {
  grid-row-start: 2;
  -ms-grid-row: 2;
  grid-column-start: 2;
  -ms-grid-column: 2;
}
.zs-data-grid[sticky-header][locked-columns] [scrollable-container] > .zs-table thead {
  visibility: hidden;
}
.zs-data-grid[resizable] .zs-table td {
  border: 1px solid #DEDCDE;
  position: relative;
}
.zs-data-grid[resizable] .zs-scrollable .zs-table td::after,
.zs-data-grid[resizable] [sticky-header-container] .zs-table td::after {
  top: 0 !important;
  right: -5px !important;
  bottom: 0 !important;
  left: auto !important;
  width: 10px;
  cursor: col-resize;
  z-index: 1000;
  content: ' ';
  position: absolute;
}
.zs-data-grid[resizable] .zs-table thead td,
.zs-data-grid[resizable] .zs-table thead th {
  border-bottom-color: inherit;
}
.zs-data-grid[resizable][locked-columns][sticky-header] [locked-sticky-header-container] {
  border-right: 1px solid #716E79;
}
.zs-data-grid[resizable][locked-columns][sticky-header] [locked-sticky-header-container] .zs-table {
  border-right: none;
}
.zs-data-grid[resizable][locked-columns][sticky-header] [locked-sticky-header-container] .zs-table thead td:last-child {
  border-right: none;
}
.zs-data-grid[resizable][locked-columns][sticky-header] [sticky-header-container] .zs-table thead td:first-child {
  border-left: none;
}
.zs-pagination {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background: transparent;
  font-size: 1em;
  display: inline-block;
}
.zs-pagination a {
  display: inline-block;
  border: none;
  border-radius: 0;
  padding: 0.5em 0.75em 0.5em 0.75em;
  cursor: pointer;
  font-size: 0.875em;
  min-width: auto;
  vertical-align: middle;
  text-align: center;
  color: #454250;
}
.zs-pagination a:hover {
  border-color: transparent;
  text-decoration: underline;
}
.zs-pagination a:first-of-type {
  margin: 0em 0em 0em 0.75em;
}
.zs-pagination a:last-of-type {
  margin: 0em 0.75em 0em 0em;
}
.zs-pagination a:last-of-type + span[current] {
  margin: 0em 1.5em 0em 0em;
}
.zs-pagination a:visited {
  color: #454250;
}
.zs-pagination a:focus {
  color: inherit;
}
.zs-pagination a:hover {
  color: inherit;
}
.zs-pagination a:active {
  color: inherit;
}
.zs-pagination a[current],
.zs-pagination a[current]:visited {
  color: #1A1628;
  background: transparent;
  border: none;
  font-weight: 700;
  text-decoration: underline;
}
.zs-pagination a[current]:hover,
.zs-pagination a[current]:visited:hover {
  color: #1A1628;
}
.zs-pagination a:first-child {
  margin-left: 0;
}
.zs-pagination .zs-icon {
  border: 0px;
  cursor: pointer;
}
.zs-pagination .zs-fab {
  font-size: 0.875em;
}
.zs-pagination .zs-fab .zs-icon {
  font-size: 1.5em;
  pointer-events: none;
}
.zs-pagination .zs-fab + .zs-fab {
  margin: 0em 0em 0em 1em;
}
.zs-pagination .zs-fab + span[current] {
  margin-left: 1.5em;
}
.zs-pagination span[current] {
  vertical-align: middle;
  color: #1A1628;
  border-radius: 0;
  background: #F4F3F3;
  border: 1.5px solid #DEDCDE;
  padding: 0.5em 0em 0.5em 0em;
  margin: 0em 0.75em 0em 0.75em;
  position: relative;
  cursor: pointer;
  font-size: 0.875em;
}
.zs-pagination span[current] select {
  -moz-appearance: none;
  appearance: none;
  -webkit-appearance: none;
  background-color: transparent;
  border: 0;
  border-radius: 0;
  outline: 0;
  font-weight: normal;
  color: #1A1628;
  padding: 0em 2.5em 0em 1em;
  font-size: inherit;
  cursor: pointer;
  text-align: center;
  text-align-last: center;
}
.zs-pagination span[current] select:focus {
  color: #2F2C3C;
}
.zs-pagination span[current] select::-ms-expand {
  display: none;
}
.zs-pagination span[current]:first-child {
  margin-left: 0;
}
.zs-pagination span[current]:after {
  content: "\ea23";
  font-size: 1.5em;
  right: 0.5em;
  font-family: 'zsIcons';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Enable Ligatures ================ */
  -webkit-font-feature-settings: "liga";
  -moz-font-feature-settings: "liga=1";
  -moz-font-feature-settings: "liga";
  -ms-font-feature-settings: "liga" 1;
  -o-font-feature-settings: "liga";
  font-feature-settings: "liga";
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  transform: translateY(-50%);
  color: #1A1628;
  top: 50%;
  position: absolute;
  pointer-events: none;
}
.zs-pagination span[current] + a:first-of-type {
  margin-left: 0;
}
.zs-overlay {
  position: fixed;
  top: 0;
  border: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(2, 45, 66, 0.4) !important;
}
dialog {
  position: absolute;
  left: 0;
  right: 0;
  width: -moz-fit-content;
  width: -webkit-fit-content;
  width: fit-content;
  height: -moz-fit-content;
  height: -webkit-fit-content;
  height: fit-content;
  margin: auto;
  border: solid;
  padding: 1em;
  background: white;
  color: black;
  display: block;
}
dialog:not([open]) {
  display: none;
}
dialog + .backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.1);
}
._dialog_overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
dialog.fixed {
  position: fixed;
  top: 50%;
  transform: translate(0, -50%);
}
.zs-dialog,
dialog.zs-dialog,
zs-dialog.zs-dialog {
  background: #ffffff;
  border-radius: 0;
  border: none;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(26, 22, 40, 0.12), 0px 10px 20px rgba(26, 22, 40, 0.18);
  width: -webkit-fit-content;
  width: fit-content;
  width: -moz-fit-content;
  width: intrinsic;
  min-width: 384px;
  min-height: 152px;
  left: 50%;
  margin: 0;
  right: auto;
  transform: translateX(-50%);
}
.zs-dialog [close],
dialog.zs-dialog [close],
zs-dialog.zs-dialog [close] {
  position: absolute;
  top: 1em;
  right: 1em;
  min-width: 1em;
  min-height: 1em;
}
.zs-dialog [close]::before,
dialog.zs-dialog [close]::before,
zs-dialog.zs-dialog [close]::before {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.zs-dialog [close] ~ *,
dialog.zs-dialog [close] ~ *,
zs-dialog.zs-dialog [close] ~ * {
  margin-right: 1.5em;
}
.zs-dialog > header,
dialog.zs-dialog > header,
zs-dialog.zs-dialog > header,
.zs-dialog > form > header,
dialog.zs-dialog > form > header,
zs-dialog.zs-dialog > form > header {
  max-width: 80vw;
  padding: 1em 1em 1em 1em;
}
.zs-dialog > section,
dialog.zs-dialog > section,
zs-dialog.zs-dialog > section,
.zs-dialog > form > section,
dialog.zs-dialog > form > section,
zs-dialog.zs-dialog > form > section {
  max-width: 80vw;
  padding: 0em 1em 0em 1em;
}
.zs-dialog > footer,
dialog.zs-dialog > footer,
zs-dialog.zs-dialog > footer,
.zs-dialog > form > footer,
dialog.zs-dialog > form > footer,
zs-dialog.zs-dialog > form > footer {
  padding: 1em 1em 1em 1em;
  max-width: 80vw;
}
.backdrop[for="zs-dialog"]:not([class*="zs-bg-overlay-"]),
.zs-dialog ~ .backdrop:not([class*="zs-bg-overlay-"]) {
  background: rgba(2, 45, 66, 0.4) !important;
}
.zs-dialog::backdrop {
  background: rgba(2, 45, 66, 0.4) !important;
}
.zs-dialog:focus {
  outline: none;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(26, 22, 40, 0.12), 0px 10px 20px rgba(26, 22, 40, 0.18);
}
.zs-master-style.zs-overlay,
.zs-master-style .zs-overlay {
  background: rgba(2, 45, 66, 0.4) !important;
}
.zs-master-style.backdrop[for="zs-dialog"]:not([class*="zs-bg-overlay-"]),
.zs-master-style.zs-dialog ~ .backdrop:not([class*="zs-bg-overlay-"]),
.zs-master-style .backdrop[for="zs-dialog"]:not([class*="zs-bg-overlay-"]),
.zs-master-style .zs-dialog ~ .backdrop:not([class*="zs-bg-overlay-"]) {
  background: rgba(2, 45, 66, 0.4) !important;
}
.zs-master-style.zs-dialog::backdrop,
.zs-master-style .zs-dialog::backdrop {
  background: rgba(2, 45, 66, 0.4) !important;
}
.zs-accordion > [expandable-header] {
  cursor: pointer;
}
.zs-accordion > [expandable-body] {
  overflow: hidden;
}
.zs-accordion[animate] [expandable-body] {
  max-height: 0;
  transition: max-height 0.5s;
}
.zs-accordion[animate] [expandable-icon] {
  transition: transform 0.5s;
}
.zs-accordion[expanded] [expandable-header-text] {
  color: #1A1628;
}
.zs-accordion[expanded] [expandable-icon] {
  transform: rotate(-180deg);
}
.zs-card {
  background: #ffffff;
  border-radius: 0;
  border: 1px solid #DEDCDE;
  box-shadow: 2px 2px 0px rgba(26, 22, 40, 0.12);
  display: inline-block;
}
.zs-card:hover,
.zs-card:focus {
  outline: none;
  box-shadow: 4px 4px 0px rgba(26, 22, 40, 0.15);
  border: 1px solid #27A6A4;
}
.zs-card[active] {
  border: 1px solid #27A6A4;
}
.zs-card[flippable] {
  perspective: 800px;
  border: 0px;
  box-shadow: none;
}
.zs-card[flippable]:hover,
.zs-card[flippable]:focus {
  outline: none;
  box-shadow: none;
  border: 0px;
}
.zs-card[flippable][flipped] [flip-card-container] [back] {
  transform: rotateY(0deg);
}
.zs-card[flippable][flipped] [flip-card-container] [front] {
  transform: rotateY(180deg);
}
.zs-card[flippable] [flip-card-container] {
  width: 100%;
  transform-style: preserve-3d;
  position: relative;
}
.zs-card[flippable] [flip-card-container] [front],
.zs-card[flippable] [flip-card-container] [back] {
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  transform-style: preserve-3d;
  width: 100%;
  border-radius: 0;
  border: 1px solid #DEDCDE;
}
.zs-card[flippable] [flip-card-container] [front] {
  position: absolute;
  transform: rotateY(0deg);
  box-shadow: 2px 2px 0px rgba(26, 22, 40, 0.12);
}
.zs-card[flippable] [flip-card-container] [back] {
  transform: rotateY(-180deg);
  box-shadow: 2px 2px 0px rgba(26, 22, 40, 0.12);
}
[hidden] {
  display: none !important;
}
[disabled] [input][disabled] {
  opacity: 1 !important;
}
.zs-field-shadow {
  display: block;
}
.zs-field {
  display: block;
}
.zs-field > label {
  display: inline-block;
}
.zs-field [input],
.zs-field [textarea] {
  outline: none;
  font-weight: normal;
  color: #2F2C3C;
  font-size: 1em;
  border-radius: 0;
  padding: 1em 1em 1em 1em;
  /**
	  * @todo a little hack to fix ZSUI-358
	  */
  margin-bottom: 0.5px;
  display: block;
}
.zs-field [input][type="number"],
.zs-field [textarea][type="number"] {
  text-align: right;
}
.zs-field [input][type="checkbox"],
.zs-field [textarea][type="checkbox"],
.zs-field [input][type="radio"],
.zs-field [textarea][type="radio"],
.zs-field [input][type="button"],
.zs-field [textarea][type="button"] {
  border: none;
}
.zs-field [input]:focus,
.zs-field [textarea]:focus,
.zs-field [input] :invalid:focus,
.zs-field [textarea] :invalid:focus {
  outline: none;
  box-shadow: none;
}
.zs-field [label] {
  display: block;
  font-size: 0.75em;
  padding: 0em 0em 0.66666667em 0em;
  font-weight: 700;
  letter-spacing: -0.013em;
  line-height: 1.37;
  color: #1A1628;
}
.zs-field [field].zs-input-icon {
  display: block;
  position: relative;
  z-index: 1;
}
.zs-field [field].zs-input-icon textarea[input] {
  padding-right: 3em !important;
}
.zs-field [field].zs-input-icon [input] {
  padding-right: 3.5em !important;
}
.zs-field [field].zs-input-icon::before {
  font-family: 'zsIcons';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Enable Ligatures ================ */
  -webkit-font-feature-settings: "liga";
  -moz-font-feature-settings: "liga=1";
  -moz-font-feature-settings: "liga";
  -ms-font-feature-settings: "liga" 1;
  -o-font-feature-settings: "liga";
  font-feature-settings: "liga";
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  display: inline-block;
  pointer-events: none;
  top: 50%;
  font-size: 1.5em;
  width: auto;
  right: 0.66666667em;
  padding: 0;
  transform: translate(0, -50%);
  z-index: 1;
  text-align: center;
  background: transparent;
  position: absolute;
}
.zs-field [clear] {
  font-size: 1.5em;
  width: auto;
  right: 0.66666667em;
  padding: 0;
}
.zs-field [input]:not([size]):not([width]):not([type="image"]):not([type="checkbox"]):not([type="radio"]):not([type="submit"]):not([type="reset"]) {
  width: 100%;
}
.zs-field [field] {
  position: relative;
  display: block;
}
.zs-field textarea + [clear] {
  top: 0.66666667em;
  transform: none;
}
.zs-field [clear] {
  position: absolute;
  display: inline-block;
  top: 50%;
  transform: translate(0, -50%);
  border: 0;
  z-index: 2;
  text-align: center;
}
.zs-field [input][type="text"],
.zs-field [input][type="password"],
.zs-field [input][type="number"],
.zs-field [input][type="tel"],
.zs-field [input][type="url"],
.zs-field [input][type="email"],
.zs-field [input][type="search"],
.zs-field [input][type="time"],
.zs-field [input][type="week"],
.zs-field [input][type="date"] {
  height: 3.5em;
  line-height: 1.5;
  border: 1px solid #DEDCDE;
  background: #FAFAFA;
}
.zs-field [input][type="text"]::-webkit-input-placeholder,
.zs-field [input][type="password"]::-webkit-input-placeholder,
.zs-field [input][type="number"]::-webkit-input-placeholder,
.zs-field [input][type="tel"]::-webkit-input-placeholder,
.zs-field [input][type="url"]::-webkit-input-placeholder,
.zs-field [input][type="email"]::-webkit-input-placeholder,
.zs-field [input][type="search"]::-webkit-input-placeholder,
.zs-field [input][type="time"]::-webkit-input-placeholder,
.zs-field [input][type="week"]::-webkit-input-placeholder,
.zs-field [input][type="date"]::-webkit-input-placeholder {
  font-style: italic;
  color: #716E79;
}
.zs-field [input][type="text"]:-moz-placeholder,
.zs-field [input][type="password"]:-moz-placeholder,
.zs-field [input][type="number"]:-moz-placeholder,
.zs-field [input][type="tel"]:-moz-placeholder,
.zs-field [input][type="url"]:-moz-placeholder,
.zs-field [input][type="email"]:-moz-placeholder,
.zs-field [input][type="search"]:-moz-placeholder,
.zs-field [input][type="time"]:-moz-placeholder,
.zs-field [input][type="week"]:-moz-placeholder,
.zs-field [input][type="date"]:-moz-placeholder {
  /* Firefox 18- */
  font-style: italic;
  color: #716E79;
}
.zs-field [input][type="text"]::-moz-placeholder,
.zs-field [input][type="password"]::-moz-placeholder,
.zs-field [input][type="number"]::-moz-placeholder,
.zs-field [input][type="tel"]::-moz-placeholder,
.zs-field [input][type="url"]::-moz-placeholder,
.zs-field [input][type="email"]::-moz-placeholder,
.zs-field [input][type="search"]::-moz-placeholder,
.zs-field [input][type="time"]::-moz-placeholder,
.zs-field [input][type="week"]::-moz-placeholder,
.zs-field [input][type="date"]::-moz-placeholder {
  /* Firefox 19+ */
  font-style: italic;
  color: #716E79;
}
.zs-field [input][type="text"]:-ms-input-placeholder,
.zs-field [input][type="password"]:-ms-input-placeholder,
.zs-field [input][type="number"]:-ms-input-placeholder,
.zs-field [input][type="tel"]:-ms-input-placeholder,
.zs-field [input][type="url"]:-ms-input-placeholder,
.zs-field [input][type="email"]:-ms-input-placeholder,
.zs-field [input][type="search"]:-ms-input-placeholder,
.zs-field [input][type="time"]:-ms-input-placeholder,
.zs-field [input][type="week"]:-ms-input-placeholder,
.zs-field [input][type="date"]:-ms-input-placeholder {
  font-style: italic;
  color: #716E79;
}
.zs-field [input][type="text"]:focus,
.zs-field [input][type="password"]:focus,
.zs-field [input][type="number"]:focus,
.zs-field [input][type="tel"]:focus,
.zs-field [input][type="url"]:focus,
.zs-field [input][type="email"]:focus,
.zs-field [input][type="search"]:focus,
.zs-field [input][type="time"]:focus,
.zs-field [input][type="week"]:focus,
.zs-field [input][type="date"]:focus {
  border: 1px solid #27A6A4;
}
.zs-field [input][type="text"]:hover,
.zs-field [input][type="password"]:hover,
.zs-field [input][type="number"]:hover,
.zs-field [input][type="tel"]:hover,
.zs-field [input][type="url"]:hover,
.zs-field [input][type="email"]:hover,
.zs-field [input][type="search"]:hover,
.zs-field [input][type="time"]:hover,
.zs-field [input][type="week"]:hover,
.zs-field [input][type="date"]:hover {
  border: 1px solid #9C9AA1;
}
.zs-field [input][type="text"]:active,
.zs-field [input][type="password"]:active,
.zs-field [input][type="number"]:active,
.zs-field [input][type="tel"]:active,
.zs-field [input][type="url"]:active,
.zs-field [input][type="email"]:active,
.zs-field [input][type="search"]:active,
.zs-field [input][type="time"]:active,
.zs-field [input][type="week"]:active,
.zs-field [input][type="date"]:active {
  border: 1px solid #27A6A4;
}
.zs-field [input][type="text"]:focus,
.zs-field [input][type="password"]:focus,
.zs-field [input][type="number"]:focus,
.zs-field [input][type="tel"]:focus,
.zs-field [input][type="url"]:focus,
.zs-field [input][type="email"]:focus,
.zs-field [input][type="search"]:focus,
.zs-field [input][type="time"]:focus,
.zs-field [input][type="week"]:focus,
.zs-field [input][type="date"]:focus {
  background: #F4F3F3;
}
.zs-field [input][type="text"]:hover,
.zs-field [input][type="password"]:hover,
.zs-field [input][type="number"]:hover,
.zs-field [input][type="tel"]:hover,
.zs-field [input][type="url"]:hover,
.zs-field [input][type="email"]:hover,
.zs-field [input][type="search"]:hover,
.zs-field [input][type="time"]:hover,
.zs-field [input][type="week"]:hover,
.zs-field [input][type="date"]:hover {
  background: #FAFAFA;
}
.zs-field [input][type="text"]:active,
.zs-field [input][type="password"]:active,
.zs-field [input][type="number"]:active,
.zs-field [input][type="tel"]:active,
.zs-field [input][type="url"]:active,
.zs-field [input][type="email"]:active,
.zs-field [input][type="search"]:active,
.zs-field [input][type="time"]:active,
.zs-field [input][type="week"]:active,
.zs-field [input][type="date"]:active {
  background: #F4F3F3;
}
.zs-field [input][type="text"]:focus:hover,
.zs-field [input][type="password"]:focus:hover,
.zs-field [input][type="number"]:focus:hover,
.zs-field [input][type="tel"]:focus:hover,
.zs-field [input][type="url"]:focus:hover,
.zs-field [input][type="email"]:focus:hover,
.zs-field [input][type="search"]:focus:hover,
.zs-field [input][type="time"]:focus:hover,
.zs-field [input][type="week"]:focus:hover,
.zs-field [input][type="date"]:focus:hover {
  border: 1px solid #27A6A4;
  background: #F4F3F3;
}
.zs-field textarea[input] {
  font-family: "Open Sans", sans-serif;
  line-height: 1.5;
  font-size: 1em;
  border-radius: 0;
  padding: 1em 1em 1em 1em;
  letter-spacing: 0.013em;
  outline: none;
  border: 1px solid #DEDCDE;
  background: #FAFAFA;
}
.zs-field textarea[input]::selection {
  background-color: #FFD680;
}
.zs-field textarea[input]::-moz-selection {
  background-color: #FFD680;
}
.zs-field textarea[input]::-webkit-input-placeholder {
  font-style: italic;
  color: #716E79;
}
.zs-field textarea[input]:-moz-placeholder {
  /* Firefox 18- */
  font-style: italic;
  color: #716E79;
}
.zs-field textarea[input]::-moz-placeholder {
  /* Firefox 19+ */
  font-style: italic;
  color: #716E79;
}
.zs-field textarea[input]:-ms-input-placeholder {
  font-style: italic;
  color: #716E79;
}
.zs-field textarea[input]:focus {
  border: 1px solid #27A6A4;
}
.zs-field textarea[input]:hover {
  border: 1px solid #9C9AA1;
}
.zs-field textarea[input]:active {
  border: 1px solid #27A6A4;
}
.zs-field textarea[input]:focus {
  background: #F4F3F3;
}
.zs-field textarea[input]:hover {
  background: #FAFAFA;
}
.zs-field textarea[input]:active {
  background: #F4F3F3;
}
.zs-field textarea[input]:focus:hover {
  border: 1px solid #27A6A4;
  background: #F4F3F3;
}
.zs-field.zs-error [input][type="text"],
.zs-field.zs-danger [input][type="text"],
.zs-field[invalid] [input][type="text"],
.zs-field.zs-error [input][type="password"],
.zs-field.zs-danger [input][type="password"],
.zs-field[invalid] [input][type="password"],
.zs-field.zs-error [input][type="number"],
.zs-field.zs-danger [input][type="number"],
.zs-field[invalid] [input][type="number"],
.zs-field.zs-error [input][type="tel"],
.zs-field.zs-danger [input][type="tel"],
.zs-field[invalid] [input][type="tel"],
.zs-field.zs-error [input][type="url"],
.zs-field.zs-danger [input][type="url"],
.zs-field[invalid] [input][type="url"],
.zs-field.zs-error [input][type="email"],
.zs-field.zs-danger [input][type="email"],
.zs-field[invalid] [input][type="email"],
.zs-field.zs-error [input][type="search"],
.zs-field.zs-danger [input][type="search"],
.zs-field[invalid] [input][type="search"],
.zs-field.zs-error [input][type="time"],
.zs-field.zs-danger [input][type="time"],
.zs-field[invalid] [input][type="time"],
.zs-field.zs-error [input][type="week"],
.zs-field.zs-danger [input][type="week"],
.zs-field[invalid] [input][type="week"],
.zs-field.zs-error [input][type="date"],
.zs-field.zs-danger [input][type="date"],
.zs-field[invalid] [input][type="date"] {
  border: 1px solid #B21111;
}
.zs-field.zs-error [input][type="text"]:focus,
.zs-field.zs-danger [input][type="text"]:focus,
.zs-field[invalid] [input][type="text"]:focus,
.zs-field.zs-error [input][type="password"]:focus,
.zs-field.zs-danger [input][type="password"]:focus,
.zs-field[invalid] [input][type="password"]:focus,
.zs-field.zs-error [input][type="number"]:focus,
.zs-field.zs-danger [input][type="number"]:focus,
.zs-field[invalid] [input][type="number"]:focus,
.zs-field.zs-error [input][type="tel"]:focus,
.zs-field.zs-danger [input][type="tel"]:focus,
.zs-field[invalid] [input][type="tel"]:focus,
.zs-field.zs-error [input][type="url"]:focus,
.zs-field.zs-danger [input][type="url"]:focus,
.zs-field[invalid] [input][type="url"]:focus,
.zs-field.zs-error [input][type="email"]:focus,
.zs-field.zs-danger [input][type="email"]:focus,
.zs-field[invalid] [input][type="email"]:focus,
.zs-field.zs-error [input][type="search"]:focus,
.zs-field.zs-danger [input][type="search"]:focus,
.zs-field[invalid] [input][type="search"]:focus,
.zs-field.zs-error [input][type="time"]:focus,
.zs-field.zs-danger [input][type="time"]:focus,
.zs-field[invalid] [input][type="time"]:focus,
.zs-field.zs-error [input][type="week"]:focus,
.zs-field.zs-danger [input][type="week"]:focus,
.zs-field[invalid] [input][type="week"]:focus,
.zs-field.zs-error [input][type="date"]:focus,
.zs-field.zs-danger [input][type="date"]:focus,
.zs-field[invalid] [input][type="date"]:focus {
  border: 1px solid #892208;
}
.zs-field.zs-error [input][type="text"]:hover,
.zs-field.zs-danger [input][type="text"]:hover,
.zs-field[invalid] [input][type="text"]:hover,
.zs-field.zs-error [input][type="password"]:hover,
.zs-field.zs-danger [input][type="password"]:hover,
.zs-field[invalid] [input][type="password"]:hover,
.zs-field.zs-error [input][type="number"]:hover,
.zs-field.zs-danger [input][type="number"]:hover,
.zs-field[invalid] [input][type="number"]:hover,
.zs-field.zs-error [input][type="tel"]:hover,
.zs-field.zs-danger [input][type="tel"]:hover,
.zs-field[invalid] [input][type="tel"]:hover,
.zs-field.zs-error [input][type="url"]:hover,
.zs-field.zs-danger [input][type="url"]:hover,
.zs-field[invalid] [input][type="url"]:hover,
.zs-field.zs-error [input][type="email"]:hover,
.zs-field.zs-danger [input][type="email"]:hover,
.zs-field[invalid] [input][type="email"]:hover,
.zs-field.zs-error [input][type="search"]:hover,
.zs-field.zs-danger [input][type="search"]:hover,
.zs-field[invalid] [input][type="search"]:hover,
.zs-field.zs-error [input][type="time"]:hover,
.zs-field.zs-danger [input][type="time"]:hover,
.zs-field[invalid] [input][type="time"]:hover,
.zs-field.zs-error [input][type="week"]:hover,
.zs-field.zs-danger [input][type="week"]:hover,
.zs-field[invalid] [input][type="week"]:hover,
.zs-field.zs-error [input][type="date"]:hover,
.zs-field.zs-danger [input][type="date"]:hover,
.zs-field[invalid] [input][type="date"]:hover {
  border: 1px solid #F27755;
}
.zs-field.zs-error [input][type="text"]:active,
.zs-field.zs-danger [input][type="text"]:active,
.zs-field[invalid] [input][type="text"]:active,
.zs-field.zs-error [input][type="password"]:active,
.zs-field.zs-danger [input][type="password"]:active,
.zs-field[invalid] [input][type="password"]:active,
.zs-field.zs-error [input][type="number"]:active,
.zs-field.zs-danger [input][type="number"]:active,
.zs-field[invalid] [input][type="number"]:active,
.zs-field.zs-error [input][type="tel"]:active,
.zs-field.zs-danger [input][type="tel"]:active,
.zs-field[invalid] [input][type="tel"]:active,
.zs-field.zs-error [input][type="url"]:active,
.zs-field.zs-danger [input][type="url"]:active,
.zs-field[invalid] [input][type="url"]:active,
.zs-field.zs-error [input][type="email"]:active,
.zs-field.zs-danger [input][type="email"]:active,
.zs-field[invalid] [input][type="email"]:active,
.zs-field.zs-error [input][type="search"]:active,
.zs-field.zs-danger [input][type="search"]:active,
.zs-field[invalid] [input][type="search"]:active,
.zs-field.zs-error [input][type="time"]:active,
.zs-field.zs-danger [input][type="time"]:active,
.zs-field[invalid] [input][type="time"]:active,
.zs-field.zs-error [input][type="week"]:active,
.zs-field.zs-danger [input][type="week"]:active,
.zs-field[invalid] [input][type="week"]:active,
.zs-field.zs-error [input][type="date"]:active,
.zs-field.zs-danger [input][type="date"]:active,
.zs-field[invalid] [input][type="date"]:active {
  border: 1px solid #5C1A0B;
}
.zs-field.zs-error textarea,
.zs-field.zs-danger textarea,
.zs-field[invalid] textarea {
  border: 1px solid #B21111;
}
.zs-field.zs-error textarea:focus,
.zs-field.zs-danger textarea:focus,
.zs-field[invalid] textarea:focus {
  border: 1px solid #892208;
}
.zs-field.zs-error textarea:hover,
.zs-field.zs-danger textarea:hover,
.zs-field[invalid] textarea:hover {
  border: 1px solid #F27755;
}
.zs-field.zs-error textarea:active,
.zs-field.zs-danger textarea:active,
.zs-field[invalid] textarea:active {
  border: 1px solid #5C1A0B;
}
.zs-field.zs-error [clear],
.zs-field.zs-danger [clear],
.zs-field[invalid] [clear] {
  color: #B21111;
}
.zs-field.zs-error [clear]:visited,
.zs-field.zs-danger [clear]:visited,
.zs-field[invalid] [clear]:visited {
  color: #B21111;
}
.zs-field.zs-error [clear]:focus,
.zs-field.zs-danger [clear]:focus,
.zs-field[invalid] [clear]:focus {
  color: #892208;
}
.zs-field.zs-error [clear]:hover,
.zs-field.zs-danger [clear]:hover,
.zs-field[invalid] [clear]:hover {
  color: #F27755;
}
.zs-field.zs-error [clear]:active,
.zs-field.zs-danger [clear]:active,
.zs-field[invalid] [clear]:active {
  color: #5C1A0B;
}
.zs-field.zs-success [input][type="text"],
.zs-field.zs-success [input][type="password"],
.zs-field.zs-success [input][type="number"],
.zs-field.zs-success [input][type="tel"],
.zs-field.zs-success [input][type="url"],
.zs-field.zs-success [input][type="email"],
.zs-field.zs-success [input][type="search"],
.zs-field.zs-success [input][type="time"],
.zs-field.zs-success [input][type="week"],
.zs-field.zs-success [input][type="date"] {
  border: 1px solid #00AA67;
}
.zs-field.zs-success [input][type="text"]:focus,
.zs-field.zs-success [input][type="password"]:focus,
.zs-field.zs-success [input][type="number"]:focus,
.zs-field.zs-success [input][type="tel"]:focus,
.zs-field.zs-success [input][type="url"]:focus,
.zs-field.zs-success [input][type="email"]:focus,
.zs-field.zs-success [input][type="search"]:focus,
.zs-field.zs-success [input][type="time"]:focus,
.zs-field.zs-success [input][type="week"]:focus,
.zs-field.zs-success [input][type="date"]:focus {
  border: 1px solid #0A6E5E;
}
.zs-field.zs-success [input][type="text"]:hover,
.zs-field.zs-success [input][type="password"]:hover,
.zs-field.zs-success [input][type="number"]:hover,
.zs-field.zs-success [input][type="tel"]:hover,
.zs-field.zs-success [input][type="url"]:hover,
.zs-field.zs-success [input][type="email"]:hover,
.zs-field.zs-success [input][type="search"]:hover,
.zs-field.zs-success [input][type="time"]:hover,
.zs-field.zs-success [input][type="week"]:hover,
.zs-field.zs-success [input][type="date"]:hover {
  border: 1px solid #54D7AF;
}
.zs-field.zs-success [input][type="text"]:active,
.zs-field.zs-success [input][type="password"]:active,
.zs-field.zs-success [input][type="number"]:active,
.zs-field.zs-success [input][type="tel"]:active,
.zs-field.zs-success [input][type="url"]:active,
.zs-field.zs-success [input][type="email"]:active,
.zs-field.zs-success [input][type="search"]:active,
.zs-field.zs-success [input][type="time"]:active,
.zs-field.zs-success [input][type="week"]:active,
.zs-field.zs-success [input][type="date"]:active {
  border: 1px solid #0C4846;
}
.zs-field.zs-success textarea {
  border: 1px solid #00AA67;
}
.zs-field.zs-success textarea:focus {
  border: 1px solid #0A6E5E;
}
.zs-field.zs-success textarea:hover {
  border: 1px solid #54D7AF;
}
.zs-field.zs-success textarea:active {
  border: 1px solid #0C4846;
}
.zs-field.zs-success [clear] {
  color: #00AA67;
}
.zs-field.zs-success [clear]:visited {
  color: #00AA67;
}
.zs-field.zs-success [clear]:focus {
  color: #0A6E5E;
}
.zs-field.zs-success [clear]:hover {
  color: #54D7AF;
}
.zs-field.zs-success [clear]:active {
  color: #0C4846;
}
.zs-field.zs-warning [input][type="text"],
.zs-field.zs-warning [input][type="password"],
.zs-field.zs-warning [input][type="number"],
.zs-field.zs-warning [input][type="tel"],
.zs-field.zs-warning [input][type="url"],
.zs-field.zs-warning [input][type="email"],
.zs-field.zs-warning [input][type="search"],
.zs-field.zs-warning [input][type="time"],
.zs-field.zs-warning [input][type="week"],
.zs-field.zs-warning [input][type="date"] {
  border: 1px solid #E3A900;
}
.zs-field.zs-warning [input][type="text"]:focus,
.zs-field.zs-warning [input][type="password"]:focus,
.zs-field.zs-warning [input][type="number"]:focus,
.zs-field.zs-warning [input][type="tel"]:focus,
.zs-field.zs-warning [input][type="url"]:focus,
.zs-field.zs-warning [input][type="email"]:focus,
.zs-field.zs-warning [input][type="search"]:focus,
.zs-field.zs-warning [input][type="time"]:focus,
.zs-field.zs-warning [input][type="week"]:focus,
.zs-field.zs-warning [input][type="date"]:focus {
  border: 1px solid #8A640C;
}
.zs-field.zs-warning [input][type="text"]:hover,
.zs-field.zs-warning [input][type="password"]:hover,
.zs-field.zs-warning [input][type="number"]:hover,
.zs-field.zs-warning [input][type="tel"]:hover,
.zs-field.zs-warning [input][type="url"]:hover,
.zs-field.zs-warning [input][type="email"]:hover,
.zs-field.zs-warning [input][type="search"]:hover,
.zs-field.zs-warning [input][type="time"]:hover,
.zs-field.zs-warning [input][type="week"]:hover,
.zs-field.zs-warning [input][type="date"]:hover {
  border: 1px solid #FDCA63;
}
.zs-field.zs-warning [input][type="text"]:active,
.zs-field.zs-warning [input][type="password"]:active,
.zs-field.zs-warning [input][type="number"]:active,
.zs-field.zs-warning [input][type="tel"]:active,
.zs-field.zs-warning [input][type="url"]:active,
.zs-field.zs-warning [input][type="email"]:active,
.zs-field.zs-warning [input][type="search"]:active,
.zs-field.zs-warning [input][type="time"]:active,
.zs-field.zs-warning [input][type="week"]:active,
.zs-field.zs-warning [input][type="date"]:active {
  border: 1px solid #563F0E;
}
.zs-field.zs-warning textarea {
  border: 1px solid #E3A900;
}
.zs-field.zs-warning textarea:focus {
  border: 1px solid #8A640C;
}
.zs-field.zs-warning textarea:hover {
  border: 1px solid #FDCA63;
}
.zs-field.zs-warning textarea:active {
  border: 1px solid #563F0E;
}
.zs-field.zs-warning [clear] {
  color: #E3A900;
}
.zs-field.zs-warning [clear]:visited {
  color: #E3A900;
}
.zs-field.zs-warning [clear]:focus {
  color: #8A640C;
}
.zs-field.zs-warning [clear]:hover {
  color: #FDCA63;
}
.zs-field.zs-warning [clear]:active {
  color: #563F0E;
}
.zs-field.zs-info [input][type="text"],
.zs-field.zs-info [input][type="password"],
.zs-field.zs-info [input][type="number"],
.zs-field.zs-info [input][type="tel"],
.zs-field.zs-info [input][type="url"],
.zs-field.zs-info [input][type="email"],
.zs-field.zs-info [input][type="search"],
.zs-field.zs-info [input][type="time"],
.zs-field.zs-info [input][type="week"],
.zs-field.zs-info [input][type="date"] {
  border: 1px solid #2B44C7;
}
.zs-field.zs-info [input][type="text"]:focus,
.zs-field.zs-info [input][type="password"]:focus,
.zs-field.zs-info [input][type="number"]:focus,
.zs-field.zs-info [input][type="tel"]:focus,
.zs-field.zs-info [input][type="url"]:focus,
.zs-field.zs-info [input][type="email"]:focus,
.zs-field.zs-info [input][type="search"]:focus,
.zs-field.zs-info [input][type="time"]:focus,
.zs-field.zs-info [input][type="week"]:focus,
.zs-field.zs-info [input][type="date"]:focus {
  border: 1px solid #141187;
}
.zs-field.zs-info [input][type="text"]:hover,
.zs-field.zs-info [input][type="password"]:hover,
.zs-field.zs-info [input][type="number"]:hover,
.zs-field.zs-info [input][type="tel"]:hover,
.zs-field.zs-info [input][type="url"]:hover,
.zs-field.zs-info [input][type="email"]:hover,
.zs-field.zs-info [input][type="search"]:hover,
.zs-field.zs-info [input][type="time"]:hover,
.zs-field.zs-info [input][type="week"]:hover,
.zs-field.zs-info [input][type="date"]:hover {
  border: 1px solid #7E92E9;
}
.zs-field.zs-info [input][type="text"]:active,
.zs-field.zs-info [input][type="password"]:active,
.zs-field.zs-info [input][type="number"]:active,
.zs-field.zs-info [input][type="tel"]:active,
.zs-field.zs-info [input][type="url"]:active,
.zs-field.zs-info [input][type="email"]:active,
.zs-field.zs-info [input][type="search"]:active,
.zs-field.zs-info [input][type="time"]:active,
.zs-field.zs-info [input][type="week"]:active,
.zs-field.zs-info [input][type="date"]:active {
  border: 1px solid #160C60;
}
.zs-field.zs-info textarea {
  border: 1px solid #2B44C7;
}
.zs-field.zs-info textarea:focus {
  border: 1px solid #141187;
}
.zs-field.zs-info textarea:hover {
  border: 1px solid #7E92E9;
}
.zs-field.zs-info textarea:active {
  border: 1px solid #160C60;
}
.zs-field.zs-info [clear] {
  color: #2B44C7;
}
.zs-field.zs-info [clear]:visited {
  color: #2B44C7;
}
.zs-field.zs-info [clear]:focus {
  color: #141187;
}
.zs-field.zs-info [clear]:hover {
  color: #7E92E9;
}
.zs-field.zs-info [clear]:active {
  color: #160C60;
}
.zs-field[type="radio"] [label],
.zs-field[type="checkbox"] [label] {
  padding: 0;
  font-size: 1em;
  line-height: 1.5;
  letter-spacing: -0.013em;
}
.zs-field[disabled] [input][type="text"],
.zs-field.zs-disabled [input][type="text"],
.zs-field[disabled] [input][type="password"],
.zs-field.zs-disabled [input][type="password"],
.zs-field[disabled] [input][type="number"],
.zs-field.zs-disabled [input][type="number"],
.zs-field[disabled] [input][type="tel"],
.zs-field.zs-disabled [input][type="tel"],
.zs-field[disabled] [input][type="url"],
.zs-field.zs-disabled [input][type="url"],
.zs-field[disabled] [input][type="email"],
.zs-field.zs-disabled [input][type="email"],
.zs-field[disabled] [input][type="search"],
.zs-field.zs-disabled [input][type="search"],
.zs-field[disabled] [input][type="time"],
.zs-field.zs-disabled [input][type="time"],
.zs-field[disabled] [input][type="week"],
.zs-field.zs-disabled [input][type="week"],
.zs-field[disabled] [input][type="date"],
.zs-field.zs-disabled [input][type="date"],
.zs-field.zs-calendar > table td[disabled] [input][type="text"],
.zs-field.zs-calendar > table td[disabled] [input][type="password"],
.zs-field.zs-calendar > table td[disabled] [input][type="number"],
.zs-field.zs-calendar > table td[disabled] [input][type="tel"],
.zs-field.zs-calendar > table td[disabled] [input][type="url"],
.zs-field.zs-calendar > table td[disabled] [input][type="email"],
.zs-field.zs-calendar > table td[disabled] [input][type="search"],
.zs-field.zs-calendar > table td[disabled] [input][type="time"],
.zs-field.zs-calendar > table td[disabled] [input][type="week"],
.zs-field.zs-calendar > table td[disabled] [input][type="date"],
.zs-field.zs-range-calendar > table td[disabled] [input][type="text"],
.zs-field.zs-range-calendar > table td[disabled] [input][type="password"],
.zs-field.zs-range-calendar > table td[disabled] [input][type="number"],
.zs-field.zs-range-calendar > table td[disabled] [input][type="tel"],
.zs-field.zs-range-calendar > table td[disabled] [input][type="url"],
.zs-field.zs-range-calendar > table td[disabled] [input][type="email"],
.zs-field.zs-range-calendar > table td[disabled] [input][type="search"],
.zs-field.zs-range-calendar > table td[disabled] [input][type="time"],
.zs-field.zs-range-calendar > table td[disabled] [input][type="week"],
.zs-field.zs-range-calendar > table td[disabled] [input][type="date"],
.zs-field.zs-range-calendar > section > table td[disabled] [input][type="text"],
.zs-field.zs-range-calendar > section > table td[disabled] [input][type="password"],
.zs-field.zs-range-calendar > section > table td[disabled] [input][type="number"],
.zs-field.zs-range-calendar > section > table td[disabled] [input][type="tel"],
.zs-field.zs-range-calendar > section > table td[disabled] [input][type="url"],
.zs-field.zs-range-calendar > section > table td[disabled] [input][type="email"],
.zs-field.zs-range-calendar > section > table td[disabled] [input][type="search"],
.zs-field.zs-range-calendar > section > table td[disabled] [input][type="time"],
.zs-field.zs-range-calendar > section > table td[disabled] [input][type="week"],
.zs-field.zs-range-calendar > section > table td[disabled] [input][type="date"] {
  border: 1px solid #B2B0B6;
  color: #B2B0B6;
  background: none;
  background-color: #F4F3F3;
}
.zs-field[disabled] [input][type="text"]::-webkit-input-placeholder,
.zs-field.zs-disabled [input][type="text"]::-webkit-input-placeholder,
.zs-field[disabled] [input][type="password"]::-webkit-input-placeholder,
.zs-field.zs-disabled [input][type="password"]::-webkit-input-placeholder,
.zs-field[disabled] [input][type="number"]::-webkit-input-placeholder,
.zs-field.zs-disabled [input][type="number"]::-webkit-input-placeholder,
.zs-field[disabled] [input][type="tel"]::-webkit-input-placeholder,
.zs-field.zs-disabled [input][type="tel"]::-webkit-input-placeholder,
.zs-field[disabled] [input][type="url"]::-webkit-input-placeholder,
.zs-field.zs-disabled [input][type="url"]::-webkit-input-placeholder,
.zs-field[disabled] [input][type="email"]::-webkit-input-placeholder,
.zs-field.zs-disabled [input][type="email"]::-webkit-input-placeholder,
.zs-field[disabled] [input][type="search"]::-webkit-input-placeholder,
.zs-field.zs-disabled [input][type="search"]::-webkit-input-placeholder,
.zs-field[disabled] [input][type="time"]::-webkit-input-placeholder,
.zs-field.zs-disabled [input][type="time"]::-webkit-input-placeholder,
.zs-field[disabled] [input][type="week"]::-webkit-input-placeholder,
.zs-field.zs-disabled [input][type="week"]::-webkit-input-placeholder,
.zs-field[disabled] [input][type="date"]::-webkit-input-placeholder,
.zs-field.zs-disabled [input][type="date"]::-webkit-input-placeholder,
.zs-field.zs-calendar > table td[disabled] [input][type="text"]::-webkit-input-placeholder,
.zs-field.zs-calendar > table td[disabled] [input][type="password"]::-webkit-input-placeholder,
.zs-field.zs-calendar > table td[disabled] [input][type="number"]::-webkit-input-placeholder,
.zs-field.zs-calendar > table td[disabled] [input][type="tel"]::-webkit-input-placeholder,
.zs-field.zs-calendar > table td[disabled] [input][type="url"]::-webkit-input-placeholder,
.zs-field.zs-calendar > table td[disabled] [input][type="email"]::-webkit-input-placeholder,
.zs-field.zs-calendar > table td[disabled] [input][type="search"]::-webkit-input-placeholder,
.zs-field.zs-calendar > table td[disabled] [input][type="time"]::-webkit-input-placeholder,
.zs-field.zs-calendar > table td[disabled] [input][type="week"]::-webkit-input-placeholder,
.zs-field.zs-calendar > table td[disabled] [input][type="date"]::-webkit-input-placeholder,
.zs-field.zs-range-calendar > table td[disabled] [input][type="text"]::-webkit-input-placeholder,
.zs-field.zs-range-calendar > table td[disabled] [input][type="password"]::-webkit-input-placeholder,
.zs-field.zs-range-calendar > table td[disabled] [input][type="number"]::-webkit-input-placeholder,
.zs-field.zs-range-calendar > table td[disabled] [input][type="tel"]::-webkit-input-placeholder,
.zs-field.zs-range-calendar > table td[disabled] [input][type="url"]::-webkit-input-placeholder,
.zs-field.zs-range-calendar > table td[disabled] [input][type="email"]::-webkit-input-placeholder,
.zs-field.zs-range-calendar > table td[disabled] [input][type="search"]::-webkit-input-placeholder,
.zs-field.zs-range-calendar > table td[disabled] [input][type="time"]::-webkit-input-placeholder,
.zs-field.zs-range-calendar > table td[disabled] [input][type="week"]::-webkit-input-placeholder,
.zs-field.zs-range-calendar > table td[disabled] [input][type="date"]::-webkit-input-placeholder,
.zs-field.zs-range-calendar > section > table td[disabled] [input][type="text"]::-webkit-input-placeholder,
.zs-field.zs-range-calendar > section > table td[disabled] [input][type="password"]::-webkit-input-placeholder,
.zs-field.zs-range-calendar > section > table td[disabled] [input][type="number"]::-webkit-input-placeholder,
.zs-field.zs-range-calendar > section > table td[disabled] [input][type="tel"]::-webkit-input-placeholder,
.zs-field.zs-range-calendar > section > table td[disabled] [input][type="url"]::-webkit-input-placeholder,
.zs-field.zs-range-calendar > section > table td[disabled] [input][type="email"]::-webkit-input-placeholder,
.zs-field.zs-range-calendar > section > table td[disabled] [input][type="search"]::-webkit-input-placeholder,
.zs-field.zs-range-calendar > section > table td[disabled] [input][type="time"]::-webkit-input-placeholder,
.zs-field.zs-range-calendar > section > table td[disabled] [input][type="week"]::-webkit-input-placeholder,
.zs-field.zs-range-calendar > section > table td[disabled] [input][type="date"]::-webkit-input-placeholder {
  color: #B2B0B6;
}
.zs-field[disabled] [input][type="text"]:-moz-placeholder,
.zs-field.zs-disabled [input][type="text"]:-moz-placeholder,
.zs-field[disabled] [input][type="password"]:-moz-placeholder,
.zs-field.zs-disabled [input][type="password"]:-moz-placeholder,
.zs-field[disabled] [input][type="number"]:-moz-placeholder,
.zs-field.zs-disabled [input][type="number"]:-moz-placeholder,
.zs-field[disabled] [input][type="tel"]:-moz-placeholder,
.zs-field.zs-disabled [input][type="tel"]:-moz-placeholder,
.zs-field[disabled] [input][type="url"]:-moz-placeholder,
.zs-field.zs-disabled [input][type="url"]:-moz-placeholder,
.zs-field[disabled] [input][type="email"]:-moz-placeholder,
.zs-field.zs-disabled [input][type="email"]:-moz-placeholder,
.zs-field[disabled] [input][type="search"]:-moz-placeholder,
.zs-field.zs-disabled [input][type="search"]:-moz-placeholder,
.zs-field[disabled] [input][type="time"]:-moz-placeholder,
.zs-field.zs-disabled [input][type="time"]:-moz-placeholder,
.zs-field[disabled] [input][type="week"]:-moz-placeholder,
.zs-field.zs-disabled [input][type="week"]:-moz-placeholder,
.zs-field[disabled] [input][type="date"]:-moz-placeholder,
.zs-field.zs-disabled [input][type="date"]:-moz-placeholder,
.zs-field.zs-calendar > table td[disabled] [input][type="text"]:-moz-placeholder,
.zs-field.zs-calendar > table td[disabled] [input][type="password"]:-moz-placeholder,
.zs-field.zs-calendar > table td[disabled] [input][type="number"]:-moz-placeholder,
.zs-field.zs-calendar > table td[disabled] [input][type="tel"]:-moz-placeholder,
.zs-field.zs-calendar > table td[disabled] [input][type="url"]:-moz-placeholder,
.zs-field.zs-calendar > table td[disabled] [input][type="email"]:-moz-placeholder,
.zs-field.zs-calendar > table td[disabled] [input][type="search"]:-moz-placeholder,
.zs-field.zs-calendar > table td[disabled] [input][type="time"]:-moz-placeholder,
.zs-field.zs-calendar > table td[disabled] [input][type="week"]:-moz-placeholder,
.zs-field.zs-calendar > table td[disabled] [input][type="date"]:-moz-placeholder,
.zs-field.zs-range-calendar > table td[disabled] [input][type="text"]:-moz-placeholder,
.zs-field.zs-range-calendar > table td[disabled] [input][type="password"]:-moz-placeholder,
.zs-field.zs-range-calendar > table td[disabled] [input][type="number"]:-moz-placeholder,
.zs-field.zs-range-calendar > table td[disabled] [input][type="tel"]:-moz-placeholder,
.zs-field.zs-range-calendar > table td[disabled] [input][type="url"]:-moz-placeholder,
.zs-field.zs-range-calendar > table td[disabled] [input][type="email"]:-moz-placeholder,
.zs-field.zs-range-calendar > table td[disabled] [input][type="search"]:-moz-placeholder,
.zs-field.zs-range-calendar > table td[disabled] [input][type="time"]:-moz-placeholder,
.zs-field.zs-range-calendar > table td[disabled] [input][type="week"]:-moz-placeholder,
.zs-field.zs-range-calendar > table td[disabled] [input][type="date"]:-moz-placeholder,
.zs-field.zs-range-calendar > section > table td[disabled] [input][type="text"]:-moz-placeholder,
.zs-field.zs-range-calendar > section > table td[disabled] [input][type="password"]:-moz-placeholder,
.zs-field.zs-range-calendar > section > table td[disabled] [input][type="number"]:-moz-placeholder,
.zs-field.zs-range-calendar > section > table td[disabled] [input][type="tel"]:-moz-placeholder,
.zs-field.zs-range-calendar > section > table td[disabled] [input][type="url"]:-moz-placeholder,
.zs-field.zs-range-calendar > section > table td[disabled] [input][type="email"]:-moz-placeholder,
.zs-field.zs-range-calendar > section > table td[disabled] [input][type="search"]:-moz-placeholder,
.zs-field.zs-range-calendar > section > table td[disabled] [input][type="time"]:-moz-placeholder,
.zs-field.zs-range-calendar > section > table td[disabled] [input][type="week"]:-moz-placeholder,
.zs-field.zs-range-calendar > section > table td[disabled] [input][type="date"]:-moz-placeholder {
  /* Firefox 18- */
  color: #B2B0B6;
}
.zs-field[disabled] [input][type="text"]::-moz-placeholder,
.zs-field.zs-disabled [input][type="text"]::-moz-placeholder,
.zs-field[disabled] [input][type="password"]::-moz-placeholder,
.zs-field.zs-disabled [input][type="password"]::-moz-placeholder,
.zs-field[disabled] [input][type="number"]::-moz-placeholder,
.zs-field.zs-disabled [input][type="number"]::-moz-placeholder,
.zs-field[disabled] [input][type="tel"]::-moz-placeholder,
.zs-field.zs-disabled [input][type="tel"]::-moz-placeholder,
.zs-field[disabled] [input][type="url"]::-moz-placeholder,
.zs-field.zs-disabled [input][type="url"]::-moz-placeholder,
.zs-field[disabled] [input][type="email"]::-moz-placeholder,
.zs-field.zs-disabled [input][type="email"]::-moz-placeholder,
.zs-field[disabled] [input][type="search"]::-moz-placeholder,
.zs-field.zs-disabled [input][type="search"]::-moz-placeholder,
.zs-field[disabled] [input][type="time"]::-moz-placeholder,
.zs-field.zs-disabled [input][type="time"]::-moz-placeholder,
.zs-field[disabled] [input][type="week"]::-moz-placeholder,
.zs-field.zs-disabled [input][type="week"]::-moz-placeholder,
.zs-field[disabled] [input][type="date"]::-moz-placeholder,
.zs-field.zs-disabled [input][type="date"]::-moz-placeholder,
.zs-field.zs-calendar > table td[disabled] [input][type="text"]::-moz-placeholder,
.zs-field.zs-calendar > table td[disabled] [input][type="password"]::-moz-placeholder,
.zs-field.zs-calendar > table td[disabled] [input][type="number"]::-moz-placeholder,
.zs-field.zs-calendar > table td[disabled] [input][type="tel"]::-moz-placeholder,
.zs-field.zs-calendar > table td[disabled] [input][type="url"]::-moz-placeholder,
.zs-field.zs-calendar > table td[disabled] [input][type="email"]::-moz-placeholder,
.zs-field.zs-calendar > table td[disabled] [input][type="search"]::-moz-placeholder,
.zs-field.zs-calendar > table td[disabled] [input][type="time"]::-moz-placeholder,
.zs-field.zs-calendar > table td[disabled] [input][type="week"]::-moz-placeholder,
.zs-field.zs-calendar > table td[disabled] [input][type="date"]::-moz-placeholder,
.zs-field.zs-range-calendar > table td[disabled] [input][type="text"]::-moz-placeholder,
.zs-field.zs-range-calendar > table td[disabled] [input][type="password"]::-moz-placeholder,
.zs-field.zs-range-calendar > table td[disabled] [input][type="number"]::-moz-placeholder,
.zs-field.zs-range-calendar > table td[disabled] [input][type="tel"]::-moz-placeholder,
.zs-field.zs-range-calendar > table td[disabled] [input][type="url"]::-moz-placeholder,
.zs-field.zs-range-calendar > table td[disabled] [input][type="email"]::-moz-placeholder,
.zs-field.zs-range-calendar > table td[disabled] [input][type="search"]::-moz-placeholder,
.zs-field.zs-range-calendar > table td[disabled] [input][type="time"]::-moz-placeholder,
.zs-field.zs-range-calendar > table td[disabled] [input][type="week"]::-moz-placeholder,
.zs-field.zs-range-calendar > table td[disabled] [input][type="date"]::-moz-placeholder,
.zs-field.zs-range-calendar > section > table td[disabled] [input][type="text"]::-moz-placeholder,
.zs-field.zs-range-calendar > section > table td[disabled] [input][type="password"]::-moz-placeholder,
.zs-field.zs-range-calendar > section > table td[disabled] [input][type="number"]::-moz-placeholder,
.zs-field.zs-range-calendar > section > table td[disabled] [input][type="tel"]::-moz-placeholder,
.zs-field.zs-range-calendar > section > table td[disabled] [input][type="url"]::-moz-placeholder,
.zs-field.zs-range-calendar > section > table td[disabled] [input][type="email"]::-moz-placeholder,
.zs-field.zs-range-calendar > section > table td[disabled] [input][type="search"]::-moz-placeholder,
.zs-field.zs-range-calendar > section > table td[disabled] [input][type="time"]::-moz-placeholder,
.zs-field.zs-range-calendar > section > table td[disabled] [input][type="week"]::-moz-placeholder,
.zs-field.zs-range-calendar > section > table td[disabled] [input][type="date"]::-moz-placeholder {
  /* Firefox 19+ */
  color: #B2B0B6;
}
.zs-field[disabled] [input][type="text"]:-ms-input-placeholder,
.zs-field.zs-disabled [input][type="text"]:-ms-input-placeholder,
.zs-field[disabled] [input][type="password"]:-ms-input-placeholder,
.zs-field.zs-disabled [input][type="password"]:-ms-input-placeholder,
.zs-field[disabled] [input][type="number"]:-ms-input-placeholder,
.zs-field.zs-disabled [input][type="number"]:-ms-input-placeholder,
.zs-field[disabled] [input][type="tel"]:-ms-input-placeholder,
.zs-field.zs-disabled [input][type="tel"]:-ms-input-placeholder,
.zs-field[disabled] [input][type="url"]:-ms-input-placeholder,
.zs-field.zs-disabled [input][type="url"]:-ms-input-placeholder,
.zs-field[disabled] [input][type="email"]:-ms-input-placeholder,
.zs-field.zs-disabled [input][type="email"]:-ms-input-placeholder,
.zs-field[disabled] [input][type="search"]:-ms-input-placeholder,
.zs-field.zs-disabled [input][type="search"]:-ms-input-placeholder,
.zs-field[disabled] [input][type="time"]:-ms-input-placeholder,
.zs-field.zs-disabled [input][type="time"]:-ms-input-placeholder,
.zs-field[disabled] [input][type="week"]:-ms-input-placeholder,
.zs-field.zs-disabled [input][type="week"]:-ms-input-placeholder,
.zs-field[disabled] [input][type="date"]:-ms-input-placeholder,
.zs-field.zs-disabled [input][type="date"]:-ms-input-placeholder,
.zs-field.zs-calendar > table td[disabled] [input][type="text"]:-ms-input-placeholder,
.zs-field.zs-calendar > table td[disabled] [input][type="password"]:-ms-input-placeholder,
.zs-field.zs-calendar > table td[disabled] [input][type="number"]:-ms-input-placeholder,
.zs-field.zs-calendar > table td[disabled] [input][type="tel"]:-ms-input-placeholder,
.zs-field.zs-calendar > table td[disabled] [input][type="url"]:-ms-input-placeholder,
.zs-field.zs-calendar > table td[disabled] [input][type="email"]:-ms-input-placeholder,
.zs-field.zs-calendar > table td[disabled] [input][type="search"]:-ms-input-placeholder,
.zs-field.zs-calendar > table td[disabled] [input][type="time"]:-ms-input-placeholder,
.zs-field.zs-calendar > table td[disabled] [input][type="week"]:-ms-input-placeholder,
.zs-field.zs-calendar > table td[disabled] [input][type="date"]:-ms-input-placeholder,
.zs-field.zs-range-calendar > table td[disabled] [input][type="text"]:-ms-input-placeholder,
.zs-field.zs-range-calendar > table td[disabled] [input][type="password"]:-ms-input-placeholder,
.zs-field.zs-range-calendar > table td[disabled] [input][type="number"]:-ms-input-placeholder,
.zs-field.zs-range-calendar > table td[disabled] [input][type="tel"]:-ms-input-placeholder,
.zs-field.zs-range-calendar > table td[disabled] [input][type="url"]:-ms-input-placeholder,
.zs-field.zs-range-calendar > table td[disabled] [input][type="email"]:-ms-input-placeholder,
.zs-field.zs-range-calendar > table td[disabled] [input][type="search"]:-ms-input-placeholder,
.zs-field.zs-range-calendar > table td[disabled] [input][type="time"]:-ms-input-placeholder,
.zs-field.zs-range-calendar > table td[disabled] [input][type="week"]:-ms-input-placeholder,
.zs-field.zs-range-calendar > table td[disabled] [input][type="date"]:-ms-input-placeholder,
.zs-field.zs-range-calendar > section > table td[disabled] [input][type="text"]:-ms-input-placeholder,
.zs-field.zs-range-calendar > section > table td[disabled] [input][type="password"]:-ms-input-placeholder,
.zs-field.zs-range-calendar > section > table td[disabled] [input][type="number"]:-ms-input-placeholder,
.zs-field.zs-range-calendar > section > table td[disabled] [input][type="tel"]:-ms-input-placeholder,
.zs-field.zs-range-calendar > section > table td[disabled] [input][type="url"]:-ms-input-placeholder,
.zs-field.zs-range-calendar > section > table td[disabled] [input][type="email"]:-ms-input-placeholder,
.zs-field.zs-range-calendar > section > table td[disabled] [input][type="search"]:-ms-input-placeholder,
.zs-field.zs-range-calendar > section > table td[disabled] [input][type="time"]:-ms-input-placeholder,
.zs-field.zs-range-calendar > section > table td[disabled] [input][type="week"]:-ms-input-placeholder,
.zs-field.zs-range-calendar > section > table td[disabled] [input][type="date"]:-ms-input-placeholder {
  color: #B2B0B6;
}
.zs-field[disabled] textarea,
.zs-field.zs-disabled textarea,
.zs-field.zs-calendar > table td[disabled] textarea,
.zs-field.zs-range-calendar > table td[disabled] textarea,
.zs-field.zs-range-calendar > section > table td[disabled] textarea {
  border: 1px solid #B2B0B6;
  color: #B2B0B6;
  background: none;
  background-color: #F4F3F3;
}
.zs-field[disabled] textarea::-webkit-input-placeholder,
.zs-field.zs-disabled textarea::-webkit-input-placeholder,
.zs-field.zs-calendar > table td[disabled] textarea::-webkit-input-placeholder,
.zs-field.zs-range-calendar > table td[disabled] textarea::-webkit-input-placeholder,
.zs-field.zs-range-calendar > section > table td[disabled] textarea::-webkit-input-placeholder {
  color: #B2B0B6;
}
.zs-field[disabled] textarea:-moz-placeholder,
.zs-field.zs-disabled textarea:-moz-placeholder,
.zs-field.zs-calendar > table td[disabled] textarea:-moz-placeholder,
.zs-field.zs-range-calendar > table td[disabled] textarea:-moz-placeholder,
.zs-field.zs-range-calendar > section > table td[disabled] textarea:-moz-placeholder {
  /* Firefox 18- */
  color: #B2B0B6;
}
.zs-field[disabled] textarea::-moz-placeholder,
.zs-field.zs-disabled textarea::-moz-placeholder,
.zs-field.zs-calendar > table td[disabled] textarea::-moz-placeholder,
.zs-field.zs-range-calendar > table td[disabled] textarea::-moz-placeholder,
.zs-field.zs-range-calendar > section > table td[disabled] textarea::-moz-placeholder {
  /* Firefox 19+ */
  color: #B2B0B6;
}
.zs-field[disabled] textarea:-ms-input-placeholder,
.zs-field.zs-disabled textarea:-ms-input-placeholder,
.zs-field.zs-calendar > table td[disabled] textarea:-ms-input-placeholder,
.zs-field.zs-range-calendar > table td[disabled] textarea:-ms-input-placeholder,
.zs-field.zs-range-calendar > section > table td[disabled] textarea:-ms-input-placeholder {
  color: #B2B0B6;
}
.zs-field[disabled] [clear],
.zs-field.zs-disabled [clear],
.zs-field[disabled] [field].zs-input-icon::before,
.zs-field.zs-disabled [field].zs-input-icon::before,
.zs-field.zs-calendar > table td[disabled] [clear],
.zs-field.zs-calendar > table td[disabled] [field].zs-input-icon::before,
.zs-field.zs-range-calendar > table td[disabled] [clear],
.zs-field.zs-range-calendar > table td[disabled] [field].zs-input-icon::before,
.zs-field.zs-range-calendar > section > table td[disabled] [clear],
.zs-field.zs-range-calendar > section > table td[disabled] [field].zs-input-icon::before {
  color: #B2B0B6;
}
.zs-field[type="checkbox"] > label,
.zs-field[type="radio"] > label {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  align-items: center;
  font-weight: normal;
  cursor: pointer;
}
.zs-field[type="checkbox"] > label [label],
.zs-field[type="radio"] > label [label] {
  font-weight: normal;
}
.zs-field[type="checkbox"][disabled] [input],
.zs-field[type="radio"][disabled] [input],
.zs-field[type="checkbox"].zs-disabled [input],
.zs-field[type="radio"].zs-disabled [input],
.zs-field[type="checkbox"].zs-calendar > table td[disabled] [input],
.zs-field[type="radio"].zs-calendar > table td[disabled] [input],
.zs-field[type="checkbox"].zs-range-calendar > table td[disabled] [input],
.zs-field[type="radio"].zs-range-calendar > table td[disabled] [input],
.zs-field[type="checkbox"].zs-range-calendar > section > table td[disabled] [input],
.zs-field[type="radio"].zs-range-calendar > section > table td[disabled] [input] {
  opacity: 0 !important;
}
.zs-field[type="checkbox"][disabled] [label],
.zs-field[type="radio"][disabled] [label],
.zs-field[type="checkbox"].zs-disabled [label],
.zs-field[type="radio"].zs-disabled [label],
.zs-field[type="checkbox"].zs-calendar > table td[disabled] [label],
.zs-field[type="radio"].zs-calendar > table td[disabled] [label],
.zs-field[type="checkbox"].zs-range-calendar > table td[disabled] [label],
.zs-field[type="radio"].zs-range-calendar > table td[disabled] [label],
.zs-field[type="checkbox"].zs-range-calendar > section > table td[disabled] [label],
.zs-field[type="radio"].zs-range-calendar > section > table td[disabled] [label] {
  color: #B2B0B6;
}
.zs-field[type="checkbox"] .zs-input-icon::before,
.zs-field[type="radio"] .zs-input-icon::before {
  display: none;
}
.zs-field[type="checkbox"] [clear],
.zs-field[type="radio"] [clear] {
  display: none;
}
.zs-field[type="checkbox"] [input],
.zs-field[type="radio"] [input] {
  width: auto;
}
.zs-field[type="color"] [input],
.zs-field[type="image"] [input] {
  padding: 0;
  border: 0;
}
.zs-field[clear][icon]:not([value=""]) [field]::before {
  display: none;
}
.zs-field[clear][icon]:not([value]) [field]::before {
  display: inline-block;
}
.zs-field-fluid,
.zs-master-style p.zs-field-fluid {
  margin: 0;
}
.zs-field-fluid:not([type="checkbox"]):not([type="radio"]) > label,
.zs-master-style p.zs-field-fluid:not([type="checkbox"]):not([type="radio"]) > label {
  display: block;
}
.zs-tabs {
  *zoom: 1;
  display: block !important;
}
.zs-tabs:before,
.zs-tabs:after {
  content: "";
  display: table;
}
.zs-tabs:after {
  clear: both;
}
.zs-tabs .zs-tab {
  color: #716E79;
  padding: 0.5em 1em 0.5em 1em;
  box-shadow: none;
  border: 1px solid #DEDCDE;
  border-bottom: 0;
  border-right: 0;
  border-radius: 0;
  margin: 0;
  display: inline-flex;
  align-items: center;
  vertical-align: top;
  float: left;
  position: relative;
  cursor: pointer;
  background: #FAFAFA;
}
.zs-tabs .zs-tab:last-of-type {
  border-right: 1px solid #DEDCDE;
}
.zs-tabs .zs-tab:hover {
  color: #1A1628;
  background: #F4F3F3;
}
.zs-tabs .zs-tab:active {
  color: #2F2C3C;
}
.zs-tabs .zs-tab.zs-active,
.zs-tabs .zs-tab[active] {
  color: #27A6A4;
  background: #ffffff;
  box-shadow: none;
  border-bottom-color: #ffffff;
}
.zs-tabs .zs-tab.zs-active .zs-tab-title-text,
.zs-tabs .zs-tab[active] .zs-tab-title-text {
  color: #27A6A4;
  font-weight: 600;
}
.zs-tabs .zs-tab.zs-active:hover,
.zs-tabs .zs-tab[active]:hover {
  color: #27A6A4;
}
.zs-tabs .zs-tab.zs-active:after,
.zs-tabs .zs-tab[active]:after {
  margin-top: -1px;
  left: 0px;
  top: 100%;
  content: '';
  right: 0px;
  height: 2px;
  background: #ffffff;
  display: inline-block;
  position: absolute;
  z-index: 1;
}
.zs-tabs .zs-tab[disabled],
.zs-tabs .zs-tab.zs-disabled,
.zs-tabs .zs-tab.zs-calendar > table td[disabled],
.zs-tabs .zs-tab.zs-range-calendar > table td[disabled],
.zs-tabs .zs-tab.zs-range-calendar > section > table td[disabled] {
  color: #B2B0B6;
  background: none;
  background-color: #F4F3F3;
}
.zs-tabs .zs-tab[disabled] > .zs-icon,
.zs-tabs .zs-tab.zs-disabled > .zs-icon,
.zs-tabs .zs-tab.zs-calendar > table td[disabled] > .zs-icon,
.zs-tabs .zs-tab.zs-range-calendar > table td[disabled] > .zs-icon,
.zs-tabs .zs-tab.zs-range-calendar > section > table td[disabled] > .zs-icon {
  color: #B2B0B6;
}
.zs-tabs.zs-tabs-solid .zs-tab {
  border: 0;
  padding-left: 0;
  padding-right: 0;
  padding: 1em 0em 1em 0em;
  background: #ffffff;
  margin: 0em 1.5em 0em 0em;
}
.zs-tabs.zs-tabs-solid .zs-tab:last-of-type {
  margin: 0em 0em 0em 0em;
}
.zs-tabs.zs-tabs-solid .zs-tab:hover::after {
  height: 0.25em;
  margin-top: -0.25em;
  left: 0px;
  top: 100%;
  content: '';
  right: 0px;
  background: #62D2D1;
  display: inline-block;
  position: absolute;
  z-index: 1;
}
.zs-tabs.zs-tabs-solid .zs-tab.zs-active::after,
.zs-tabs.zs-tabs-solid .zs-tab[active]::after {
  height: 0.25em;
  background: #27A6A4;
  margin-top: -0.25em;
}
.zs-tabs.zs-tabs-solid [source-id] {
  border: none;
}
.zs-tabs.zs-tabs-vertical .zs-tab {
  color: #454250;
  display: block;
  float: none;
  padding: 0.5em 2em 0.5em 2em;
  border: 0;
  background: #F4F3F3;
}
.zs-tabs.zs-tabs-vertical .zs-tab:hover {
  background: #FAFAFA;
}
.zs-tabs.zs-tabs-vertical .zs-tab:hover .zs-tab-title-text {
  color: #1A1628;
}
.zs-tabs.zs-tabs-vertical .zs-tab:active {
  background: #F4F3F3;
}
.zs-tabs.zs-tabs-vertical .zs-tab:active .zs-tab-title-text {
  color: #2F2C3C;
}
.zs-tabs.zs-tabs-vertical .zs-tab.zs-active .zs-tab-title-text,
.zs-tabs.zs-tabs-vertical .zs-tab[active] .zs-tab-title-text {
  color: #27A6A4;
  font-weight: 600;
}
.zs-tabs.zs-tabs-vertical .zs-tab.zs-active:hover,
.zs-tabs.zs-tabs-vertical .zs-tab[active]:hover {
  background: #F4F3F3;
}
.zs-tabs.zs-tabs-vertical .zs-tab.zs-active::after,
.zs-tabs.zs-tabs-vertical .zs-tab[active]::after {
  height: auto;
  width: 0.25em;
  background: #27A6A4;
  left: auto;
  top: 0;
  bottom: 0;
  margin-top: auto;
}
.zs-tabs.zs-tabs-vertical [source-id] {
  border: none;
}
.zs-tabs [source-id] {
  box-shadow: none;
  display: none;
  clear: left;
  border: 1px solid #DEDCDE;
  background: #ffffff;
  vertical-align: top;
  border-radius: 0;
  position: relative;
  top: -1px;
}
.zs-tabs [source-id][active] {
  display: block;
}
.zs-tab > *:not(.zs-icon) {
  margin: 0em 0.5em 0em 0.5em;
}
.zs-tab > *:first-child {
  margin-left: 0;
}
.zs-tab > *:last-child {
  margin-right: 0;
}
.zs-master-style zs-tooltip,
.zs-master-style .zs-tooltip {
  background-clip: padding-box;
  -webkit-background-clip: padding-box;
  background-color: #1A1628;
  cursor: default;
  font-size: 16px;
  font-weight: normal;
  color: #ffffff;
  position: fixed;
  top: 0;
  left: 0;
  text-align: left;
  white-space: normal;
  visibility: visible;
  z-index: 1060;
  line-height: 1.5;
  padding: 0.25em 0.5em 0.25em 0.5em;
  min-width: 0.375em;
  max-width: 50vw;
  opacity: 0;
}
.zs-master-style zs-tooltip > zs-tooltip-arrow,
.zs-master-style .zs-tooltip > zs-tooltip-arrow,
.zs-master-style zs-tooltip > zs-tooltip-arrow:after,
.zs-master-style .zs-tooltip > zs-tooltip-arrow:after {
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}
.zs-master-style zs-tooltip > zs-tooltip-arrow,
.zs-master-style .zs-tooltip > zs-tooltip-arrow {
  border-width: 0.375em;
}
.zs-master-style zs-tooltip > zs-tooltip-arrow:after,
.zs-master-style .zs-tooltip > zs-tooltip-arrow:after {
  content: "";
  border-width: 0.375em;
}
.zs-master-style zs-tooltip.top > zs-tooltip-arrow,
.zs-master-style .zs-tooltip.top > zs-tooltip-arrow {
  bottom: -0.375em;
  border-top-color: #DEDCDE;
  border-bottom-width: 0;
  left: 50%;
  margin-left: -0.375em;
}
.zs-master-style zs-tooltip.top > zs-tooltip-arrow:after,
.zs-master-style .zs-tooltip.top > zs-tooltip-arrow:after {
  bottom: 1px;
  content: " ";
  border-top-color: #1A1628;
  border-bottom-width: 0;
  margin-left: -0.375em;
}
.zs-master-style zs-tooltip.right > zs-tooltip-arrow,
.zs-master-style .zs-tooltip.right > zs-tooltip-arrow {
  border-right-color: #DEDCDE;
  border-left-width: 0;
  top: 50%;
  left: -0.375em;
  margin-top: -0.375em;
}
.zs-master-style zs-tooltip.right > zs-tooltip-arrow:after,
.zs-master-style .zs-tooltip.right > zs-tooltip-arrow:after {
  border-right-color: #1A1628;
  border-left-width: 0;
  bottom: -0.375em;
  content: " ";
  left: 1px;
}
.zs-master-style zs-tooltip.bottom > zs-tooltip-arrow,
.zs-master-style .zs-tooltip.bottom > zs-tooltip-arrow {
  top: -0.375em;
  left: 50%;
  margin-left: -0.375em;
  border-top-width: 0;
  border-bottom-color: #DEDCDE;
}
.zs-master-style zs-tooltip.bottom > zs-tooltip-arrow:after,
.zs-master-style .zs-tooltip.bottom > zs-tooltip-arrow:after {
  top: 1px;
  margin-left: -0.375em;
  content: " ";
  border-top-width: 0;
  border-bottom-color: #1A1628;
}
.zs-master-style zs-tooltip.left > zs-tooltip-arrow,
.zs-master-style .zs-tooltip.left > zs-tooltip-arrow {
  top: 50%;
  right: -0.375em;
  margin-top: -0.375em;
  border-right-width: 0;
  border-left-color: #DEDCDE;
}
.zs-master-style zs-tooltip.left > zs-tooltip-arrow:after,
.zs-master-style .zs-tooltip.left > zs-tooltip-arrow:after {
  right: 1px;
  bottom: -0.375em;
  content: " ";
  border-right-width: 0;
  border-left-color: #1A1628;
}
.zs-master-style zs-tooltip,
zs-tooltip.zs-master-style {
  color: #ffffff;
  background: #1A1628;
}
.zs-master-style zs-tooltip.left > zs-tooltip-arrow:after,
zs-tooltip.zs-master-style.left > zs-tooltip-arrow:after {
  border-left-color: #1A1628;
}
.zs-master-style zs-tooltip.bottom > zs-tooltip-arrow:after,
zs-tooltip.zs-master-style.bottom > zs-tooltip-arrow:after {
  border-bottom-color: #1A1628;
}
.zs-master-style zs-tooltip.top > zs-tooltip-arrow:after,
zs-tooltip.zs-master-style.top > zs-tooltip-arrow:after {
  border-top-color: #1A1628;
}
.zs-master-style zs-tooltip.right > zs-tooltip-arrow:after,
zs-tooltip.zs-master-style.right > zs-tooltip-arrow:after {
  border-right-color: #1A1628;
}
.zs-tag {
  background-color: #d6ebf2;
  border-radius: 1.5em;
  padding: 0.5em 1em 0.5em 1em;
  white-space: nowrap;
  display: inline-block;
  border: 1px solid transparent;
  margin-right: 0.5em;
  margin-bottom: 0.5em;
  cursor: pointer;
  position: relative;
  color: #2D8B93;
  border: 1px solid 'transparent';
  background: #d6ebf2;
}
.zs-tag > span {
  line-height: 1.5;
  vertical-align: middle;
}
.zs-tag[dismissable] {
  padding: 0.5em 1em 0.5em 1em;
  position: relative;
}
.zs-tag[dismissable] [close] {
  margin: 0em 0em 0em 0.25em;
  padding: 0;
  right: 0;
  top: 0;
  position: static;
  line-height: 1;
  vertical-align: middle;
  display: inline-flex;
}
.zs-tag[dismissable] [close]:focus {
  outline: 2px solid #27A6A4;
}
.zs-tag[nonclickable] {
  cursor: default;
  pointer-events: none;
  background-color: #d6ebf2;
  border-color: transparent;
  color: #2D8B93;
}
.zs-tag[nonclickable]:focus::before {
  content: '';
}
.zs-tag[nonclickable] [close] {
  pointer-events: auto;
}
.zs-tag:hover,
.zs-tag:focus {
  outline: none;
}
.zs-tag:focus::before {
  content: ' ';
  position: absolute;
  border: 2px solid #27A6A4;
  left: calc(-1 * (0.5 * 0.5em + 1px));
  top: calc(-1 * (0.5 * 0.5em + 1px));
  right: calc(-1 * (0.5 * 0.5em + 1px));
  bottom: calc(-1 * (0.5 * 0.5em + 1px));
  border-radius: 1.5em;
  z-index: -1;
}
.zs-tag.zs-highlight {
  background-color: #FFD680;
  color: inherit;
}
.zs-tag .zs-interactive-primary {
  color: #2D8B93;
}
.zs-tag .zs-interactive-primary:visited {
  color: #2D8B93;
}
.zs-tag .zs-interactive-primary:focus {
  color: #0524A4;
}
.zs-tag .zs-interactive-primary:hover {
  color: #7CC0FF;
}
.zs-tag .zs-interactive-primary:active {
  color: #000077;
}
.zs-tag:visited {
  color: #2D8B93;
}
.zs-tag:focus {
  color: #0524A4;
}
.zs-tag:hover {
  color: #2D8B93;
}
.zs-tag:active {
  color: #000077;
}
.zs-tag:focus {
  border: 1px solid #2D8B93;
}
.zs-tag:hover {
  border: 1px solid #2D8B93;
}
.zs-tag:active {
  border: 1px solid #000077;
}
.zs-tag:focus {
  background: #d6ebf2;
}
.zs-tag:hover {
  background: #d6ebf2;
}
.zs-tag:active {
  background: #d6ebf2;
}
.zs-tag[active] {
  color: #000077;
  border: 1px solid #000077;
}
.zs-tag.zs-danger {
  color: #B21111;
  border: 1px solid 'transparent';
  background: #FFEDE9;
}
.zs-tag.zs-danger:visited {
  color: #B21111;
}
.zs-tag.zs-danger:focus {
  color: #892208;
}
.zs-tag.zs-danger:hover {
  color: #F27755;
}
.zs-tag.zs-danger:active {
  color: #5C1A0B;
}
.zs-tag.zs-danger:focus {
  border: 1px solid #892208;
}
.zs-tag.zs-danger:hover {
  border: 1px solid #F27755;
}
.zs-tag.zs-danger:active {
  border: 1px solid #5C1A0B;
}
.zs-tag.zs-danger:focus {
  background: #FFEDE9;
}
.zs-tag.zs-danger:hover {
  background: #FFEDE9;
}
.zs-tag.zs-danger:active {
  background: #FFEDE9;
}
.zs-tag.zs-danger[active] {
  color: #5C1A0B;
  border: 1px solid #5C1A0B;
}
.zs-tag.zs-success {
  color: #00AA67;
  border: 1px solid 'transparent';
  background: #D2FAF7;
}
.zs-tag.zs-success:visited {
  color: #00AA67;
}
.zs-tag.zs-success:focus {
  color: #0A6E5E;
}
.zs-tag.zs-success:hover {
  color: #54D7AF;
}
.zs-tag.zs-success:active {
  color: #0C4846;
}
.zs-tag.zs-success:focus {
  border: 1px solid #0A6E5E;
}
.zs-tag.zs-success:hover {
  border: 1px solid #54D7AF;
}
.zs-tag.zs-success:active {
  border: 1px solid #0C4846;
}
.zs-tag.zs-success:focus {
  background: #D2FAF7;
}
.zs-tag.zs-success:hover {
  background: #D2FAF7;
}
.zs-tag.zs-success:active {
  background: #D2FAF7;
}
.zs-tag.zs-success[active] {
  color: #0C4846;
  border: 1px solid #0C4846;
}
.zs-tag.zs-warning {
  color: #E3A900;
  border: 1px solid 'transparent';
  background: #FFF9F1;
}
.zs-tag.zs-warning:visited {
  color: #E3A900;
}
.zs-tag.zs-warning:focus {
  color: #8A640C;
}
.zs-tag.zs-warning:hover {
  color: #FDCA63;
}
.zs-tag.zs-warning:active {
  color: #563F0E;
}
.zs-tag.zs-warning:focus {
  border: 1px solid #8A640C;
}
.zs-tag.zs-warning:hover {
  border: 1px solid #FDCA63;
}
.zs-tag.zs-warning:active {
  border: 1px solid #563F0E;
}
.zs-tag.zs-warning:focus {
  background: #FFF9F1;
}
.zs-tag.zs-warning:hover {
  background: #FFF9F1;
}
.zs-tag.zs-warning:active {
  background: #FFF9F1;
}
.zs-tag.zs-warning[active] {
  color: #563F0E;
  border: 1px solid #563F0E;
}
.zs-tag.zs-info {
  color: #2B44C7;
  border: 1px solid 'transparent';
  background: #EEEEFF;
}
.zs-tag.zs-info:visited {
  color: #2B44C7;
}
.zs-tag.zs-info:focus {
  color: #141187;
}
.zs-tag.zs-info:hover {
  color: #7E92E9;
}
.zs-tag.zs-info:active {
  color: #160C60;
}
.zs-tag.zs-info:focus {
  border: 1px solid #141187;
}
.zs-tag.zs-info:hover {
  border: 1px solid #7E92E9;
}
.zs-tag.zs-info:active {
  border: 1px solid #160C60;
}
.zs-tag.zs-info:focus {
  background: #EEEEFF;
}
.zs-tag.zs-info:hover {
  background: #EEEEFF;
}
.zs-tag.zs-info:active {
  background: #EEEEFF;
}
.zs-tag.zs-info[active] {
  color: #160C60;
  border: 1px solid #160C60;
}
.zs-tag.zs-disabled,
.zs-tag [disabled],
.zs-tag.zs-calendar > table td[disabled],
.zs-tag.zs-range-calendar > table td[disabled],
.zs-tag.zs-range-calendar > section > table td[disabled] {
  color: #B2B0B6;
  background: none;
  background-color: #F4F3F3;
}
.zs-tag.zs-disabled:focus::before,
.zs-tag [disabled]:focus::before,
.zs-tag.zs-calendar > table td[disabled]:focus::before,
.zs-tag.zs-range-calendar > table td[disabled]:focus::before,
.zs-tag.zs-range-calendar > section > table td[disabled]:focus::before {
  content: '';
  border: none;
}
.zs-tag.zs-disabled[dismissable] [close]:focus,
.zs-tag [disabled][dismissable] [close]:focus,
.zs-tag.zs-calendar > table td[disabled][dismissable] [close]:focus,
.zs-tag.zs-range-calendar > table td[disabled][dismissable] [close]:focus,
.zs-tag.zs-range-calendar > section > table td[disabled][dismissable] [close]:focus {
  outline: none;
}
zs-dialog.zs-dialog.modal[open] {
  transform: translate(-50%, -50%);
}
.zs-range-calendar {
  min-width: 33.5em;
}
.zs-range-calendar > footer [clear-range] {
  display: none;
}
.zs-range-calendar > header span:after {
  display: none;
}
.zs-range-calendar > header [role="prev"] ~ span {
  margin: 0em 0.25em 0em 0.25em;
}
.zs-action-field [label] {
  font-size: 0.9em;
}
* {
  box-sizing: border-box;
}
html {
  height: 100%;
}
html body {
  font-family: "Roboto", sans-serif;
  margin: 0;
  height: 100%;
  overflow: hidden;
}
.zs-body {
  font-size: 0.813em;
  color: black;
}
.lds-default {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-default div {
  position: absolute;
  width: 6px;
  height: 6px;
  background: #fff;
  border-radius: 50%;
  animation: lds-default 1.2s linear infinite;
}
.lds-default div:nth-child(1) {
  animation-delay: 0s;
  top: 37px;
  left: 66px;
}
.lds-default div:nth-child(2) {
  animation-delay: -0.1s;
  top: 22px;
  left: 62px;
}
.lds-default div:nth-child(3) {
  animation-delay: -0.2s;
  top: 11px;
  left: 52px;
}
.lds-default div:nth-child(4) {
  animation-delay: -0.3s;
  top: 7px;
  left: 37px;
}
.lds-default div:nth-child(5) {
  animation-delay: -0.4s;
  top: 11px;
  left: 22px;
}
.lds-default div:nth-child(6) {
  animation-delay: -0.5s;
  top: 22px;
  left: 11px;
}
.lds-default div:nth-child(7) {
  animation-delay: -0.6s;
  top: 37px;
  left: 7px;
}
.lds-default div:nth-child(8) {
  animation-delay: -0.7s;
  top: 52px;
  left: 11px;
}
.lds-default div:nth-child(9) {
  animation-delay: -0.8s;
  top: 62px;
  left: 22px;
}
.lds-default div:nth-child(10) {
  animation-delay: -0.9s;
  top: 66px;
  left: 37px;
}
.lds-default div:nth-child(11) {
  animation-delay: -1s;
  top: 62px;
  left: 52px;
}
.lds-default div:nth-child(12) {
  animation-delay: -1.1s;
  top: 52px;
  left: 62px;
}
@keyframes lds-default {
  0%,
  20%,
  80%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
}
.zs-ordered-list {
  margin-left: 0.9em;
}
.zs-sub-ordered-list {
  margin-left: 1.5em;
  list-style-type: lower-alpha;
}
.cds-bg-white {
  background: #fff !important;
  padding: 1em;
}
.cds-select-blue {
  background-color: rgba(39, 166, 164, 0.1) !important;
}
.zs-select > [overlay] {
  width: 100% !important;
}
.zs-search-dropdown > nav > a {
  white-space: normal;
}
input[type='number'] {
  max-width: 4.5em;
  min-height: 2em;
  text-align: center;
  border: 1px solid #DEDCDE;
  line-height: 2em;
  margin: auto 0.5em;
}
input[type='text'] {
  height: 2.05em;
  padding: 0.75em;
  border: 1px solid #DEDCDE;
  line-height: 2em;
  margin: auto auto;
}
input[type='number']:hover,
input[type='text']:hover {
  background: #FAFAFA;
  border: 1px solid #9C9AA1;
  outline: none;
}
input[type='number']:active,
input[type='number']:focus,
input[type='text']:active,
input[type='text']:focus {
  background: #F4F3F3;
  border: 1px solid #27A6A4;
  outline: none;
}
.zs-bg-warning-secondary {
  background: #FFF9F1 !important;
  align-items: center;
  display: flex;
  margin: 1em;
  border: 0.1px solid #E3A900 !important;
}
.zs-bg-warning-secondary .zs-icon {
  margin: 0.75em 0.5em;
}
.zs-bg-success-secondary {
  background: #D2FAF7 !important;
  align-items: center;
  display: flex;
  margin: 1em;
  border: 0.1px solid #00AA67 !important;
}
.zs-bg-success-secondary .zs-icon {
  margin: 0.75em 0.5em;
}
.zs-master-style zs-tooltip,
.zs-master-style .zs-tooltip {
  font-size: 13px;
}
.cds-disabled {
  background: none;
  background-color: #F4F3F3 !important;
  z-index: 1;
  opacity: 0.4;
  cursor: not-allowed;
  pointer-events: none;
}
input[type='checkbox'],
input[type='radio'] {
  margin: auto 0.5em;
  cursor: pointer;
}
.zs-custom-scrollbar ::-webkit-scrollbar-track,
.zs-custom-scrollbar::-webkit-scrollbar-track {
  background: none;
}
.cds-sticky-table-header {
  background: #fff !important;
  position: sticky;
  top: 0;
  z-index: 1;
  box-shadow: 0px -1px 0px 0px #DEDCDE, 0px 0.5px 0px 0px #716E79;
}
.cds-resizable-table-header {
  resize: horizontal;
  overflow: auto;
  overflow-x: hidden;
}
.cds-sticky-table-column-first thead th:first-child {
  left: 0;
  z-index: 3;
  position: sticky;
  background: #fff;
}
.cds-sticky-table-column-first tbody td:first-child {
  left: 0;
  z-index: 2;
  position: sticky;
  background: #fff;
}
.cds-cell-highlight {
  background: #FFE0C2 !important;
}
.zs-range-calendar {
  z-index: 200000;
}
.cds-icon-reset {
  background: url('assets/img/restart-line.svg');
  width: 1.3em;
  height: 1.3em;
  background-repeat: no-repeat;
}
.cds-icon-forecast {
  background: url('assets/img/line-chart-fill.svg');
  width: 1.3em;
  height: 1.3em;
  background-repeat: no-repeat;
}
.zs-master-style a,
.zs-icon {
  cursor: pointer;
}
.zs-calendar > header span > select[name] {
  border-color: transparent;
}
.zs-tabs .zs-tab[active] {
  cursor: not-allowed;
  pointer-events: none;
}
.zs-table {
  border-collapse: separate;
  margin: 0;
}
.cds-border-right {
  box-shadow: inset -1px 0 0 0 #dedcde, 0px -1px 0px 0px #dedcde, 0px 0.5px 0px 0px #716e7b;
}
.cds-sticky-cell {
  position: sticky;
  background: #fff !important;
}
.cds-sticky-rightmost {
  right: 0;
  box-shadow: inset 1px 0 0 0 #dedcde, 0px -1px 0px 0px #dedcde, 0px 0.5px 0px 0px #716e7b;
}
.product-col--min-width {
  min-width: 9.5rem;
}
.cds-encapsulation-border {
  border: 2px solid #e1e9ef;
  padding: 0.5em;
}
.cds-tooltip-custom {
  position: absolute;
  font-size: 13px;
  color: #ffffff;
  background: #1A1628;
  padding: 0.25em 0.5em 0.25em 0.5em;
  z-index: 1060;
  border-radius: 0.2em;
  transition: none 0s ease 0s;
  line-height: 1.5;
  text-align: justify;
  margin-left: 0.6em;
}
.zs-table {
  color: black;
}
.zs-body {
  color: black;
}
.zs-master-style .zs-link-action {
  color: black;
}
.zs-button {
  color: black;
}
.zs-button.zs-button-action {
  color: #fff;
}
.zs-button.zs-button-solid {
  color: #fff;
}
.zs-button.zs-disabled,
.zs-button[disabled],
.zs-button.zs-calendar > table td[disabled],
.zs-button.zs-range-calendar > table td[disabled],
.zs-button.zs-range-calendar > section > table td[disabled] {
  color: grey;
}
.zs-master-style {
  color: black;
}
.cds-highlight--grey {
  background-color: #F2F2F2;
}
.cds-highlight--teal-dark {
  background-color: #9CD9D3;
}
.table-container > .zs-table.zs-table-condense-percapita tbody td .zs-select {
  width: 10em;
  min-width: 10rem;
}
.table-container > .zs-table.zs-table-condense-percapita tbody td .zs-select > [overlay] {
  width: 10rem !important;
}
.table-container > .zs-table.zs-table-condense-percapita tbody td .zs-action-field [input] {
  width: 10rem;
}
.zs-table.zs-table-condense-percapita tr > td,
.zs-table.zs-table-condense-percapita tr > th {
  padding: 0.2em;
  padding-bottom: 0;
}
